import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { env } from '../../App/config/env';
import { languages } from '../../consts';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { LanguageCode } from '../../graphql/globalTypes';

const DEFAULT_LANGUAGE_CODE = 'en';

let _initialised = false;

export function getI18n() {
  if (!_initialised) {
    throw new Error('call initI18n first');
  }

  return i18n;
}

export function setLanguage(language: LanguageCode): Promise<any> {
  const languageCode = languages[language]?.code || DEFAULT_LANGUAGE_CODE;

  if (!_initialised) {
    return initI18n(languageCode);
  } else {
    return i18n.changeLanguage(languageCode);
  }
}

export async function initI18n(language: string): Promise<any> {
  if (_initialised) {
    return i18n;
  }

  return i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      backend: {
        // // custom request fn
        // request: async (
        //   options: Object,
        //   url: string,
        //   payload: Object,
        //   callback: Function
        // ) => {
        //   try {
        //     const translation = await fetch(url);
        //     const data = await translation.json();
        //     callback(null, { status: 200, data });
        //   } catch (e) {
        //     callback(e);
        //   }
        // },
      },

      lng: language,
      debug: !env.IS_RTM,

      parseMissingKeyHandler: (key) => {
        captureInSentry(`react-i18next key "${key}" not found.`);
        return key;
      },

      fallbackLng: {
        'it-IL': ['it'],
        'en-US': ['en'],
        default: ['en'],
      },

      react: { useSuspense: false },

      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })
    .then(() => {
      _initialised = true;
    });
}
