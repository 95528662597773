import React from 'react';
import AppContext from '../context/appContext';

export const useApp = () => {
  const context = React.useContext(AppContext);

  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider');
  }

  return context;
};
