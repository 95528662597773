export enum ModalType {
  // eslint-disable-next-line no-unused-vars
  NONE = 'NONE',

  // eslint-disable-next-line no-unused-vars
  ADD_PLACE_TO_PLAN_MODAL = 'ADD_PLACE_TO_PLAN_MODAL',
  // eslint-disable-next-line no-unused-vars
  ADD_TOUR_TO_PLAN_MODAL = 'ADD_TOUR_TO_PLAN_MODAL',

  // eslint-disable-next-line no-unused-vars
  ACTIVATE_PRODUCT_MODAL = 'ACTIVATE_PRODUCT_MODAL',

  // eslint-disable-next-line no-unused-vars
  SESSION_EXPIRED_MODAL = 'SESSION_EXPIRED_MODAL',

  // eslint-disable-next-line no-unused-vars
  AUTH_LOGIN = 'AUTH_LOGIN',
  // eslint-disable-next-line no-unused-vars
  AUTH_SIGNUP = 'AUTH_SIGNUP',
  // eslint-disable-next-line no-unused-vars
  AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
  // eslint-disable-next-line no-unused-vars
  AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS',
  // eslint-disable-next-line no-unused-vars
  AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS',
  // eslint-disable-next-line no-unused-vars
  CONVERT_GUEST_ACCOUNT = 'CONVERT_GUEST_ACCOUNT',

  // In tour
  // eslint-disable-next-line no-unused-vars
  BAD_ORIENTATION_ALERT = 'BAD_ORIENTATION_ALERT',
  // eslint-disable-next-line no-unused-vars
  TOUR_END_CTA = 'TOUR_END_CTA',
  // eslint-disable-next-line no-unused-vars
  TRIAL_TOUR_END_CTA = 'TRIAL_TOUR_END_CTA',
  // eslint-disable-next-line no-unused-vars
  TRIAL_TOUR_END_CTA_ITINERARY = 'TRIAL_TOUR_END_CTA_ITINERARY',
  // eslint-disable-next-line no-unused-vars
  TOUR_EXIT_CONFIRMATION = 'TOUR_EXIT_CONFIRMATION',

  // Purchase
  // eslint-disable-next-line no-unused-vars
  PURCHASE_SUCCESS = 'PURCHASE_SUCCESS',
  // eslint-disable-next-line no-unused-vars
  PURCHASE_FAILURE = 'PURCHASE_FAILURE',

  // Help
  // eslint-disable-next-line no-unused-vars
  GIFT_PURCHASE_MEANING = 'GIFT_PURCHASE_MEANING',

  // eslint-disable-next-line no-unused-vars
  ITINERARY = 'ITINERARY',

  // eslint-disable-next-line no-unused-vars
  SINGLE_IMAGE_VIEWER = 'SINGLE_IMAGE_VIEWER',
  // eslint-disable-next-line no-unused-vars
  MULTI_IMAGE_VIEWER = 'MULTI_IMAGE_VIEWER',

  // Business application messages
  // eslint-disable-next-line no-unused-vars
  BUSINESS_APPLICATION_SUCCESS_MESSAGE = 'BUSINESS_APPLICATION_SUCCESS_MESSAGE',

  // Error view
  // eslint-disable-next-line no-unused-vars
  ERROR = 'ERROR',

  // eslint-disable-next-line no-unused-vars
  EXPLAINER_VIDEO = 'EXPLAINER_VIDEO',

  // eslint-disable-next-line no-unused-vars
  WHE_PROMO_CODE = 'WHE_PROMO_CODE',
}

// TODO: strict typing using unions
// https://www.typescriptlang.org/docs/handbook/advanced-types.html
// modal state is not persisted. it is filtered out from transforms.ts
export type ModalState = {
  modalType: ModalType;
  modalData: any;
};

export const initialState: ModalState = {
  modalType: ModalType.NONE,
  modalData: null,
};
