import React from 'react';
import styled from '../style/styled';
import * as Sentry from '@sentry/react';
import ErrorIcon from 'mdi-react/ErrorIcon';
import { env } from '../App/config/env';
import { Trans } from 'react-i18next';

type Props = {
  error?: Error | null;
};

export class ErrorView extends React.Component<Props> {
  componentDidMount() {
    const { error } = this.props;

    if (!error) {
      return;
    }

    Sentry.captureException(error);

    if (env.isDevelopment) {
      console.error(error);
    }
  }

  render() {
    return (
      <ErrorViewContainer>
        <ErrorIcon size={64} />
        <h2>
          <Trans>An unknown error occurred!</Trans>
        </h2>
        <p>
          <Trans>Our engineers have been notified about it.</Trans>
        </p>

        <h3>
          <Trans>Recovery steps</Trans>
        </h3>
        <div>
          <ul>
            <li>
              <Trans>Refresh the page.</Trans>
            </li>
            <li>
              <Trans>Sign out and sign back in.</Trans>
            </li>
            <li>
              <Trans>Clear browser cache, and try again.</Trans>
            </li>
          </ul>
        </div>
      </ErrorViewContainer>
    );
  }
}

const ErrorViewContainer = styled.div`
  padding: 16px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 4px;
  background: #fff;

  > * {
    margin-bottom: 20px;
  }
`;
