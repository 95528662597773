import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/app/selectors';
import { ROUTES } from '../routes';
import { RedirectWithParams } from './RedirectWithParams';

interface Props extends RouteProps {
  component: any;
}

export const UnauthedRoute: React.FC<Props> = ({
  component: Component,
  ...restProps
}) => {
  const isAuthed = useSelector(selectIsAuthenticated);

  if (!isAuthed) {
    return <Route {...restProps} component={Component} />;
  }

  return <RedirectWithParams to={ROUTES.index} {...restProps} />;
};
