import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import styled from '../../../style/styled';
import { LanguageCode } from '../../../graphql/globalTypes';
import { setLanguage } from '../../../store/app/actions';
import {
  selectLanguage,
  selectWhitelabelAppSettings,
} from '../../../store/app/selectors';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from '../styledComponents';
import { HEADER_BUTTON_SIZE } from '../../../style/SIZES';
import { DEFAULT_LANGUAGE } from '../../../consts';
import {
  captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import { LanguageSelectorMenuItem } from './LanguageSelectorMenuItem';
import { env } from '../../../App/config/env';
import { getSupportedLanguages } from './getSupportedLanguages';
import { getRTL } from '../../../features/i18n/getRTL';

export const LanguageSelector = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const whitelabelAppSettings = useSelector(selectWhitelabelAppSettings);

  const languagesSupported = env.IS_STOCK
    ? env.ENABLE_I18N
    : whitelabelAppSettings.header.showLanguages;

  const supportedLnaguages = getSupportedLanguages(
    whitelabelAppSettings.features.supportedLanguageCodes ||
      whitelabelAppSettings.features.supportedLanguages
  );

  const selectedLanguageEntry = supportedLnaguages.find(
    (i) => i.value === language
  );

  // If the language in state does not match a supported language, reset to default language code.
  // This will also reset the language when switching between whitelabel apps, if an unsupported language was found.
  useEffect(() => {
    if (!selectedLanguageEntry || !languagesSupported) {
      dispatch(setLanguage(DEFAULT_LANGUAGE));
    }
  }, [selectedLanguageEntry, languagesSupported, dispatch]);

  if (!selectedLanguageEntry || !languagesSupported) {
    return null;
  }

  const menuItems = supportedLnaguages.filter(
    (i) => i.value !== selectedLanguageEntry.value
  );

  // If there aren't any menu items to display, the app only supports one language.
  // We don't need to show this component at all.
  if (menuItems.length === 0) {
    return null;
  }

  const toggleMenuVisibility = () => {
    captureButtonPress({
      buttonName: 'Language',
      page: window.location.pathname,
    });

    setShowMenu(!showMenu);
  };

  const changeLanguage = (value: LanguageCode) => {
    captureEvent({
      name: EventNames.LANGUAGE_CHANGED,
      data: [
        {
          key: DataKeys.LANGUAGE,
          value,
        },
      ],
    });

    dispatch(setLanguage(value));
    setShowMenu(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setShowMenu(false)}>
      <Container>
        <AppHeaderButtonContainer>
          <FlagWithDropDownIconContainer onClick={toggleMenuVisibility}>
            <AppHeaderButtonText
              color="#fff"
              size={HEADER_BUTTON_SIZE}
              rtl={getRTL(language)}
            >
              {/* <img
                src={selectedLanguageEntry.uri || ''}
                alt=""
                width="24px"
                height="18px"
              /> */}
              {selectedLanguageEntry.label}
            </AppHeaderButtonText>
          </FlagWithDropDownIconContainer>
        </AppHeaderButtonContainer>

        {showMenu ? (
          <MenuContainer>
            {menuItems.map(({ label, value }) => (
              <LanguageSelectorMenuItem
                key={label}
                onClick={() => changeLanguage(value)}
                caption={label}
                // uri={uri}
              />
            ))}
          </MenuContainer>
        ) : null}
      </Container>
    </OutsideClickHandler>
  );
};

const FlagWithDropDownIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
`;

const MenuContainer = styled.div`
  position: absolute;
  background: #000;
  color: #fff;
  z-index: 2;
  right: 0;
  margin-top: 8px;
  box-shadow: 0px 0 3px 1px #00000082;
`;
