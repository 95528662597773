import styled from '../../../style/styled';

export const AppHeaderButtonText = styled.p<{
  color: string;
  size: number;
  bold?: boolean;
  rtl?: boolean;
}>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size}px;
  margin-left: ${({ rtl }) => (rtl ? 0 : '4px')};
  margin-right: ${({ rtl }) => (rtl ? '4px' : 0)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  user-select: none;
  text-transform: uppercase;
`;
