import React from 'react';
import { Howl, Howler } from 'howler';
import PauseIcon from 'mdi-react/PauseIcon';
import PlayIcon from 'mdi-react/PlayIcon';
import styled from '../../../../../style/styled';
import { COLORS } from '../../../../../style/colors';
import { Lottie } from '../../../../Lottie';
import { IconButton } from '../../../../IconButton';
import animationData from '../../../../../animations/genericLoading.json';
import { AudioState } from './types';
import { Section } from '../styledComponents/Section';
import { AudioSlider } from './AudioSlider';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AvatarContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
`;

const PlayingStatus = styled.div`
  margin-right: 16px;
`;

const AudioSliderContainer = styled.div`
  flex-grow: 1;
`;

interface IntroNarrationSectionProps {
  trackURI: string;
  guildeAvatarURI?: string | null;
  duration: number;
}

interface IntroNarrationSectionState {
  seekPos: number;
  playingState: AudioState;
  length: number;
}

export class IntroNarrationSection extends React.Component<
  IntroNarrationSectionProps,
  IntroNarrationSectionState
> {
  state = {
    seekPos: 0,
    playingState: AudioState.loading,
    length: 0,
  };

  api: Howl | null = null;
  _timerID = -1;

  componentDidMount() {
    this.api = new Howl({
      src: [this.props.trackURI],
      volume: 1,
      loop: false,
      html5: true,
    });
    this.api.on('playerror', () => {
      this.api?.off();
      this.api?.unload();
    });

    this.api.on('loaderror', () => {
      this.api?.off();
      this.api?.unload();
    });
    this.api.on('end', () => {
      this.setState({ playingState: AudioState.completed, seekPos: 0 });
    });

    this.api.on('load', () => {
      if (this.api) {
        let duration = this.api.duration();

        if (duration === Infinity) {
          duration = this.props.duration;
        }

        this.setState({
          playingState: AudioState.systemPaused,
          length: Math.floor(duration),
        });
      }
    });

    this.api.on('pause', () => {
      this.setState({ playingState: AudioState.paused });
    });

    this.api.on('play', () => {
      if (this.api) {
        this.setState({ playingState: AudioState.playing });

        clearTimeout(this._timerID);

        this._timerID = window.setTimeout(() => {
          this.step();
        }, 500);
      }
    });
  }

  componentWillUnmount() {
    if (this.api) {
      this.api.off();
      this.api.unload();
    }

    clearTimeout(this._timerID);
  }

  step = () => {
    if (!this.api) {
      return;
    }

    const seekPos = this.api.seek();

    if (typeof seekPos === 'number') {
      this.setState({ seekPos });
    }

    const isPlaying = this.api.playing();

    if (isPlaying) {
      // continue stepping
      requestAnimationFrame(this.step);
    }
  };

  pause = () => {
    if (this.api) {
      this.api.pause();
    }
  };

  play = () => {
    if (this.api) {
      if (Howler.ctx) {
        Howler.ctx.resume();
      }

      const isPlaying = this.api.playing();

      if (!isPlaying) {
        this.api.play();
      }
    }
  };

  seek = (value: number) => {
    if (!this.api) {
      return;
    }

    this.api.seek(value);

    this.setState({ seekPos: value });
  };

  render() {
    const { playingState, seekPos, length } = this.state;
    const { guildeAvatarURI } = this.props;

    return (
      <Section>
        <Container>
          {guildeAvatarURI && (
            <AvatarContainer>
              <img alt="" src={guildeAvatarURI} width="100%" height="100%" />
            </AvatarContainer>
          )}

          <PlayingStatus>
            {playingState === AudioState.loading && (
              <Lottie
                height={42}
                width={42}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            )}

            {playingState === AudioState.playing && (
              <IconButton
                Icon={PauseIcon}
                onClick={this.pause}
                iconSize={28}
                iconBgColor={COLORS.darkTextColor}
              />
            )}

            {(playingState === AudioState.paused ||
              playingState === AudioState.systemPaused) && (
              <IconButton
                Icon={PlayIcon}
                onClick={this.play}
                iconSize={28}
                iconBgColor={COLORS.darkTextColor}
              />
            )}
          </PlayingStatus>

          <AudioSliderContainer>
            <AudioSlider
              seek={this.seek}
              seekPos={seekPos}
              length={length}
              playingState={playingState}
              pause={this.pause}
              play={this.play}
            />
          </AudioSliderContainer>
        </Container>
      </Section>
    );
  }
}
