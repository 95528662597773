import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../style/styled';
import {
  SCREEN_WIDTH_BREAK_SMALL,
  HEADER_BUTTON_SIZE,
} from '../../style/SIZES';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../Routes/routes';
import { captureButtonPress } from '../../features/analytics';
import { COLORS } from '../../style/colors';
import { useSelector } from 'react-redux';
import { selectWhitelabelAppSettings } from '../../store/app/selectors';

interface Props {
  size?: number;
  color?: string;
}

export const AppLogoAndText: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const whitelabelAppSettings = useSelector(selectWhitelabelAppSettings);

  const size = props.size || HEADER_BUTTON_SIZE;
  const color = props.color || COLORS.lightTextColor;
  const invert = color === '#000' ? 1 : 0;
  const { url, text } = whitelabelAppSettings.logo;

  return (
    <Link to={ROUTES.index}>
      <AncientWorldLogoContainer
        onClick={() =>
          captureButtonPress({
            page: window.location.pathname,
            buttonName: 'AncientWorldLogo',
          })
        }
      >
        <AncientWorldLogoImage alt="" src={url} size={size} invert={invert} />

        {whitelabelAppSettings.header.showAppNameInHeader && (
          <AncientWorldLogText size={size} color={color}>
            {t(text)}
          </AncientWorldLogText>
        )}
      </AncientWorldLogoContainer>
    </Link>
  );
};

const AncientWorldLogoContainer = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
  user-select: none;
`;

const AncientWorldLogText = styled.p<{ size: number; color: string }>`
  color: ${(props) => props.color};
  text-transform: uppercase;
  font-size: ${(props) => props.size}px;
  margin-left: 10px;
  user-select: none;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;

const AncientWorldLogoImage = styled.img<{ size: number; invert: number }>`
  filter: invert(${(props) => props.invert});
  height: ${(props) => props.size / 0.6}px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    height: ${(props) => props.size}px;
  }
`;
