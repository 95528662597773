import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from './Asset';
import { I18NSTRING } from './I18nString';

export const TOUR_DISCOVERY_FRAGMENT = gql`
  fragment TourDiscoveryFragment on Discovery {
    id
    stopID
    discoveryType
    internalReference
    titleI18n {
      ...I18nStringFragment
    }
    subtitleI18n {
      ...I18nStringFragment
    }
    captionI18n {
      ...I18nStringFragment
    }
    descriptionI18n {
      ...I18nStringFragment
    }
    thumbnail {
      id
      name
      uri
      contentLength
    }
    imageType
    cover {
      id
      contentLength
      uri
      name
    }
    model {
      id
      contentLength
      uri
      name
    }
    narrations(input: {}) {
      id
      language {
        code
        displayTextEnglish
      }
      gender
      narrationSource
      voiceTrackLengthInSeconds
      voiceTrack {
        ...AssetFragment
      }
      subtitlesI18n {
        en_US {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        ar {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        el_GR {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        es_ES {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        fr_FR {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        he_IL {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        hi_IN {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        it_IT {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        ja_JP {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        pt_PT {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        ru_RU {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
        zh {
          id
          srtEncoding
          srt {
            ...AssetFragment
          }
        }
      }
    }
  }

  ${ASSET_FRAGMENT}
  ${I18NSTRING}
`;
