import decodeJWT from 'jwt-decode';
import * as Sentry from '@sentry/react';
import { UNKNOWN_ERROR } from '../consts';

interface JWTProps {
  r?: number[];
  roles?: number[];
}

export function getRolesFromJWT(jwt: string): number[] {
  let roles: number[] = [];

  try {
    const decoded = decodeJWT(jwt) as JWTProps;

    if (Array.isArray(decoded.r)) {
      roles = decoded.r;
    } else if (Array.isArray(decoded.roles)) {
      roles = decoded.roles;
    }

    if (roles.length === 0) {
      Sentry.captureMessage(`getRolesFromJWT.ts roles are empty: ${jwt}`);
    }
  } catch (error) {
    Sentry.captureMessage(
      `getRolesFromJWT.ts error: ${error?.message || UNKNOWN_ERROR}`
    );
  }

  return roles;
}
