import React from 'react';
import styled from '../../../../style/styled';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../style/SIZES';
import { ROUTES } from '../../../../Routes/routes';
import { TourInfoLotsToDoSection } from './TourInfoLotsToDoSection';
import { TourInfoGeneralSection } from './styledComponents/TourInfoGeneralSection';
import { TourInfoToSeeSection } from './TourInfoToSeeSection';
import { TourInfoWhenAndWhereSection } from './TourInfoWhenAndWhereSection/TourInfoWhenAndWhereSection';
import { TourInfoGuideSection } from './TourInfoGuideSection';
import { TourInfoIntroVideoSection } from './TourInfoIntroVideoSection';
import { TourInfoBasicDetailsSection } from './TourInfoBasicDetailsSection';
import { TourPurchaseState } from '../../../../types';
import { TourInfoPrice } from './TourInfoPrice';
import { TourInfoSharingAndPriceSection } from './TourInfoSharingAndPriceSection';
import { TourInfoIntroNarrationSection } from './TourInfoIntroNarrationSection/TourInfoIntroNarrationSection';
import { useTranslation } from 'react-i18next';
import { getI18nFieldValue } from '../../../../features/i18n/getI18nFieldValue';
import { useSelector } from 'react-redux';
import {
  selectLanguage,
  selectWhitelabelAppSettings,
} from '../../../../store/app/selectors';
import { DEFAULT_LANGUAGE } from '../../../../consts';
import { captureInSentry } from '../../../../App/config/reporting/captureInSentry';
import { env } from '../../../../App/config/env';
import { CurrencyCode } from '../../../../graphql/globalTypes';
import { EXPLORE_WEB_GetPartialTourByInternalReference_result } from '../../../../graphql/queries/__generated__/EXPLORE_WEB_GetPartialTourByInternalReference';

const THUMBNAIL_SPACER = '/7a350f63a17cf55a378f7906b225b6c0.png';

interface Props {
  tour: EXPLORE_WEB_GetPartialTourByInternalReference_result & {
    currencyCode: CurrencyCode;
    unitPrice: number;
    priceInCents: boolean;
  };
  purchaseState: TourPurchaseState;
}

export const MAX_WIDTH = 800;

export const TourInfo: React.FC<Props> = ({ tour, purchaseState }) => {
  const {
    tourInfo: {
      displayIntroVideoSection,
      toSeeSection: { displayTimePeriodOfVPs },
      displayFewLittleTipsSection,
    },
  } = useSelector(selectWhitelabelAppSettings);
  const language = useSelector(selectLanguage);
  const { t } = useTranslation();

  if (tour.stops.length === 0) {
    captureInSentry('TourInfo.tsx Tour has not viewpoints or no purchase info');

    return <Redirect to={ROUTES.index} />;
  }

  // metatags should be English, I guess?
  const titleMeta = `${
    env.IS_STOCK ? 'Ancient World' : 'Lithodomos Audio-Visual Guide'
  } - ${getI18nFieldValue(tour.nameI18n, DEFAULT_LANGUAGE, 'Tour')}`;

  const descriptionLongMeta = getI18nFieldValue(
    tour.descriptionLongI18n,
    DEFAULT_LANGUAGE
  );

  const tourName = getI18nFieldValue(tour.nameI18n, language) || t('Tour');
  const descriptionLong =
    getI18nFieldValue(tour.descriptionLongI18n, language) ||
    t('Uh oh! This tour has no long description!');
  const guide = tour.guide;
  const coverURI = tour.cover?.uri;

  return (
    <Container>
      <Helmet>
        <title>{titleMeta}</title>
        {descriptionLongMeta && (
          <meta name="description" content={descriptionLongMeta} />
        )}
        <meta property="og:title" content={titleMeta} />
        <meta property="og:image" content={coverURI} />
        {descriptionLongMeta && (
          <meta property="og:description" content={descriptionLongMeta} />
        )}
      </Helmet>

      <Images viewpoints={tour.stops} />

      <MainContent>
        <TourInfoBasicDetailsSection
          name={tourName}
          description={descriptionLong}
        />

        <TourInfoSharingAndPriceSection
          id={tour.id}
          currencyCode={tour.currencyCode}
          price={tour.unitPrice}
          priceInCents={tour.priceInCents}
          purchaseState={purchaseState}
          name={tourName}
          description={descriptionLong}
          tags={tour.tags}
          internalReference={tour.internalReference}
        />

        {/*{purchaseState === TourPurchaseState.PURCHASED && (*/}
        {/*  <DownloadSection tourID={tour.id} tourName={tourName} />*/}
        {/*)}*/}

        {env.ENABLE_ITINERARY_INTRO_NARRATIONS && (
          <TourInfoIntroNarrationSection
            introNarrations={tour.introNarrations}
            guildeAvatarURI={guide?.avatar?.uri}
          />
        )}

        {displayIntroVideoSection && (
          <TourInfoIntroVideoSection
            tourID={tour.id}
            coverURI={coverURI}
            playlistFileURI={tour.introVideo?.playlistFile?.uri}
          />
        )}

        <TourInfoWhenAndWhereSection tour={tour} />

        <TourInfoLotsToDoSection tour={tour} />

        <TourInfoToSeeSection
          tour={tour}
          displayTimePeriods={displayTimePeriodOfVPs}
        />

        {displayFewLittleTipsSection && <TourInfoGeneralSection />}

        {guide && (
          <TourInfoGuideSection
            name={guide.name}
            location={guide.location}
            avatarURI={guide.avatar?.uri || ''}
            bio={guide.bio}
          />
        )}

        <RightAlignedContainer>
          <TourInfoPrice
            id={tour.id}
            currencyCode={tour.currencyCode}
            price={tour.unitPrice}
            priceInCents={tour.priceInCents}
            purchaseState={purchaseState}
          />
        </RightAlignedContainer>
      </MainContent>
    </Container>
  );
};

const Container = styled.div`
  min-height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${MAX_WIDTH}px;
  max-width: 100%;
  padding: 10px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const ImagesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  color: black;

  img:first-child {
    margin-top: 0;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    width: 100%;
    height: 100px;
    flex-direction: row;

    img:first-child {
      margin-left: 0;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  margin-left: 0;
  margin-top: 8px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-left: 8px;
    margin-top: 0;
    width: auto;
    height: 100%;
  }
`;

const MainContent = styled.div`
  padding-left: 10px;
  width: 75%;
  margin-left: 25%;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-top: 110px;
    padding: 0;
    margin-left: 0;
    width: 100%;
  }
`;

const RightAlignedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ImagesProps {
  viewpoints: Array<{
    id: string;
    thumbnail: {
      uri: string;
    } | null;
  }>;
}

const Images: React.FC<ImagesProps> = ({ viewpoints }) => {
  return (
    <ImagesContainer>
      {viewpoints.map((vp) => {
        const uri = vp.thumbnail?.uri || THUMBNAIL_SPACER;

        return (
          <Img
            key={vp.id}
            alt=""
            src={uri}
            onError={(e) => {
              // TODO: types
              // @ts-ignore
              e.target.onerror = null;
              // TODO: types
              // @ts-ignore
              e.target.src = THUMBNAIL_SPACER;
            }}
          />
        );
      })}
    </ImagesContainer>
  );
};
