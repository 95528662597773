import { captureInSentry } from '../App/config/reporting/captureInSentry';
import { UNKNOWN_ERROR } from '../consts';

export function getTimezone(): string | null {
  if (typeof window.Intl?.DateTimeFormat === 'function') {
    try {
      return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
      captureInSentry(
        `getTimezone.ts error: ${error?.message || UNKNOWN_ERROR}`
      );
    }
  }

  return null;
}
