import React, { useEffect, useState } from 'react';
import styled from '../../../../style/styled';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../Routes/routes';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../../features/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../../store/app/selectors';
import { removeQueryVariable } from '../../../../utils/removeQueryVariable';
import { CodeKeys } from '../../../../types';
import { setModalType } from '../../../../store/modal/actions';
import { ModalType } from '../../../../store/modal/types';
import { addPinnedTours, doRefetch } from '../../../../store/app/actions';
import { LogInScreen } from '../../../../Routes/Auth/login/LogInScreen';
import { env } from '../../../config/env';
import { ActivateProductView } from './ActivateProductView';
import qs from 'query-string';
import { unlock } from '../../../../features/audio/howlerSetup';
import { EXPLORE_WEB_ActivateProductMutation_result_purchase_tour } from '../../../../graphql/mutations/__generated__/EXPLORE_WEB_ActivateProductMutation';
import { useUser } from '../../../../hooks/useUser';

interface Props {
  code?: string | null;
  setActivateError: React.Dispatch<React.SetStateAction<boolean>>;
}

// Activates a product (i.e. a tour) using a redeem code.
export const ActivateProduct: React.FC<Props> = ({
  code,
  setActivateError,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { refetch: refetchUser } = useUser();

  const attemptingToLaunchStop = history.location.pathname.includes(
    ROUTES.stop
  );

  const authed = useSelector(selectIsAuthenticated) && !attemptingToLaunchStop;

  // this is how we show the auth modal. ModalContent.NONE the non-auth modals are shown.
  // in this case, it means, it will show the activate modal.
  const [authing, setAuthing] = useState<boolean>(!authed);

  // Why do we need this??? Isn't it passed from the props?
  const rCode = qs.parse(history.location.search);

  // this is required for some reason.
  // when coming from Facebook Redirect page,
  // authed is false at the beginning, and then authed becomes true,
  // but modalContent doesn't change.
  useEffect(() => {
    if (authed && attemptingToLaunchStop) {
      setAuthing(false);
    }
  }, [authed, attemptingToLaunchStop]);

  const launchStop = (
    tour: EXPLORE_WEB_ActivateProductMutation_result_purchase_tour
  ) => {
    //unlock single viewpoint
    const stopID = history.location.pathname.split('/').filter(Boolean).pop();

    const stopIndex = tour.stops.findIndex(({ id }) => id === stopID);

    unlock();

    history.push(ROUTES.tour, {
      playlistID: tour.id,
      // sanitise the stopIndex
      currentStopIndex: stopIndex >= 0 ? stopIndex : 0,
    });
  };

  // this is called on a successful activation
  const onActivateSuccess = (
    redeemCodeUsed: string,
    activatedTourIDs: string[],
    tour?: EXPLORE_WEB_ActivateProductMutation_result_purchase_tour | null
  ) => {
    refetchUser();

    // close the activate modal
    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));

    // pin the last activated tours to the pinned tours
    dispatch(addPinnedTours(activatedTourIDs.map((tourID) => ({ tourID }))));

    // capture analytics
    captureEvent({
      name: EventNames.ACTIVATED_PRODUCT,
      data: [{ key: DataKeys.REDEEM_CODE, value: redeemCodeUsed }],
    });

    // redirect after activation
    if (env.IS_STOCK) {
      history.push({
        pathname: ROUTES.myTrips,
        search: removeQueryVariable(CodeKeys.REDEEM_CODE),
      });
    } else if (!env.IS_STOCK && !rCode.rcode) {
      history.push({
        pathname: ROUTES.index,
        search: removeQueryVariable(CodeKeys.REDEEM_CODE),
      });
      setTimeout(() => {
        history.go(0);
      }, 1000);
    } else if (
      attemptingToLaunchStop &&
      rCode.rcode &&
      activatedTourIDs.length === 1 &&
      tour
    ) {
      launchStop(tour);
    } else {
      history.push({
        pathname: ROUTES.index,
        search: removeQueryVariable(CodeKeys.REDEEM_CODE),
      });
    }

    dispatch(doRefetch());
  };

  if (authing) {
    return (
      <Wrapper>
        <LogInScreen
          onSuccess={() => setAuthing(false)}
          // will be converted to i18n in the LogInScreen component
          reason="Please sign up or log in to redeem a voucher code."
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ActivateProductView
        codeFromDeeplink={code}
        onSuccess={onActivateSuccess}
        setActivateError={setActivateError}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
`;
