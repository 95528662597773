import React from 'react';
import styled from '../../../../style/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section } from './styledComponents/Section';
import { SectionTitle } from './styledComponents/SectionTitle';
import { AvatarContainer } from '../../../../Routes/Guides/GuideWidget';
import { useTranslation } from 'react-i18next';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../style/SIZES';

interface Props {
  name: string;
  bio: string;
  location: string;
  avatarURI: string;
}

// TODO: i18n how to handle guides
export const TourInfoGuideSection = ({
  name,
  bio,
  location,
  avatarURI,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle>{t('Meet your guide')}</SectionTitle>

      <Container>
        <NameAndLocation>
          {name} {location ? `from ${location}` : ''}
        </NameAndLocation>

        <CenterAlignedOnMobile>
          <AvatarContainer>
            <LazyLoadImage src={avatarURI} alt="" width={120} height={120} />
          </AvatarContainer>
        </CenterAlignedOnMobile>

        <BioContainer>
          <p>{bio}</p>
        </BioContainer>
      </Container>
    </Section>
  );
};

const CenterAlignedOnMobile = styled.div`
  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin: 0 auto;
  }
`;

const BioContainer = styled.div`
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
`;

const NameAndLocation = styled.p`
  margin-bottom: 8px;
`;
