import React, { useEffect } from 'react';
import { getQueryVariable } from '../../utils/getQueryVariable';
import { CodeKeys, PromoCode } from '../../types';
import { captureEvent, DataKeys, EventNames } from '../../features/analytics';
import { storeAffiliateCode } from '../../features/affiliateCodes/storeAffiliateCode';
import { useDispatch } from 'react-redux';
// import { pushMessage } from '../../store/app/actions';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
// import { storeCouponCodeInLocalStorage } from '../Shop/Checkout/utils/storeCouponCodeInLocalStorage';
// import { getCouponCodeFromLocalStorage } from '../Shop/Checkout/utils/getCouponCodeFromLocalStorage';
// import { removeStoredCouponCode } from '../Shop/Checkout/utils/removeStoredCouponCode';
import { setPromoCode } from '../../store/app/actions';

export const AffiliateCodeExtractor: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const affiliateCode = getQueryVariable(CodeKeys.AFFILIATE_CODE);

    if (typeof affiliateCode === 'string') {
      storeAffiliateCode(affiliateCode);

      captureEvent({
        name: EventNames.AFFILIATE_CODE_EXTRACTED,
        data: [
          {
            key: DataKeys.AFFILIATE_CODE,
            value: affiliateCode,
          },
        ],
      });

      // temp handling of AHE
      if (affiliateCode === 'ZORJ9YT7') {
        // dispatch(
        //   pushMessage(
        //     'Hi, as a World History Encyclopedia user, use the coupon code <strong>ANCIENT25</strong> at checkout to get 25% off your entire purchase.'
        //   )
        // );
        const promoCode: PromoCode = {
          id: '6107a9e3fe1a10000638e167',
          code: 'WHE25',
          discountRate: 25,
          expired: false,
        };

        // in case we want to change the promocode
        // const promoCode2: PromoCode = {
        //   id: '6108cfd2f851a5000873107c',
        //   code: 'WHEHALFPRICE',
        //   discountRate: 50,
        //   expired: false,
        // };
        // // clean up
        // const oldPromoCodeID = getCouponCodeFromLocalStorage()?.id;
        // if (
        //   oldPromoCodeID === promoCode1.id ||
        //   oldPromoCodeID === promoCode2.id
        // ) {
        //   removeStoredCouponCode();
        // }
        // storeCouponCodeInLocalStorage(promoCode);
        // dispatch(setModalType({ modalType: ModalType.WHE_PROMO_CODE }));

        dispatch(setPromoCode(promoCode));

        dispatch(
          setModalType({
            modalType: ModalType.WHE_PROMO_CODE,
            modalData: {
              discountRate: promoCode.discountRate,
            },
          })
        );
      }
    }
  }, [dispatch]);

  return null;
};
