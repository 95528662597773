import React from 'react';
import { nonNullable } from '../../utils/nonNullable';
import { GenericMarkerData } from './types';
import { GoogleMapsLatLngLiteral } from './types';
import { calculateBoundOfPoints } from './utils/calculateBoundOfPoints';
import { calculateCenterOfPoints } from './utils/calculateCenterOfPoints';
import GoogleMapsMiniItinerary from './GoogleMapsMiniItinerary';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';

interface Props {
  center?: GoogleMapsLatLngLiteral | null;
  zoom?: number;
  bounds?: GoogleMapsLatLngLiteral[] | null;
  restrictToBounds?: GoogleMapsLatLngLiteral[];
  markerData?: GenericMarkerData[];
  trailGeoJSONURI?: string | null;
}

export const ItineraryGoogleMap: React.FC<Props> = ({
  center,
  bounds,
  zoom = 11,
  restrictToBounds,
  markerData = [],
  trailGeoJSONURI,
}) => {
  let mapBounds = bounds;
  let mapCenter = center;

  // fallback if bounds and center aren't defined
  if (!mapBounds || !mapCenter) {
    const markerPositions = markerData
      .filter(nonNullable)
      .map(({ position }) => position);

    mapBounds = mapBounds || calculateBoundOfPoints(markerPositions);
    mapCenter = mapCenter || calculateCenterOfPoints(mapBounds);
  }

  if (!mapCenter || !mapBounds) {
    captureInSentry('MapMini.tsx mapCenter or mapBounds is not provided');

    return null;
  }

  return (
    <GoogleMapsMiniItinerary
      trailGeoJSONURI={trailGeoJSONURI}
      markerData={markerData}
      center={mapCenter}
      initialBounds={mapBounds}
      zoom={zoom}
      restrictToBounds={restrictToBounds}
    />
  );
};
