import { gql } from '@apollo/client';
import { createContext } from 'react';
import {
  EXPLORE_WEB_CurrentUserForUserContext_currentUser,
  EXPLORE_WEB_CurrentUserForUserContext_currentUser_purchases as Purchase,
} from '../context/__generated__/EXPLORE_WEB_CurrentUserForUserContext';

export const CURRENT_USER_QUERY = gql`
  query EXPLORE_WEB_CurrentUserForUserContext {
    currentUser {
      id
      email
      name
      visitedPlaylistDiscoveries {
        playlistID
        discoveryID
      }
      purchases {
        id
        productSKU
        productID
        expiresAt
      }
      purchasedGifts {
        id
        productSKU
        productID
        redeemCode
        redeemedAt
      }
    }
  }
`;

export const UPDATE_VISITED_PLAYLIST_DISCOVERIES = gql`
  mutation EXPLORE_WEB_UpdateVisitedPlaylistDiscoveries(
    $input: UpdateVisitedPlaylistDiscoveriesInput!
  ) {
    result: updateVisitedPlaylistDiscoveries(input: $input) {
      error {
        message
      }
    }
  }
`;

export type User = EXPLORE_WEB_CurrentUserForUserContext_currentUser;

export type UserContextValue = {
  user: User | null;
  refetch: () => void;
  tourPurchasesMap: Record<string, Purchase>;
  tourBundlePurchasesMap: Record<string, Purchase>;
  updateVisitedPlaylistDiscoveries: (
    playlistID: string,
    discoveryIDs: Array<string>
  ) => Promise<void>;
};

const UserContext = createContext<UserContextValue>({
  user: null,
  refetch: () => {},
  tourPurchasesMap: {},
  tourBundlePurchasesMap: {},
  updateVisitedPlaylistDiscoveries: () => Promise.resolve(),
});

export default UserContext;
