import React from 'react';
import { toast } from 'react-toastify';
import { env } from '../../App/config/env';
import styled, { useTheme } from '../../style/styled';

const VersionText = styled.p<{ interactive: boolean; color: string }>`
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
  margin: 0 10px;
  color: ${(props) => props.color};
`;

interface DevVersionProps {
  color: string;
}

export const DevVersion: React.FC<DevVersionProps> = (props) => {
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;

  const version = `${env.APP_VERSION} ${env.GIT_HASH || ''} (${
    env.IS_ALPHA ? 'Alpha' : 'Beta'
  })`;

  const interactive =
    navigator.clipboard && typeof navigator.clipboard.writeText === 'function';

  const handleClick = () => {
    if (interactive) {
      navigator.clipboard
        .writeText(version)
        .then(() => {
          toast(`Version number copied to clipboard`, {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
          });
        })
        .catch(() => {
          toast.error(`Error copying version number to clipboard`, {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
            toastId: 'version-number-copy-fail',
          });
        });
    }
  };

  return (
    <VersionText color={color} interactive={interactive} onClick={handleClick}>
      {version}
    </VersionText>
  );
};
