import { createAction } from 'redux-actions';

export const ACTION_TYPES = {
  ENABLE_FEATURE: 'enableFeature',
  DISABLE_FEATURE: 'disableFeature',
};

export type EnableFeature = { feature: string };

export type DisableFeature = { feature: string };

export type EnableFeatureAction = {
  type: typeof ACTION_TYPES.ENABLE_FEATURE;
  payload: EnableFeature;
};

export type DisableFeatureAction = {
  type: typeof ACTION_TYPES.DISABLE_FEATURE;
  payload: DisableFeature;
};

export const enableFeature = createAction<EnableFeature>(
  ACTION_TYPES.ENABLE_FEATURE
);

export const disableFeature = createAction<DisableFeature>(
  ACTION_TYPES.DISABLE_FEATURE
);
