import React from 'react';
import { Stack } from '../primitives/Stack';
import { Version } from './Version';

export const Copyright: React.FC = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Stack gutter={8} style={{ marginBottom: 50 }}>
      <p>©{year || 2022} Lithodomos VR Pty Ltd</p>

      <Version />
    </Stack>
  );
};
