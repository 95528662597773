import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { captureButtonPress } from '../../../../features/analytics';
import { GET_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL } from '../../../../graphql/queries/GetTourByInternalReference';
import { setModalType } from '../../../../store/modal/actions';
import { ModalType } from '../../../../store/modal/types';
import { runGraphQLQuery } from '../../../../utils/runGraphQLQuery';
import { Button } from '../../../Button';

interface Props {
  tourID: string;
  internalReference: string;
}

export const TourWidgetItineraryButtonForPopup: React.FC<Props> = ({
  tourID,
  internalReference,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClick = () => {
    captureButtonPress({
      buttonName: 'Itinerary',
      tourID,
      page: window.location.pathname,
    });

    dispatch(
      setModalType({
        modalType: ModalType.ITINERARY,
        modalData: { internalReference },
      })
    );
  };

  return (
    <Button
      secondary
      width="100%"
      onClick={handleClick}
      onMouseEnter={() => {
        // Optimisation
        runGraphQLQuery(GET_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL, {
          input: {
            internalReference,
          },
        });
      }}
    >
      {t('Learn more')}
    </Button>
  );
};
