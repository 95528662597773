import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '../../../style/styled';
import * as Sentry from '@sentry/react';
import {
  TourInfo,
  MAX_WIDTH,
} from '../../../components/contentSections/toursSection/tourInfo/TourInfo';
import {
  EXPLORE_WEB_GetPartialTourByInternalReference as Data,
  EXPLORE_WEB_GetPartialTourByInternalReferenceVariables as Variables,
} from '../../../graphql/queries/__generated__/EXPLORE_WEB_GetPartialTourByInternalReference';
import { LoadingView } from '../../../components/LoadingView';
import { Dim, useResize } from '../../../hooks/useResize';
import {
  selectCurrencyCode,
  selectPlaylistsMapInCart,
} from '../../../store/app/selectors';
import { TourPurchaseState } from '../../../types';
import { setModalType } from '../../../store/modal/actions';
import { ModalType } from '../../../store/modal/types';
import { getTourPrice } from '../../../features/multiCurrency/getTourPrice';
import { GET_PARTIAL_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL } from '../../../graphql/queries/GetPartialTourByInternalReference';
import { useUser } from '../../../hooks/useUser';

interface Props {
  internalReference: string;
}

const TourItinerary: React.FC<Props> = ({ internalReference }) => {
  const dispatch = useDispatch();
  const playlistsMapInCart = useSelector(selectPlaylistsMapInCart);
  const currencyCode = useSelector(selectCurrencyCode);
  const variables = { input: { internalReference } };
  const { tourPurchasesMap } = useUser();

  const { data, loading } = useQuery<Data, Variables>(
    GET_PARTIAL_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL,
    {
      variables,
      errorPolicy: 'all',
      skip: !internalReference,
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', variables);
          Sentry.captureException(error);
        });

        dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));
      },
    }
  );

  if (loading && !data?.result) {
    return <Loading />;
  }

  if (data?.result) {
    const tour = data.result;
    const tourPrice = getTourPrice({ tour: data.result, currencyCode });
    const tourWithProcessedPricing = {
      ...tour,
      unitPrice: tourPrice.price,
      currencyCode: tourPrice.currencyCode,
      priceInCents: true,
    };

    let purchaseState = TourPurchaseState.NONE;

    if (
      tourPurchasesMap[tourWithProcessedPricing.id] ||
      tourWithProcessedPricing.trial
    ) {
      purchaseState = TourPurchaseState.PURCHASED;
    } else if (playlistsMapInCart[tourWithProcessedPricing.id]) {
      purchaseState = TourPurchaseState.IN_CART;
    }

    return (
      <TourInfo tour={tourWithProcessedPricing} purchaseState={purchaseState} />
    );
  }

  return null;
};

export default TourItinerary;

const Loading: React.FC = () => {
  const [width, setWidth] = useState(
    Math.min(window.innerWidth - 40, MAX_WIDTH)
  );

  useResize(Dim.WIDTH, (newWidth: number) =>
    setWidth(Math.min(newWidth - 40, MAX_WIDTH))
  );

  return (
    <LoadingViewContainer width={width}>
      <LoadingView black timeout={0} />
    </LoadingViewContainer>
  );
};

const LoadingViewContainer = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  max-height: 90%;
`;
