import {
  Gender,
  LanguageCode,
  NarrationSource,
} from './../../../graphql/globalTypes';
import {
  EXPLORE_WEB_GetFullTour_result_narrationConfig,
  EXPLORE_WEB_GetFullTour_result_stops_narrations_language,
  EXPLORE_WEB_GetFullTour_result_stops_narrations_subtitlesI18n,
  EXPLORE_WEB_GetFullTour_result_stops_narrations_voiceTrack,
} from '../../../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';
import { languages } from '../../../consts';

type Narration = {
  id: string;
  language: EXPLORE_WEB_GetFullTour_result_stops_narrations_language;
  gender: Gender;
  narrationSource: NarrationSource;
  voiceTrackLengthInSeconds: number;
  voiceTrack: EXPLORE_WEB_GetFullTour_result_stops_narrations_voiceTrack;
  subtitlesI18n: EXPLORE_WEB_GetFullTour_result_stops_narrations_subtitlesI18n;
};

interface Args {
  parent?: {
    narrations?: Array<Narration>;
  };
  overridingNarrationConfig:
    | EXPLORE_WEB_GetFullTour_result_narrationConfig
    | null
    | undefined;
  narrationLanguage: LanguageCode;
}

export function resolvePreferredNarration({
  parent,
  overridingNarrationConfig,
  narrationLanguage,
}: Args): Narration | null | undefined {
  if (
    !parent ||
    !Array.isArray(parent.narrations) ||
    parent.narrations.length === 0
  ) {
    return null;
  }

  const code = languages[narrationLanguage]?.code;

  // by default, pick the English track in this order
  const narration = parent.narrations.find(
    (n) =>
      n.language.code === code &&
      n.narrationSource === 'PROFESSIONALLY_RECORDED'
  );

  // if the playlist doesn't override the default narration config, return whatever was returned by the find call. it can be null.
  if (!overridingNarrationConfig) {
    return narration;
  }

  const { languageCode, narrationSource, gender } = overridingNarrationConfig;

  let options = parent.narrations.slice(0);

  if (languageCode) {
    const languageCodeFromGQLEnum = languageCode.replace('_', '-');
    options = options.filter(
      (narration: any) => narration.language.code === languageCodeFromGQLEnum
    );
  }

  if (narrationSource) {
    options = options.filter(
      (narration: any) => narration.narrationSource === narrationSource
    );
  }

  if (gender) {
    options.filter((narration: any) => narration.gender === gender);
  }

  if (options.length > 0) {
    return options[0];
  }

  return narration;
}
