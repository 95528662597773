import { DEFAULT_LANGUAGE } from '../../consts';
import { LanguageCode } from '../../graphql/globalTypes';
import { FullTour } from '../../graphql/queries/GetFullTourQuery';
import { ClosestViewpointDetectionMode } from '../location/types';

export enum CompassSupport {
  // eslint-disable-next-line no-unused-vars
  SUPPORTED,
  // eslint-disable-next-line no-unused-vars
  UNSUPPORTED,
  // eslint-disable-next-line no-unused-vars
  ENABLED,
  // eslint-disable-next-line no-unused-vars
  QUERYING,
}

export type ViewerState = {
  facingDirection: string;
  tour: FullTour | null;

  // closest viewpoint
  closestViewpointIndex: number;
  closestViewpointDistance: number;
  closestViewpointDetectionMode: ClosestViewpointDetectionMode;

  currentViewpointIndex: number;
  currentViewpointProjectionName: string;
  currentVpIndex: number;
  previousVpIndex: number;
  previousViewpointIndex: number;
  currentImageIndex: number;
  // currentDiscoveryIndex: number;
  currentDiscoveryID: string;
  currentArtefactIndex: number;
  artefactClickPosition: { x: number; y: number };
  previousImageIndex: number;
  viewerOptions: ViewerOptionsState;
  visitedVPIndices: number[];
  visitedDiscoveryIDs: string[];

  // the following states aren't persisted. they are removed from the outbound transform (transforms.ts)
  isLoading: boolean;
  isViewpointSwitching: boolean;
  audio: AudioState;
  discoveryAudio: AudioState;
  imageCaption: string | null;
  endOfTourPopupClosed: boolean;
  resyncWithCompassCounter: number;
  deviceOrientation: Partial<DeviceOrientationEvent> | null;
  // we use this state to determine if we can enable gyro when starting the tour or not.
  // it is set from the TourViewCompassToggle component
  // and read from FreeRoamScreen
  compassSupport: CompassSupport;

  displayUI: boolean;

  userSelectedAViewpoint: boolean;
};

export enum TOUR_TYPE {
  // eslint-disable-next-line no-unused-vars
  NONE,
  // eslint-disable-next-line no-unused-vars
  FREE_ROAM,
  // eslint-disable-next-line no-unused-vars
  GUIDED,
}

export type ViewerOptionsState = {
  isFingerTutorialDisplayed: boolean;
  isInfoPanelEnabled: boolean;
  isOptionsMenuVisible: boolean;
  isStreetViewEnabled: boolean;
  isDiscoveriesVisible: boolean;
  isMapsVisible: boolean;
  isSlideVisible: boolean;
  isSideBySideVisible: boolean;
  isImagesEnabled: boolean;
  isImageCaptionsVisible: boolean;
  isDistractionFree: boolean;
  isPlaylistThumbnailsVisible: boolean;
  isMetaDataWindowVisible: boolean;
  isGuidePanelVisible: boolean;
  isFullscreen: boolean;
  isStereoMode: boolean;
  isAutoRotating: boolean;
  isMediaControlsVisible: boolean;
  // DEPRECATED
  isGyroEnabled: boolean;
  tourType: TOUR_TYPE;
  isTutorialVisible: boolean;
  tutorialDisplayed: boolean;
  slideTutorialDisplayed: boolean;
  tutorialImagesButtonHighlighted: boolean;
  tutorialVPGridButtonHighlighted: boolean;
  tutorialVPInfoButtonHighlighted: boolean;
  tutorialSubtitlesButtonHighlighted: boolean;
  tutorialToggleMusicButtonHighlighted: boolean;
  tutorialNarrationPlayPauseButtonHighlighted: boolean;
  tutorialGyroToggleButtonHighlighted: boolean;
  tutorialMapToggleButtonHighlighted: boolean;
};

export const initialViewerOptions: ViewerOptionsState = {
  isFingerTutorialDisplayed: false,
  isInfoPanelEnabled: false,
  isOptionsMenuVisible: true,
  isStreetViewEnabled: false,
  isDiscoveriesVisible: false,
  isMapsVisible: false,
  isSlideVisible: true,
  isSideBySideVisible: false,
  isImagesEnabled: false,
  isImageCaptionsVisible: true,
  isDistractionFree: false,
  isPlaylistThumbnailsVisible: false,
  isGuidePanelVisible: false,
  isMetaDataWindowVisible: false,
  isFullscreen: false,
  isStereoMode: false,
  isAutoRotating: false,
  isMediaControlsVisible: false,
  // DEPRECATED
  isGyroEnabled: false,
  tourType: TOUR_TYPE.NONE,
  isTutorialVisible: true,
  tutorialDisplayed: false,
  slideTutorialDisplayed: false,
  tutorialImagesButtonHighlighted: false,
  tutorialVPGridButtonHighlighted: false,
  tutorialVPInfoButtonHighlighted: false,
  tutorialSubtitlesButtonHighlighted: false,
  tutorialToggleMusicButtonHighlighted: false,
  tutorialNarrationPlayPauseButtonHighlighted: false,
  tutorialGyroToggleButtonHighlighted: false,
  tutorialMapToggleButtonHighlighted: false,
};

export enum AudioPlayingState {
  // eslint-disable-next-line no-unused-vars
  paused,
  // eslint-disable-next-line no-unused-vars
  playing,
  // eslint-disable-next-line no-unused-vars
  completed,
  // eslint-disable-next-line no-unused-vars
  systemPaused,
  // eslint-disable-next-line no-unused-vars
  loading,
}

export type AudioState = {
  showSubtitles: boolean;
  subtitlesLanguage: LanguageCode;
  musicMuted: boolean;
  narrationLanguage: LanguageCode;
  changingSlider: boolean;
  seekPos: number;
  length: number;
  pausedByUser: boolean;
  pausedByTheSystem: boolean;
  error: unknown | Error | null;
  loading: boolean;
  ready: boolean;
  playing: boolean;
  started: boolean;
  completed: boolean;
  unlocked: boolean;
};

export const initialAudioState: AudioState = {
  seekPos: 0,
  length: 0,
  showSubtitles: false,
  subtitlesLanguage: DEFAULT_LANGUAGE,
  musicMuted: false,
  narrationLanguage: DEFAULT_LANGUAGE,
  changingSlider: false,
  pausedByUser: false,
  pausedByTheSystem: false,
  error: null,
  loading: false,
  ready: false,
  playing: false,
  started: false,
  completed: false,
  unlocked: true,
};

export type Video = {
  id: string;
  playlistFile: {
    id: string;
    uri: string;
  };
};

export const initialState: ViewerState = {
  facingDirection: 'North',
  tour: null,
  currentViewpointIndex: 0,
  currentViewpointProjectionName: '',
  currentVpIndex: 0,
  previousVpIndex: -1,
  closestViewpointDistance: Infinity,
  closestViewpointDetectionMode: ClosestViewpointDetectionMode.RADIUS,
  previousViewpointIndex: -1,
  closestViewpointIndex: -1,
  currentImageIndex: 0,
  currentDiscoveryID: '',
  currentArtefactIndex: 0,
  artefactClickPosition: { x: 0, y: 0 },
  previousImageIndex: -1,
  isLoading: false,
  isViewpointSwitching: false,
  audio: initialAudioState,
  discoveryAudio: initialAudioState,
  viewerOptions: initialViewerOptions,
  imageCaption: null,
  endOfTourPopupClosed: false,
  resyncWithCompassCounter: 0,
  deviceOrientation: null,
  compassSupport: CompassSupport.QUERYING,
  visitedVPIndices: [0],
  visitedDiscoveryIDs: [],
  displayUI: true,
  userSelectedAViewpoint: false,
};
