import React from 'react';
import { CookieDisclaimer } from '../../components/CookieDisclaimer';
import { env } from '../config/env';
import { ErrorBoundaryMini } from '../../components/ErrorBoundaryMini';
import { RenderIfAuthed } from '../../components/RenderIfAuthed';
import { DownloadAppAssets } from '../../features/offline/components/DownloadAppAssets';
import { UpdateNotification } from '../../features/offline/UpdateNotification';
import { FullscreenStateSynchroniser } from './FullscreenStateSynchroniser';
import { MobileDebug } from './MobileDebug/MobileDebug';
import { OfflineAssetSynchroniser } from './OfflineAssetSynchroniser';
import { ReduxStateListener } from './ReduxStateListener';
import { CurrentLocationWatcher } from '../../features/map/CurrentLocationWatcher';
import { RequestLocationMonitoring } from '../../features/geolocation/components/RequestLocationMonitoring';

// Mount these components lazily
export const LazyComponents: React.FC = () => {
  return (
    <ErrorBoundaryMini>
      {/* Alert users we are using cookies */}
      {!env.BYPASS_COOKIE_DISCLAIMER && <CookieDisclaimer />}

      {/* Write any side effects to be fired when the state changes */}
      <ReduxStateListener />

      {/* Alerts the user when an update to the website is available.
      This works in conjunction with the service worker. */}
      <UpdateNotification />

      {/* Synchronise fullscreen redux state when the browser's
      native fullscreen functionality is triggered */}
      <FullscreenStateSynchroniser />

      {/* Sync assets of tours which should be available offline,
      in case there was an change to the tour assets */}
      <ErrorBoundaryMini>
        <OfflineAssetSynchroniser />
      </ErrorBoundaryMini>

      {/* Cache app assets if the user is logged in */}
      <RenderIfAuthed>
        <ErrorBoundaryMini>
          <DownloadAppAssets />
        </ErrorBoundaryMini>

        {/* When the user requests to track location, we would listen
        to the geolocation updates and store it in `location` state */}
      </RenderIfAuthed>

      {/* Automatically ask for location monitoring  */}
      <ErrorBoundaryMini>
        <RequestLocationMonitoring />
      </ErrorBoundaryMini>

      {/* Update the current location in Redux state - wonder if excessive */}
      <ErrorBoundaryMini>
        <CurrentLocationWatcher />
      </ErrorBoundaryMini>

      {/* Show eruda "mobile" console for mobile browsers for debugging */}
      {env.ENABLE_MOBILE_DEBUG && (
        <ErrorBoundaryMini>
          <MobileDebug />
        </ErrorBoundaryMini>
      )}
    </ErrorBoundaryMini>
  );
};
