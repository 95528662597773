import { Store } from 'redux';
import { History } from 'history';
import { AudioPlayer } from '../../features/audio/AudioPlayer';
import {
  initialState as initialViewerState,
  ViewerState,
} from '../viewer/types';
import { initialState as initialAppState, AppState } from '../app/types';
import { MusicPlayer } from '../../features/audio/MusicPlayer';
import {
  OfflineState,
  initialState as initialOfflineState,
} from '../offline/types';
import { CacheManagerForTourAssets } from '../../features/offline/CacheManagerForTourAssets';
import { SWState, initialState as initialSWState } from '../sw/types';
import {
  LocationState,
  initialState as initialLocationState,
} from '../location/types';
import { ModalState, initialState as initialModalState } from '../modal/types';
import {
  GoogleOptimizeState,
  initialState as initialGoogleOptimizeState,
} from '../googleOptimize/types';
import {
  FeaturesState,
  initialState as initialFeaturesState,
} from '../features/types';

export type RootState = {
  app: AppState;
  googleOptimize: GoogleOptimizeState;
  location: LocationState;
  modals: ModalState;
  offline: OfflineState;
  sw: SWState;
  viewer: ViewerState;
  features: FeaturesState;
};

export type Actions = any;

export type ReduxInstance = {
  store: Store<RootState, Actions>;
  persistor: any;
};

export enum SagaContextKeys {
  // eslint-disable-next-line no-unused-vars
  audio = 'audio',
  // eslint-disable-next-line no-unused-vars
  cacheManager = 'cacheManager',
  // eslint-disable-next-line no-unused-vars
  history = 'history',
  // eslint-disable-next-line no-unused-vars
  music = 'music',
}

export type SagasContext = {
  [SagaContextKeys.audio]: AudioPlayer;
  [SagaContextKeys.cacheManager]: CacheManagerForTourAssets;
  [SagaContextKeys.history]: History;
  [SagaContextKeys.music]: MusicPlayer;
};

export const initialState: RootState = {
  app: initialAppState,
  googleOptimize: initialGoogleOptimizeState,
  location: initialLocationState,
  modals: initialModalState,
  offline: initialOfflineState,
  sw: initialSWState,
  viewer: initialViewerState,
  features: initialFeaturesState,
};
