import { env } from '../env';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
// import { configureScope, init } from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

// logging. githash is received via the build script
if (env.GIT_HASH) {
  // if (env.ENABLE_LOG_ROCKET_REPORTING) {
  //   LogRocket.init(env.LOG_ROCKET_ID, { release: env.GIT_HASH });
  //
  //   setupLogRocketReact(LogRocket);
  // }
  // if (env.ENABLE_SENTRY_REPORTING) {
  //   init({
  //     dsn: env.SENTRY_DSN,
  //     release: env.GIT_HASH,
  //     environment: env.ENVIRONMENT,
  //     debug: !env.IS_RTM,
  //     ignoreErrors: [
  //       'TypeError: Failed to fetch',
  //       'TypeError: NetworkError when attempting to fetch resource.',
  //     ],
  //     integrations: [
  //       // new Integrations.BrowserTracing()
  //     ],
  //     tracesSampleRate: 1.0,
  //
  //     // TODO: sanitize private info?
  //     // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  //   });
  //
  //   if (env.ENABLE_LOG_ROCKET_REPORTING) {
  //     // Integrate LogRocket with Sentry
  //     // refer: https://blog.logrocket.com/extending-sentry-with-logrocket-52e2f5b67d5a/
  //     configureScope((scope) => {
  //       scope.setExtra('sessionURL', LogRocket.sessionURL);
  //     });
  //   }
  // }
}
