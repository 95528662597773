import { isMobile } from 'react-device-detect';
import { env } from '../../App/config/env';
import {
  CurrencyCode,
  LanguageCode,
  PurchaseMode,
} from '../../graphql/globalTypes';
import { PromoCode } from '../../types';
import { DEFAULT_LANGUAGE, DEFAULT_CURRENCY_CODE } from './../../consts';
import { IEnv } from '../../features/whitelabeling/branding/types';
import { DEFAULT_APP_CONFIGURATION } from '../../features/whitelabeling/branding/consts';

export type CartState = Array<{
  productSKU: string;
  productID: string;
  count?: number;
}>;

export type PromoCodeState = PromoCode | null;

export type PinnedTour = {
  tourID: string;
  viewpointID?: string;
};

export type TourSettingsState = {
  maintainAudioPausedStateWhenSwitchingVPs: boolean;
  autoLoadViewpointWhenNearby: boolean;
};

export const DEFAULT_TOUR_SETTINGS_STATE: TourSettingsState = {
  maintainAudioPausedStateWhenSwitchingVPs: true,
  autoLoadViewpointWhenNearby: true,
};

export type AppSettingsState = {
  detectClosestViewpoints: boolean;
  closestViewpointDetectionThresholdDistance: number;
  closetViewpointDetectionThresholdAccuracy: number;
};

export const DEFAULT_APP_SETTINGS_STATE: AppSettingsState = {
  detectClosestViewpoints: isMobile,
  closestViewpointDetectionThresholdDistance: env.isDevelopment ? 100 : 20,
  closetViewpointDetectionThresholdAccuracy: env.isDevelopment ? 200 : 10,
};

export enum CurrencySetMode {
  // eslint-disable-next-line no-unused-vars
  DEFAULT = 0,
  // eslint-disable-next-line no-unused-vars
  AUTOMATIC = 1,
  // eslint-disable-next-line no-unused-vars
  USER = 2,
}

export type AppState = {
  user: UserState | null;
  doRefetch: boolean;

  // show the cookie disclaimer
  showDisclaimer: boolean;

  // shop related state
  cart: CartState;
  purchaseMode: PurchaseMode;
  currencyCode: CurrencyCode;
  currencyCodeSetMode: CurrencySetMode;

  redirectToCart: boolean;
  AddToHomeScreenPopupDisplayedTimestamp: number | null;

  // protect staging crawlers
  protected: boolean;

  pinnedTours: PinnedTour[];

  // the following states aren't persisted. they are removed from the outbound transform (transforms.ts)
  landscapeMessageDisplayed: boolean;
  promoCode: PromoCodeState;

  // language
  language: LanguageCode;

  tourSettings: TourSettingsState;
  appSettings: AppSettingsState;

  messages: string[];

  enableGoogleAnalytics: boolean;

  whitelabelAppSettings: IEnv;
};

export type UserState = {
  id: string;
  jwt: string;
};

export const initialState: AppState = {
  user: null,
  doRefetch: false,
  cart: [],
  purchaseMode: PurchaseMode.PERSONAL,
  currencyCode: DEFAULT_CURRENCY_CODE,
  currencyCodeSetMode: CurrencySetMode.DEFAULT,

  redirectToCart: false,
  landscapeMessageDisplayed: false,
  AddToHomeScreenPopupDisplayedTimestamp: null,

  showDisclaimer: true,
  protected: true,

  promoCode: null,

  pinnedTours: [],

  language: DEFAULT_LANGUAGE,

  appSettings: DEFAULT_APP_SETTINGS_STATE,
  tourSettings: DEFAULT_TOUR_SETTINGS_STATE,

  messages: [],

  enableGoogleAnalytics: false,

  whitelabelAppSettings: DEFAULT_APP_CONFIGURATION.settings,
};
