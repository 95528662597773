import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import { withCrawlerProtection } from '../../hocs/withCrawlerProtection';
import { LoadingView } from '../../components/LoadingView';
import { TourRoute } from '../components/TourRoute';
import { ROUTES } from '../routes';
import RegularPageRoutes from './RegularPageRoutes';

const BusinessRoutes = React.lazy(() => import('../Business/BusinessRoutes'));
const ComingSoonScreen = React.lazy(
  () => import('../ComingSoon/ComingSoonPage')
);
const TourRoutes = React.lazy(() => import('../Tour/TourRoutesScreen'));

const AppRoutesComp: React.FC = () => {
  return (
    <React.Suspense fallback={<LoadingView />}>
      <Switch>
        <Route exact path={ROUTES.comingSoon} component={ComingSoonScreen} />

        <TourRoute path={ROUTES.tour} component={TourRoutes} />

        <Route path={ROUTES.business} component={BusinessRoutes} />

        {/* all the routes that require the regular page layout with a header and a footer */}
        <Route render={() => <RegularPageRoutes />} />
      </Switch>
    </React.Suspense>
  );
};

// export const AppRoutes = withCrawlerProtection(AppRoutesComp);
export const AppRoutes = AppRoutesComp;
