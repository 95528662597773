import React from 'react';
import Icon from 'mdi-react/CollectionIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { captureButtonPress } from '../../features/analytics';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { ROUTES } from '../../Routes/routes';
import { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
import { useSelector } from 'react-redux';
import { selectVariant } from '../../store/googleOptimize/selectors';
import { env } from '../../App/config/env';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  rtl?: boolean;
}

export const ToursLibraryLink: React.FC<Props> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const variant = useSelector(selectVariant(env.GO_EXP_ID_FOR_LIBRARY_PAGE));

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'ToursLibrary_Header',
    });

    if (variant === '0') {
      history.push({ pathname: ROUTES.index, hash: '#tours_section' });
    } else if (variant === '1') {
      history.push(ROUTES.library);
    } else {
      history.push(ROUTES.index);
    }
  };

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleClick}>
      <Icon color={color} size={size * 1.4} />
      <AppHeaderButtonText size={size} color={color} rtl={props.rtl}>
        {t('Tours library')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};
