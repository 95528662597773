import React from 'react';
import Alert from '../../../components/Alert';
import { useTranslation } from 'react-i18next';

interface Props {
  errorMessage: string;
}

// Purchase failure alert
const PurchaseFailure: React.FC<Props> = ({ errorMessage }) => {
  const { t } = useTranslation();

  return (
    <Alert
      title={t('Your order could not be completed')}
      message={t(errorMessage)}
    />
  );
};

export default PurchaseFailure;
