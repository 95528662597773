import React from 'react';
import GoogleMapReact from 'google-map-react';
import * as Sentry from '@sentry/react';
import styled from '../../style/styled';
import { GoogleMapsLatLngLiteral } from './types';
import { GenericMarker } from './components/GenericMarker';
import { GenericMarkerData } from './types';
import { setGeoJSONStyle } from './utils/setGeoJSONStyle';
import { InfoPanel } from '../../Viewer/components/OfflineTourMap/InfoPanel';
import { SelectedFeature } from '../offlineMap/types';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';

const MAP_SPACER = '/e9cd9a2284d958214b2dcd16446986a5.png';

const poiDisabledStyles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
];

type HandleApiLoadedArgs = {
  map: google.maps.Map;
  maps: any;
  ref: Element | null;
};

interface Props {
  trailGeoJSONURI?: string | null;
  markerData: Array<GenericMarkerData>;
  zoom: number;
  center: GoogleMapsLatLngLiteral;
  initialBounds?: GoogleMapsLatLngLiteral[];
  restrictToBounds?: GoogleMapsLatLngLiteral[];
}

interface State {
  selectedPlaceID: string | null;
  searching: boolean;
}

class GoogleMapsMiniItineraryComp extends React.Component<Props, State> {
  state = {
    selectedPlaceID: null,
    searching: false,
  };

  map: google.maps.Map | null = null;
  panorama: google.maps.StreetViewPanorama | null = null;
  panoVisibilityListener: any = null;

  handleApiLoaded = ({ map, maps }: HandleApiLoadedArgs) => {
    if (!map) {
      Sentry.captureException(
        new Error('[GoogleMaps:handleApiLoaded] map is null')
      );
      return;
    }

    this.map = map;

    // This is important. This might be a hack though.
    // Viewing streetview with gyro enabled (i.e. mobile) would slow the whole app down, increasingly with each launch.
    // Disabling it when turning streetview off would fix it.
    // So, we grab a handle to the streetview, and add a listener to visible_changed event, and disable gyro it when streetview is disabled.
    this.panorama = map.getStreetView();
    this.panoVisibilityListener = google.maps.event.addListener(
      this.panorama,
      'visible_changed',
      this.streetViewPanoramaVisibilityChanged
    );

    // fit map to bounds
    if (this.props.initialBounds) {
      this.map.fitBounds(
        new maps.LatLngBounds(
          this.props.initialBounds[0],
          this.props.initialBounds[1]
        ),
        64
      );
    }

    // load geojson layer if provices
    if (this.props.trailGeoJSONURI) {
      this.map.data.loadGeoJson(this.props.trailGeoJSONURI);
      this.map.data.setStyle(setGeoJSONStyle);
    }
  };

  streetViewPanoramaVisibilityChanged = () => {
    if (!this.panorama) {
      return;
    }

    const visible = this.panorama.getVisible();

    try {
      // toggle gyro when toggling visibility of streetview
      this.panorama.setMotionTracking(visible);
    } catch (error) {
      captureInSentry(
        'GoogleMapsMini.tsx GoogleMapsMiniItineraryComp streetViewPanoramaVisibilityChanged() Error disabling setMotionTracking'
      );
    }
  };

  componentWillUnmount() {
    if (this.panorama) {
      this.panorama.setMotionTracking(false);
    }

    // cleanup the streetview visible_changed event listener
    if (this.panoVisibilityListener) {
      google.maps.event.removeListener(this.panoVisibilityListener);
    }

    // dunno if this is necessary, but at least it would help with GC I guess?
    this.panorama = null;
    this.map = null;
  }

  createMapOptions = (maps: any) => {
    const mapOptions: any = {
      controlSize: 24,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: false,
      // disabling this because it conflicts with custom close button
      // doesn't work on iOS anyway, and on the Desktop you can resize the window
      fullscreenControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
      streetViewControlOptions: {
        position: google.maps.ControlPosition.LEFT_TOP,
      },
    };

    mapOptions.mapTypeId = google.maps.MapTypeId.ROADMAP;
    mapOptions.styles = poiDisabledStyles;

    return mapOptions;
  };

  selectPlace = (placeID: string | null) => {
    this.setState({ selectedPlaceID: placeID });
  };

  renderMarkersFromProps = () => {
    return this.props.markerData.map((data) => (
      <GenericMarker
        key={`${data.type}_${data.id}`}
        data={data}
        selected={
          typeof this.state.selectedPlaceID === 'string' &&
          this.state.selectedPlaceID === data.id
        }
        setSelected={this.selectPlace}
        {...data.position}
      />
    ));
  };

  render() {
    const selectedFeature = getSelectedFeature(
      this.props.markerData,
      this.state.selectedPlaceID
    );

    return (
      <MapGroupContainer>
        <MapContainer>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyByuN9H0H55DSkogLlOcZBxTcU2QaYzsfw',
            }}
            defaultCenter={{ lat: 0, lng: 0 }}
            center={this.props.center}
            defaultZoom={11}
            zoom={this.props.zoom}
            options={this.createMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={this.handleApiLoaded}
          >
            {this.renderMarkersFromProps()}
          </GoogleMapReact>
        </MapContainer>

        <InfoPanel
          selectedFeature={selectedFeature}
          onClose={() => this.selectPlace(null)}
          withViewpointVisiting={false}
        />
      </MapGroupContainer>
    );
  }
}

function getSelectedFeature(
  markerData: Array<GenericMarkerData>,
  id: string | null
): SelectedFeature | null {
  if (!id) {
    return null;
  }

  const marker = markerData.find((data) => data.id === id);

  if (!marker) {
    return null;
  }

  const { position, ...rest } = marker;

  const feature = {
    ...rest,
    position: [position.lng, position.lat],
  };

  return feature;
}

export default GoogleMapsMiniItineraryComp;

const MapGroupContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const MapContainer = styled.div`
  background: #eee url(${MAP_SPACER});
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;
