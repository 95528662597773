import React, { useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from '../../../components/Modal';
// import { ForgotPasswordScreen } from '../../../Routes/Auth/forgotPassword/ForgotPasswordScreen';
import { LogInScreen } from '../../../Routes/Auth/login/LogInScreen';
// import { SignUpScreen } from '../../../Routes/Auth/signup/SignUpScreen';
import { setModalType } from '../../../store/modal/actions';
import {
  selectModalType,
  selectModalData,
} from '../../../store/modal/selectors';
import { ModalType } from '../../../store/modal/types';
import SessionExpired from './SessionExpired';
import OrientationAlert from './OrientationAlert';
import TourEndPopup from './TourEndPopup/TourEndPopup';
import TrialTourEndPopup from './TrialTourEndPopup';
import TourExitConfirmationPopup from './TourExitConfirmationPopup';
import PurchaseFailure from './PurchaseFailure';
import GiftPurchaseMeaning from './GiftPurchaseMeaning';
import TourItinerary from './TourItinerary';
import SingleImageViewer from './SingleImageViewer';
import MultiImageViewer from './MultiImageViewer';
import { SuccessMessage } from '../../../Routes/Business/SuccessMessage';
import { ErrorView } from '../../../components/ErrorView';
import { ActivateProduct } from './ActivateProduct/ActivateProduct';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import { ConvertGuestAccount } from './ConvertGuestAccount';
import WHEPromoCode from './WHEPromoCode';

const Modals: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const modalType = useSelector(selectModalType);
  const modalData = useSelector(selectModalData);
  const [activateError, setActivateError] = useState(false);

  if (modalType === ModalType.SESSION_EXPIRED_MODAL) {
    return (
      <Modal
        name="Session Expired Alert"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Session Expired Alert',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
        isOpen
        fullPage={false}
        lockBackground
      >
        <SessionExpired />
      </Modal>
    );
  }

  if (modalType === ModalType.PURCHASE_FAILURE) {
    return (
      <Modal
        name="Purchase Failed Alert"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Purchase Failed Alert',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
        isOpen
        fullPage={false}
        lockBackground
      >
        <PurchaseFailure errorMessage={modalData.errorMessage} />
      </Modal>
    );
  }

  if (modalType === ModalType.AUTH_LOGIN) {
    return (
      <Modal
        name="Login"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Login',
              },
            ],
          });

          dispatch(setModalType({ modalType: ModalType.NONE }));
        }}
        isOpen
        fullPage={isMobileOnly}
        lockBackground={!isMobile}
        clickOutsideToClose={false}
      >
        <LogInScreen
          reason={modalData.reason}
          redirectedHref={modalData.redirectedHref}
          originalHref={modalData.originalHref}
          errorMessage={modalData.errorMessage}
        />
      </Modal>
    );
  }

  if (modalType === ModalType.BAD_ORIENTATION_ALERT) {
    return (
      <Modal
        name="Bad orientation alert"
        isOpen
        fullPage={isMobileOnly}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Bad orientation alert',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <OrientationAlert />
      </Modal>
    );
  }

  if (modalType === ModalType.TOUR_END_CTA) {
    return (
      <Modal
        name="Tour end"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={false}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Tour end',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <TourEndPopup
          tourID={modalData.tourID}
          tourName={modalData.tourName}
          tourInternalReference={modalData.tourInternalReference}
        />
      </Modal>
    );
  }

  if (modalType === ModalType.TRIAL_TOUR_END_CTA) {
    return (
      <Modal
        name="Trial tour end"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={false}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Trial tour end',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <TrialTourEndPopup />
      </Modal>
    );
  }

  if (modalType === ModalType.TRIAL_TOUR_END_CTA_ITINERARY) {
    return (
      <Modal
        name="Trial tour end itinerary"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={false}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Trial tour end itinerary',
              },
            ],
          });

          dispatch(
            setModalType({
              modalType: ModalType.TRIAL_TOUR_END_CTA,
              modalData: null,
            })
          );
        }}
      >
        <TourItinerary internalReference={modalData.internalReference} />
      </Modal>
    );
  }

  if (modalType === ModalType.TOUR_EXIT_CONFIRMATION) {
    return (
      <Modal
        name="Exit tour confirmation"
        isOpen
        fullPage={false}
        clickOutsideToClose={false}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Exit tour confirmation',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <TourExitConfirmationPopup
          tourID={modalData.tourID}
          viewpointID={modalData.viewpointID}
          isTrialTour={modalData.isTrialTour}
        />
      </Modal>
    );
  }

  if (modalType === ModalType.GIFT_PURCHASE_MEANING) {
    return (
      <Modal
        name="Gift purchase meaning"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={true}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Gift purchase meaning',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <GiftPurchaseMeaning />
      </Modal>
    );
  }

  if (modalType === ModalType.ITINERARY) {
    return (
      <Modal
        name="Itinerary"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={true}
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Itinerary',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <TourItinerary internalReference={modalData.internalReference} />
      </Modal>
    );
  }

  if (modalType === ModalType.SINGLE_IMAGE_VIEWER) {
    return (
      <Modal
        name="SingleImageViewer"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={true}
        padding="0"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'SingleImageViewer',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <SingleImageViewer imageURL={modalData.imageURL} />
      </Modal>
    );
  }

  if (modalType === ModalType.MULTI_IMAGE_VIEWER) {
    return (
      <Modal
        name="MultiImageViewer"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={true}
        padding="0"
        withNoScrolling
        contentBgColor="#000"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'MultiImageViewer',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <MultiImageViewer
          images={modalData.images}
          selectedIndex={modalData.selectedIndex}
        />
      </Modal>
    );
  }

  if (modalType === ModalType.BUSINESS_APPLICATION_SUCCESS_MESSAGE) {
    return (
      <Modal
        name="BusinessApplicationSuccessMessage"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={true}
        padding="0"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'BusinessApplicationSuccessMessage',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <SuccessMessage />
      </Modal>
    );
  }

  if (modalType === ModalType.ERROR) {
    return (
      <Modal
        name="ErrorMessage"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={true}
        padding="0"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'ErrorMessage',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <ErrorView error={modalData.error} />
      </Modal>
    );
  }

  if (modalType === ModalType.ACTIVATE_PRODUCT_MODAL) {
    return (
      <Modal
        activateError={activateError}
        name="Activate product"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={false}
        padding="0"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Activate product',
              },
            ],
          });

          if (modalData.code) {
            history.replace(location.pathname);
          }

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <ActivateProduct
          code={modalData.code}
          setActivateError={setActivateError}
        />
      </Modal>
    );
  }

  if (modalType === ModalType.CONVERT_GUEST_ACCOUNT) {
    return (
      <Modal
        name="Convert guest account"
        isOpen
        fullPage={isMobileOnly}
        clickOutsideToClose={false}
        padding="0"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'Convert guest account',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <ConvertGuestAccount />
      </Modal>
    );
  }

  if (modalType === ModalType.WHE_PROMO_CODE) {
    return (
      <Modal
        name="AHE Promo Code"
        isOpen
        fullPage={false}
        clickOutsideToClose={true}
        padding="0"
        onClose={() => {
          captureEvent({
            name: EventNames.CLOSE_MODAL,
            data: [
              {
                key: DataKeys.MODAL,
                value: 'AHE Promo Code',
              },
            ],
          });

          dispatch(
            setModalType({ modalType: ModalType.NONE, modalData: null })
          );
        }}
      >
        <WHEPromoCode discountRate={modalData.discountRate || 0} />
      </Modal>
    );
  }

  return null;
};

export default Modals;
