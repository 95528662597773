import React from 'react';
import { Section } from '../../../components/contentSections/toursSection/tourInfo/styledComponents/Section';
import { SectionTitle } from '../../../components/contentSections/toursSection/tourInfo/styledComponents/SectionTitle';
import styled from '../../../style/styled';
import { useTranslation } from 'react-i18next';

const GiftPurchaseMeaning: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('Purchasing tours as gifts')}</Title>

      <Section>
        <SectionText>
          {t(
            'When you purchase a tour as a gift, you will receive a voucher code that unlocks that particular tour.'
          )}
        </SectionText>

        <SectionText>
          {t(
            'You can give this voucher code to someone else to redeem the tour.'
          )}
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>
          {t('Where can I find the voucher codes I purchased?')}
        </SectionTitle>

        <SectionText>
          {t('Voucher codes can be found in My Tours page.')}
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>
          {t('How does someone redeem the voucher code?')}
        </SectionTitle>

        <SectionText>
          {t(
            'Click the REDEEM button at the top right hand corner to open the redemption dialog. Enter the code and submit. If the code is valid, the tour will be automatically added to My Trips section.'
          )}
        </SectionText>

        <SectionText>
          {t(
            'If the user is currently not logged in, they will be asked to login or create an account before that can redeem the tour.'
          )}
        </SectionText>
      </Section>

      <Section>
        <SectionTitle>{t('How long are the tours valid for?')}</SectionTitle>

        <SectionText>
          {t(
            'Tours redeemed using a voucher code are valid for 6 months in general or as indicated when checking out.'
          )}
        </SectionText>

        <SectionText>
          {t(
            'The count down starts from the time the voucher code is redeemed, and not from the time it was purchased. The user who redeemed the tour enjoys the full validity period of the tour.'
          )}
        </SectionText>
      </Section>
    </Container>
  );
};

export default GiftPurchaseMeaning;

const Container = styled.div`
  user-select: none;
  padding: 20px;
  max-width: 600px;
`;

const Title = styled.h3`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const SectionText = styled.p`
  margin-bottom: 16px;
`;
