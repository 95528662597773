import { Howl } from 'howler';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { setUnlocked } from './howlerSetup';

interface Sound {
  url: string;
}

interface SoundInstance {
  id?: number;
  api: Howl;
  sound: Sound;
}

type PlayMusicOptions = {
  play: boolean;
  muted: boolean;
  length: number;
};

export class MusicPlayer {
  private _currentSound: SoundInstance | null = null;
  private _queuedSound: Sound | null = null;

  isPlaying = () => {
    if (this._currentSound) {
      return this._currentSound.api.playing();
    }

    return false;
  };

  isPaused = () => {
    if (this._currentSound) {
      return this._currentSound.api.playing();
    }

    return false;
  };

  playMusic = (
    sound: Sound,
    { play = true, muted = false, length }: PlayMusicOptions
  ) => {
    // if audio is currently being played
    if (this._currentSound) {
      // if the sound file is the same, continue playing
      if (this._currentSound.sound.url === sound.url) {
        // if we are to play it, then play ONLY if
        // - not muted
        // - not playing (could be playing is locked, or play wasn't called at all)
        if (!muted && play && this._currentSound.id === undefined) {
          this._currentSound.id = this._currentSound.api.play();
        } else if (!muted && play && !this.isPlaying()) {
          this._currentSound.api.play(this._currentSound.id);
        }

        return;
      }

      // queue the new sound
      this._queuedSound = sound;

      if (this._currentSound.api.playing()) {
        const current = this._currentSound;

        // fade out the current sound
        this.fadeOutSound(current).then(() => {
          if (this._currentSound) {
            this._currentSound.api.off();
            this._currentSound.api.stop();
            this._currentSound.api.unload();
          }

          this._currentSound = null;

          if (this._queuedSound) {
            // play the queued sound
            const queued = this._queuedSound;
            this._queuedSound = null;

            this.playNewSound(queued, { play, muted, length });
          }
        });
      } else {
        this._currentSound.api.unload();
        this._currentSound = null;

        if (this._queuedSound) {
          const queued = this._queuedSound;
          this._queuedSound = null;

          this.playNewSound(queued, { play, muted, length });
        }
      }
    } else if (this._queuedSound) {
      this._queuedSound = sound;
    } else {
      this.playNewSound(sound, { play, muted, length });
    }
  };

  playNewSound = (sound: Sound, { play, muted }: PlayMusicOptions) => {
    const api = new Howl({
      src: [sound.url],
      loop: false,
      volume: 0.3,
      // this causes issuses on resuming music on iPhone
      // instead, muting is done once playing has started
      // mute: muted,
      // this is important and sort of a work around.
      // on iOS, we cannot control the volume of the audio if we use HTML5.
      // we need to be able to control it to so that the music doesn't down out the narration.
      // technically, when we disable HTML5, we can't play the audio when the iOS device's screen is off,
      // however, since we use HTML5 for the narration, it seems to work?! Safari is weird.
      // HTML5 works great on Android.
      html5: true,
      // volume: 0.1,
    });

    api.on('end', () => {});

    api.on('load', () => {});

    api.on('play', () => {});

    api.on('playerror', (id, error) => {
      api?.off();
      api?.unload();
      console.error('onplayerror:', error);

      captureInSentry(
        // @ts-ignore
        error?.message || 'MusicPlayer.tsx playNewSound Unknown error',
        { src: sound.url }
      );
    });

    api.on('loaderror', () => {
      api?.off();
      api?.unload();
    });

    api.on('unlock', setUnlocked);

    // store the current sound with a handle to its howler instance
    this._currentSound = { api, sound };

    // IMPORTANT: this is an edge case
    // iphone doesn't like playing muted. can't unmute. so, we don't play if muted. it's fine for music.
    if (!muted && play) {
      this._currentSound.id = api.play();
    }
  };

  pauseMusic = () => {
    if (this._currentSound) {
      this._currentSound.api.pause();
    }
  };

  resumeMusic = () => {
    if (this._currentSound) {
      const { api, id } = this._currentSound;

      if (id) {
        api.play(id);
      } else {
        this._currentSound.id = api.play();
      }
    }
  };

  muteMusic = (mute: boolean) => {
    if (this._currentSound) {
      const { api, id } = this._currentSound;

      // IMPORTANT: this is an edge case
      // if we have been muted from the beginning, then we would not have played.
      // so, we have to play
      if (!mute && !id) {
        this._currentSound.id = api.play();
      } else {
        if (id) {
          api.mute(mute, id);
        } else {
          api.mute(mute);
        }
      }
    }
  };

  restartMusic = () => {
    if (this._currentSound) {
      const { api, id } = this._currentSound;

      if (id) {
        api.play(id);
      } else {
        this._currentSound.id = api.play();
      }
    }
  };

  stop = () => {
    this._queuedSound = null;

    const current = this._currentSound;
    this._currentSound = null;

    if (!current) {
      return;
    }

    // remove any events currently being listened to
    current.api.off();

    if (current.api.playing()) {
      this.fadeOutSound(current).then(() => {
        current.api.unload();
      });
    } else {
      current.api.unload();
    }
  };

  fadeOutSound = ({ api }: SoundInstance) => {
    return new Promise<void>((resolve) => {
      api.once('fade', () => {
        api.off();
        api.stop();

        resolve();
      });

      api.fade(api.volume(), 0, 500);
    });
  };
}
