// this is required to continue playing the narration on iOS
// Howler.autoSuspend = false;

// whether browser has determined that we can play audio
let _unlocked = true;

export function getAudioUnlocked(): boolean {
  // if (_unlocked) {
  //   return true;
  // }

  // try {
  //   const locked = await isAudioLocked();

  //   return !locked;
  // } catch (error) {
  //   return false;
  // }

  return _unlocked;
}

export function setUnlocked() {
  _unlocked = true;
}

// const api = new Howl({
//   src: [EMPTY_AUDIO_URL],
//   loop: false,
//   html5: true,
//   onunlock: setUnlocked,
//   onplayerror: () => {
//     api?.off();
//     api?.unload();
//   },
//   onloaderror: () => {
//     api?.off();
//     api?.unload();
//   },
//   onend: () => {
//     api?.off();
//     api?.unload();
//   },
// });

// let id: number | null = null;

export function unlock() {
  if (_unlocked) {
    return;
  }

  // if (id) {
  //   api.play(id);
  // } else {
  //   id = api.play();
  // }
}

// const isAudioLocked = () => {
//   return new Promise<boolean>((resolve, reject) => {
//     const checkHTML5Audio = async () => {
//       const audio = new Audio();

//       try {
//         audio.play();

//         resolve(false);
//       } catch (error) {
//         resolve(true);
//       }
//     };

//     try {
//       // @ts-expect-error
//       const context = new (window.AudioContext || window.webkitAudioContext)();

//       resolve(context.state === 'suspended');
//     } catch (error) {
//       checkHTML5Audio();
//     }
//   });
// };
