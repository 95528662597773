import React, { useState } from 'react';
import AppContext, {
  Config,
  GET_APP_FOR_APP_PROVIDER_QUERY,
  QUERY_ID,
} from '../context/appContext';
import { useQuery } from '@apollo/client';
import {
  EXPLORE_WEB_GetAppForAppProvider as Data,
  EXPLORE_WEB_GetAppForAppProviderVariables as Variables,
} from '../context/__generated__/EXPLORE_WEB_GetAppForAppProvider';
import { useDispatch, useSelector } from 'react-redux';
import { selectFetchPolicy } from '../store/offline/selectors';
import { setQueryCachedAt } from '../store/offline/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getAppConfig } from '../utils/getAppConfig';
import { setAppID } from '../utils/setAppID';
import { setWhitelabelAppSettings } from '../store/app/actions';

type Props = {
  appID: string;
  children: React.ReactNode;
};

const AppProvider = ({ appID, children }: Props) => {
  const dispatch = useDispatch();
  const fetchPolicy = useSelector(selectFetchPolicy(QUERY_ID));
  const [config, setConfig] = useState<Config | null>(null);

  const { data, refetch } = useQuery<Data, Variables>(
    GET_APP_FOR_APP_PROVIDER_QUERY,
    {
      fetchPolicy,
      errorPolicy: 'all',
      variables: {
        input: {
          appID,
        },
      },
      onCompleted: (data) => {
        if (fetchPolicy === 'cache-and-network' && data.app) {
          dispatch(
            setQueryCachedAt({ queryID: QUERY_ID, timestampInMs: Date.now() })
          );
        }
      },
    }
  );

  const app = data?.app || null;

  useDeepCompareEffect(() => {
    if (app) {
      // doesn't matter if it's still fetching from the network or not,
      // if there is app, load the config, setAppID and setAppEnv
      getAppConfig(app).then((result) => {
        setAppID(app.appID);
        dispatch(setWhitelabelAppSettings(result.settings));

        // It is critical setConfig is called after setWhitelabelAppSettings
        setConfig({
          stylesheet: result.stylesheet,
          theme: result.theme,
        });
      });
    }
  }, [app, dispatch]);

  // loading spinner similar to the one in the index.html (bouncing logo)
  if (!config) {
    return (
      <div className="full-page-loader-container">
        <div>
          <img src="/apple-touch-icon.png" alt="" />
        </div>
      </div>
    );
  }

  return (
    <AppContext.Provider value={{ app, refetch, config }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
