import React from 'react';
import styled, { keyframes } from '../../../style/styled';
import { Lottie } from '../../../components/Lottie';
import { whiteLabelAppTitleForTradeShow } from '../../../App/config/enums';
import animationDataBlack from '../../Tour/default/ruinsLoadingAnimationBlack.json';
import animationDataWhite from '../../Tour/default/ruinsLoadingAnimationWhite.json';
import { selectWhitelabelAppSettings } from '../../../store/app/selectors';
import { useSelector } from 'react-redux';
const WHITE = '/plane_loading_white.png';
const BLACK = '/plane_loading_black.png';
interface Props {
  black?: boolean;
  height?: number;
  width?: number;
}

export const LoadingIndicator = ({
  black = false,
  height = 100,
  width = 100,
}: Props) => {
  const {
    banner: { title },
  } = useSelector(selectWhitelabelAppSettings);

  return (
    <>
      {whiteLabelAppTitleForTradeShow.indexOf(title) >= 0 ? (
        <Container height={height} width={width}>
          <Lottie
            height={height}
            width={width}
            options={{
              loop: true,
              autoplay: true,
              animationData: black ? animationDataBlack : animationDataWhite,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
          />
        </Container>
      ) : (
        <AnimationContainer height={height} width={width}>
          <img width="100%" height="100%" src={black ? BLACK : WHITE} alt="" />
        </AnimationContainer>
      )}
    </>
  );
};

const rotate = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<{
  height: number;
  width: number;
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;
const AnimationContainer = styled.div<{
  height: number;
  width: number;
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  animation: ${rotate} 1.2s linear infinite;
`;
