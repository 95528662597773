import React from 'react';
import styled from '../style/styled';

const StyledButton = styled.button<{
  bgColor?: string;
  secondary?: boolean;
  highlight?: boolean;
  width?: string;
  padding?: string;
  uppercase?: boolean;
  small?: boolean;
  boldText?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ bgColor, secondary, highlight, theme }) => {
    if (bgColor) {
      return bgColor;
    } else if (secondary) {
      return theme.palette.secondary.main;
    } else if (highlight) {
      return theme.palette.highlight.main;
    }

    return theme.palette.primary.main;
  }};
  color: ${({ color, secondary, highlight, theme }) => {
    if (color) {
      return color;
    } else if (secondary) {
      return theme.palette.secondary.contrastColor;
    } else if (highlight) {
      return theme.palette.highlight.contrastColor;
    }

    return theme.palette.primary.contrastColor;
  }};
  width: ${({ width }) => width};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  outline: none;
  padding: ${({ padding }) => (padding ? padding : '8px')};
  user-select: none;

  border: ${({ bgColor, secondary, highlight, theme }) => {
    if (bgColor) {
      return `1px solid ${bgColor}`;
    } else if (secondary) {
      return `1px solid ${theme.palette.secondary.contrastColor}`;
    } else if (highlight) {
      return `1px solid ${theme.palette.highlight.main}`;
    }

    return `1px solid ${theme.palette.primary.main}`;
  }};

  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'auto')};
  appearance: none;

  font-weight: ${({ boldText }) => (boldText ? 'bold' : 'inherit')};

  -webkit-tap-highlight-color: transparent;
  transition: box-shadow 0.2s ease-in-out;
  &::-moz-focus-inner {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  @media not all and (hover: none) {
    &:hover {
      box-shadow: ${({ disabled }) =>
        disabled ? 'none' : '0px 0px 10px -1px #000000aa'};
    }
  }

  @media (hover: none) and (pointer: coarse) {
    padding: ${(props) => (props.small ? 8 : 15)}px;
  }
`;

// TODO: add captureEvent to onClick
// TODO: write tests
export const Button = ({ children, ...restProps }: any) => (
  <StyledButton {...restProps}>{children}</StyledButton>
);
