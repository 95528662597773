import { FetchPolicy } from '@apollo/client';
import { getApolloClient } from '../../../App/config/apollo/ApolloClient';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { UNKNOWN_ERROR } from '../../../consts';
import {
  GET_FULL_TOUR_QUERY,
  Data,
  Variables,
} from '../../../graphql/queries/GetFullTourQuery';

export async function fetchTour(
  playlistID: string,
  fetchPolicy: FetchPolicy
  // mobileProjectionsOnly: boolean = false
) {
  const variables = { playlistID };

  try {
    const client = await getApolloClient();
    // important: this needs to be same as the one in TourRoutesView.tsx
    const response = await client.query<Data, Variables>({
      query: GET_FULL_TOUR_QUERY,
      fetchPolicy,
      variables,
    });

    return response?.data?.result;
  } catch (error: any) {
    captureInSentry(error?.message || UNKNOWN_ERROR, { playlistID });

    return null;
  }
}
