export class GPSKalmanFilter {
  decay: number;
  variance = -1;
  minAccuracy = 1;
  lat = 0;
  lng = 0;
  timestampInMs = 0;

  constructor(decay = 3) {
    this.decay = decay;
  }

  reset() {
    this.variance = -1;
    this.minAccuracy = 1;
    this.lat = 0;
    this.lng = 0;
    this.timestampInMs = 0;
  }

  process(lat: number, lng: number, accuracy: number, timestampInMs: number) {
    if (accuracy < this.minAccuracy) {
      accuracy = this.minAccuracy;
    }

    if (this.variance < 0) {
      this.timestampInMs = timestampInMs;
      this.lat = lat;
      this.lng = lng;
      this.variance = accuracy * accuracy;
    } else {
      const timeIncMs = timestampInMs - this.timestampInMs;

      if (timeIncMs > 0) {
        this.variance += (timeIncMs * this.decay * this.decay) / 1000;
        this.timestampInMs = timestampInMs;
      }

      const _k = this.variance / (this.variance + accuracy * accuracy);

      this.lat += _k * (lat - this.lat);
      this.lng += _k * (lng - this.lng);

      this.variance = (1 - _k) * this.variance;
    }

    return [this.lng, this.lat];
  }
}
