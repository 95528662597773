import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from '../../../../style/styled';
import { SCREEN_WIDTH_BREAK_EXTRA_SMALL } from '../../../../style/SIZES';
import { ROUTES } from '../../../../Routes/routes';
import { REDBUBBLE_ICON } from '../../../../features/offline/appAssets';
import { getCDNURI } from '../../../../utils/getCDNURI';

export const Shop: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ShopContainer>
      <h3>{t('Shop')}</h3>

      <ShopButtonsContainer>
        <Link to={ROUTES.merch}>
          <img alt="" src={getCDNURI(REDBUBBLE_ICON)} width={32} height={32} />
        </Link>
      </ShopButtonsContainer>
    </ShopContainer>
  );
};

const ShopButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 8px;
  margin: 0 auto;
`;

const ShopContainer = styled.div`
  grid-column: 2/3;
  text-align: center;
  display: grid;
  grid-gap: 8px;

  h3 {
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_EXTRA_SMALL}px) {
    grid-column: 1/2;
  }
`;
