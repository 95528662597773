import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from '../graphql/fragments/Asset';
import { PRICE_FRAGMENT } from '../graphql/fragments/Price';
import { I18NSTRING } from '../graphql/fragments/I18nString';
import { createContext } from 'react';
import { EXPLORE_WEB_GetAppForAppProvider_app } from './__generated__/EXPLORE_WEB_GetAppForAppProvider';
import { ITheme } from '../features/whitelabeling/branding/types';

export const QUERY_ID = 'APP_CONTEXT';

export const GET_APP_FOR_APP_PROVIDER_QUERY = gql`
  query EXPLORE_WEB_GetAppForAppProvider($input: GetAppInput!) {
    app: getApp(input: $input) {
      id
      appID
      url
      stylesheet {
        ...AssetFragment
      }
      theme {
        ...AssetFragment
      }
      settings {
        ...AssetFragment
      }
      curatedPlaylists {
        id
        internalReference
        trial
        published
        descriptionI18n {
          ...I18nStringFragment
        }
        countries {
          id
          name
        }
        nameI18n {
          ...I18nStringFragment
        }
        viewpoints {
          imagesCount
        }
        validityPeriodMonths
        trailLength
        timePeriod
        audioDurationMinutes
        price {
          ...PriceFragment
        }
        pastThumbnail {
          ...AssetFragment
        }
        presentThumbnail {
          ...AssetFragment
        }
      }
      playlistBundles {
        id
        internalReference
        published
        nameI18n {
          ...I18nStringFragment
        }
        price {
          ...PriceFragment
        }
        thumbnail {
          ...AssetFragment
        }
        presentThumbnail {
          ...AssetFragment
        }
        playlists {
          id
        }
      }
    }
  }

  ${ASSET_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${I18NSTRING}
`;

export type App = EXPLORE_WEB_GetAppForAppProvider_app;

export type Config = {
  theme: ITheme;
  stylesheet: string;
};

export type AppContextValueType = {
  app: App | null;
  refetch: () => void;
  config: Config | null;
};

const AppContext = createContext<AppContextValueType>({
  app: null,
  refetch: () => {},
  config: null,
});

export default AppContext;
