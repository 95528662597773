import { handleActions } from 'redux-actions';
import {
  ACTION_TYPES,
  OpenImageByIndexAction,
  OpenTourSuccessAction,
  SetAudioStatePartialAction,
  SetChangingSliderAction,
  setClosestViewpointIndexAction,
  SetCompassSupportAction,
  SetContentIndexAction,
  SetImageCaptionAction,
  SetNarrationLanguageAction,
  SetSubtitleLanguageAction,
  SetSubtitleVisibilityAction,
  SetViewerLoadingStateAction,
  SetViewerOptionsAction,
  SetVPVisitedAction,
  UpdateNarrationLengthAction,
  UpdateNarrationSeekPosAction,
  SetCameraFacingDirectionAction,
  SetViewpointSwitchingStateAction,
  SetContentVpIndexAction,
  SetArtefactIndexAction,
  SetArtefactClickPositionAction,
  SetDiscoveryVisitedAction,
  SetDiscoveryIDAction,
  InitDiscoveryVisitedAction,
  SetDeviceOrientationAction,
} from './actions';
import {
  ACTION_TYPES as APP_ACTION_TYPES,
  SetLanguageAction,
} from '../app/actions';
import { initialState, ViewerState } from './types';

export const reducer = handleActions<ViewerState, any>(
  {
    [ACTION_TYPES.CLOSE_VIEWER]: (state: ViewerState): ViewerState => ({
      ...initialState,
      viewerOptions: {
        ...initialState.viewerOptions,
        isTutorialVisible: state.viewerOptions.isTutorialVisible,
        tutorialDisplayed: state.viewerOptions.tutorialDisplayed,
      },
      audio: {
        ...initialState.audio,
        narrationLanguage: state.audio.narrationLanguage,
        subtitlesLanguage: state.audio.subtitlesLanguage,
      },
    }),

    [ACTION_TYPES.OPEN_TOUR_SUCCESS]: (
      state: ViewerState,
      { payload }: OpenTourSuccessAction
    ): ViewerState => ({
      ...state,
      tour: payload.tour,
      currentViewpointIndex: payload.currentViewpointIndex,
      currentVpIndex: payload.currentVpIndex,
      viewerOptions: {
        ...initialState.viewerOptions,
        isTutorialVisible: state.viewerOptions.isTutorialVisible,
        tutorialDisplayed: state.viewerOptions.tutorialDisplayed,
        ...payload.viewerOptions,
      },
      audio: {
        ...initialState.audio,
        narrationLanguage: state.audio.narrationLanguage,
        subtitlesLanguage: state.audio.subtitlesLanguage,
      },
    }),

    [ACTION_TYPES.INCREMENT_VIEWPOINT_INDEX]: (
      state: ViewerState
    ): ViewerState => {
      const { tour, currentViewpointIndex, visitedVPIndices } = state;

      if (!tour) {
        return state;
      }

      const { stops } = tour;

      const nextViewpointIndex =
        currentViewpointIndex + 1 === stops.length
          ? 0
          : currentViewpointIndex + 1;
      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(nextViewpointIndex)),
      ];

      return {
        ...state,
        currentViewpointIndex: nextViewpointIndex,
        previousViewpointIndex: currentViewpointIndex,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
        visitedVPIndices: nextVsitedVPIndices,
        // audio: {
        //   ...state.audio,
        //   playingState: null,
        // },
      };
    },

    [ACTION_TYPES.DECREMENT_VIEWPOINT_INDEX]: (
      state: ViewerState
    ): ViewerState => {
      const { currentViewpointIndex, visitedVPIndices, tour } = state;

      if (!tour) {
        return state;
      }

      const { stops } = tour;

      const previousViewpointIndex =
        (currentViewpointIndex < 1 ? stops.length : currentViewpointIndex) - 1;
      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(previousViewpointIndex)),
      ];

      return {
        ...state,
        currentViewpointIndex: previousViewpointIndex,
        previousViewpointIndex: currentViewpointIndex,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
        visitedVPIndices: nextVsitedVPIndices,
        // audio: {
        //   ...state.audio,
        //   playingState: null,
        // },
      };
    },

    [ACTION_TYPES.SET_VP_VISITED]: (
      state: ViewerState,
      { payload }: SetVPVisitedAction
    ): ViewerState => {
      const { visitedVPIndices } = state;

      const nextVsitedVPIndices = [
        ...new Set(visitedVPIndices.concat(payload.index)),
      ];

      return {
        ...state,
        visitedVPIndices: nextVsitedVPIndices,
      };
    },

    [ACTION_TYPES.SET_DISCOVERY_VISITED]: (
      state: ViewerState,
      { payload }: SetDiscoveryVisitedAction
    ): ViewerState => {
      const { visitedDiscoveryIDs } = state;

      const nextVsitedDiscoveryIDs = [
        ...new Set(visitedDiscoveryIDs.concat(payload.id)),
      ];

      return {
        ...state,
        visitedDiscoveryIDs: nextVsitedDiscoveryIDs,
      };
    },

    [ACTION_TYPES.INIT_DISCOVERY_VISITED]: (
      state: ViewerState,
      { payload }: InitDiscoveryVisitedAction
    ): ViewerState => {
      return {
        ...state,
        visitedDiscoveryIDs: payload.ids,
      };
    },

    [ACTION_TYPES.SET_VIEWPOINT_INDEX]: (
      state: ViewerState,
      action: SetContentIndexAction
    ): ViewerState => {
      const { currentViewpointIndex, visitedVPIndices, tour } = state;
      const indexRequested = action.payload.index;
      const projectionNameRequested = action.payload.projectionName || '';

      if (!tour || typeof indexRequested !== 'number') {
        return state;
      }

      const { stops } = tour;

      // if it's the same vp or it's not available (unlikely)
      // don't do anything.
      if (indexRequested === currentViewpointIndex || !stops[indexRequested]) {
        return {
          ...state,
          userSelectedAViewpoint: true,
        };
      }

      const nextVisitedVPIndices = [
        ...new Set(visitedVPIndices.concat(indexRequested)),
      ];

      return {
        ...state,
        currentViewpointIndex: indexRequested,
        currentViewpointProjectionName: projectionNameRequested,
        previousViewpointIndex: currentViewpointIndex,
        // reset the currentVpIndex to 0
        currentVpIndex: 0,
        previousVpIndex: -1,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
        visitedVPIndices: nextVisitedVPIndices,
        userSelectedAViewpoint: true,
      };
    },

    [ACTION_TYPES.SET_VP_INDEX]: (
      state: ViewerState,
      action: SetContentVpIndexAction
    ): ViewerState => {
      const { currentVpIndex, tour } = state;
      const indexRequested = action.payload.index;

      if (!tour || typeof indexRequested !== 'number') {
        return state;
      }

      return {
        ...state,
        currentVpIndex: indexRequested,
        previousVpIndex: currentVpIndex,
        // reset the currentImageIndex to 0
        currentImageIndex: 0,
        previousImageIndex: -1,
      };
    },

    [ACTION_TYPES.SET_CLOSEST_VIEWPOINT_INDEX]: (
      state: ViewerState,
      action: setClosestViewpointIndexAction
    ) => {
      return {
        ...state,
        closestViewpointIndex: action.payload.index,
        closestViewpointDistance: action.payload.distance,
        closestViewpointDetectionMode: action.payload.mode,
      };
    },

    [ACTION_TYPES.INCREMENT_IMAGE_INDEX]: (state: ViewerState): ViewerState => {
      const { tour, currentViewpointIndex, currentImageIndex } = state;

      if (!tour) {
        return state;
      }

      const { stops } = tour;

      const currentStop = stops[currentViewpointIndex];

      if (!currentStop) {
        return state;
      }

      const images = currentStop.images;

      // infinite scrolling
      const nextImageIndex =
        currentImageIndex + 1 === images.length ? 0 : currentImageIndex + 1;

      return {
        ...state,
        currentImageIndex: nextImageIndex,
        previousImageIndex: currentImageIndex,
      };
    },

    [ACTION_TYPES.DECREMENT_IMAGE_INDEX]: (state: ViewerState): ViewerState => {
      const { tour, currentViewpointIndex, currentImageIndex } = state;

      if (!tour) {
        return state;
      }

      const { stops } = tour;

      const currentStop = stops[currentViewpointIndex];

      if (!currentStop) {
        return state;
      }

      const images = currentStop.images;

      // infinite scrolling
      const previousImageIndex =
        (currentImageIndex < 1 ? images.length : currentImageIndex) - 1;

      return {
        ...state,
        currentImageIndex: previousImageIndex,
        previousImageIndex: currentImageIndex,
      };
    },

    [ACTION_TYPES.SET_IMAGE_INDEX]: (
      state: ViewerState,
      action: OpenImageByIndexAction
    ): ViewerState => {
      const { currentViewpointIndex, currentImageIndex, tour } = state;
      const indexRequested = action.payload;

      if (!tour || typeof indexRequested !== 'number') {
        return state;
      }

      const { stops } = tour;

      const currentStop = stops[currentViewpointIndex];

      if (!currentStop) {
        return state;
      }

      const images = currentStop.images;

      if (!images[indexRequested] || indexRequested === currentImageIndex) {
        return state;
      }

      return {
        ...state,
        currentImageIndex: indexRequested,
        previousImageIndex: currentImageIndex,
      };
    },
    [ACTION_TYPES.SET_DISCOVERY_ID]: (
      state: ViewerState,
      action: SetDiscoveryIDAction
    ) => {
      const { visitedDiscoveryIDs } = state;
      if (action.payload.id === '') {
        return {
          ...state,
          currentDiscoveryID: action.payload.id,
        };
      }
      const nextVsitedDiscoveryIDs = [
        ...new Set(visitedDiscoveryIDs.concat(action.payload.id)),
      ];
      return {
        ...state,
        currentDiscoveryID: action.payload.id,
        visitedDiscoveryIDs: nextVsitedDiscoveryIDs,
      };
    },

    [ACTION_TYPES.SET_ARTEFACT_INDEX]: (
      state: ViewerState,
      action: SetArtefactIndexAction
    ) => {
      return {
        ...state,
        currentArtefactIndex: action.payload.index,
      };
    },

    [ACTION_TYPES.SET_ARTEFACT_CLICK_POSITION]: (
      state: ViewerState,
      action: SetArtefactClickPositionAction
    ) => {
      return {
        ...state,
        artefactClickPosition: {
          x: action.payload.x,
          y: action.payload.y,
        },
      };
    },

    [ACTION_TYPES.SET_VIEWER_OPTIONS]: (
      state: ViewerState,
      { payload }: SetViewerOptionsAction
    ): ViewerState => ({
      ...state,
      viewerOptions: {
        ...state.viewerOptions,
        ...payload.options,
      },
    }),

    [ACTION_TYPES.SET_VIEWER_LOADING_STATE]: (
      state: ViewerState,
      { payload }: SetViewerLoadingStateAction
    ): ViewerState => ({
      ...state,
      isLoading: payload.isLoading,
    }),
    [ACTION_TYPES.SET_VIEWPOINT_SWITCHING_STATE]: (
      state: ViewerState,
      { payload }: SetViewpointSwitchingStateAction
    ): ViewerState => ({
      ...state,
      isViewpointSwitching: payload.isViewpointSwitching,
    }),

    [ACTION_TYPES.SET_CHANGING_SLIDER]: (
      state: ViewerState,
      { payload }: SetChangingSliderAction
    ) => {
      return {
        ...state,
        audio: {
          ...state.audio,
          changingSlider: payload,
        },
      };
    },

    [ACTION_TYPES.SET_AUDIO_STATE_PARTIAL]: (
      state: ViewerState,
      { payload }: SetAudioStatePartialAction
    ) => {
      return {
        ...state,
        audio: {
          ...state.audio,
          ...payload,
        },
      };
    },

    [ACTION_TYPES.UPDATE_NARRATION_LENGTH]: (
      state: ViewerState,
      { payload }: UpdateNarrationLengthAction
    ) => {
      const { seconds } = payload;

      return {
        ...state,
        audio: {
          ...state.audio,
          length: seconds,
          // if length is zero or negative, reset the seekpos
          seekPos: seconds <= 0 ? 0 : state.audio.seekPos,
        },
      };
    },

    [ACTION_TYPES.UPDATE_NARRATION_SEEK_POS]: (
      state: ViewerState,
      { payload }: UpdateNarrationSeekPosAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        seekPos: payload.seek,
      },
    }),

    [ACTION_TYPES.SET_IMAGE_CAPTION]: (
      state: ViewerState,
      { payload }: SetImageCaptionAction
    ) => ({
      ...state,
      imageCaption: payload.caption,
    }),

    [ACTION_TYPES.TOGGLE_SUBTITLE_VISIBILITY]: (state: ViewerState) => ({
      ...state,
      audio: {
        ...state.audio,
        showSubtitles: !state.audio.showSubtitles,
      },
    }),

    [ACTION_TYPES.SET_SUBTITLE_VISIBILITY]: (
      state: ViewerState,
      { payload }: SetSubtitleVisibilityAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        showSubtitles: payload.visibility,
      },
    }),

    [APP_ACTION_TYPES.SET_LANGUAGE]: (
      state: ViewerState,
      { payload }: SetLanguageAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        narrationLanguage: payload,
      },
    }),

    [ACTION_TYPES.SET_SUBTITLE_LANGUAGE]: (
      state: ViewerState,
      { payload }: SetSubtitleLanguageAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        subtitlesLanguage: payload.languageCode,
        showSubtitles: true,
      },
    }),

    [ACTION_TYPES.SET_NARRATION_LANGUAGE]: (
      state: ViewerState,
      { payload }: SetNarrationLanguageAction
    ) => ({
      ...state,
      audio: {
        ...state.audio,
        narrationLanguage: payload.languageCode,
      },
    }),

    [ACTION_TYPES.TOGGLE_MUTE_MUSIC]: (state: ViewerState) => ({
      ...state,
      audio: {
        ...state.audio,
        musicMuted: !state.audio.musicMuted,
      },
    }),

    [ACTION_TYPES.SET_END_OF_TOUR_POPUP_CLOSED]: (state: ViewerState) => ({
      ...state,
      endOfTourPopupClosed: true,
    }),

    [ACTION_TYPES.INCREMENT_RESYNC_WITH_COMPASS_COUNTER]: (
      state: ViewerState
    ) => ({
      ...state,
      resyncWithCompassCounter: (state.resyncWithCompassCounter || 0) + 1,
    }),
    [ACTION_TYPES.SET_DEVICE_ORIENTATION]: (
      state: ViewerState,
      { payload }: SetDeviceOrientationAction
    ) => ({
      ...state,
      deviceOrientation: payload.deviceOrientation,
    }),
    [ACTION_TYPES.SET_COMPASS_SUPPORT]: (
      state: ViewerState,
      { payload }: SetCompassSupportAction
    ) => ({
      ...state,
      compassSupport: payload.compassSupport,
    }),

    [ACTION_TYPES.TOGGLE_UI]: (state: ViewerState) => ({
      ...state,
      displayUI: !state.displayUI,
    }),
    [ACTION_TYPES.SET_CAMERA_FACING_DIRECTION]: (
      state: ViewerState,
      { payload }: SetCameraFacingDirectionAction
    ): ViewerState => ({
      ...state,
      facingDirection: payload.facingDirection,
    }),
  },
  initialState
);
