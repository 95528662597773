import React, { useState } from 'react';
import { LoadingView } from '../../components/LoadingView';
import styled from '../../style/styled';

interface Props {
  src: string;
  alt?: string;
  // height of the image in pixels
  height: number;
  // width of the image in pixels
  width: number;
  // this will be shown until the image is loaded
  bgColor?: string;
}

const LoadingContainerZIndex = 1;

export const ImageAspectRatio: React.FC<Props> = ({
  src,
  alt = '',
  height,
  width,
  bgColor = 'none',
}) => {
  const [loading, setLoading] = useState<boolean>(true);

  const aspectRatio = (height * 100) / width;

  return (
    <Container aspectRatio={aspectRatio} bgColor={bgColor}>
      {loading && (
        <LoadingContainer>
          <LoadingView showText={false} timeout={200} black />
        </LoadingContainer>
      )}

      <Image
        src={src}
        alt={alt}
        width="100%"
        height="auto"
        onLoad={() => setLoading(false)}
      />
    </Container>
  );
};

const Container = styled.div<{
  aspectRatio: number;
  bgColor: string;
}>`
  position: relative;
  padding-top: ${(props) => props.aspectRatio}%;
  background-color: ${(props) => props.bgColor};
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${LoadingContainerZIndex};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${LoadingContainerZIndex + 1};
  pointer-events: none;
`;
