import urljoin from 'url-join';

const CDN_URI = 'https://gaia.lithodomos.com';

export function getCDNURI(filename: string) {
  if (filename) {
    return urljoin(CDN_URI, filename);
  }

  return '';
}
