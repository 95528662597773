import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose, AnyAction } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import logger from 'redux-logger';
import LogRocket from 'logrocket';
import storage from 'redux-persist/lib/storage';
import { env } from '../../App/config/env';
import { UNKNOWN_ERROR } from './../../consts';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSaga';
import { RootState, SagasContext } from './types';
import { transforms } from './transforms';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { ACTION_TYPES } from '../viewer/actions';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

let _store: any;
let _persistor: any;
let _context: SagasContext;

export function createReduxStore(context: SagasContext) {
  _context = context;

  const persistedReducer = persistReducer(
    {
      version: env.REDUX_PERSIST_VERSION,
      key: env.REDUX_PERSIST_KEY,
      // localstorage by default
      storage,
      debug: env.REDUX_PERSIST_ENABLE_DEBUGGING,
      // transform the shape of the state persisted and read back
      transforms,
    },
    rootReducer
  );

  const sagaMiddleware = createSagaMiddleware({
    context: _context,
    onError: onSagaError,
  });

  const actionSanitizer = <A extends AnyAction>(action: A) => {
    if (action.type === ACTION_TYPES.OPEN_TOUR_SUCCESS) {
      return {
        type: ACTION_TYPES.OPEN_TOUR_SUCCESS,
        payload: '<<LONG_BLOB>>',
      };
    } else if (action.type === ACTION_TYPES.OPEN_TOUR_REQUEST) {
      return {
        type: ACTION_TYPES.OPEN_TOUR_REQUEST,
        payload: '<<LONG_BLOB>>',
      };
    } else {
      return {
        type: action.type,
        payload: action.payload,
      };
    }
  };

  // Refer to: https://extension.remotedev.io/#usage
  const reduxDevToolsExtensionCompose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const composeEnhancers = reduxDevToolsExtensionCompose
    ? reduxDevToolsExtensionCompose({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        actionSanitizer,
        stateSanitizer: (state: RootState) => {
          const viewer = state.viewer ? '<<LONG_BLOB>>' : state.viewer;
          const offline = state.offline ? '<<LONG_BLOB>>' : state.offline;
          return {
            ...state,
            viewer,
            offline,
          };
        },
      })
    : compose;

  const middleware = [
    sagaMiddleware,
    env.IS_STAGING_SITE && logger,
    // Note: LogRocket should be the final middleware
    env.ENABLE_LOG_ROCKET_REPORTING &&
      LogRocket.reduxMiddleware({
        stateSanitizer: function (state: RootState) {
          // sanitise state
          const appState = state.app;

          let user = appState?.user;

          if (user) {
            user = { ...user, jwt: 'removed' };
          }

          return {
            ...state,
            appState: { ...appState, user },
          };
        },
      }),
  ].filter(Boolean);

  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  _store = createStore(persistedReducer, enhancer);

  _persistor = persistStore(_store);

  sagaMiddleware.run(rootSaga);

  return {
    store: _store,
    persistor: _persistor,
  };
}

function onSagaError(error: any) {
  captureInSentry(
    `createReduxeStore.ts onSagaError() error: ${
      error?.message || UNKNOWN_ERROR
    }`
  );
}
