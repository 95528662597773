import { useContext, useEffect } from 'react';
import OLVectorLayer from 'ol/layer/Vector';
import { Options } from 'ol/layer/BaseVector';
import MapContext from '../MapContext';

interface Props {
  zIndex?: number;
  layerOptions: Options;
}

const VectorLayer: React.FC<Props> = ({ layerOptions, zIndex = 0 }) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    let vectorLayer: OLVectorLayer | null = null;

    if (map) {
      vectorLayer = new OLVectorLayer(layerOptions);

      map.addLayer(vectorLayer);
      vectorLayer.setZIndex(zIndex);
    }

    return () => {
      if (map && vectorLayer) {
        map.removeLayer(vectorLayer);
      }
    };
  }, [map, layerOptions, zIndex]);

  return null;
};

export default VectorLayer;
