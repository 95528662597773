import { createAction } from 'redux-actions';
import { QueueStatus, JobStatus, Asset } from './types';

export const ACTION_TYPES = {
  DOWNLOAD_COMPLETE: 'downloadComplete',
  REMOVE_ASSET: 'removeAsset',
  DOWNLOAD_PLAYLIST_METADATA: 'downloadPlaylistMetadata',
  DOWNLOAD_PLAYLIST: 'downloadPlaylist',
  REMOVE_PLAYLIST: 'removePlaylist',
  RECHECK_PLAYLIST: 'recheckPlaylist',
  UPDATE_PLAYLIST_STATS: 'updatePlaylistStats',
  REFRESH_PLAYLIST_STATS: 'refreshPlaylistStats',
  SET_QUEUE_STATUS: 'setQueueStatus',
  REMOVE_DEPRECATED_FILES: 'removeDeprecatedFiles',
  // ---
  SET_QUERY_CACHED_AT: 'setQueryCachedAt',
  RESET_QUERIES_CACHED_AT: 'resetQueriesCachedAt',
};

export type RemoveDeprecatedFilesPayload = {
  playlistID: string;
  deprecatedAssetIDs: string[];
};

export type RemoveDeprecatedFilesAction = {
  type: typeof ACTION_TYPES.REMOVE_DEPRECATED_FILES;
  payload: RemoveDeprecatedFilesPayload;
};

export type DownloadCompletePayload = {
  playlistID: string;
  playlistName: string;
  asset: Asset;
  automatic: boolean;
};

export type DownloadCompleteAction = {
  type: typeof ACTION_TYPES.DOWNLOAD_COMPLETE;
  payload: DownloadCompletePayload;
};

export type RemoveAssetPayload = { asset: Asset };

export type RemoveAssetAction = {
  type: typeof ACTION_TYPES.REMOVE_ASSET;
  payload: RemoveAssetPayload;
};

export type DownloadPlaylistMetadataPayload = {
  playlistID: string;
  automatic: boolean;
};

export type DownloadPlaylistMetadataAction = {
  type: typeof ACTION_TYPES.DOWNLOAD_PLAYLIST_METADATA;
  payload: DownloadPlaylistMetadataPayload;
};

export type DownloadPlaylistPayload = {
  playlistID: string;
  automatic: boolean;
  playlistName: string;
  assets: Array<Asset>;
};

export type DownloadPlaylistAction = {
  type: typeof ACTION_TYPES.DOWNLOAD_PLAYLIST;
  payload: DownloadPlaylistPayload;
};

export type RemovePlaylistPayload = {
  playlistID: string;
  playlistName: string;
  automatic: boolean;
};

export type RemovePlaylistAction = {
  type: typeof ACTION_TYPES.REMOVE_PLAYLIST;
  payload: RemovePlaylistPayload;
};

export type RecheckPlaylistPayload = { playlistID: string; automatic: boolean };

export type RecheckPlaylistAction = {
  type: typeof ACTION_TYPES.RECHECK_PLAYLIST;
  payload: RecheckPlaylistPayload;
};

export type UpdatePlaylistStatsPayload = {
  playlistID: string;
  playlistName: string;
  totalSize: number;
  cachedSize: number;
  status: JobStatus;
  automatic: boolean;
};

export type UpdatePlaylistStatsAction = {
  type: typeof ACTION_TYPES.UPDATE_PLAYLIST_STATS;
  payload: UpdatePlaylistStatsPayload;
};

export type SetQueueStatusPayload = { status: QueueStatus };

export type SetQueueStatusAction = {
  type: typeof ACTION_TYPES.SET_QUEUE_STATUS;
  payload: SetQueueStatusPayload;
};

export type SetQueryCachedAtPayload = {
  queryID: string;
  timestampInMs: number;
};

export type SetQueryCachedAtAction = {
  type: typeof ACTION_TYPES.SET_QUERY_CACHED_AT;
  payload: SetQueryCachedAtPayload;
};

export type ResetQueriesCachedAtAction = {
  type: typeof ACTION_TYPES.RESET_QUERIES_CACHED_AT;
};

export const downloadComplete = createAction<DownloadCompletePayload>(
  ACTION_TYPES.DOWNLOAD_COMPLETE
);
export const removeAsset = createAction<RemoveAssetPayload>(
  ACTION_TYPES.REMOVE_ASSET
);
export const downloadPlaylistMetadata = createAction<DownloadPlaylistMetadataPayload>(
  ACTION_TYPES.DOWNLOAD_PLAYLIST_METADATA
);
export const downloadPlaylist = createAction<DownloadPlaylistPayload>(
  ACTION_TYPES.DOWNLOAD_PLAYLIST
);
export const removePlaylist = createAction<RemovePlaylistPayload>(
  ACTION_TYPES.REMOVE_PLAYLIST
);
export const recheckPlaylist = createAction<RecheckPlaylistPayload>(
  ACTION_TYPES.RECHECK_PLAYLIST
);
export const updatePlaylistStats = createAction<UpdatePlaylistStatsPayload>(
  ACTION_TYPES.UPDATE_PLAYLIST_STATS
);
export const setQueueStatus = createAction<SetQueueStatusPayload>(
  ACTION_TYPES.SET_QUEUE_STATUS
);
export const removeDeprecatedFiles = createAction<RemoveDeprecatedFilesPayload>(
  ACTION_TYPES.REMOVE_DEPRECATED_FILES
);
export const setQueryCachedAt = createAction<SetQueryCachedAtPayload>(
  ACTION_TYPES.SET_QUERY_CACHED_AT
);
export const resetQueriesCachedAt = createAction<void>(
  ACTION_TYPES.RESET_QUERIES_CACHED_AT
);
