import React, { PropsWithChildren } from 'react';
import styled from '../style/styled';
import { COLORS } from '../style/colors';
import { ICON_BUTTON_SIZE, SCREEN_WIDTH_BREAK_SMALL } from '../style/SIZES';
import { isMobile } from 'react-device-detect';

export type IconButtonProps = PropsWithChildren<{
  isActive?: boolean;
  iconSize?: number;
  iconFgColor?: string | null;
  iconBgColor?: string | null;
  labelText?: string;
  tooltipText?: string;
  accessbilityLabel?: string;
  circular?: boolean;
  disabled?: boolean;
  Icon: any;
  onClick: (e?: any) => void;
  'data-testid'?: string;
}>;

export type IconToggleButtonProps = {
  isActive?: boolean;
  onClick: () => any;
  labelText?: string;
  iconFgColor?: string | null;
  iconBgColor?: string | null;
  disabled?: boolean;
  circular?: boolean;
  iconSize?: number;
  noPulse?: boolean;
};

export const IconButton: React.FC<IconButtonProps> = ({
  Icon,
  labelText,
  children,
  onClick,
  'data-testid': dataTestID,
  ...restProps
}) => {
  const color = restProps.iconBgColor;
  return (
    <ButtonContainerWithLabel
      onClick={onClick}
      title={labelText}
      disabled={restProps.disabled}
    >
      <ButtonStyled data-testid={dataTestID} {...restProps}>
        {Icon && <Icon size={restProps.iconSize} color={color} />}
      </ButtonStyled>

      {/* {labelText && !isMobile && <Label color={color}>{labelText}</Label>} */}
    </ButtonContainerWithLabel>
  );
};

IconButton.defaultProps = {
  iconSize: isMobile ? 25 : ICON_BUTTON_SIZE,
  // iconBgColor: COLORS.lightTextColor,
  iconBgColor: COLORS.tertiaryTextColor,
};

const ButtonContainerWithLabel = styled.div<{ disabled?: boolean }>`
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`;

export const ButtonStyled = styled.div<
  Partial<{
    size: number;
    disabled: boolean;
    iconFgColor?: string | null;
    iconBgColor?: string | null;
    circular?: boolean;
    isActive?: boolean;
    iconSize?: number;
  }>
>`
  width: ${(props) => (props.size ? `${props.size}px` : '80px')};
  height: ${(props) => (props.size ? `${props.size}px` : '80px')};
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  border-radius: ${(props) => (props.circular ? '50%' : 'auto')};
  background: ${({ circular, isActive }) => {
    if (!circular) {
      return 'none';
    }

    return isActive ? `rgba(0, 0, 0, .4)` : `rgba(255, 255, 255, .4)`;
  }};

  > svg {
    fill: ${({ isActive, circular, iconBgColor, iconFgColor }) => {
      if (iconFgColor) {
        return iconFgColor;
      }

      if (circular) {
        return isActive ? iconBgColor : '#fff';
      }

      return isActive ? '#fff' : iconBgColor;
    }};
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    width: 50px;
    height: 50px;
  }

  @media only screen and (orientation: landscape) and (max-height: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    width: 50px;
    height: 50px;
  }
`;
