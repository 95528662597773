import { createAction } from 'redux-actions';
import {
  AppSettingsState,
  CurrencySetMode,
  PinnedTour,
  PromoCodeState,
  TourSettingsState,
} from './types';
import {
  CurrencyCode,
  LanguageCode,
  PurchaseMode,
} from '../../graphql/globalTypes';
import { PromoCode } from '../../types';
import { IEnv } from '../../features/whitelabeling/branding/types';

export const ACTION_TYPES = {
  LOGIN_SUCCESS: 'loginSuccess',

  LOGOUT_REQUEST: 'logoutRequest',
  LOGOUT_SUCCESS: 'logoutSuccess',
  DO_REFETCH: 'doRefetch',

  SET_COOKIE_DISCLAIMER_VISIBILITY: 'setCookieDisclaimerVisibility',

  ADD_TO_CART: 'addToCart',
  ADD_TO_CART_ALLOWED: 'addToCardAllowed',
  REMOVE_FROM_CART: 'removeFromCart',
  EMPTY_CART: 'emptyCart',
  UPDATE_ITEM_COUNT: 'updateItemCount',
  UPDATE_CART_MODE: 'updatePurchaseMode',
  SET_CURRENCY_CODE: 'setCurrencyCode',

  SET_LANDSCAPE_MESSAGE_DISPLAYED: 'setLandscapeMessageDisplayed',
  SET_ADD_TO_HOMESCREEN_POPUP_DISPLAYED: 'setAddToHomeScreenPopupDisplayed',

  SET_PROTECTED: 'setProtected',

  SET_PROMO_CODE: 'setPromoCode',

  ADD_PINNED_TOUR: 'addPinnedTour',
  ADD_PINNED_TOURS: 'addPinnedTours',
  REMOVE_PINNED_TOUR: 'removePinnedTour',
  REMOVE_ALL_PINNED_TOURS: 'removeAllPinnedTours',

  SET_LANGUAGE: 'setLanguage',

  SET_APPNAME: 'setAppname',

  UPDATE_APP_SETTINGS: 'updateAppSettings',
  UPDATE_TOUR_SETTINGS: 'updateTourSettings',

  PUSH_MESSAGE: 'pushMessage',
  REMOVE_LAST_MESSAGE: 'removeLastMessage',

  ENABLE_GOOGLE_ANALYTICS: 'toggleGoogleAnaltics',

  SET_WHITELABEL_APP_SETTINGS: 'setWhitelabelAppSettings',
};

export type LoginSuccessPayload = { userId: string; jwt: string };

export type LoginSuccessAction = {
  type: typeof ACTION_TYPES.LOGIN_SUCCESS;
  payload: LoginSuccessPayload;
};

export type LogoutRequestPayload = { automatic?: boolean };

export type LogoutRequestAction = {
  type: typeof ACTION_TYPES.LOGOUT_REQUEST;
  payload: LogoutRequestPayload;
};

export type AddToCartPayload = {
  productID: string;
  productSKU: string;
  count?: number;
  currencyCode?: CurrencyCode | null;
};

export type AddToCartAction = {
  type: typeof ACTION_TYPES.ADD_TO_CART;
  payload: AddToCartPayload;
};

export type AddToCartAllowedPayload = {
  productID: string;
  productSKU: string;
  count?: number;
  currencyCode?: CurrencyCode | null;
};

export type AddToCartAllowedAction = {
  type: typeof ACTION_TYPES.ADD_TO_CART_ALLOWED;
  payload: AddToCartAllowedPayload;
};

export type RemoveFromCartPayload = {
  productID: string;
  productSKU: string;
};

export type RemoveFromCartAction = {
  type: typeof ACTION_TYPES.REMOVE_FROM_CART;
  payload: RemoveFromCartPayload;
};

export type RedirectToCartPayload = {
  expiry: string | null;
};

export type EmptyCartAction = {
  type: typeof ACTION_TYPES.EMPTY_CART;
};

export type UpdateItemCountPayload = {
  productID: string;
  productSKU: string;
  count: number;
  purchaseMode?: PurchaseMode;
};

export type UpdateItemCountAction = {
  type: typeof ACTION_TYPES.UPDATE_ITEM_COUNT;
  payload: UpdateItemCountPayload;
};

export type UpdatePurchaseModePayload = {
  purchaseMode: PurchaseMode;
};

export type UpdatePurchaseModeAction = {
  type: typeof ACTION_TYPES.UPDATE_CART_MODE;
  payload: UpdatePurchaseModePayload;
};

export type SetCurrencyCodePayload = {
  currencyCode: CurrencyCode;
  currencyCodeSetMode: CurrencySetMode;
};

export type SetCurrencyCodeAction = {
  type: typeof ACTION_TYPES.SET_CURRENCY_CODE;
  payload: SetCurrencyCodePayload;
};

export type SetCookieDisclaimerVisibilityPayload = {
  visibility: boolean;
};

export type SetCookieDisclaimerVisibilityAction = {
  type: typeof ACTION_TYPES.SET_COOKIE_DISCLAIMER_VISIBILITY;
  payload: SetCookieDisclaimerVisibilityPayload;
};

export type SetProtectedPayload = {
  protected: boolean;
};

export type SetProtectedAction = {
  type: typeof ACTION_TYPES.SET_PROTECTED;
  payload: SetProtectedPayload;
};

export type SetPromoCodeAction = {
  type: typeof ACTION_TYPES.SET_PROMO_CODE;
  payload: PromoCodeState;
};

export type AddPinnedTourAction = {
  type: typeof ACTION_TYPES.ADD_PINNED_TOUR;
  payload: PinnedTour;
};

export type AddPinnedToursAction = {
  type: typeof ACTION_TYPES.ADD_PINNED_TOURS;
  payload: PinnedTour[];
};

export type RemovePinnedTourAction = {
  type: typeof ACTION_TYPES.REMOVE_PINNED_TOUR;
  payload: PinnedTour;
};

export type RemoveAllPinnedTourAction = {
  type: typeof ACTION_TYPES.REMOVE_ALL_PINNED_TOURS;
};

export type UpdateTourSettingsPayload = { options: Partial<TourSettingsState> };

export type UpdateTourSettingsAction = {
  type: typeof ACTION_TYPES.UPDATE_TOUR_SETTINGS;
  payload: UpdateTourSettingsPayload;
};

export type UpdateAppSettingsPayload = { options: Partial<AppSettingsState> };

export type UpdateAppSettingsAction = {
  type: typeof ACTION_TYPES.UPDATE_APP_SETTINGS;
  payload: UpdateAppSettingsPayload;
};

export type SetLanguagePayload = LanguageCode;

export type SetLanguageAction = {
  type: typeof ACTION_TYPES.SET_LANGUAGE;
  payload: SetLanguagePayload;
};

export type PushMessagePayload = string;

export type PushMessageAction = {
  type: typeof ACTION_TYPES.PUSH_MESSAGE;
  payload: PushMessagePayload;
};

export type SetWhitelabelAppSettingsAction = {
  type: typeof ACTION_TYPES.SET_WHITELABEL_APP_SETTINGS;
  payload: IEnv;
};

export const loginSuccess = createAction<LoginSuccessPayload>(
  ACTION_TYPES.LOGIN_SUCCESS
);
export const logoutRequest = createAction<LogoutRequestPayload>(
  ACTION_TYPES.LOGOUT_REQUEST
);
export const logoutSuccess = createAction(ACTION_TYPES.LOGOUT_SUCCESS);
export const setCookieDisclaimerVisibility = createAction<SetCookieDisclaimerVisibilityPayload>(
  ACTION_TYPES.SET_COOKIE_DISCLAIMER_VISIBILITY
);
export const addToCart = createAction<AddToCartPayload>(
  ACTION_TYPES.ADD_TO_CART
);
export const addToCartAllowed = createAction<AddToCartAllowedPayload>(
  ACTION_TYPES.ADD_TO_CART_ALLOWED
);
export const removeFromCart = createAction<RemoveFromCartPayload>(
  ACTION_TYPES.REMOVE_FROM_CART
);
export const emptyCart = createAction(ACTION_TYPES.EMPTY_CART);
export const updateItemCount = createAction<UpdateItemCountPayload>(
  ACTION_TYPES.UPDATE_ITEM_COUNT
);
export const updatePurchaseMode = createAction<UpdatePurchaseModePayload>(
  ACTION_TYPES.UPDATE_CART_MODE
);
export const setLandscapeMessageDisplayed = createAction(
  ACTION_TYPES.SET_LANDSCAPE_MESSAGE_DISPLAYED
);
export const setAddToHomeScreenPopupDisplayed = createAction(
  ACTION_TYPES.SET_ADD_TO_HOMESCREEN_POPUP_DISPLAYED
);
export const setProtected = createAction<SetProtectedPayload>(
  ACTION_TYPES.SET_PROTECTED
);
export const setPromoCode = createAction<PromoCode | null>(
  ACTION_TYPES.SET_PROMO_CODE
);
export const addPinnedTour = createAction<PinnedTour>(
  ACTION_TYPES.ADD_PINNED_TOUR
);
export const addPinnedTours = createAction<PinnedTour[]>(
  ACTION_TYPES.ADD_PINNED_TOURS
);
export const removePinnedTour = createAction<PinnedTour>(
  ACTION_TYPES.REMOVE_PINNED_TOUR
);
export const removeAllPinnedTours = createAction(
  ACTION_TYPES.REMOVE_ALL_PINNED_TOURS
);

export const setLanguage = createAction<SetLanguagePayload>(
  ACTION_TYPES.SET_LANGUAGE
);

export const updateAppSettings = createAction<UpdateAppSettingsPayload>(
  ACTION_TYPES.UPDATE_APP_SETTINGS
);

export const updateTourSettings = createAction<UpdateTourSettingsPayload>(
  ACTION_TYPES.UPDATE_TOUR_SETTINGS
);

export const setCurrencyCode = createAction<SetCurrencyCodePayload>(
  ACTION_TYPES.SET_CURRENCY_CODE
);

export const pushMessage = createAction<PushMessagePayload>(
  ACTION_TYPES.PUSH_MESSAGE
);

export const removeLastMessage = createAction(ACTION_TYPES.REMOVE_LAST_MESSAGE);

export const enableGoogleAnalytics = createAction(
  ACTION_TYPES.ENABLE_GOOGLE_ANALYTICS
);

export const doRefetch = createAction(ACTION_TYPES.DO_REFETCH);

export const setWhitelabelAppSettings = createAction<IEnv>(
  ACTION_TYPES.SET_WHITELABEL_APP_SETTINGS
);
