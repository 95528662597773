import React from 'react';
import { Button } from '../../../components/Button';
import styled from '../../../style/styled';
import { useTranslation } from 'react-i18next';

type Props = any;

// closeToast is injected by react-toastify
export const UpdateAvailableAlert: React.FC<Props> = ({ closeToast }) => {
  const { t } = useTranslation();

  return (
    <MessageContainer>
      <MessageText>{t('An update to the app is available')}</MessageText>

      <ButtonCotainer>
        <Button
          onClick={() => {
            // TODO: how to capture analytics
            window.location.reload();
          }}
        >
          {t('Update now')}
        </Button>

        <Spacer />

        <Button secondary onClick={closeToast}>
          {t('Update later')}
        </Button>
      </ButtonCotainer>
    </MessageContainer>
  );
};

const ButtonCotainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Spacer = styled.div`
  width: 8px;
`;

const MessageContainer = styled.div``;

const MessageText = styled.p`
  margin-bottom: 16px;
`;
