import React from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from './Button';
import { captureButtonPress } from '../features/analytics';
// import { RECAPTCHA_ERROR } from '../consts';
// import { captureInSentry } from '../App/config/reporting/captureInSentry';

interface Props {
  context: string;
  handleClick?: (token: string | null) => any;
  children: any;
  disabled: boolean;
  buttonName: string;
  uppercase?: boolean;
}

export const AuthButtonWithRecaptcha3: React.FC<Props> = ({
  children,
  context,
  handleClick,
  buttonName,
  ...rest
}) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const clickHandler = async () => {
    captureButtonPress({
      buttonName,
      page: window.location.pathname,
    });

    if (handleClick) {
      // let token: string | null = null;

      // if (executeRecaptcha) {
      //   try {
      //     token = await executeRecaptcha(context);

      //     handleClick(token);
      //   } catch (error) {
      //     // this works for only the domains specified by us.
      //     // currently, only localhost, ancient-world and ancient-jerusalem are registered.

      //     captureInSentry(error.toString() || RECAPTCHA_ERROR, {
      //       recaptchaContext: context,
      //     });

      //     handleClick(null);
      //   }
      // }

      handleClick(null);
    }
  };

  return (
    <Button type="submit" onClick={clickHandler} {...rest}>
      {children}
    </Button>
  );
};
