import React, { useCallback } from 'react';
import styled, { useTheme } from '../../../../style/styled';
import CheckIcon from 'mdi-react/CheckIcon';
import ProgressDownloadIcon from 'mdi-react/ProgressDownloadIcon';
import DownloadIcon from 'mdi-react/DownloadIcon';
import LoadingIcon from 'mdi-react/LoadingIcon';
import RefreshIcon from 'mdi-react/RefreshIcon';
import { captureButtonPress } from '../../../../features/analytics';
import { IconButton } from '../../../IconButton';
import { JobStatus } from '../../../../store/offline/types';
import {
  downloadPlaylistMetadata,
  removePlaylist,
} from '../../../../store/offline/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectOfflinePlaylist } from '../../../../store/offline/selectors';
import { COLORS } from '../../../../style/colors';
import { useTranslation } from 'react-i18next';
import { env } from '../../../../App/config/env';

const ANALYTICS_BUTTON_NAME = 'TourWidgetOfflineStatusIndicator_Download';

interface Props {
  playlistID: string;
  playlistName: string;
}

export const TourWidgetOfflineStatusIndicator: React.FC<Props> = ({
  playlistID,
  playlistName,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { jobStatus, progress } = useSelector(
    selectOfflinePlaylist(playlistID)
  );
  const theme = useTheme();

  const handleDownloadButtonClick = useCallback(
    (e: any) => {
      e.stopPropagation();

      captureButtonPress({
        page: window.location.pathname,
        buttonName: ANALYTICS_BUTTON_NAME,
      });

      dispatch(downloadPlaylistMetadata({ playlistID, automatic: false }));
    },
    [dispatch, playlistID]
  );

  const handleCleanupButtonClick = useCallback(
    (e: any) => {
      e.stopPropagation();

      captureButtonPress({
        page: window.location.pathname,
        buttonName: 'TourWidgetOfflineStatusIndicator_CleanUp',
      });

      dispatch(removePlaylist({ playlistID, playlistName, automatic: false }));
    },
    [dispatch, playlistID, playlistName]
  );

  let icon = null;
  let interactive = true;

  if (jobStatus === JobStatus.DOWNLOAD_SUCCESS) {
    const title = `The ${
      env.IS_STOCK ? 'tour' : 'guide'
    } content is downloaded for the best experience`;

    icon = (
      <div title={t(title)}>
        <CheckIcon color={COLORS.lightTextColor} />
      </div>
    );
  } else if (jobStatus === JobStatus.DOWNLOADING) {
    interactive = false;

    const title = `The ${
      env.IS_STOCK ? 'tour' : 'guide'
    } data is being downloaded for the best experience. You can start the ${
      env.IS_STOCK ? 'tour' : 'guide'
    } while the download is in progress.`;

    icon = (
      <>
        <ProgressText>{progress}%</ProgressText>
        <div title={t(title)}>
          <ProgressDownloadIcon color={COLORS.lightTextColor} />
        </div>
      </>
    );
  } else if (jobStatus === JobStatus.DOWNLOAD_ERROR) {
    const title = `Oops! We ran into an error while downloading the ${
      env.IS_STOCK ? 'tour' : 'guide'
    } data. Please try again`;

    icon = (
      <IconButton
        iconBgColor={theme.palette.common.white}
        Icon={RefreshIcon}
        onClick={handleDownloadButtonClick}
        labelText={t(title)}
      />
    );
  } else if (jobStatus === JobStatus.CLEANING_UP) {
    const title = `The ${
      env.IS_STOCK ? 'tour' : 'guide'
    } data is being removed to clear up storage space. You can always download the ${
      env.IS_STOCK ? 'tour' : 'guide'
    } data again.`;

    interactive = false;
    icon = <LoadingIcon color={COLORS.lightTextColor} xlinkTitle={t(title)} />;
  } else if (jobStatus === JobStatus.CLEANUP_ERROR) {
    const title = `Oops! We ran into an error while removing the ${
      env.IS_STOCK ? 'tour' : 'guide'
    } data. Please try again.`;

    icon = (
      <IconButton
        iconBgColor={theme.palette.common.white}
        Icon={RefreshIcon}
        onClick={handleCleanupButtonClick}
        labelText={t(title)}
      />
    );
  } else {
    const title = `Download the ${
      env.IS_STOCK ? 'tour' : 'guide'
    } data for the best experience.`;

    icon = (
      <IconButton
        iconBgColor={theme.palette.common.white}
        Icon={DownloadIcon}
        onClick={handleDownloadButtonClick}
        labelText={t(title)}
      />
    );
  }

  return <Container interactive={interactive}>{icon}</Container>;
};

const ProgressText = styled.p`
  margin-right: 8px;
  color: ${(props) => props.theme.palette.common.white};
  text-shadow: 0 0 4px #000;
  font-weight: bold;
  user-select: none;
`;

const Container = styled.div.attrs<{
  interactive: boolean;
}>(({ interactive }) => ({
  style: {
    cursor: interactive ? 'pointer' : 'auto',
  },
}))<{ interactive: boolean }>`
  position: absolute;
  right: 4px;
  bottom: 4px;
  z-index: 4;
  display: flex;
  align-items: center;
`;
