import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from './Asset';
import { I18NSTRING } from './I18nString';

export const PRE_TOUR_STOP_FRAGMENT = gql`
  fragment PreTourStopFragment on Stop {
    id
    internalReference
    nameI18n {
      ...I18nStringFragment
    }
    imagesCount
    timePeriodSpecific
    thumbnail {
      ...AssetFragment
    }
    location {
      type
      coordinates {
        longitude
        latitude
      }
    }
    projections {
      mobile
      id
      originalName
      asset {
        ...AssetFragment
      }
      d1
      d2
      d3
      a1
      a2
      a3
      portalStops
    }
    vps {
      cameraPosition
      id
      internalReferenceIndex
      startingVp
      internalReference
      closestVps
      location {
        coordinates {
          latitude
          longitude
        }
      }
    }
  }

  ${ASSET_FRAGMENT}
  ${I18NSTRING}
`;
