import React, { useCallback, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import MenuIcon from 'mdi-react/MenuIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import styled, { createGlobalStyle, useTheme } from '../../style/styled';
import { IconButton } from '../IconButton';
import { AppLogoAndText } from './AppLogoAndText';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { whiteLabelAppTitleForTradeShow } from '../../App/config/enums';
import { useSelector } from 'react-redux';
import { selectWhitelabelAppSettings } from '../../store/app/selectors';

interface Props {
  isAuthed: boolean;
  items: React.ReactElement[];
  size?: number;
  rtl: boolean;
}

export const MobileMenu: React.FC<Props> = React.memo((props) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const {
    banner: { title },
  } = useSelector(selectWhitelabelAppSettings);
  const theme = useTheme();

  // having the colors change is distracting
  const color = theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;

  const hideMenu = useCallback(() => {
    setMenuVisible(false);
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuVisible((visible) => !visible);
  }, []);

  const MenuToggleIcon = menuVisible ? CloseIcon : MenuIcon;

  return (
    <OutsideClickHandler onOutsideClick={hideMenu}>
      {menuVisible && <GlobalStyleForOpenMenu />}

      <Container>
        <MenuButton>
          <div>
            <MenuToggleIcon
              color={color}
              size={size * 1.4}
              onClick={toggleMenu}
            />
          </div>

          {menuVisible && (
            <Menu
              // close menu when an item is clicked upon
              onClick={toggleMenu}
              title={title}
              rtl={props.rtl}
            >
              <MenuContent>
                <MenuTitleBar color={color}>
                  <AppLogoAndText size={size} color={color} />

                  <IconButton
                    Icon={CloseIcon}
                    iconSize={size * 1.4}
                    onClick={(e) => {
                      e.stopPropagation();
                      hideMenu();
                    }}
                    iconBgColor={color}
                  />
                </MenuTitleBar>

                <MenuItems>{props.items}</MenuItems>
              </MenuContent>
            </Menu>
          )}
        </MenuButton>
      </Container>
    </OutsideClickHandler>
  );
});

const GlobalStyleForOpenMenu = createGlobalStyle`
  body {
    @media (pointer: none), (pointer: coarse) {
      overflow: hidden;
    }
  }
`;

const Container = styled.div`
  position: relative;
`;

const MenuButton = styled.div`
  cursor: pointer;
`;

const Menu = styled.div<{ title: string; rtl: boolean }>`
  background-color: ${({ theme, title }) =>
    whiteLabelAppTitleForTradeShow.indexOf(title) === 0 ||
    whiteLabelAppTitleForTradeShow.indexOf(title) === 3 ||
    whiteLabelAppTitleForTradeShow.indexOf(title) === 4 ||
    whiteLabelAppTitleForTradeShow.indexOf(title) === 5 ||
    whiteLabelAppTitleForTradeShow.indexOf(title) === 6
      ? '#000'
      : whiteLabelAppTitleForTradeShow.indexOf(title) === 1
      ? '#E5745D'
      : whiteLabelAppTitleForTradeShow.indexOf(title) === 2
      ? '#8D2332'
      : theme.palette.appHeader.background};
  display: flex;
  flex-direction: column;
  right: ${({ rtl }) => (rtl ? 'auto' : 0)};
  left: ${({ rtl }) => (rtl ? 0 : 'auto')};
  width: max-content;
  position: absolute;
  height: auto;
  box-shadow: 0 0 3px 1px #00000082;

  @media (pointer: none), (pointer: coarse) {
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;
    height: 100vh;
    box-shadow: none;
  }
`;

const MenuContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MenuTitleBar = styled.div<{ color: string }>`
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding-left: 10px;
  color: ${({ theme }) => theme.palette.brand.contrastColor};

  @media (pointer: none), (pointer: coarse) {
    display: flex;
  }
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding-top: 0;

  @media (pointer: none), (pointer: coarse) {
    padding-top: 56px;
  }
`;
