import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { selectIsAuthenticated } from '../../store/app/selectors';
import { ROUTES } from '../routes';

type Props = RouteProps & {
  component: any;
};

export const TourRoute: React.FC<Props> = ({
  component: Component,
  ...restProps
}) => {
  const isAuthed = useSelector(selectIsAuthenticated);
  const allowAccess = // @ts-ignore TODO: fix later and do it properly for trial tour
    restProps.location?.state?.playlistID === '5ee83853cc7ee6000776bbfe' ||
    isAuthed;

  return (
    <Route
      {...restProps}
      render={(props: RouteProps) =>
        allowAccess ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: ROUTES.auth }} />
        )
      }
    />
  );
};
