import * as serviceWorker from './serviceWorker';
import './features/audio/howlerSetup';
import { env } from './App/config/env';
import './App/config/reporting/setupReporting';
import { ApolloProvider } from '@apollo/client';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import { getApolloClient } from './App/config/apollo/ApolloClient';
import { ErrorBoundary } from './App/ErrorBoundary';
import { setInitialLanguage } from './features/i18n/setInitialLanguage';
import { createStore } from './store';
import { createDeviceID } from './utils/createDeviceID';
import { setUpdateAvailable } from './store/sw/actions';
import { AppWithRemoteConfig } from './App/AppWithRemoteConfig';
import { DeviceOrientationProvider } from './providers/DeviceOrientationProvider';
import UserProvider from './providers/UserProvider';
import { getAppID } from './App/config/getAppID';
import AppProvider from './providers/AppProvider';

createDeviceID();

const appID = getAppID(env.IS_STOCK, env.IS_RTM);

const history = createBrowserHistory();

// we pass the history because we want to pass it to saga context so that we can use routing
// inside a saga.
const { store, persistor } = createStore(history);

async function renderApp() {
  const { apolloClient } = await loadInitialLibraries();

  render(
    <StrictMode>
      <ErrorBoundary>
        {/* required for routing */}
        <Router history={history}>
          {/* required for redux */}
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={apolloClient}>
                <AppProvider appID={appID}>
                  <UserProvider>
                    <DeviceOrientationProvider>
                      <AppWithRemoteConfig />
                    </DeviceOrientationProvider>
                  </UserProvider>
                </AppProvider>
              </ApolloProvider>
            </PersistGate>
          </ReduxProvider>
        </Router>
      </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
  );
}

async function loadInitialLibraries() {
  const [apolloClient, language] = await Promise.all([
    // getApolloClient is async because rehydrating apollo cache to idb storage is async
    getApolloClient(),
    // setInitialLanguage is async because setLanguage fn called within it is async
    setInitialLanguage(),
  ]);

  return { apolloClient, language };
}

renderApp();

// service worker registration is required for offline support.
if (env.ENABLE_SERVICE_WORKER) {
  serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
      console.log('Service worker: update available.');

      store.dispatch(setUpdateAvailable({ available: true }));
    },
    onSuccess: (registration: ServiceWorkerRegistration) => {
      console.log('Service worker: successfully registered.');
    },
  });
} else {
  serviceWorker.unregister();
}
