import { createSelector } from 'reselect';
import { RootState } from '../redux/types';
import {
  AudioPlayingState,
  AudioState,
  CompassSupport,
  ViewerOptionsState,
  ViewerState,
} from './types';
import { FullTour, Guide } from '../../graphql/queries/GetFullTourQuery';
import { LanguageCode } from '../../graphql/globalTypes';
import { getI18nFieldValue } from '../../features/i18n/getI18nFieldValue';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { selectFeatures } from '../features/selectors';
import { isFeatureSelected } from '../../utils/isFeatureSelected';
import { Feature } from '../features/types';
import { selectCurrentLocation } from '../location/selectors';
import { getDistanceInMetersBetweenTwoCoords } from '../../utils/getDistanceInMetersBetweenTwoCoords';
import { EXPLORE_WEB_GetFullTour_result_stops } from '../../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';
import { Location } from '../../types';
import { resolvePreferredNarration } from './util/resolvePreferredNarration';

export function selectViewerFacingDirection(state: RootState): string {
  return state.viewer.facingDirection;
}
export function selectViewer(state: RootState): ViewerState {
  return state.viewer;
}

export function selectViewerIsLoading(state: RootState): boolean {
  return state.viewer.isLoading;
}
export function selectViewpointIsSwitching(state: RootState): boolean {
  return state.viewer.isViewpointSwitching;
}

export function selectViewerOptions(state: RootState): ViewerOptionsState {
  return state.viewer.viewerOptions;
}

export function selectViewerOptionIsImagesEnabled(state: RootState): boolean {
  return state.viewer.viewerOptions.isImagesEnabled;
}

export function selectViewpointOptionIsGuidePanelVisible(
  state: RootState
): boolean {
  return state.viewer.viewerOptions.isGuidePanelVisible;
}

export function selectViewerOptionsIsMetaDataWindowVisible(
  state: RootState
): boolean {
  return state.viewer.viewerOptions.isMetaDataWindowVisible;
}

export function selectViewerOptionIsMapsVisible(state: RootState): boolean {
  return (
    !selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.isMapsVisible
  );
}

export function selectViewerOptionIsDiscoveriesVisible(
  state: RootState
): boolean {
  return (
    !selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.isDiscoveriesVisible
  );
}
export function selectViewerOptionIsInfoPanelEnabled(
  state: RootState
): boolean {
  return (
    !selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.isInfoPanelEnabled
  );
}

export function selectViewerOptionIsSideBySideVisible(
  state: RootState
): boolean {
  return state.viewer.viewerOptions.isSideBySideVisible;
}

export function selectViewerOptionIsSlideVisible(state: RootState): boolean {
  return state.viewer.viewerOptions.isSlideVisible;
}

export function selectViewerOptionIsGyroEnabled(state: RootState): boolean {
  return state.viewer.viewerOptions.isGyroEnabled;
}
export function selectViewerOptionIsAutoRotating(state: RootState): boolean {
  return state.viewer.viewerOptions.isAutoRotating;
}

export function selectViewerOptionsIsFullscreen(state: RootState): boolean {
  return state.viewer.viewerOptions.isFullscreen;
}

export function selectTour(state: RootState): FullTour | null {
  return state.viewer.tour;
}

export function selectTourEnded(state: RootState): boolean {
  const isLastViewpoint = selectIsLastViewpoint(state);
  const audioCompleted =
    selectViewerAudioPlayingState(state) === AudioPlayingState.completed;

  return isLastViewpoint && audioCompleted;
}

export function selectIsLastViewpoint(state: RootState): boolean {
  const tour = selectTour(state);

  if (!tour) {
    return false;
  }

  const currentViewpointIndex = selectCurrentViewpointIndex(state);

  return tour.stops.length <= currentViewpointIndex + 1;
}
export function selectCurrentVpIndex(state: RootState): number {
  return state.viewer.currentVpIndex;
}
export function selectCurrentViewpointIndex(state: RootState): number {
  return state.viewer.currentViewpointIndex;
}

export function selectCurrentDiscoveryID(state: RootState): string {
  return state.viewer.currentDiscoveryID;
}

export function selectCurrentArtefactIndex(state: RootState): number {
  return state.viewer.currentArtefactIndex;
}
export function selectArtefactClickPosition(
  state: RootState
): { x: number; y: number } {
  return state.viewer.artefactClickPosition;
}
export function selectUserSelectedAViewpoint(state: RootState): boolean {
  return state.viewer.userSelectedAViewpoint;
}
export function selectPreviousViewpointIndex(state: RootState): number {
  return state.viewer.previousViewpointIndex;
}

export const selectCurrentViewpointLatitudeAndLongitude = createSelector(
  selectTour,
  selectCurrentViewpointIndex,
  (tour, currentViewpointIndex) => {
    if (!tour) {
      return null;
    }

    const stop = tour.stops[currentViewpointIndex];

    if (!stop) {
      return null;
    }

    return stop;
  }
);

export const selectCurrentViewpointID = createSelector(
  selectCurrentViewpointLatitudeAndLongitude,
  (stop): string | null => {
    return stop?.id || null;
  }
);

export function selectCurrentImageIndex(state: RootState): number {
  return state.viewer.currentImageIndex;
}

export function selectImageCaption(state: RootState): string | null {
  const { tour, currentViewpointIndex, currentImageIndex } = state.viewer;
  const { language } = state.app;

  const currentStop = (tour?.stops || [])[currentViewpointIndex];

  if (!currentStop) {
    captureInSentry('currentViewpoint is null in selectImageCaption', {
      tourID: tour?.id,
      currentViewpointIndex,
    });

    return null;
  }

  const { images } = currentStop;

  const image = images[currentImageIndex];

  if (!image) {
    captureInSentry('Image is null in selectImageCaption', {
      tourID: tour?.id,
      currentViewpointIndex,
      currentImageIndex,
    });

    return null;
  }

  return getI18nFieldValue(image.nameI18n, language);
}

export function selectViewerAudio(state: RootState): AudioState {
  return state.viewer.audio;
}

// export function selectShouldDisplaySubtitles(state: RootState): boolean {
//   const {
//     pausedByUser,
//     pausedByTheSystem,
//     playing,
//     completed,
//   } = state.viewer.audio;

//   return playing || (!completed && (pausedByUser || pausedByTheSystem));
// }

// export function selectSubtitleTimestamp(state: RootState): number {
//   if (selectShouldDisplaySubtitles(state)) {
//     return selectViewerAudioSeekPos(state);
//   }

//   return -1;
// }

export function selectViewerAudioIsPlaying(state: RootState): boolean {
  return state.viewer.audio.playing;
}

export function selectViewerAudioPlayingState(
  state: RootState
): AudioPlayingState | null {
  const {
    pausedByUser,
    pausedByTheSystem,
    loading,
    playing,
    completed,
  } = state.viewer.audio;

  if (loading) {
    return AudioPlayingState.loading;
  }

  if (pausedByTheSystem) {
    return AudioPlayingState.systemPaused;
  }

  if (pausedByUser) {
    return AudioPlayingState.paused;
  }

  if (completed) {
    return AudioPlayingState.completed;
  }

  if (playing) {
    return AudioPlayingState.playing;
  }

  return null;
}

export function selectViewerAudioSeekPos(state: RootState): number {
  return state.viewer.audio.seekPos;
}

export function selectViewerAudioLength(state: RootState): number {
  return state.viewer.audio.length;
}

export function selectViewerAudioShowSubtitles(state: RootState): boolean {
  const features = selectFeatures(state);
  const isSubtitlesFeatureEnabled = isFeatureSelected(
    features,
    Feature.InTourSubtitles
  );

  return !!(isSubtitlesFeatureEnabled && state.viewer.audio.showSubtitles);
}

export function selectViewerAudioSubtitleLanguage(
  state: RootState
): LanguageCode {
  return state.viewer.audio.subtitlesLanguage;
}

export function selectViewerAudioNarrationLanguage(
  state: RootState
): LanguageCode {
  return state.viewer.audio.narrationLanguage;
}

export function selectViewerAudioMusicMuted(state: RootState): boolean {
  return state.viewer.audio.musicMuted;
}
export function selectViewerNarrationMuted(state: RootState): boolean {
  return state.viewer.audio.playing;
}

export function selectGuide(state: RootState): Guide | null | undefined {
  return state.viewer.tour?.guide;
}

export function selectEndOfTourPopupClosed(state: RootState): boolean {
  return state.viewer.endOfTourPopupClosed;
}

export function selectCompassSupport(state: RootState): CompassSupport {
  return state.viewer.compassSupport;
}

export function selectDeviceOrientation(
  state: RootState
): Partial<DeviceOrientationEvent> | null {
  return state.viewer.deviceOrientation;
}

export function selectIsTutorialVisible(state: RootState): boolean {
  return state.viewer.viewerOptions.isTutorialVisible;
}

export function selectTutorialDisplayed(state: RootState): boolean {
  return state.viewer.viewerOptions.tutorialDisplayed;
}

export function selectSlideTutorialDisplayed(state: RootState): boolean {
  return state.viewer.viewerOptions.slideTutorialDisplayed;
}

export function selectDisplayOnboardingTutorial({
  viewer: { viewerOptions },
}: RootState): boolean {
  return viewerOptions.isTutorialVisible || !viewerOptions.tutorialDisplayed;
}

export function selectViewerVisitedVPIndices(state: RootState): number[] {
  return state.viewer.visitedVPIndices;
}
export function selectViewerVisitedDiscoveryIDs(state: RootState): string[] {
  return state.viewer.visitedDiscoveryIDs;
}

export function selectIsPlaylistThumbnailsVisible(state: RootState): boolean {
  return state.viewer.viewerOptions.isPlaylistThumbnailsVisible;
}

export function selectUIVisible(state: RootState): boolean {
  return state.viewer.displayUI;
}

export function selectAudioSetChangingSlider(state: RootState): boolean {
  return Boolean(state.viewer.audio.changingSlider);
}

export function selectImagesButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialImagesButtonHighlighted
  );
}

export function selectVPGridButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialVPGridButtonHighlighted
  );
}

export function selectVPInfoButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialVPInfoButtonHighlighted
  );
}

export function selectSubtitlesButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialSubtitlesButtonHighlighted
  );
}

export function selectToggleMusicButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialToggleMusicButtonHighlighted
  );
}

export function selectNarrationPlayPauseButtonHighlighted(
  state: RootState
): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialNarrationPlayPauseButtonHighlighted
  );
}

export function selectGyroToggleButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialGyroToggleButtonHighlighted
  );
}

export function selectMapToggleButtonHighlighted(state: RootState): boolean {
  return (
    selectDisplayOnboardingTutorial(state) &&
    state.viewer.viewerOptions.tutorialMapToggleButtonHighlighted
  );
}

export function isFingerTutorialDisplayed(state: RootState): boolean {
  return state.viewer.viewerOptions.isFingerTutorialDisplayed;
}

export const selectDistanceToViewpoint = createSelector(
  selectCurrentViewpointLatitudeAndLongitude,
  selectCurrentLocation,
  (
    currentViewpoint: EXPLORE_WEB_GetFullTour_result_stops | null,
    currentLocation: Location | null
  ): number => {
    let distance = Infinity;

    const viewpointLocation = currentViewpoint?.location;

    if (viewpointLocation && currentLocation) {
      distance = getDistanceInMetersBetweenTwoCoords(
        {
          lat: currentLocation?.coords.latitude,
          lng: currentLocation?.coords.longitude,
        },
        {
          lat: viewpointLocation?.coordinates.latitude,
          lng: viewpointLocation?.coordinates.longitude,
        }
      );
    }

    return distance;
  }
);

export const selectCurrentStopNarration = createSelector(
  selectTour,
  selectCurrentViewpointIndex,
  selectViewerAudioNarrationLanguage,
  (tour, currentStopIndex, narrationLanguage) => {
    if (!tour) {
      return null;
    }

    const currentStop = tour?.stops[currentStopIndex];

    if (!currentStop) {
      return null;
    }

    const narrationToPlay = resolvePreferredNarration({
      parent: currentStop,
      overridingNarrationConfig: tour.narrationConfig,
      narrationLanguage,
    });

    return narrationToPlay;
  }
);

export const selectActiveDiscovery = createSelector(
  selectTour,
  selectCurrentViewpointID,
  selectCurrentDiscoveryID,
  (tour, currentStopID, currentDiscoveryID) => {
    const discoveryArray = tour?.discoveries?.filter(
      ({ stopID }) => stopID === currentStopID
    );

    const activeDiscovery = discoveryArray?.find(
      (current) => current.id === currentDiscoveryID
    );

    return activeDiscovery;
  }
);
