import { EXPLORE_WEB_GetFullTour_result_stops } from '../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';
import { isTablet } from 'react-device-detect';

export function getTargetProjection(
  curStop: EXPLORE_WEB_GetFullTour_result_stops,
  isMobile: boolean,
  projectionName?: string
) {
  const targetStartingVp = curStop?.vps?.find((v) => v.startingVp);
  const targetVp =
    projectionName &&
    curStop?.vps?.find((v) => v.internalReference === projectionName);
  if (targetVp) {
    const initialIndex = curStop?.projections
      ?.filter(
        (p) => (isMobile && !isTablet ? p?.mobile : !p?.mobile)
        // !p?.mobile
      )
      ?.findIndex((pro) => pro.originalName === targetVp.internalReference);
    return { initialIndex };
  } else if (targetStartingVp) {
    const initialIndex = curStop?.projections
      ?.filter(
        (p) => (isMobile && !isTablet ? p?.mobile : !p?.mobile)
        // !p?.mobile
      )
      ?.findIndex(
        (pro) => pro.originalName === targetStartingVp.internalReference
      );
    return { initialIndex };
  } else if (
    curStop.projections &&
    curStop.projections.length > 0 &&
    curStop.projections[0]
  ) {
    return {
      initialIndex: 0,
    };
  } else {
    return null;
  }
}
