import React from 'react';
import styled from '../../style/styled';
import { COLORS } from '../../style/colors';
import {
  SCREEN_WIDTH_MAX,
  SCREEN_WIDTH_BREAK_SMALL,
  SCREEN_WIDTH_BREAK_MEDIUM,
  SCREEN_WIDTH_BREAK_EXTRA_SMALL,
} from '../../style/SIZES';
import { ContactUsLink } from '../../components/links/ContactUsLink';
import { Subscribe } from '../../components/SubscribeManual';
import { ROUTES } from '../../Routes/routes';
import NavLinkWithCapture from '../../components/NavLinkWithCapture';
import LinkWithCapture from '../../components/LinkWithCapture';
import { useTranslation } from 'react-i18next';
import { runGraphQLQuery } from '../../utils/runGraphQLQuery';
import { GET_GUIDES_QUERY } from '../../graphql/queries/GetGuides';
import { getDecorationImageURL } from '../../utils/getDecorationImageURL';
import { Copyright } from './Copyright';
import { selectVariant } from '../../store/googleOptimize/selectors';
import { env } from '../../App/config/env';
import { useSelector } from 'react-redux';

// TODO: write tests
const FooterStock: React.FC = () => {
  const { t } = useTranslation();
  const variant = useSelector(selectVariant(env.GO_EXP_ID_FOR_LIBRARY_PAGE));

  return (
    <Container id="footer">
      <Banner />
      <Wrapper>
        <SubscribeSection>
          <FooterTitle>{t('Sign up for our newsletter')}</FooterTitle>
          <Subscribe />
        </SubscribeSection>

        <FooterCols>
          <FooterSection>
            <FooterTitle>{t('Ancient World')}</FooterTitle>

            <FooterNav>
              <FooterNavLinks>
                {variant !== null && (
                  <li>
                    <Label>
                      <LinkWithCapture
                        buttonName="ToursLibrary_Footer"
                        to={(function () {
                          if (variant === '0') {
                            return {
                              pathname: ROUTES.index,
                              hash: '#tours_section',
                            };
                          } else if (variant === '1') {
                            return ROUTES.library;
                          }
                          return ROUTES.index;
                        })()}
                      >
                        {t('Tours library')}
                      </LinkWithCapture>
                    </Label>
                  </li>
                )}

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="YourGuides"
                      to={ROUTES.yourGuides}
                      onMouseOver={() => {
                        runGraphQLQuery(GET_GUIDES_QUERY, null);
                      }}
                    >
                      {t('Tour guides')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture buttonName="Merch" to={ROUTES.merch}>
                      {t('Merch')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture buttonName="FAQ" to={ROUTES.faq}>
                      {t('FAQ')}
                    </NavLinkWithCapture>
                  </Label>
                </li>
              </FooterNavLinks>
            </FooterNav>
          </FooterSection>

          <FooterSection>
            <FooterTitle>{t('Business')}</FooterTitle>

            <FooterNav>
              <FooterNavLinks>
                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="Partners"
                      to={ROUTES.business}
                    >
                      {t('Partner with us')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="CreateTours"
                      to={ROUTES.createTours}
                    >
                      {t('Create tours')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="AboutUs"
                      to={ROUTES.aboutUs}
                    >
                      {t('About us')}
                    </NavLinkWithCapture>
                  </Label>
                </li>
              </FooterNavLinks>
            </FooterNav>
          </FooterSection>

          <FooterSection>
            <FooterTitle>{t('Policy and terms')}</FooterTitle>

            <FooterNav>
              <FooterNavLinks>
                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="PrivacyPolicy"
                      to={ROUTES.privacyPolicy}
                    >
                      {t('Privacy policy')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="TermsConditions"
                      to={ROUTES.termsConditions}
                    >
                      {t('General terms')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="AffiliateTerms"
                      to={ROUTES.affiliateTerms}
                    >
                      {t('Affiliate terms')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="AssociateTerms"
                      to={ROUTES.associateTerms}
                    >
                      {t('Associate terms')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="HeritageClubTerms"
                      to={ROUTES.heritageClubTerms}
                    >
                      {t('Heritage club terms')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="ListTourTerms"
                      to={ROUTES.tourListingTerms}
                    >
                      {t('Tour listing terms')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="RequestAccountDeletion"
                      to={ROUTES.deleteAccountRequest}
                    >
                      {t('Request account deletion')}
                    </NavLinkWithCapture>
                  </Label>
                </li>
              </FooterNavLinks>
            </FooterNav>
          </FooterSection>

          <FooterSection>
            <FooterTitle>{t('Get in touch')}</FooterTitle>
            <FooterNav>
              <FooterNavLinks>
                <li>
                  <ContactUsLink color={COLORS.primaryFontColor} />
                </li>
              </FooterNavLinks>
            </FooterNav>
          </FooterSection>
        </FooterCols>

        <Copyright />
      </Wrapper>
    </Container>
  );
};

export default FooterStock;

const SubscribeSection = styled.div`
  width: 400px;
  padding-bottom: 32px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding-bottom: 20px;
    width: 100%;
  }
`;

const FooterTitle = styled.h4`
  margin-bottom: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-bottom: 12px;
  }
`;

const FooterNav = styled.nav`
  padding-bottom: 32px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding-bottom: 20px;
  }
`;

const FooterNavLinks = styled.ul`
  list-style: outside none none;
  padding: 0px;
  margin: 0px;

  li {
    padding: 3px 0;
  }
`;

const FooterSection = styled.div`
  flex: 0 0 25%;
  max-width: 25%;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_EXTRA_SMALL}px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_WIDTH_MAX}px;
  margin: 0 auto;
  padding: 30px 10px;
`;

const FooterCols = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${COLORS.primaryFontColor};
`;

const Container = styled.footer`
  margin-top: 20px;
  background: ${COLORS.offwhite};
`;

const Banner = styled.div`
  height: 100px;
  width: 100%;
  background-image: url(${getDecorationImageURL()});
  background-size: auto 100px;
  background-position: center bottom;
  background-repeat: repeat-x;
`;

const Label = styled.p`
  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding: 12px 0;
  }
`;
