import React from 'react';
import styled from '../../../../style/styled';
import { Section } from './styledComponents/Section';
import { SectionTitle } from './styledComponents/SectionTitle';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../store/app/selectors';
import { getI18nFieldValue } from '../../../../features/i18n/getI18nFieldValue';
import { SCREEN_WIDTH_BREAK_MEDIUM } from '../../../../style/SIZES';
import { Asset, I18nInfoField, ViewpointLocation } from '../../../../types';

export type TourInfoToSeeSectionTour = {
  stops: Array<{
    id: string;
    nameI18n: I18nInfoField;
    timePeriodSpecific: string | null;
    thumbnail: Asset | null;
    location: ViewpointLocation | null;
  }>;
};

interface Props {
  tour: TourInfoToSeeSectionTour;
  displayTimePeriods: boolean;
}

export const TourInfoToSeeSection = React.memo(
  ({ tour, displayTimePeriods }: Props) => {
    const { t } = useTranslation();
    const language = useSelector(selectLanguage);
    const { stops } = tour;

    const toSee: Array<string> = stops.map((vp) => {
      if (displayTimePeriods) {
        return `${getI18nFieldValue(vp.nameI18n, language)} (${
          vp.timePeriodSpecific
        })`;
      }

      return getI18nFieldValue(vp.nameI18n, language);
    });
    const filteredToSee = [...new Set(toSee)];

    const cutOff = Math.max(Math.ceil(filteredToSee.length / 2), 5);
    const toSeeLeft = filteredToSee.slice(0, cutOff);
    const toSeeRight = filteredToSee.slice(cutOff);

    return (
      <Section>
        <SectionTitle>{t('Lots to see...')}</SectionTitle>

        <ItemsContainer>
          <ol>
            {toSeeLeft.map((t, index) => (
              <li key={index + 1}>{t}</li>
            ))}
          </ol>
          <ol start={cutOff + 1}>
            {toSeeRight.map((t, index) => (
              <li key={cutOff + index + 1}>{t}</li>
            ))}
          </ol>
        </ItemsContainer>
      </Section>
    );
  }
);

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;

  > ol {
    margin-left: 40px;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    flex-direction: column;
  }
`;
