import { gql } from '@apollo/client';
import {
  EXPLORE_WEB_GetFullTour,
  EXPLORE_WEB_GetFullTour_result,
  EXPLORE_WEB_GetFullTour_result_stops,
  EXPLORE_WEB_GetFullTour_result_stops_images,
  EXPLORE_WEB_GetFullTour_result_discoveries,
  EXPLORE_WEB_GetFullTour_result_guide,
  EXPLORE_WEB_GetFullTourVariables,
  EXPLORE_WEB_GetFullTour_result_stops_projections,
} from './__generated__/EXPLORE_WEB_GetFullTour';
import { TOUR_FRAGMENT } from '../fragments/Tour';
import { TOUR_STOP_FRAGMENT } from '../fragments/TourStop';
import { TOUR_DISCOVERY_FRAGMENT } from './../fragments/TourDiscovery';
import { ASSET_FRAGMENT } from '../fragments/Asset';
import { I18NSTRING } from '../fragments/I18nString';

export type Variables = EXPLORE_WEB_GetFullTourVariables;
export type Data = EXPLORE_WEB_GetFullTour;
export type FullTour = EXPLORE_WEB_GetFullTour_result;
export type FullStop = EXPLORE_WEB_GetFullTour_result_stops;
export type FullDiscovery = EXPLORE_WEB_GetFullTour_result_discoveries;
export type FullImage = EXPLORE_WEB_GetFullTour_result_stops_images;
export type Guide = EXPLORE_WEB_GetFullTour_result_guide;
export type Projection = EXPLORE_WEB_GetFullTour_result_stops_projections;

// Important: If new asset fields are added, make sure they are added to
export const GET_FULL_TOUR_QUERY = gql`
  query EXPLORE_WEB_GetFullTour($playlistID: ID!) {
    result: getPublicPlaylist(input: { playlistID: $playlistID }) {
      ...TourFragment

      stops {
        ...TourStopFragment
      }

      discoveries {
        ...TourDiscoveryFragment
      }
    }
  }

  ${TOUR_FRAGMENT}
  ${TOUR_STOP_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${I18NSTRING}
  ${TOUR_DISCOVERY_FRAGMENT}
`;
