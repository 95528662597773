import { env } from '../../App/config/env';

export enum Feature {
  // eslint-disable-next-line no-unused-vars
  GazeCursor = 'GazeCursor',
  // eslint-disable-next-line no-unused-vars
  InTourLanguageControls = 'InTourLanguageControls',
  // eslint-disable-next-line no-unused-vars
  TourExitButton = 'TourExitButton',
  // eslint-disable-next-line no-unused-vars
  InTourSubtitles = 'InTourSubtitles',
}

// TODO: strict typing using unions
// https://www.typescriptlang.org/docs/handbook/advanced-types.html
// modal state is not persisted. it is filtered out from transforms.ts
export type FeaturesState = Record<Feature, boolean>;

export const initialState: FeaturesState = {
  GazeCursor: env.IS_STAGING_SITE,
  InTourLanguageControls: true,
  TourExitButton: false,
  InTourSubtitles: true,
};
