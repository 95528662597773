import { handleActions } from 'redux-actions';
import { PurchaseMode } from '../../graphql/globalTypes';
import {
  ACTION_TYPES,
  LoginSuccessAction,
  AddToCartAction,
  RemoveFromCartAction,
  UpdateItemCountAction,
  UpdatePurchaseModeAction,
  SetCurrencyCodeAction,
  SetCookieDisclaimerVisibilityAction,
  SetProtectedAction,
  SetPromoCodeAction,
  AddPinnedTourAction,
  RemovePinnedTourAction,
  AddPinnedToursAction,
  SetLanguageAction,
  UpdateTourSettingsAction,
  UpdateAppSettingsAction,
  PushMessageAction,
  SetWhitelabelAppSettingsAction,
} from './actions';
import { AppState, initialState } from './types';

export const reducer = handleActions<AppState, any>(
  {
    [ACTION_TYPES.LOGOUT_SUCCESS]: (): AppState => ({
      ...initialState,
      showDisclaimer: false,
      protected: false,
    }),
    [ACTION_TYPES.DO_REFETCH]: (state: AppState): AppState => ({
      ...state,
      doRefetch: true,
    }),
    [ACTION_TYPES.UPDATE_APP_SETTINGS]: (
      state: AppState,
      action: UpdateAppSettingsAction
    ): AppState => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        ...action.payload.options,
      },
    }),

    [ACTION_TYPES.UPDATE_TOUR_SETTINGS]: (
      state: AppState,
      action: UpdateTourSettingsAction
    ): AppState => ({
      ...state,
      tourSettings: {
        ...state.tourSettings,
        ...action.payload.options,
      },
    }),

    [ACTION_TYPES.LOGIN_SUCCESS]: (
      state: AppState,
      action: LoginSuccessAction
    ): AppState => ({
      ...state,
      user: { id: action.payload.userId, jwt: action.payload.jwt },
    }),

    [ACTION_TYPES.SET_CURRENCY_CODE]: (
      state: AppState,
      action: SetCurrencyCodeAction
    ): AppState => ({ ...state, ...action.payload }),

    [ACTION_TYPES.ADD_TO_CART_ALLOWED]: (
      state: AppState,
      action: AddToCartAction
    ): AppState => {
      const { productSKU, productID, count, currencyCode } = action.payload;
      const cart = state.cart || [];
      const nextCurrencyCode = currencyCode || state.currencyCode;

      const existingItem = cart.find(
        (item) => item.productID === productID && item.productSKU === productSKU
      );

      if (existingItem) {
        if (count === undefined || count === 0) {
          // consider a duplicate, so return existing state
          return state;
        } else {
          const existingCount = existingItem.count || 1;
          const item = { ...existingItem, count: existingCount + count };
          const cartItems = state.cart
            .filter(
              (item) =>
                !(
                  item.productID === productID && item.productSKU === productSKU
                )
            )
            .concat(item);

          return { ...state, cart: cartItems, currencyCode: nextCurrencyCode };
        }
      }

      return {
        ...state,
        cart: [...cart, action.payload],
        currencyCode: nextCurrencyCode,
      };
    },

    [ACTION_TYPES.UPDATE_ITEM_COUNT]: (
      state: AppState,
      action: UpdateItemCountAction
    ): AppState => {
      const cart = state.cart || [];
      const { productSKU, productID, count, purchaseMode } = action.payload;

      const existingItem = cart.find(
        (item) => item.productID === productID && item.productSKU === productSKU
      );

      if (existingItem) {
        const item = { ...existingItem, count };
        const cartItems = state.cart
          .filter(
            (item) =>
              !(item.productID === productID && item.productSKU === productSKU)
          )
          .concat(item);

        return {
          ...state,
          cart: cartItems,
          purchaseMode:
            purchaseMode || state.purchaseMode || PurchaseMode.PERSONAL,
        };
      }

      return {
        ...state,
        cart: [...cart, action.payload],
        purchaseMode:
          purchaseMode || state.purchaseMode || PurchaseMode.PERSONAL,
      };
    },

    [ACTION_TYPES.UPDATE_CART_MODE]: (
      state: AppState,
      { payload }: UpdatePurchaseModeAction
    ): AppState => {
      const { purchaseMode } = payload;

      if (purchaseMode === PurchaseMode.GIFT) {
        return { ...state, purchaseMode };
      }

      // update count of each item to 1
      const updated = (state.cart || []).map((item) => ({ ...item, count: 1 }));

      return { ...state, cart: updated, purchaseMode };
    },

    [ACTION_TYPES.REMOVE_FROM_CART]: (
      state: AppState,
      action: RemoveFromCartAction
    ): AppState => {
      const cart = state.cart || [];
      const { productSKU, productID } = action.payload;

      const filteredCart = cart.filter(
        (item) =>
          !(item.productID === productID && item.productSKU === productSKU)
      );

      return { ...state, cart: filteredCart };
    },

    [ACTION_TYPES.SET_LANDSCAPE_MESSAGE_DISPLAYED]: (
      state: AppState
    ): AppState => ({ ...state, landscapeMessageDisplayed: true }),

    [ACTION_TYPES.SET_ADD_TO_HOMESCREEN_POPUP_DISPLAYED]: (
      state: AppState
    ): AppState => ({
      ...state,
      AddToHomeScreenPopupDisplayedTimestamp: Date.now(),
    }),

    [ACTION_TYPES.SET_COOKIE_DISCLAIMER_VISIBILITY]: (
      state: AppState,
      action: SetCookieDisclaimerVisibilityAction
    ): AppState => ({ ...state, showDisclaimer: action.payload.visibility }),

    [ACTION_TYPES.SET_PROTECTED]: (
      state: AppState,
      action: SetProtectedAction
    ): AppState => ({ ...state, protected: action.payload.protected }),

    [ACTION_TYPES.SET_PROMO_CODE]: (
      state: AppState,
      action: SetPromoCodeAction
    ): AppState => ({ ...state, promoCode: action.payload }),

    [ACTION_TYPES.ADD_PINNED_TOUR]: (
      state: AppState,
      { payload }: AddPinnedTourAction
    ): AppState => {
      // remove existing and add back
      const pinnedTours = [
        payload,
        ...state.pinnedTours.filter((t) => t.tourID !== payload.tourID),
      ];

      return { ...state, pinnedTours };
    },

    [ACTION_TYPES.ADD_PINNED_TOURS]: (
      state: AppState,
      { payload }: AddPinnedToursAction
    ): AppState => {
      // remove existing and add back
      const pinnedTours = [
        ...payload,
        ...state.pinnedTours.filter(
          (t) => !payload.find((p) => p.tourID === t.tourID)
        ),
      ];

      return { ...state, pinnedTours };
    },

    [ACTION_TYPES.REMOVE_PINNED_TOUR]: (
      state: AppState,
      { payload }: RemovePinnedTourAction
    ): AppState => {
      // remove existing and add back
      const pinnedTours = state.pinnedTours.filter(
        (t) => t.tourID !== payload.tourID
      );

      return { ...state, pinnedTours };
    },

    [ACTION_TYPES.REMOVE_ALL_PINNED_TOURS]: (state: AppState): AppState => ({
      ...state,
      pinnedTours: [],
    }),

    [ACTION_TYPES.SET_LANGUAGE]: (
      state: AppState,
      { payload }: SetLanguageAction
    ): AppState => ({
      ...state,
      language: payload,
    }),

    [ACTION_TYPES.PUSH_MESSAGE]: (
      state: AppState,
      { payload }: PushMessageAction
    ): AppState => ({
      ...state,
      messages: state.messages.concat(payload),
    }),

    [ACTION_TYPES.REMOVE_LAST_MESSAGE]: (state: AppState): AppState => ({
      ...state,
      messages: state.messages.splice(0, state.messages.length - 1),
    }),

    [ACTION_TYPES.ENABLE_GOOGLE_ANALYTICS]: (state: AppState): AppState => ({
      ...state,
      enableGoogleAnalytics: true,
    }),

    [ACTION_TYPES.SET_WHITELABEL_APP_SETTINGS]: (
      state: AppState,
      { payload }: SetWhitelabelAppSettingsAction
    ): AppState => ({
      ...state,
      whitelabelAppSettings: payload,
    }),
  },
  initialState
);
