export const AUTH_TOKEN_KEY = 'token';
export const AFFILIATE_CODE_KEY = 'affiliateCode';
export const COUPON_CODE_KEY = 'couponCode';
export const APP_ID_KEY = 'appID';

// Taken from @lvr/http-core
export enum LITHODOMOS_HEADERS {
  // eslint-disable-next-line no-unused-vars
  APP_ID = 'x-lvr-app-id',
  // eslint-disable-next-line no-unused-vars
  APP_VERSION = 'x-lvr-app-version',
  // eslint-disable-next-line no-unused-vars
  CORRELATION_ID = 'x-lvr-correlation-id',
  // eslint-disable-next-line no-unused-vars
  DEVICE_ID = 'x-lvr-device-id',
  // eslint-disable-next-line no-unused-vars
  TIMEZONE = 'x-lvr-timezone',
  // eslint-disable-next-line no-unused-vars
  LOCALE = 'x-lvr-locale',
}

export enum APP_ID {
  // eslint-disable-next-line no-unused-vars
  ANCIENT_WORLD = 'ancient-world',
  // eslint-disable-next-line no-unused-vars
  ANCIENT_WORLD_STAGING = 'viewer-lithodomos-com',
  // eslint-disable-next-line no-unused-vars
  ANCIENT_WORLD_WHITELABEL = 'ancient-world-whitelabel',
  // eslint-disable-next-line no-unused-vars
  ABERCROMBIE_KENT = 'abercrombiekent',
  // eslint-disable-next-line no-unused-vars
  OSTIA = 'ostia',
  // eslint-disable-next-line no-unused-vars
  NOT_FAIR = 'notfair',
  // eslint-disable-next-line no-unused-vars
  NOT_FAIR_STAGING = 'notfair-staging',
  // eslint-disable-next-line no-unused-vars
  HELLENIC_MUSEUM = 'hellenicmuseum',
  // eslint-disable-next-line no-unused-vars
  HELLENIC_MUSEUM_STAGING = 'hellenicmuseum-staging',
  // eslint-disable-next-line no-unused-vars
  ANCIENT_WORLD_CAMBRIDGE = 'cambridge',
  // eslint-disable-next-line no-unused-vars
  ANCIENT_WORLD_CAMBRIDGE_STAGING = 'cambridge-staging',
}
// london malaga barcelona same button color with acropolis
// IMPORTANT!!!!!!
// it is critical that the order of these entries aren't changed.
// any new entries should be added to the end.
export const whiteLabelAppTitleForTradeShow = [
  'The Acropolis', // 0
  'Discover Paestum', // 1
  'Medina Azahara', // 2
  'London', // 3
  'Malaga', // 4
  'Barcelona', // 5
  'Islamic Museum of Australia', // 6
  'Ancient Pompeii', // 7
  'Paphos, Cyprus', // 8
];

export const whiteLabelAppHeaderUrls = [
  '/athenian-acropolis',
  '/athenian-acropolis-staging',
  '/islamic_museum_australia',
  '/islamic_museum_australia_staging',
  '/',
  '/paestum',
  '/paestum-staging',
  '/medina-azahara',
  '/medina-azahara-staging',
  '/london',
  '/london-staging',
  '/malaga',
  '/malaga-staging',
  '/barcelona',
  '/barcelona-staging',
];
