import { captureInSentry } from '../App/config/reporting/captureInSentry';

let _deviceID = '';

export function getDeviceID(): string {
  const deviceID = _deviceID;

  if (deviceID === '') {
    captureInSentry('deviceID.ts DeviceID is not set before it was called!!!');
  }

  return deviceID;
}

export function setDeviceID(deviceID: string) {
  _deviceID = deviceID;
}
