import React, { useRef, useState, useEffect, useCallback } from 'react';
import memoizeOne from 'memoize-one';
import styled, { useTheme } from '../../style/styled';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TopRightContainer } from '../../styledComponents/TopRightContainer';
import { env } from '../../App/config/env';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { ActivateProduct } from './ActivateProduct';
import { Z_INDEX } from '../../style/Z_INDEX';
import {
  selectIsAuthenticated,
  selectIsGuestUser,
  selectLanguage,
  selectWhitelabelAppSettings,
} from '../../store/app/selectors';
import { ROUTES } from '../../Routes/routes';
import { CartButton } from './CartButton';
import { SignOutLink } from './SignOutLink';
import { SignInLink } from './SignInLink';
import { MyTripsButton } from './MyTripsButton';
import { HomeButton } from './HomeButton';
import { DevButton } from './DevButton';
import { MobileMenu } from './MobileMenu';
import { AppLogoAndText } from './AppLogoAndText';
import { nonNullable } from '../../utils/nonNullable';
import { ToursLibraryLink } from './ToursLibraryLink';
import { LanguageSelector } from './LanguageSelector/LanguageSelector';
import { ConvertGuestAccountLink } from './ConvertGuestAccountLink';
import { NAVBAR_HEIGHT } from '../../consts';
import { useHistory } from 'react-router';
import {
  whiteLabelAppHeaderUrls,
  whiteLabelAppTitleForTradeShow,
} from '../../App/config/enums';
import { DevVersion } from './DevVersion';
import { getRTL } from '../../features/i18n/getRTL';

const AppHeader = () => {
  const {
    banner: { title },
  } = useSelector(selectWhitelabelAppSettings);
  const isAuthed = useSelector(selectIsAuthenticated);
  const isGuestUser = useSelector(selectIsGuestUser);
  const language = useSelector(selectLanguage);

  const location = useLocation();
  const history = useHistory();

  const theme = useTheme();
  const headerRef = useRef<HTMLHeadElement>(null);
  const [atTop, setAtTop] = useState<boolean>(window.scrollY <= 0);

  const { pathname } = location;
  const color = theme.palette.appHeader.color;
  const bgColor = theme.palette.appHeader.background;
  const nonMenuCommonProps = { color, size: HEADER_BUTTON_SIZE };

  const rtl = getRTL(language);

  const inMenuCommonProps = { ...nonMenuCommonProps, fullWidth: true, rtl };

  const updateAtTop = useCallback(() => {
    const header = headerRef.current;

    if (header) {
      const isAtTop = window.scrollY < header.offsetTop + header.offsetHeight;

      if (isAtTop !== atTop) {
        setAtTop(isAtTop);
      }
    }
  }, [atTop]);

  // Scroll event listener.
  // Updates whether we've scrolled to the top or not, to be used for toggling the box shadow of the header.
  useEffect(() => {
    window.addEventListener('scroll', updateAtTop);

    return () => {
      window.removeEventListener('scroll', updateAtTop);
    };
  }, [updateAtTop]);

  const {
    dev,
    version,
    cart,
    home,
    redeem,
    myTrips,
    signOut,
    signIn,
    library,
    languageSelector,
    convertGuestAccount,
  } = getButtonVisibilityMemoized(pathname, isAuthed, isGuestUser);

  const menuItems = [
    library ? (
      <MenuItem key="toursLibrary" bgColor={bgColor}>
        <ToursLibraryLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    convertGuestAccount ? (
      <MenuItem key="convertGuestAccount" bgColor={bgColor}>
        <ConvertGuestAccountLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    signOut ? (
      <MenuItem key="signOut" bgColor={bgColor}>
        <SignOutLink {...inMenuCommonProps} />
      </MenuItem>
    ) : null,

    dev ? (
      <MenuItem key="dev" bgColor={bgColor}>
        <DevButton {...inMenuCommonProps} fullWidth />
      </MenuItem>
    ) : null,

    version ? (
      <MenuItem key="version" bgColor={bgColor} nonInteractive>
        <DevVersion {...inMenuCommonProps} />
      </MenuItem>
    ) : null,
  ].filter(nonNullable);

  const backgroundColor =
    whiteLabelAppTitleForTradeShow.indexOf(title) >= 0
      ? atTop &&
        whiteLabelAppHeaderUrls.indexOf(history.location.pathname) !== -1
        ? bgColor
        : whiteLabelAppTitleForTradeShow.indexOf(title) === 0 ||
          whiteLabelAppTitleForTradeShow.indexOf(title) === 3 ||
          whiteLabelAppTitleForTradeShow.indexOf(title) === 4 ||
          whiteLabelAppTitleForTradeShow.indexOf(title) === 5 ||
          whiteLabelAppTitleForTradeShow.indexOf(title) === 6
        ? '#000'
        : whiteLabelAppTitleForTradeShow.indexOf(title) === 1
        ? '#E5745D'
        : '#8D2332'
      : bgColor;

  return (
    <AppHeaderContainer
      ref={headerRef}
      id="header"
      bgColor={backgroundColor}
      atTop={atTop}
      fontFamily={
        whiteLabelAppTitleForTradeShow.indexOf(title) === 6
          ? 'Rama Gothic'
          : 'Roboto Condensed'
      }
      fontSize={whiteLabelAppTitleForTradeShow.indexOf(title) === 6 ? 18 : 16}
    >
      <NavBar rtl={rtl}>
        <AppLogoAndText size={18} />

        <nav>
          <TopRightContainer>
            {languageSelector && <LanguageSelector />}

            {home && <HomeButton {...nonMenuCommonProps} />}

            {cart && <CartButton {...nonMenuCommonProps} />}

            {redeem && <ActivateProduct {...nonMenuCommonProps} />}

            {/* There is a reference to the location of MyTripsButton in the FAQ page. Make sure it is updated if this button is moved. */}
            {myTrips && <MyTripsButton {...inMenuCommonProps} />}

            {signIn && <SignInLink {...inMenuCommonProps} />}

            {menuItems.length > 0 && (
              <MobileMenu isAuthed={isAuthed} items={menuItems} rtl={rtl} />
            )}
          </TopRightContainer>
        </nav>
      </NavBar>
    </AppHeaderContainer>
  );
};

export default AppHeader;

const AppHeaderContainer = styled.header.attrs<{
  atTop: boolean;
}>(({ atTop }) => ({
  style: {
    boxShadow: atTop ? 'none' : '0px 1px 20px 0px #000000',
  },
}))<{
  bgColor: string;
  atTop: boolean;
  fontFamily?: string;
  fontSize?: number;
}>`
  background: ${({ bgColor }) => bgColor};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: ${Z_INDEX.HEADER};
  transition: all 0.2s ease-in-out;
  & * {
    font-family: ${({ fontFamily }) => fontFamily}, sans-serif;
    font-size: ${({ fontSize }) => fontSize}px;
  }
`;

const NavBar = styled.div<{ rtl: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${NAVBAR_HEIGHT}px;
  padding: 10px;
  /* flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')}; */
  /* direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')}; */
`;

const getButtonVisibilityMemoized = memoizeOne(getButtonVisibility);

function getButtonVisibility(
  pathname: string,
  isAuthed: boolean,
  isGuestUser: boolean
) {
  return {
    dev: env.ENABLE_DEV_MENU_ITEM,
    version: env.ENABLE_DEV_MENU_ITEM,
    cart: true,
    home:
      pathname !== ROUTES.cart &&
      pathname !== ROUTES.checkout &&
      pathname !== ROUTES.index,
    redeem: pathname !== ROUTES.tour && !pathname.startsWith(ROUTES.auth),
    myTrips: isAuthed,
    signOut: isAuthed,
    signIn: !isAuthed && pathname !== ROUTES.authLogin,
    library: true,
    languageSelector: true,
    convertGuestAccount: isAuthed && isGuestUser,
  };
}

const MenuItem = styled.div<{ nonInteractive?: boolean; bgColor: string }>`
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0 10px;

  svg {
    transition: transform 0.2s;
  }

  p {
    transition: transform 0.2s;
  }
`;
