import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled, { useTheme } from '../style/styled';
import CloseIcon from 'mdi-react/CloseIcon';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
  BodyScrollOptions,
} from 'body-scroll-lock';
import { isMobileOnly } from 'react-device-detect';
import { Z_INDEX } from '../style/Z_INDEX';
import { useKeyPress } from '../hooks/useKeyPress';
import { useResize, Dim } from '../hooks/useResize';
import { captureEvent, DataKeys, EventNames } from '../features/analytics';
import { getDecorationImageURL } from '../utils/getDecorationImageURL';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectRTL } from '../store/app/selectors';

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

interface Props {
  children: any;
  name: string;
  isOpen: boolean;
  onClose: () => void;
  fullPage?: boolean;
  clickOutsideToClose?: boolean;
  loading?: boolean;
  // Important: make sure to test this on mobile/tablet if there is virtual keyboard data entry inside the modal
  lockBackground?: boolean;
  padding?: string;
  withBanner?: boolean;
  withNoScrolling?: boolean;
  contentBgColor?: string;
  activateError?: boolean;
}

// TODO: write tests
export const Modal: React.FC<Props> = ({
  children,
  isOpen,
  name,
  onClose,
  fullPage = isMobileOnly,
  clickOutsideToClose = true,
  lockBackground = true,
  padding = '16px',
  withBanner = false,
  withNoScrolling = false,
  contentBgColor,
  activateError,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const rCode = qs.parse(history.location.search);
  const rtl = useSelector(selectRTL);

  const [{ height }, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useResize(Dim.BOTH, setSize);

  const clickOutside = useCallback(() => {
    clickOutsideToClose && onClose();
  }, [clickOutsideToClose, onClose]);

  // for body scroll management
  const modalRef = useRef<HTMLDivElement>(null);

  // close on escape key press
  useKeyPress('Escape', clickOutside);

  useEffect(() => {
    if (lockBackground && modalRef.current) {
      // this is a better solution to manage body scrolling
      if (isOpen) {
        disableBodyScroll(modalRef.current, options);
      } else {
        enableBodyScroll(modalRef.current);
      }
    }

    if (isOpen) {
      captureEvent({
        name: EventNames.VIEW_MODAL,
        data: [{ key: DataKeys.MODAL, value: name }],
      });
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isOpen, lockBackground, name]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContainer
      style={{ display: activateError || !rCode.rcode ? 'flex' : 'none' }}
      className="modal"
      fullPage={fullPage}
      onClick={clickOutside}
      rtl={rtl}
    >
      <ModalDialog
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
        height={height}
        fullPage={fullPage}
        role="button"
        withBanner={withBanner}
        withNoScrolling={withNoScrolling}
        bgColor={contentBgColor || theme.palette.common.white}
      >
        <Content padding={padding}>{children}</Content>

        <ModalCloseButton fullPage={fullPage} onClick={onClose} rtl={rtl}>
          <CloseIcon color={theme.palette.common.black} />
        </ModalCloseButton>
      </ModalDialog>
    </ModalContainer>
  );
};

const Content = styled.div<{ padding: string }>`
  position: relative;
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const ModalContainer = styled.div<{
  fullPage: boolean;
  rtl: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX.MODAL};
  background: ${(props) =>
    props.fullPage ? props.theme.palette.common.white : '#000000aa'};
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
`;

const ModalDialog = styled.div<{
  fullPage: boolean;
  height: number;
  withBanner: boolean;
  withNoScrolling: boolean;
  bgColor: string;
}>`
  position: ${({ fullPage }) => (fullPage ? 'fixed' : 'relative')};
  overflow-x: hidden;
  overflow-y: ${({ withNoScrolling }) => (withNoScrolling ? 'hidden' : 'auto')};
  -webkit-overflow-scrolling: touch;

  box-shadow: ${({ fullPage }) =>
    fullPage ? 'none' : '0px 0px 21px -1px rgba(0, 0, 0, 0.75)'};

  width: ${({ fullPage }) => (fullPage ? '100%' : 'auto')};
  max-width: ${({ fullPage }) => (fullPage ? '100%' : '90%')};

  height: ${({ fullPage }) => (fullPage ? '100%' : 'auto')};
  min-height: ${({ fullPage, height }) => (fullPage ? `${height}px` : 'auto')};
  max-height: ${({ fullPage }) => (fullPage ? 'auto' : 'calc(100% - 32px)')};

  background: ${({ bgColor }) => bgColor};
  background-image: ${({ withBanner }) =>
    withBanner ? `url(${getDecorationImageURL()})` : 'none'};
  background-size: auto 120px;
  background-position: center bottom;
  background-repeat: repeat-x;
`;

const ModalCloseButton = styled.div<{ fullPage: boolean; rtl: boolean }>`
  align-items: center;
  background: ${(props) => props.theme.palette.common.white};
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  right: ${({ rtl }) => (rtl ? 'auto' : '8px')};
  left: ${({ rtl }) => (rtl ? '8px' : 'auto')};
  top: 8px;
  position: absolute;
  width: 32px;
  z-index: 10;

  &:hover {
    background: ${(props) => props.theme.palette.common.black};
    border: 2px solid ${(props) => props.theme.palette.common.white};

    > svg {
      fill: ${(props) => props.theme.palette.common.white};
    }
  }
`;
