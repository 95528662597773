import { SUPPORTED_LANGUAGES } from '../../../consts';
import { LanguageCode } from '../../../graphql/globalTypes';

export const getSupportedLanguages = (
  supportedLanguageCodes: Array<LanguageCode> = []
) => {
  return SUPPORTED_LANGUAGES.filter((i) => {
    // if supported codes is not set, then all codes are supported
    if (supportedLanguageCodes.length > 0) {
      return supportedLanguageCodes.includes(i.value);
    } else {
      return true;
    }
  });
};
