import React from 'react';
import Icon from 'mdi-react/HomeIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from '../../style/styled';
import { ROUTES } from '../../Routes/routes';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText as _AppHeaderButtonText,
} from './styledComponents';
import { useSelector } from 'react-redux';
import { selectRTL } from '../../store/app/selectors';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const HomeButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const rtl = useSelector(selectRTL);
  const { t } = useTranslation();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Home',
    });

    history.push(ROUTES.index);
  };

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleClick}>
      <div>
        <Icon color={color} size={size * 1.4} />
      </div>
      <AppHeaderButtonText size={size} color={color} rtl={rtl}>
        {t('Home')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};

const AppHeaderButtonText = styled(_AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
