import { DEFAULT_LANGUAGE, languages } from '../../../../../../consts';
import { LanguageCode } from '../../../../../../graphql/globalTypes';
import { EXPLORE_WEB_GetTourByInternalReference_result_introNarrations } from '../../../../../../graphql/queries/__generated__/EXPLORE_WEB_GetTourByInternalReference';

export function getIntroNarration(
  introNarrations: EXPLORE_WEB_GetTourByInternalReference_result_introNarrations[],
  language: LanguageCode
):
  | EXPLORE_WEB_GetTourByInternalReference_result_introNarrations
  | null
  | undefined {
  let narration = introNarrations.find(
    (n) => n.language.code === languages[language]?.code
  );

  if (!narration) {
    narration = introNarrations.find(
      (n) => n.language.code === languages[DEFAULT_LANGUAGE]?.code
    );
  }

  return narration;
}
