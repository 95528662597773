import React from 'react';
import { SelectedFeature } from '../../types';
import vector from '../Source/vector';
import OLVectorLayer from '../Layers/VectorLayer';
import { createMarkerFeature } from './createMarkerFeature';

interface Props {
  markers: Array<SelectedFeature>;
  selectedFeature: SelectedFeature | null;
}

const Markers: React.FC<Props> = ({ markers, selectedFeature }) => {
  const features = markers.map((feature) => {
    return createMarkerFeature(feature, selectedFeature?.id);
  });

  return <OLVectorLayer layerOptions={{ source: vector({ features }) }} />;
};

export default Markers;
