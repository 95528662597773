import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from 'mdi-react/DeveloperBoardIcon';
import { ROUTES } from '../../Routes/routes';
import { HEADER_BUTTON_SIZE } from '../../style/SIZES';
import { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText,
} from './styledComponents';
interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
  rtl?: boolean;
}

export const DevButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  return (
    <AppHeaderButtonContainer
      fullWidth={fullWidth}
      onClick={() => history.push(ROUTES.dev)}
    >
      <Icon color={color} size={size * 1.4} />
      <AppHeaderButtonText size={size} color={color} rtl={props.rtl}>
        DEV
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};
