import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../store/app/actions';
import { selectIsAuthenticated } from '../../store/app/selectors';
import { CodeKeys } from '../../types';
import { getQueryVariable } from '../../utils/getQueryVariable';
import { getUserIDFromJWT } from '../../utils/getUserIDFromJWT';
import {
  EXPLORE_WEB_GenerateJWTFromRefreshToken as Data,
  EXPLORE_WEB_GenerateJWTFromRefreshTokenVariables as Variables,
} from './__generated__/EXPLORE_WEB_GenerateJWTFromRefreshToken';
import { captureEvent, EventNames } from '../../features/analytics';
import { captureInSentry } from '../config/reporting/captureInSentry';
import { UNKNOWN_ERROR } from '../../consts';
import { removeQueryVariable } from '../../utils/removeQueryVariable';
import { useHistory, useLocation } from 'react-router-dom';

const REQUEST_JWT_FROM_REFRESH_TOKEN = gql`
  mutation EXPLORE_WEB_GenerateJWTFromRefreshToken(
    $input: GenerateJWTFromRefreshTokenInput!
  ) {
    result: generateJWTFromRefreshToken(input: $input) {
      jwt
      error {
        id
        message
        type
      }
    }
  }
`;

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

// Log in from a refresh token.
// - Extract the refresh token from query string
// - Send it to generateJWTFromRefreshToken mutation
// - Receive a jwt and store in redux state
// TODO: [LVR-3063] As a user who has purchased a tour, I should be able to log myself in even when someone else is currently logged in, so that I can access my tours quickly
export const LoginFromRefreshToken: React.FC = () => {
  // extract the refresh token from the URL
  const refreshToken = getQueryVariable(CodeKeys.REFRESH_TOKEN);

  const authed = useSelector(selectIsAuthenticated);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [requestJwt] = useMutation<Data, Variables>(
    REQUEST_JWT_FROM_REFRESH_TOKEN,
    {
      onError: (error) => {
        const errorMessage =
          error?.message ||
          t('Sorry, we could not automatically log you in.') ||
          UNKNOWN_ERROR;

        captureInSentry(errorMessage, { variables: getVariables() });

        toast.error(errorMessage, {
          autoClose: 3000,
          pauseOnHover: false,
          hideProgressBar: true,
          toastId: errorMessage,
        });

        const historyObject = {
          pathname: location.pathname,
          search: removeQueryVariable(CodeKeys.REFRESH_TOKEN),
        };

        history.replace(historyObject);
      },
      onCompleted: ({ result }) => {
        const { jwt, error } = result || {};

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          Sentry.configureScope(function (scope) {
            scope.setUser({ id: userID });
          });

          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({ name: EventNames.LOGGED_IN });

          const toastSuccessMessage = t(
            'You have been automatically logged in. You can access your tours from My Tours.'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });
        } else {
          const errorMessage =
            error?.message ||
            t('Sorry, we could not automatically log you in.') ||
            UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables(), result });

          toast.error(errorMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: errorMessage,
          });
        }

        const historyObject = {
          pathname: location.pathname,
          search: removeQueryVariable(CodeKeys.REFRESH_TOKEN),
        };

        history.replace(historyObject);
      },
    }
  );

  useEffect(() => {
    if (refreshToken && !authed) {
      const variables = {
        input: { refreshToken },
      };

      setVariables(variables);

      requestJwt({ variables });
    } else if (refreshToken) {
      const historyObject = {
        pathname: location.pathname,
        search: removeQueryVariable(CodeKeys.REFRESH_TOKEN),
      };

      history.replace(historyObject);
    }
  }, [refreshToken, authed, requestJwt, history, location.pathname]);

  return null;
};
