import { formatDistanceStrict, differenceInCalendarDays } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import itLocale from 'date-fns/locale/it';
import { LanguageCode } from '../../../graphql/globalTypes';

export function getExpiryInfo(
  expiresAt: string | null | undefined,
  language: LanguageCode
) {
  let expiresIn = null;
  let expiresSoon = false;

  if (expiresAt) {
    const expiryDate = new Date(expiresAt);
    const now = new Date();

    let locale = enLocale;

    if (language === LanguageCode.it_IT) {
      locale = itLocale;
    }

    expiresIn = formatDistanceStrict(expiryDate, now, {
      locale,
    });

    const daysBeforeExpiration = differenceInCalendarDays(expiryDate, now);
    expiresSoon = daysBeforeExpiration < 7;
  }

  return { expiresIn, expiresSoon };
}
