import { gql, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { useDispatch } from 'react-redux';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import { loginSuccess } from '../../../store/app/actions';
import { useHistory } from 'react-router-dom';
import { env } from '../../../App/config/env';
import {
  EXPLORE_WEB_AuthWithFacebook as Data,
  EXPLORE_WEB_AuthWithFacebookVariables as Variables,
} from './__generated__/EXPLORE_WEB_AuthWithFacebook';
import { unbox } from '../../../utils/unbox';
import { getQueryVariable } from '../../../utils/getQueryVariable';
import { LoadingView } from '../../../components/LoadingView';
import { setModalType } from '../../../store/modal/actions';
import { ModalType } from '../../../store/modal/types';
import { ROUTES } from '../../routes';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';

const AUTH_WITH_FACEBOOK_MUTATION = gql`
  mutation EXPLORE_WEB_RedirectedAuthWithFacebook(
    $input: AuthWithFacebookInput!
  ) {
    result: authWithFacebook(input: $input) {
      jwt
      newUser
      error {
        id
        message
        type
      }
    }
  }
`;

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

const FacebookRedirectHandler: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const code = unbox(getQueryVariable('code')) || '';
  const stateStr = unbox(getQueryVariable('state')) || '{}';

  const stateObj = JSON.parse(stateStr);
  const redirectURL = stateObj.redirectURL;
  const redirectedHref = stateObj.redirectedHref;
  const originalHref = stateObj.originalHref;

  const variables: Variables = {
    input: {
      code,
      appID: env.FACEBOOK_APP_ID,
      redirectURL,
      referralAppID: env.APP_ID,
    },
  };

  const affiliateCode = getStoredAffiliateCode();

  if (affiliateCode) {
    variables.input.affiliateCode = affiliateCode;
  }

  setVariables(variables);

  const [authWithFacebook, { loading }] = useMutation<Data, Variables>(
    AUTH_WITH_FACEBOOK_MUTATION,
    {
      variables,
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        // send them back to the original route where the auth was initiated from
        const originalURL = new URL(originalHref || window.location.origin);

        history.replace({
          pathname: originalURL.pathname,
          search: originalURL.search,
        });

        dispatch(
          setModalType({
            modalType: ModalType.AUTH_LOGIN,
            modalData: {
              redirectedHref: redirectedHref || window.location.origin,
              originalHref: originalHref || window.location.origin,
              errorMessage: error?.message || UNKNOWN_ERROR,
            },
          })
        );
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry(
            'FacebookRedirectHandler.tsx result is null or undefined',
            { variables: getVariables() }
          );

          return;
        }

        const { jwt, error, newUser } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          Sentry.configureScope(function (scope) {
            scope.setUser({ id: userID });
          });

          dispatch(loginSuccess({ userId: userID, jwt }));

          if (newUser) {
            captureEvent({
              name: EventNames.USER_REGISTERED,
              data: [{ key: DataKeys.SIGNUP_METHOD, value: 'Facebook' }],
            });
          } else {
            captureEvent({
              name: EventNames.LOGGED_IN,
              data: [{ key: DataKeys.LOGIN_METHOD, value: 'Facebook' }],
            });
          }

          const successMessage = t(
            'You have successfully logged in with Facebook'
          );

          toast.success(successMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: 'SUCCESSFUL FACEBOOK LOGIN ALERT',
          });

          const redirectedURL = new URL(
            redirectedHref || window.location.origin
          );

          history.replace({
            pathname: redirectedURL.pathname,
            search: redirectedURL.search,
          });
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          console.error(errorMessage);

          // show the auth modal again
          dispatch(
            setModalType({
              modalType: ModalType.AUTH_LOGIN,
              modalData: {
                redirectedHref: redirectedHref || window.location.origin,
                originalHref: originalHref || window.location.origin,
                errorMessage,
              },
            })
          );

          // send them back to the original route where the auth was initiated from
          const originalURL = new URL(originalHref || window.location.origin);

          history.replace({
            pathname: originalURL.pathname,
            search: originalURL.search,
          });
        }
      },
    }
  );

  useEffect(
    () => {
      if (code) {
        authWithFacebook();
      } else {
        history.replace(ROUTES.index);
      }
    },
    // important! only call this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (loading) {
    return <LoadingView timeout={0} black />;
  }

  return null;
};

export default FacebookRedirectHandler;
