import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../../../style/styled';
import { SCREEN_WIDTH_BREAK_EXTRA_SMALL } from '../../../../style/SIZES';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../../features/analytics';
import { Stars } from './Stars';

interface Props {
  tourID: string;
}

export const StarRating: React.FC<Props> = ({ tourID }) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const rate = useCallback(
    (rating: number) => {
      setRating(rating);
      setSubmitted(true);
      captureEvent({
        name: EventNames.RATED_TOUR,
        data: [
          {
            key: DataKeys.RATING,
            value: (rating / 5).toString(),
          },
          {
            key: DataKeys.TOUR_ID,
            value: tourID,
          },
        ],
      });
    },
    [tourID]
  );

  return (
    <StarRatingContainer>
      <h3>{t('How was that?')}</h3>

      <Stars rating={rating} setRating={rate} disabled={submitted} />
    </StarRatingContainer>
  );
};

const StarRatingContainer = styled.div`
  grid-column: 1/3;
  text-align: center;
  display: grid;
  grid-gap: 8px;

  h3 {
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_EXTRA_SMALL}px) {
    grid-column: 1/2;
  }
`;
