import React, { useCallback, useMemo } from 'react';
import UserContext, {
  CURRENT_USER_QUERY,
  UPDATE_VISITED_PLAYLIST_DISCOVERIES,
} from '../context/userContext';
import {
  EXPLORE_WEB_CurrentUserForUserContext as Data,
  EXPLORE_WEB_CurrentUserForUserContext_currentUser_purchases as Purchase,
} from '../context/__generated__/EXPLORE_WEB_CurrentUserForUserContext';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../store/app/selectors';
import { ProductSKU } from '../graphql/globalTypes';
import useOnAuthChanged from '../hooks/useOnAuthChanged';

type Props = {
  children: React.ReactNode;
};

const UserProvider = ({ children }: Props) => {
  const isAuthed = useSelector(selectIsAuthenticated);

  const { data, refetch } = useQuery<Data, any>(CURRENT_USER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const [_updateVisitedPlaylistDiscoveries] = useMutation(
    UPDATE_VISITED_PLAYLIST_DISCOVERIES,
    {
      refetchQueries: [{ query: CURRENT_USER_QUERY }],
    }
  );

  useOnAuthChanged(() => refetch());

  const user = (isAuthed && data?.currentUser) || null;

  const { tourPurchasesMap, tourBundlePurchasesMap } = useMemo(() => {
    const purchases = user?.purchases ?? [];

    const tourPurchasesMap = purchases
      .filter((p) => p.productSKU === ProductSKU.PLAYLIST)
      .reduce((acc, p) => {
        acc[p.productID] = p;
        return acc;
      }, {} as Record<string, Purchase>);

    const tourBundlePurchasesMap = purchases
      .filter((p) => p.productSKU === ProductSKU.PLAYLIST_BUNDLE)
      .reduce((acc, p) => {
        acc[p.productID] = p;
        return acc;
      }, {} as Record<string, Purchase>);

    return { tourPurchasesMap, tourBundlePurchasesMap };
  }, [user]);

  const updateVisitedPlaylistDiscoveries = useCallback(
    async (playlistID: string, discoveryIDs: Array<string>) => {
      const visitedPlaylistDiscoveries = discoveryIDs.map((discoveryID) => ({
        playlistID,
        discoveryID,
      }));

      try {
        await _updateVisitedPlaylistDiscoveries({
          variables: {
            input: {
              visitedPlaylistDiscoveries,
            },
          },
        });
      } catch (error) {}
    },
    [_updateVisitedPlaylistDiscoveries]
  );

  return (
    <UserContext.Provider
      value={{
        user,
        refetch,
        tourPurchasesMap,
        tourBundlePurchasesMap,
        updateVisitedPlaylistDiscoveries,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
