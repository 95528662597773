import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { toast } from 'react-toastify';
import styled from '../../../style/styled';
import * as Sentry from '@sentry/react';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { useDispatch } from 'react-redux';
import {
  captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import { loginSuccess } from '../../../store/app/actions';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { env } from '../../../App/config/env';
import {
  EXPLORE_WEB_AuthWithGoogle as Data,
  EXPLORE_WEB_AuthWithGoogleVariables as Variables,
} from './__generated__/EXPLORE_WEB_AuthWithGoogle';
import { Button } from '../../../components/Button';
// import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { detectIncognito } from 'detect-incognito';
import { isChrome } from 'react-device-detect';

const AUTH_WITH_GOOGLE_MUTATION = gql`
  mutation EXPLORE_WEB_AuthWithGoogle($input: AuthWithGoogleInput!) {
    result: authWithGoogle(input: $input) {
      jwt
      newUser
      error {
        id
        message
        type
      }
    }
  }
`;

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  redirectedHref?: string | null;
  setBusy: (busy: boolean) => void;
  busy: boolean;
}

export const ContinueWithGoogle: React.FC<Props> = ({
  onFailure,
  onSuccess,
  setBusy,
  busy,
  redirectedHref,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [oauthRequested, setOauthRequested] = useState<boolean>(false);
  const [authWithGoogle, { loading }] = useMutation<Data, Variables>(
    AUTH_WITH_GOOGLE_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        onFailure
          ? onFailure(error)
          : toast.error(error?.message || UNKNOWN_ERROR);
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry(
            'ContinueWithGoogle.tsx result is null or undefined',
            { variables: getVariables() }
          );

          return;
        }

        const { jwt, error, newUser } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          Sentry.configureScope(function (scope) {
            scope.setUser({ id: userID });
          });

          dispatch(loginSuccess({ userId: userID, jwt }));

          if (newUser) {
            captureEvent({
              name: EventNames.USER_REGISTERED,
              data: [{ key: DataKeys.SIGNUP_METHOD, value: 'Google' }],
            });
          } else {
            captureEvent({
              name: EventNames.LOGGED_IN,
              data: [{ key: DataKeys.LOGIN_METHOD, value: 'Google' }],
            });
          }

          const toastSuccessMessage = t(
            'You have successfully logged in with Google'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          onFailure
            ? onFailure(new Error(errorMessage))
            : toast.error(errorMessage);
        }
      },
    }
  );

  useEffect(() => {
    setBusy(loading || oauthRequested);
  }, [loading, oauthRequested, setBusy]);

  const handleClick = async () => {
    captureButtonPress({
      buttonName: 'ContinueWithGoogle',
      page: window.location.pathname,
    });

    setOauthRequested(true);
  };

  const onPermissionGranted = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    if ('tokenId' in response) {
      const variables: Variables = {
        input: {
          accessToken: response.tokenId,
          clientID: env.GOOGLE_CLIENT_ID,
          referralAppID: env.APP_ID,
        },
      };

      const affiliateCode = getStoredAffiliateCode();

      if (affiliateCode) {
        variables.input.affiliateCode = affiliateCode;
      }

      setVariables(variables);

      authWithGoogle({ variables });
    } else {
      captureInSentry(
        'ContinueWithGoogle.tsx Did not receive an access token from Google auth',
        { response: JSON.stringify(response) }
      );
    }

    setOauthRequested(false);
  };

  const onPermissionError = (response: any) => {
    if (response.error && response.details) {
      captureInSentry(response.error, { details: response.details });
    } else if (response.error) {
      captureInSentry(response.error);
    } else {
      captureInSentry(response.error, { edetails: JSON.stringify(response) });
    }

    toast.error(
      t(
        'Authentication with Google failed. Please try again later, or try another way to log in.'
      )
    );

    setOauthRequested(false);
  };

  const handlePermissionError = (response: any) => {
    detectIncognito().then((result) => {
      if (result.isPrivate && isChrome) {
        setOauthRequested(false);
      } else {
        onPermissionError(response);
      }
    });
  };

  // based on local busy state
  const caption = t(
    loading || oauthRequested ? 'Please Wait...' : 'Continue with Google'
  );

  return (
    <GoogleLogin
      // based on global busy state
      disabled={busy}
      autoLoad={false}
      clientId={env.GOOGLE_CLIENT_ID}
      onSuccess={onPermissionGranted}
      onFailure={handlePermissionError}
      cookiePolicy="single_host_origin"
      onRequest={handleClick}
      render={({ onClick, disabled }) => (
        <GoogleButton onClick={onClick} disabled={disabled} bgColor="#db3236">
          {caption}
        </GoogleButton>
      )}
    />
  );
};

const GoogleButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
  border: none;
`;
