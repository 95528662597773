import React from 'react';
import styled from '../../../../style/styled';
import ReactPlayer from 'react-player/lazy';
import { Section } from './styledComponents/Section';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../../features/analytics';

interface Props {
  tourID: string;
  coverURI?: string | null;
  playlistFileURI?: string | null;
}

// const defaultOptions = {
//   autoplay: false,
//   controls: true,
//   muted: false,
//   loop: false,
//   fluid: true,
//   notSupportedMessage: '',
// };

export const TourInfoIntroVideoSection = React.memo(
  ({ tourID, coverURI, playlistFileURI }: Props) => {
    // const [playing, setPlaying] = useState<boolean>(false);

    if (!playlistFileURI) {
      return null;
    }

    return (
      <Section>
        <Container>
          <VideoContainer poster={coverURI || ''}>
            <ReactPlayer
              controls
              light
              playing
              playsinline
              onPlay={() => {
                captureEvent({
                  name: EventNames.ITINERARY_VIDEO_PLAYED,
                  data: [
                    {
                      key: DataKeys.TOUR_ID,
                      value: tourID,
                    },
                  ],
                });
              }}
              url={playlistFileURI}
              config={{
                file: {
                  attributes: {
                    poster: coverURI || '',
                  },
                },
              }}
              height="100%"
              width="100%"
              // onError={(e) => {
              //   setPlaying(false);
              // }}
            />
          </VideoContainer>
        </Container>
      </Section>
    );
  }
);

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
`;

const VideoContainer = styled.div<{ poster: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  background-image: url(${({ poster }) => poster}), url(texture.png);
  background-size: cover, auto;
  background-position: center, initial;
  background-repeat: no-repeat, repeat;
`;
