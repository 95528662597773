export function getFetchPolicy(
  cachedAt: number,
  cacheForMS: number,
  queryID: string
) {
  if (cachedAt === 0) {
    return 'cache-and-network';
  }

  const expiresAt = cachedAt + cacheForMS;
  const distanceToExpiry = expiresAt - Date.now();

  const isExpired = distanceToExpiry < 0;

  if (isExpired) {
    return 'cache-and-network';
  }

  return 'cache-first';
}
