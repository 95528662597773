import * as Sentry from '@sentry/react';
// This is the way we can import a webworker without messing with the stock webpack setup.
//  @ts-ignore
import worker from 'workerize-loader!../../../worker'; // eslint-disable-line import/no-webpack-loader-syntax
import { getCDNURI } from '../../../utils/getCDNURI';
import { findAllAssetsInSettings } from '../../whitelabeling/branding/utils/findAllAssetsInSettings';
import { COMMON_APP_ASSETS } from '../appAssets';
import { findAllAssets } from '../utils/findAllAssets';
import { useDeepCompareEffect } from 'react-use';
import { useApp } from '../../../hooks/useApp';
import { useSelector } from 'react-redux';
import { selectWhitelabelAppSettings } from '../../../store/app/selectors';

const instance = worker();

type UpdateAppAssetCacheResult = {
  cached: number;
  deleted: number;
  errors: Array<string>;
};

// Download the app assets
export const DownloadAppAssets = () => {
  const { app } = useApp();
  const whitelabelAppSettings = useSelector(selectWhitelabelAppSettings);

  useDeepCompareEffect(() => {
    const urls = [
      // the assets coming from graphql types
      ...findAllAssets(app).map(({ uri }) => uri),

      // the assets included in the settings.json
      ...findAllAssetsInSettings(whitelabelAppSettings),

      // the common app assets
      ...COMMON_APP_ASSETS.map(getCDNURI),
    ];

    const uniqueUrls = [...new Set(urls)];

    if (typeof instance.updateAppAssetCache === 'function') {
      // call the cacheURLs webworker function
      instance
        .updateAppAssetCache(uniqueUrls)
        .then((result: UpdateAppAssetCacheResult) => {
          console.info(
            `${result.cached} assets cached, ${result.deleted} assets deleted`
          );

          if (result.errors.length > 0) {
            Sentry.captureEvent({
              message: 'Error caching app assets',
              extra: {
                errors: result.errors,
              },
            });
          }
        })
        .catch((error: Error) => {
          console.error(error);
        });
    }
  }, [app, whitelabelAppSettings]);

  return null;
};
