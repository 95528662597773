import { AssetFragment } from '../../graphql/fragments/__generated__/AssetFragment';

export enum JobStatus {
  // eslint-disable-next-line no-unused-vars
  DOWNLOADING,
  // eslint-disable-next-line no-unused-vars
  DOWNLOAD_SUCCESS,
  // eslint-disable-next-line no-unused-vars
  DOWNLOAD_ERROR,
  // eslint-disable-next-line no-unused-vars
  CLEANING_UP,
  // eslint-disable-next-line no-unused-vars
  CLEANUP_SUCCESS,
  // eslint-disable-next-line no-unused-vars
  CLEANUP_ERROR,
  // eslint-disable-next-line no-unused-vars
  NONE,
}

export type Playlist = {
  id: string;
  totalSize: number;
  cachedSize: number;
  name: string;
  jobStatus: JobStatus;
  automatic: boolean;
};

export enum QueueStatus {
  // eslint-disable-next-line no-unused-vars
  IDLE,
  // eslint-disable-next-line no-unused-vars
  BUSY,
}

export type Asset = {
  id: string;
  uri: string;
  contentLength: number;
  playlistIDs: string[];
};

export type OfflineState = {
  files: {
    [assetID: string]: {
      uri: string;
      contentLength: number;
      playlistIDs: string[];
    };
  };
  playlists: Array<Playlist>;
  // #DEPRECATED
  // TODO: remove using redux persist features
  assets: Array<AssetFragment>;

  // the following states aren't persisted. they are removed from the outbound transform (transforms.ts)
  queueStatus: QueueStatus;
  cachedQueries: Record<string, number>; // Record<queryID: timestampInMs>
};

export const initialState: OfflineState = {
  assets: [],
  playlists: [],
  queueStatus: QueueStatus.IDLE,
  files: {},
  cachedQueries: {},
};
