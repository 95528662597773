// elm-pep "polyfill" is required to support touch actions on old iOS Safari version
import 'elm-pep';
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PluggableMap, { MapOptions } from 'ol/PluggableMap';
import OLView, { ViewOptions } from 'ol/View';
import OLMap from 'ol/Map';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { MarkerType } from '../types';
import MapContext from './MapContext';
import { Extent } from 'ol/extent';
import { isMacOs, isMobile } from 'react-device-detect';

const POINTER_COUNT_FOR_DRAG_PANNING = isMobile ? 2 : 1;

interface Props {
  viewOptions: ViewOptions;
  fitExtent: Extent | null;
  onSingleClick?: (event: MapBrowserEvent<UIEvent>) => void;
  children: any;
}

const Map: React.FC<Props> = ({
  children,
  viewOptions,
  fitExtent,
  onSingleClick,
}) => {
  const [hovering, setHovering] = useState<boolean>(false);
  const [scrolling, setScrolling] = useState<boolean>(false);

  const mapRef = useRef<any>();
  const [map, setMap] = useState<any>(null);

  // on mount
  useEffect(
    () => {
      function onChangeMousePointer(
        this: PluggableMap,
        evt: MapBrowserEvent<UIEvent>
      ) {
        const hit = this.forEachFeatureAtPixel(evt.pixel, function (feature) {
          const type = feature.get('type');
          const requiredType =
            type === MarkerType.VIEWPOINT ||
            type === MarkerType.TOUR ||
            type === MarkerType.PLACE;

          return requiredType;
        });

        this.getTargetElement().style.cursor = hit ? 'pointer' : 'grab';
      }

      const options: MapOptions = {
        view: new OLView(viewOptions),
        layers: [],
        controls: [],
        overlays: [],
        // https://openlayers.org/en/latest/examples/two-finger-pan-scroll.html
        // by default, whenever the mouse pointer is over the map, it would zoom
        // when the user would want to scroll the page.
        // so we disable the defaults, and then override them with conditions.
        // 1. drag only if you have clicked first
        // 2. scroll only if you have pressed the control or command key
        interactions: defaults({
          dragPan: false,
          mouseWheelZoom: false,
        }).extend([
          new DragPan({
            condition: function (event) {
              return (
                // example sets this at 2, but 1 works best and is same as Google Maps
                this.getPointerCount() === POINTER_COUNT_FOR_DRAG_PANNING ||
                platformModifierKeyOnly(event)
              );
            },
          }),
          new MouseWheelZoom({
            condition: platformModifierKeyOnly,
          }),
        ]),
      };

      const mapObject = new OLMap(options);

      mapObject.setTarget(mapRef.current);

      if (onSingleClick) {
        mapObject.on('singleclick', onSingleClick);
      }

      // this is how we change the mouse cursor when hovering over a marker
      mapObject.on('pointermove', onChangeMousePointer);

      if (fitExtent) {
        mapObject.getView().fit(fitExtent, {
          padding: [80, 80, 80, 80],
        });
      }

      setMap(mapObject);

      return () => {
        if (onSingleClick) {
          mapObject.un('singleclick', onSingleClick);
        }
        mapObject.un('pointermove', onChangeMousePointer);
        mapObject.setTarget(undefined);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    let timer = -1;

    function handleScroll() {
      if (!hovering) {
        return;
      }

      if (timer >= 0) {
        window.clearTimeout(timer);
      }

      timer = window.setTimeout(() => {
        setScrolling(false);
      }, 2000);

      setScrolling(true);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);

      if (timer >= 0) {
        window.clearTimeout(timer);
      }

      setScrolling(false);
    };
  }, [hovering]);

  return (
    <MapContainer
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
    >
      <MapContext.Provider value={{ map }}>
        <MapEl ref={mapRef}>{children}</MapEl>
      </MapContext.Provider>

      <Tip visible={hovering && scrolling}>
        <p>Use {isMacOs ? '\u2318' : 'ctrl'} + scroll to zoom the map</p>
      </Tip>
    </MapContainer>
  );
};

export default Map;

// this and MapEl styles are required for responsive sizing
const MapContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #f2efe9;
`;

const MapEl = styled.div`
  min-height: 0;
  width: 100%;
  flex: 1;
  position: relative;
`;

const Tip = styled.div.attrs<{
  visible: boolean;
}>(({ visible }) => ({
  style: {
    opacity: visible ? 1 : 0,
  },
}))<{ visible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
  background: #000000aa;
  color: #fff;
  pointer-events: none;
  opacity: 0;

  p {
    font-family: Roboto, Arial, sans-serif;
    font-size: 22px;
  }
`;
