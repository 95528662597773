import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../Routes/routes';
import { captureButtonPress } from '../../../../features/analytics';
import { Button } from '../../../Button';
import { unlock } from '../../../../features/audio/howlerSetup';
import { useTranslation } from 'react-i18next';
import { selectWhitelabelAppSettings } from '../../../../store/app/selectors';
import { useSelector } from 'react-redux';

interface Props {
  tourID: string;
}

export const TourWidgetContinueTourButton: React.FC<Props> = ({ tourID }) => {
  const { t } = useTranslation();
  const page = window.location.pathname;
  const history = useHistory();
  const { text } = useSelector(selectWhitelabelAppSettings);

  const handleGoToGateButtonClick = () => {
    unlock();

    captureButtonPress({
      buttonName: 'ContinueTour',
      tourID,
      page,
    });

    history.push(ROUTES.tour, { playlistID: tourID });
  };

  return (
    <Button width="100%" onClick={handleGoToGateButtonClick} highlight>
      {t(text.continueTourCaption)}
    </Button>
  );
};
