import { SagaIterator } from 'redux-saga';
import { all, spawn, call } from 'redux-saga/effects';
import { UNKNOWN_ERROR } from '../../consts';
import { sagas as viewerSagas } from '../viewer/sagas';
import { sagas as appSagas } from '../app/sagas';
import { sagas as offlineSagas } from '../offline/sagas';
import { sagas as locationSagas } from '../location/sagas';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';

export function* rootSaga(): SagaIterator {
  const sagas = [
    ...viewerSagas,
    ...appSagas,
    ...offlineSagas,
    ...locationSagas,
  ];

  // Boilerplate that allows for throws in sagas to be restarted
  // as per https://redux-saga.js.org/docs/advanced/RootSaga.html
  yield all(
    sagas.map((saga) =>
      spawn(function* spawned() {
        while (true) {
          try {
            yield call(saga);

            break;
          } catch (error) {
            captureInSentry(
              `rootSaga.ts error: ${error?.message || UNKNOWN_ERROR}`
            );
          }
        }
      })
    )
  );
}
