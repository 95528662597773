import {
  ALPHA_STOCK_URL,
  BETA_STOCK_URL,
  RTM_STOCK_URL,
  DEV_URL,
} from '../consts';
import urljoin from 'url-join';
import { env } from '../App/config/env';
import { ROUTES } from '../Routes/routes';
import { whiteLabelAppTitleForTradeShow } from '../App/config/enums';
import { RootState } from '../store/redux/types';
import { getStore } from '../store';

export function getTourShareURL(internalReference: string): string {
  if (env.IS_STOCK) {
    let hostname = RTM_STOCK_URL;
    const pageRoute = ROUTES.itinerary.replace(
      ':internalReference',
      internalReference
    );

    if (env.IS_ALPHA) {
      hostname = ALPHA_STOCK_URL;
    } else if (env.IS_BETA) {
      hostname = BETA_STOCK_URL;
    } else if (env.isDevelopment) {
      hostname = DEV_URL;
    }

    return urljoin(hostname, pageRoute);
  }

  // TODO: support whitelabel alpha site as well?
  const state: RootState = getStore()?.getState();
  const title = state?.app.whitelabelAppSettings.banner.title;

  const hostname =
    whiteLabelAppTitleForTradeShow.indexOf(title) >= 0
      ? 'https://discover.lithodomos.com'
      : 'https://guide.lithodomos.com';
  // const hostname = 'https://guide.lithodomos.com';

  // if we are on the itinerary page, use the internalReference
  if (window.location.href.includes('itinerary')) {
    return urljoin(
      hostname,
      ROUTES.itinerary.replace(':internalReference', internalReference)
    );
  }

  // use the index route with the appID appended
  return urljoin(hostname, env.APP_ID);
}
