import React, { useCallback } from 'react';
import styled from '../../../style/styled';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import { MarkerProps, MarkerType } from '../types';
import { GenericMarkerData } from '../types';
import { COLORS } from '../../../style/colors';

interface Props extends MarkerProps {
  data: GenericMarkerData;
  selected: boolean;
  setSelected: (placeID: string | null) => void;
  withNumbers?: boolean;
}

export const GenericMarker: React.FC<Props> = ({
  data,
  selected,
  setSelected,
  withNumbers = true,
}) => {
  const { id, type } = data;

  const handleClick = useCallback(() => {
    if (!id) {
      return;
    }

    if (selected) {
      setSelected(null);
    } else {
      setSelected(id);
    }
  }, [setSelected, id, selected]);

  let iconSrc = '/icons/Tour_Icon_02.png';

  if (type === MarkerType.VIEWPOINT) {
    iconSrc = '/icons/Viewpoint_Tour_Icon_02.png';
  }

  return (
    <Container onClick={handleClick}>
      <Icon src={iconSrc} selected={selected} />

      {/*{withNumbers && typeof idx === 'number' && (*/}
      {/*  <MarkerNumberContainer>*/}
      {/*    <MarkerNumber>{idx + 1}</MarkerNumber>*/}
      {/*  </MarkerNumberContainer>*/}
      {/*)}*/}
    </Container>
  );
};

export const Title = styled.p`
  font-weight: bold;
  font-size: 12px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    font-size: 10px;
  }
`;

export const MetadataContainer = styled.div`
  padding: 4px;
`;

export const BookmarkIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px;

  > svg {
    fill: #fff;
    stroke: #000;
  }
`;

export const DetailsPanel = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 128px;
  flex-direction: column;
  background: #fff;
  z-index: 3;
  box-shadow: 0 0 3px 1px #00000082;
`;

export const DetailsPanelContainer = styled.div`
  position: relative;
`;

export const Container = styled.div`
  position: relative;
  cursor: pointer;
`;

export const Icon = styled.img<{ selected: boolean }>`
  position: absolute;
  width: 32px;
  height: 32px;
  left: -16px;
  top: -32px;
  filter: brightness(${({ selected }) => (selected ? 50 : 100)}%);
`;

export const MarkerNumberContainer = styled.div`
  z-index: 2;
  position: absolute;
  width: 14px;
  height: 14px;
  left: -7px;
  top: -27px;
  background: ${(props) => props.theme.palette.common.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MarkerNumber = styled.p`
  color: ${(props) => props.theme.palette.common.black};
  font-weight: bold;
  font-size: 10px;
`;

export const CoverImage = styled.div<{ img: string }>`
  background-color: ${COLORS.offwhite};
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 60px;
`;

export const ClassNameContainer = styled.div`
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
  background: #00000077;
  color: ${(props) => props.theme.palette.common.white};

  p {
    margin: 0;
  }
`;

export const MetadataText = styled.p`
  font-size: 10px;
  margin-bottom: 8px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    font-size: 8px;
  }
`;
