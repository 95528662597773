import styled from '../../../style/styled';
import { MAX_WIDTH } from '../../../components/contentSections/toursSection/tourInfo/TourInfo';
import { ShopToursGrid } from '../../../components/ShopToursGrid';

const TrialTourEndPopup = () => {
  return (
    <Container>
      <ShopToursGrid />
    </Container>
  );
};

export default TrialTourEndPopup;

// width should be same as max width
const Container = styled.div`
  padding: 20px;
  max-width: ${MAX_WIDTH}px;
`;
