import React from 'react';
import Icon from 'mdi-react/AccountIcon';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../Routes/routes';
import styled, { useTheme } from '../../style/styled';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText as _AppHeaderButtonText,
} from './styledComponents';
import { useTranslation } from 'react-i18next';
import { env } from '../../App/config/env';
import { useSelector } from 'react-redux';
import { selectRTL } from '../../store/app/selectors';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const MyTripsButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const rtl = useSelector(selectRTL);
  const { t } = useTranslation();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const title = env.IS_STOCK ? 'My tours' : 'My guides';

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'MyTrips',
    });

    history.push(ROUTES.myTrips);
  };

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleClick}>
      <div>
        <Icon color={color} size={size * 1.4} />
      </div>
      <AppHeaderButtonText
        size={size}
        color={color}
        bold={window.location.pathname === ROUTES.myTrips}
        rtl={rtl}
      >
        {t(title)}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};

const AppHeaderButtonText = styled(_AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
