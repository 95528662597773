import { AppWithTheme } from './AppWithTheme';
import { useApp } from '../hooks/useApp';

export const AppWithRemoteConfig = () => {
  const { config } = useApp();

  if (!config) {
    // loading spinner similar to the one in the index.html (bouncing logo)
    return (
      <div className="full-page-loader-container">
        <div>
          <img src="/apple-touch-icon.png" alt="" />
        </div>
      </div>
    );
  }

  return (
    <AppWithTheme stylesheetURL={config.stylesheet} theme={config.theme} />
  );
};
