import React from 'react';
import styled from '../../../../style/styled';
import { getPriceFormatted } from '../../../../utils/getPriceFormatted';
import { ContentSectionWidgetPrice } from '../../styledComponents';
import { CurrencyCode } from '../../../../graphql/globalTypes';
import { getTotalAmount } from '../../../../utils/getTotalAmount';

interface Props {
  currencyCode: CurrencyCode;
  price: number;
  priceInCents: boolean;
  discountRate: number;
}

export const TourWidgetPrice: React.FC<Props> = ({
  currencyCode,
  price,
  priceInCents,
  discountRate,
}) => {
  const originalPrice = getPriceFormatted({
    currencyCode,
    price,
    priceInCents,
  });

  const discountedPrice =
    discountRate > 0
      ? getPriceFormatted({
          currencyCode,
          priceInCents: false,
          price: getTotalAmount([
            {
              currencyCode,
              unitPrice: priceInCents ? price : Math.floor(price * 100),
              count: 1,
              discountRate,
            },
          ]),
        })
      : originalPrice;

  const priceIsDiscounted = Boolean(discountRate > 0 && price > 0);

  if (priceIsDiscounted) {
    return (
      <Container>
        <ContentSectionWidgetPrice strikeOut marginRight={8}>
          {originalPrice}
        </ContentSectionWidgetPrice>
        <ContentSectionWidgetPrice>{discountedPrice}</ContentSectionWidgetPrice>
      </Container>
    );
  }

  return (
    <Container>
      <ContentSectionWidgetPrice>{originalPrice}</ContentSectionWidgetPrice>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 4;
  padding: 4px 8px;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background: ${({ theme }) => theme.palette.highlight.main};
`;
