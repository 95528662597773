import { PluggableMap } from 'ol';
import React from 'react';

interface Props {
  map: PluggableMap | null;
}

const MapContext = React.createContext<Props>({ map: null });

export default MapContext;
