import PQueue from 'p-queue';
import { toast } from 'react-toastify';
import {
  removeAsset,
  updatePlaylistStats,
  setQueueStatus,
} from '../../store/offline/actions';
import { TOUR_ASSETS_CACHE } from './cacheNames';
import { QueueStatus, JobStatus, Asset } from '../../store/offline/types';
import { env } from '../../App/config/env';
import { nonNullable } from '../../utils/nonNullable';
import { UNKNOWN_ERROR } from '../../consts';
import { captureInSentry } from '../../App/config/reporting/captureInSentry';

type Dispatcher = (payload: any) => any;

type Options = {
  getDispatch: (() => Dispatcher) | (() => any) | null;
};

export class CacheManagerForTourAssets {
  private _getDispatch: (() => Dispatcher) | (() => any) | null;
  private downloadQueue = new PQueue({ concurrency: 4 });

  constructor({ getDispatch }: Options) {
    this._getDispatch = getDispatch;

    // this.downloadQueue.on('idle', () => this.setQueueIdle());
    // this.downloadQueue.on('active', () => this.setQueueBusy());
  }

  get dispatch(): Dispatcher {
    let dispatcher;

    if (this._getDispatch) {
      dispatcher = this._getDispatch();
    } else {
      dispatcher = () => {};
    }

    return dispatcher;
  }

  updatePlaylistCacheStats = async (
    playlistID: string,
    playlistName: string,
    assets: Asset[],
    jobStatus: JobStatus,
    automatic: boolean
  ) => {
    if (!env.ENABLE_SERVICE_WORKER) {
      return;
    }

    const queueIsBusy = this.downloadQueue.size > 0;

    try {
      const cache = await caches.open(TOUR_ASSETS_CACHE);

      if (!cache) {
        captureInSentry(
          `CacheManagerForTourAssets.ts updatePlaylistCacheStats() Cache ${TOUR_ASSETS_CACHE} not found`
        );

        return;
      }

      let totalSize = 0;
      let cachedSize = 0;

      for (let i = 0; i < assets.length; i++) {
        const { contentLength, uri } = assets[i];

        totalSize += contentLength;

        try {
          const cached = await cache.match(uri);

          if (cached) {
            cachedSize += contentLength;
          }
        } catch (error: any) {
          // do nothing
        }
      }

      if (jobStatus === JobStatus.CLEANUP_SUCCESS) {
        totalSize = -1;
      }

      // if it has been downloaded before, but either the files have changed, or has been removed unexpectedly
      // as result of removing the cache of another playlist, trigger an auto download.

      let derivedStatus = jobStatus;
      // derive job status based on the totalSize, cacheSize and current jobStatus
      if (
        jobStatus === JobStatus.DOWNLOAD_SUCCESS &&
        totalSize !== cachedSize
      ) {
        derivedStatus = JobStatus.DOWNLOAD_ERROR;
      } else if (
        jobStatus === JobStatus.DOWNLOADING &&
        totalSize === cachedSize
      ) {
        derivedStatus = JobStatus.DOWNLOAD_SUCCESS;
      } else if (jobStatus === JobStatus.DOWNLOADING && !queueIsBusy) {
        derivedStatus = JobStatus.DOWNLOAD_ERROR;
      } else if (
        (jobStatus === JobStatus.CLEANING_UP ||
          jobStatus === JobStatus.CLEANUP_SUCCESS) &&
        cachedSize === 0
      ) {
        derivedStatus = JobStatus.NONE;
      } else if (jobStatus === JobStatus.CLEANING_UP && !queueIsBusy) {
        derivedStatus = JobStatus.CLEANUP_ERROR;
      } else if (
        (jobStatus === JobStatus.DOWNLOAD_ERROR ||
          jobStatus === JobStatus.CLEANUP_ERROR ||
          jobStatus === JobStatus.NONE) &&
        totalSize === cachedSize
      ) {
        derivedStatus = JobStatus.DOWNLOAD_SUCCESS;
      }

      const updatedStats = {
        playlistID,
        playlistName,
        totalSize,
        cachedSize,
        status: derivedStatus,
        automatic,
      };

      this.dispatch(updatePlaylistStats(updatedStats));
    } catch (error: any) {
      captureInSentry(
        `CacheManagerForTourAssets.ts updatePlaylistCacheStats error: ${
          error.toString() || UNKNOWN_ERROR
        }`
      );
    }
  };

  cacheAssets = async (
    playlistID: string,
    playlistName: string,
    assets: Array<Asset>,
    automatic: boolean
  ) => {
    if (!env.ENABLE_SERVICE_WORKER) {
      return;
    }

    try {
      // IMPORTANT!
      // this needs to run first
      // update the stats first which also adds a playlist to the state if it's a new playlist
      // await this.updatePlaylistCacheStats(playlist, JobStatus.DOWNLOADING);

      const cache = await caches.open(TOUR_ASSETS_CACHE);

      if (!cache) {
        captureInSentry(
          `CacheManagerForTourAssets.ts cacheAssets() Cache ${TOUR_ASSETS_CACHE} not found`
        );

        if (!automatic) {
          toast.error(`${playlistName} download is not supported.`, {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
            position: 'bottom-right',
            toastId: 'download_not_supported',
          });
        }

        return;
      }

      let errored = false;

      try {
        await this.downloadQueue.addAll(
          assets.map((asset) => {
            return async () => {
              const { uri } = asset;

              try {
                // TODO: [LVR-2248] As a developer, I should integrate p-retry library to retry downloads so that if the downloads fail for network errors, it will retry before failing immediately.
                await cache.add(uri);

                // this action is required to update download progress
                // this.dispatch(
                //   downloadComplete({
                //     playlistID,
                //     playlistName,
                //     asset,
                //     automatic,
                //   })
                // );
              } catch (error: any) {
                captureInSentry(
                  `CacheManagerForTourAssets.ts cacheAssets individual asset error: ${
                    error.toString() || 'Unknown error'
                  }`
                );

                errored = true;
                // don't do anything. will be handled in updatePlaylistCacheStats
              }
            };
          })
        );
      } catch (error: any) {
        // don't do anything.
      }

      // final checks will be performed here, and necessary actions will be dispatched to
      // alert the user if required.
      await this.updatePlaylistCacheStats(
        playlistID,
        playlistName,
        assets,
        errored ? JobStatus.DOWNLOAD_ERROR : JobStatus.DOWNLOAD_SUCCESS,
        automatic
      );
    } catch (error: any) {
      captureInSentry(
        `CacheManagerForTourAssets.ts cacheAssets error: ${
          error.toString() || UNKNOWN_ERROR
        }`
      );
    }
  };

  removePlaylistAssets = async (
    playlistID: string,
    playlistName: string,
    assets: Asset[],
    automatic: boolean
  ) => {
    if (!env.ENABLE_SERVICE_WORKER) {
      return;
    }

    try {
      const cache = await caches.open(TOUR_ASSETS_CACHE);

      if (!cache) {
        captureInSentry(
          `CacheManagerForTourAssets.ts removePlaylistAssets() Cache ${TOUR_ASSETS_CACHE} not found`
        );

        return;
      }

      let errored = false;

      try {
        await Promise.all(
          assets.map(async (asset) => {
            const { uri } = asset;

            try {
              await this.downloadQueue.add(async () => {
                try {
                  const cached = await cache.match(uri);

                  if (cached) {
                    await cache.delete(uri);

                    this.dispatch(removeAsset({ asset }));

                    // since this is a quick operation, we don't update the size per each file deletion
                  }
                } catch (error: any) {
                  errored = true;
                }
              });
            } catch (error: any) {
              captureInSentry(
                `CacheManagerForTourAssets.ts removePlaylistAssets Promise.all async func error: ${
                  error.toString() || UNKNOWN_ERROR
                }`
              );
            }
          })
        );
      } catch (error: any) {
        // don't do anything. will be handled in updatePlaylistCacheStats
      }

      await this.updatePlaylistCacheStats(
        playlistID,
        playlistName,
        assets,
        errored ? JobStatus.CLEANUP_ERROR : JobStatus.CLEANUP_SUCCESS,
        automatic
      );
    } catch (error: any) {
      captureInSentry(
        `CacheManagerForTourAssets.ts removePlaylistAssets error: ${
          error.toString() || 'Unknown error'
        }`
      );
    }
  };

  removeAssets = async (assets: Asset[]): Promise<void> => {
    if (!env.ENABLE_SERVICE_WORKER) {
      return;
    }

    try {
      const cache = await caches.open(TOUR_ASSETS_CACHE);

      if (!cache) {
        captureInSentry(
          `CacheManagerForTourAssets.ts removeAssets() Cache ${TOUR_ASSETS_CACHE} not found`
        );

        return;
      }

      await Promise.all(
        assets.map(async (asset) => {
          const { uri } = asset;

          try {
            await this.downloadQueue.add(async () => {
              try {
                const cached = await cache.match(uri);

                if (cached) {
                  await cache.delete(uri);
                }
              } catch (error: any) {
                captureInSentry(error);
              }
            });
          } catch (error: any) {
            captureInSentry(error);
          }
        })
      );
    } catch (error: any) {
      captureInSentry(
        `CacheManagerForTourAssets.ts removeAssets error: ${
          error.toString() || UNKNOWN_ERROR
        }`
      );
    }
  };

  getMissingAssets = async (assets: Asset[]): Promise<Array<Asset>> => {
    if (!env.ENABLE_SERVICE_WORKER) {
      return [];
    }

    try {
      const cache = await caches.open(TOUR_ASSETS_CACHE);

      if (!cache) {
        captureInSentry(
          `CacheManagerForTourAssets.ts getMissingAssets() Cache ${TOUR_ASSETS_CACHE} not found`
        );

        return [];
      }

      const missingAssets = await Promise.all(
        assets.map(async (asset) => {
          try {
            const cached = await cache.match(asset.uri);

            return cached ? null : asset;
          } catch (error: any) {
            captureInSentry(error);
          }

          return null;
        })
      );

      return missingAssets.filter(nonNullable);
    } catch (error: any) {
      captureInSentry(
        `CacheManagerForTourAssets.ts getMissingAssets error: ${
          error.toString() || UNKNOWN_ERROR
        }`
      );
    }

    return [];
  };

  setQueueBusy = () => {
    this.dispatch(setQueueStatus({ status: QueueStatus.BUSY }));
  };

  setQueueIdle = () => {
    this.dispatch(setQueueStatus({ status: QueueStatus.IDLE }));
  };
}
