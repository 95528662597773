import { createSelector } from 'reselect';
import { env } from '../../App/config/env';
import { getRTL } from '../../features/i18n/getRTL';
import { IEnv } from '../../features/whitelabeling/branding/types';
import {
  CurrencyCode,
  LanguageCode,
  ProductSKU,
  PurchaseMode,
} from '../../graphql/globalTypes';
import { isGuestUser } from '../../utils/isGuestUser';
import { RootState } from '../redux/types';
import {
  CartState,
  PinnedTour,
  PromoCodeState,
  initialState,
  AppSettingsState,
  CurrencySetMode,
} from './types';
import { whiteLabelAppTitleForTradeShow } from '../../App/config/enums';

type ProductMapInCart = {
  [productID: string]: number;
};

export function selectIsAuthenticated(state: RootState): boolean {
  return Boolean(state.app.user);
}

export function selectUserID(state: RootState): string | null {
  return state.app.user?.id || null;
}

export function selectJWT(state: RootState) {
  return state.app.user?.jwt;
}
export function selectDoRefetch(state: RootState) {
  return state.app.doRefetch;
}
export function selectIsGuestUser(state: RootState): boolean {
  const jwt = state.app.user?.jwt;

  if (!jwt) {
    return false;
  }

  return isGuestUser(jwt);
}

export function selectCart(state: RootState): CartState {
  const { cart = [] } = state.app;

  return cart;
}

export function selectCartProductsCount(state: RootState): number {
  return selectCart(state).length;
}

export function selectPlaylistsMapInCart(state: RootState): ProductMapInCart {
  const { cart = [] } = state.app;

  return cart
    .filter((c) => c.productSKU === ProductSKU.PLAYLIST)
    .reduce((accum, cartItem) => {
      // @ts-ignore
      accum[cartItem.productID] = cartItem.count || 1;

      return accum;
    }, {});
}

export function selectPlaylistBundlesMapInCart(
  state: RootState
): ProductMapInCart {
  const { cart = [] } = state.app;

  return cart
    .filter((c) => c.productSKU === ProductSKU.PLAYLIST_BUNDLE)
    .reduce((accum, cartItem) => {
      // @ts-ignore
      accum[cartItem.productID] = cartItem.count || 1;

      return accum;
    }, {});
}

export function selectPlaylistCount(playlistID: string) {
  return function (state: RootState): number {
    const playlistsMapInCart = selectPlaylistsMapInCart(state);

    return playlistsMapInCart[playlistID] || 0;
  };
}

export function selectProductCount(productID: string, productSKU: string) {
  return function (state: RootState): number {
    const cart = selectCart(state);

    const item = cart.find(
      (i) => i.productID === productID && i.productSKU === productSKU
    );

    return item?.count || 0;
  };
}

export function selectPurchaseMode(state: RootState): PurchaseMode {
  return state.app.purchaseMode || PurchaseMode.PERSONAL;
}

export function selectLandscapeMessageDisplayed(state: RootState): boolean {
  return Boolean(state.app.landscapeMessageDisplayed);
}

export function selectAddToHomeScreenPopupDisplayedTimestamp(
  state: RootState
): null | Date {
  const timestamp = state.app.AddToHomeScreenPopupDisplayedTimestamp;

  return typeof timestamp === 'number' ? new Date() : null;
}

export function selectShowDisclaimer(state: RootState): boolean {
  return state.app.showDisclaimer;
}

export function selectProtected(state: RootState): boolean {
  return Boolean(state.app.protected);
}

export function selectPromoCode(state: RootState): PromoCodeState {
  return state.app.promoCode;
}

export function selectPromoCodeDiscountRate(state: RootState): number {
  return state.app.promoCode?.discountRate || 0;
}

export function selectPinnedTours(state: RootState): PinnedTour[] {
  return state.app.pinnedTours;
}

export function selectLanguage(state: RootState): LanguageCode {
  return env.ENABLE_I18N ? state.app.language : initialState.language;
}

export function selectSupportedLanguages(state: RootState): LanguageCode[] {
  return (
    state.app.whitelabelAppSettings?.features?.supportedLanguages || [
      LanguageCode.en_US,
    ]
  );
}

export function selectRTL(state: RootState): boolean {
  return getRTL(selectLanguage(state));
}

export function selectCurrencyCode(state: RootState): CurrencyCode {
  return state.app.currencyCode;
}

export function selectCurrencyCodeSetMode(state: RootState): CurrencySetMode {
  return state.app.currencyCodeSetMode;
}

export function selectShouldAutoUpdateCurrencyCode(state: RootState): boolean {
  const { currencyCodeSetMode } = state.app;

  return currencyCodeSetMode !== CurrencySetMode.USER;
}

export function selectTourSettingsMaintainAudioPausedStateWhenSwitchingVPs(
  state: RootState
): boolean {
  return state.app.tourSettings.maintainAudioPausedStateWhenSwitchingVPs;
}

export function selectTourSettingsAutoLoadViewpointWhenNearby(
  state: RootState
): boolean {
  return state.app.tourSettings.autoLoadViewpointWhenNearby;
}

export function selectAppSettings(state: RootState): AppSettingsState {
  return state.app.appSettings;
}

export function selectClosestViewpointDetectionThresholdDistance(
  state: RootState
): number {
  return state.app.appSettings.closestViewpointDetectionThresholdDistance;
}

export function selectClosestViewpointDetectionThresholdAccuracy(
  state: RootState
): number {
  return state.app.appSettings.closetViewpointDetectionThresholdAccuracy;
}

export function selectMessageToShow(state: RootState): string | null {
  const { messages } = state.app;

  // get the final message
  return messages[messages.length - 1] || null;
}

export function selectEnableGoogleAnalytics(state: RootState): boolean {
  return state.app.enableGoogleAnalytics;
}

export function selectWhitelabelAppSettings(state: RootState): IEnv {
  return state.app.whitelabelAppSettings;
}

export const selectIsAutoPlayUnsupportedApp = createSelector(
  selectWhitelabelAppSettings,
  (whitelabelAppSettings) => {
    // TODO: need to be coming from the whitelabel app settings

    const {
      banner: { title },
    } = whitelabelAppSettings;

    return (
      whiteLabelAppTitleForTradeShow.indexOf(title) === 2 ||
      whiteLabelAppTitleForTradeShow.indexOf(title) === 4 ||
      whiteLabelAppTitleForTradeShow.indexOf(title) === 5
    );
  }
);
