import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '../../../style/styled';
import { setModalType } from '../../../store/modal/actions';
import { ModalType } from '../../../store/modal/types';
import { closeViewer } from '../../../store/viewer/actions';
import { Button } from '../../../components/Button';
import { captureButtonPress } from '../../../features/analytics';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import { ROUTES } from '../../../Routes/routes';
import { useTranslation } from 'react-i18next';
import { Padbox } from '../../../Layouts/primitives/Padbox';
import { Stack } from '../../../Layouts/primitives/Stack';
import { selectVariant } from '../../../store/googleOptimize/selectors';
import { env } from '../../config/env';

interface Props {
  tourID: string;
  viewpointID: string;
  isTrialTour: boolean;
}

// Confirmation when trying to exit a tour
const TourExitConfirmationPopup: React.FC<Props> = ({
  tourID,
  viewpointID,
  isTrialTour,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const variant = useSelector(selectVariant(env.GO_EXP_ID_FOR_LIBRARY_PAGE));

  const confirm = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Tour Close Confirmed',
      tourID,
      viewpointID,
    });

    if (!isTrialTour) {
      history.push(ROUTES.myTrips);
    } else if (variant === '0') {
      history.push(ROUTES.library);
    } else if (variant === '1') {
      history.push({ pathname: ROUTES.index, hash: '#tours_section' });
    } else {
      // fallback to home page
      history.push(ROUTES.index);
    }

    dispatch(closeViewer());

    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));
  };

  const cancel = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'Tour Close Cancelled',
      tourID,
      viewpointID,
    });

    dispatch(setModalType({ modalType: ModalType.NONE, modalData: null }));
  };

  return (
    <Container>
      <Padbox padding={[32]}>
        <Stack gutter={16}>
          <Title>{t('Are you sure you want to exit the tour?')}</Title>

          <ButtonContainer>
            <Button onClick={confirm}>{t('Yes')}</Button>

            <Button secondary onClick={cancel}>
              {t('No')}
            </Button>
          </ButtonContainer>
        </Stack>
      </Padbox>
    </Container>
  );
};

export default TourExitConfirmationPopup;

const Container = styled.div`
  /* this centers the content inside the modal */
  margin: auto;
  color: ${(props) => props.theme.palette.common.black};

  @media only screen and (min-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    max-width: 300px;
  }
`;

const Title = styled.h3`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
`;
