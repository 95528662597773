import { combineReducers } from 'redux';
import { reducer as appReducer } from '../app/reducer';
import { reducer as featuresReducer } from '../features/reducer';
import { reducer as googleOptimizeReducer } from '../googleOptimize/reducer';
import { reducer as locationReducer } from '../location/reducer';
import { reducer as modalReducer } from '../modal/reducer';
import { reducer as offlineReducer } from '../offline/reducer';
import { reducer as swReducer } from '../sw/reducer';
import { reducer as viewerReducer } from '../viewer/reducer';

export const rootReducer = combineReducers({
  app: appReducer,
  features: featuresReducer,
  googleOptimize: googleOptimizeReducer,
  location: locationReducer,
  modals: modalReducer,
  offline: offlineReducer,
  sw: swReducer,
  viewer: viewerReducer,
});
