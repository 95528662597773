import React from 'react';
import styled from 'styled-components';
import CloseIcon from 'mdi-react/CloseIcon';
import { SelectedFeature } from '../../../features/offlineMap/types';
import { Button } from '../../../components/Button';
import { ImageAspectRatio } from '../../../Layouts/primitives/ImageAspectRatio';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentViewpointIndex,
  selectTour,
} from '../../../store/viewer/selectors';
import {
  setCameraFacingDirection,
  setViewpointIndex,
  stopNarration,
} from '../../../store/viewer/actions';

interface Props {
  selectedFeature: SelectedFeature | null | undefined;
  onClose: () => void;
  withViewpointVisiting: boolean;
  withNumbers?: boolean;
}

export const InfoPanel: React.FC<Props> = ({
  onClose,
  selectedFeature,
  withViewpointVisiting = true,
  withNumbers = true,
}) => {
  const dispatch = useDispatch();
  const currentViewpointIndex = useSelector(selectCurrentViewpointIndex);

  const { idx, title, description, thumbnailURL } = getFeatureDetails(
    selectedFeature
  );

  const tour = useSelector(selectTour);
  const currentViewpointName =
    tour?.stops[currentViewpointIndex]?.nameI18n?.en_US;
  return (
    <Panel visible={Boolean(selectedFeature)}>
      <PanelContent>
        <ImageContainerWrapper>
          <ImageAspectRatio height={90} width={160} src={thumbnailURL} />
        </ImageContainerWrapper>

        <MetadataContainer>
          <div>
            <Title>{title}</Title>
            {description && <Description>{description}</Description>}
          </div>

          {withViewpointVisiting && title !== currentViewpointName && (
            <GoButtonContainer>
              <Button
                width="100px"
                secondary
                onClick={() => {
                  // stopNarration for free roam

                  dispatch(stopNarration());

                  dispatch(setViewpointIndex({ index: idx }));
                  //setup facing direction back to default north
                  dispatch(
                    setCameraFacingDirection({ facingDirection: 'North' })
                  );
                }}
              >
                Go
              </Button>
            </GoButtonContainer>
          )}
        </MetadataContainer>

        {withNumbers && <NumberCircle>{idx + 1}</NumberCircle>}
      </PanelContent>

      <CloseButtonContainer onClick={onClose} title="Close info panel">
        <CloseIcon color="#fff" />
      </CloseButtonContainer>
    </Panel>
  );
};

function getFeatureDetails(feature: SelectedFeature | null | undefined) {
  let idx = 0;
  let title = '';
  let description = '';
  let thumbnailURL = '';

  if (feature) {
    idx = feature.idx;
    title = feature.name;
    description = feature.description;
    thumbnailURL = feature.thumbnailURL;
  }

  return { idx, title, description, thumbnailURL };
}

const ImageContainerWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 150px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    max-width: 110px;
  }
`;

const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  flex-grow: 999;
  flex-basis: 0;
  justify-content: space-between;
`;

const GoButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

const NumberCircle = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background: #fff;
  color: #000;
  z-index: 1;
`;

const Title = styled.h3`
  margin-bottom: 8px;
  margin-right: 20px;
  font-size: 14px;
`;

const Description = styled.p`
  font-size: 75%;
`;

// TODO: Move Panel positioning and z-index props to a separate reusable component
const Panel = styled.div.attrs<{ visible: boolean }>((props) => ({
  style: {
    top: props.visible ? 'auto' : '100%',
    bottom: props.visible ? '0' : 'auto',
  },
}))<{ visible: boolean }>`
  position: absolute;
  background: #000;
  width: 100%;
  bottom: 0;
  z-index: 1;
  right: 0;
  color: #fff;
  overflow: hidden;
`;

const PanelContent = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  padding: 8px;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  border-radius: 50%;
  top: 4px;
  right: 4px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const ImageContainer = styled.div`
//   padding-top: 56.25%;
//   position: relative;
// `;

// const ImageContent = styled.div<{ imgURL: string }>`
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   background-color: #aaa;
//   background-image: url(${({ imgURL }) => imgURL});
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
// `;
