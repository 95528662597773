import { getStore } from '../store';
import { RootState } from '../store/redux/types';

declare global {
  interface Window {
    Modernizr: any;
  }
}
export function getDecorationImageURL(): string {
  try {
    const state: RootState = getStore()?.getState();

    if (!state?.app.whitelabelAppSettings.pageLayout.displayDecorationBanner) {
      return '';
    }

    return window.Modernizr?.webp
      ? '/decoration.webp'
      : '/1c03e0e1deec99ecf5372efe912bb4e9.jpg';
  } catch (error) {
    return '';
  }
}
