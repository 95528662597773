import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundaryMini } from '../components/ErrorBoundaryMini';
import { CurrencyAutoSetter } from '../features/multiCurrency/components/CurrencyAutoSetter';
import { ITheme } from '../features/whitelabeling/branding/types';
import { Helmet } from '../helmets/Helmet';
import { AppGlobalStyle } from '../style/AppGlobalStyle';
import { ThemeProvider } from '../style/styled';
import { App } from './App';
import GoogleOptimizeExperimentsLoader from './components/GoogleOptimizeExperimentsLoader';
import Tracking from './config/tracking/Tracking';

interface Props {
  stylesheetURL: string;
  theme: ITheme;
}

export const AppWithTheme = ({ theme, stylesheetURL }: Props) => {
  return (
    // styled components theme providers. theme can be defined per app (whitelabel app)
    <ThemeProvider theme={theme}>
      <Helmet stylesheetURL={stylesheetURL} />

      {/* Automatically set the currency for a user, based on their IP info */}
      <ErrorBoundaryMini>
        <CurrencyAutoSetter />
      </ErrorBoundaryMini>

      {/* Common styles */}
      <AppGlobalStyle />

      {/* Common styles */}
      {/* <GlobalStyle /> */}

      <ErrorBoundaryMini>
        <GoogleOptimizeExperimentsLoader />
      </ErrorBoundaryMini>

      {/* Main app and routes */}
      <App />

      {/* Commented out because Optimize stopped working due to lazy loading Google Analytics */}
      <Tracking />

      {/* react-toastify integration. DO NOT REMOVE unless ditching the library */}
      <ToastContainer />
    </ThemeProvider>
  );
};
