import React, { useState } from 'react';
import styled from '../../../../../style/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Section } from '../styledComponents/Section';
import { SectionTitle } from '../styledComponents/SectionTitle';
import { IconWithTextContainer } from '../styledComponents/IconWithTextContainer';
import { BodyTextWithIcon } from '../styledComponents/BodyTextWithIcon';
import { getViewpointsMarkerData } from '../../../../../features/map/utils/getViewpointsMarkerData';
import { ItineraryGoogleMap } from '../../../../../features/map/ItineraryGoogleMap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectLanguage,
  selectWhitelabelAppSettings,
} from '../../../../../store/app/selectors';
import {
  LanguageCode,
  PlaylistFeature,
} from '../../../../../graphql/globalTypes';
import { getI18nFieldValue } from '../../../../../features/i18n/getI18nFieldValue';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../../style/SIZES';
import { tourSupportsGoogleMap } from '../../../../../utils/tourSupportsGoogleMap';
import { tourSupportsCustomMap } from '../../../../../utils/tourSupportsCustomMap';
import { MapType } from './types';
import { TourInfoWhenAndWhereSectionMapSwitcher } from './TourInfoWhenAndWhereSectionMapSwitcher';
import { OfflineTourMap } from '../../../../../Viewer/components/OfflineTourMap/OfflineTourMap';
import { EXPLORE_WEB_GetFullTour_result_map_geoJSONFiles } from '../../../../../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';
import { env } from '../../../../../App/config/env';
import { Asset, I18nInfoField, StopLocation } from '../../../../../types';

const TOUR_INFO_WHERE_AND_WHEN_ICON = '/4b57c320c0e877c2d2b0912bffae5cf4.png';

interface Stop {
  id: string;
  nameI18n: I18nInfoField;
  taglineI18n: I18nInfoField;
  timePeriodSpecific: string | null;
  location: StopLocation | null;
  thumbnail: Asset | null;
}

interface Tour {
  id: string;
  features: PlaylistFeature[];
  stops: Array<Stop>;
  mapGeoJSON: {
    id: string;
    uri: string;
  } | null;
  map: {
    id: string;
    projectionCode: string;
    extXMin: number;
    extYMin: number;
    extXMax: number;
    extYMax: number;
    defaultViewBoxXMin: number | null;
    defaultViewBoxYMin: number | null;
    defaultViewBoxXMax: number | null;
    defaultViewBoxYMax: number | null;
    geoJSONFiles: EXPLORE_WEB_GetFullTour_result_map_geoJSONFiles[];
  } | null;
}

interface Props {
  tour: Tour;
}

export const TourInfoWhenAndWhereSection = React.memo(({ tour }: Props) => {
  const { t } = useTranslation();
  const [mapType, setMapType] = useState<MapType>(getDefaultMapType(tour));
  const language = useSelector(selectLanguage);
  const {
    tourInfo: {
      mapSection: { title, displayStartingVP },
    },
  } = useSelector(selectWhitelabelAppSettings);
  const viewpointMarkerData = getViewpointsMarkerData(tour.stops, language);
  const startingPointText = getStartingPointText(tour.stops, language);

  return (
    <Section>
      <SectionTitle>{t(title || 'Where and When?')}</SectionTitle>

      {displayStartingVP && startingPointText && (
        <IconWithTextContainer>
          <LazyLoadImage width={32} src={TOUR_INFO_WHERE_AND_WHEN_ICON} />
          {<BodyTextWithIcon>{startingPointText}</BodyTextWithIcon>}
        </IconWithTextContainer>
      )}

      {env.ENABLE_PLAYLIST_FEATURES_BASED_MAPS_ON_THE_ITINERARY &&
        mapType !== MapType.NONE && (
          <MapContainer>
            <ConditionallyVisibleMapContianer
              visible={mapType === MapType.GOOGLE}
            >
              <ItineraryGoogleMap
                markerData={viewpointMarkerData}
                trailGeoJSONURI={tour.mapGeoJSON?.uri}
                zoom={14}
              />
            </ConditionallyVisibleMapContianer>

            <ConditionallyVisibleMapContianer
              visible={mapType === MapType.CUSTOM}
            >
              <OfflineTourMap
                currentViewpointIndex={0}
                tour={tour}
                isMapsVisible={true}
                showUserPosition={false}
                withViewpointVisiting={false}
                withZoomControls
              />
            </ConditionallyVisibleMapContianer>

            <TourInfoWhenAndWhereSectionMapSwitcher
              selected={mapType}
              tour={tour}
              changeMapType={(mapType: MapType) => setMapType(mapType)}
            />
          </MapContainer>
        )}

      {!env.ENABLE_PLAYLIST_FEATURES_BASED_MAPS_ON_THE_ITINERARY && (
        <MapContainer>
          <ItineraryGoogleMap
            markerData={viewpointMarkerData}
            trailGeoJSONURI={tour.mapGeoJSON?.uri}
            zoom={14}
          />
        </MapContainer>
      )}
    </Section>
  );
});

const ConditionallyVisibleMapContianer = styled.div<{ visible: boolean }>`
  z-index: ${({ visible }) => (visible ? 2 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

function getStartingPointText(
  stops: Array<Stop>,
  language: LanguageCode
): string | null {
  const firstViewpoint = stops[0];

  if (!firstViewpoint) {
    return null;
  }

  const name = getI18nFieldValue(firstViewpoint.nameI18n, language);

  if (!name) {
    return null;
  }

  const timePeriod = firstViewpoint.timePeriodSpecific
    ? ` (${firstViewpoint.timePeriodSpecific})`
    : '';

  return `${name}${timePeriod}`;
}

const MapContainer = styled.div`
  margin-top: 10px;
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #ccc;
  border: 1px solid #ccc;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    height: 400px;
  }
`;

function getDefaultMapType(tour: Tour): MapType {
  if (tourSupportsGoogleMap(tour)) {
    return MapType.GOOGLE;
  }

  if (tourSupportsCustomMap(tour)) {
    return MapType.CUSTOM;
  }

  return MapType.NONE;
}
