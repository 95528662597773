export const EXPLAINER_VIDEO_PLAYLIST_FILENAME =
  '9963848235dcccb695fdcdfc0d1f298c.m3u8';
export const EXPLAINER_VIDEO_POSTER = 'ac7cae2342c8d7b7ef63748edb05d61f.jpg';
export const BANNER_IMAGE = '94278706c1f4c1bd33ab68c0c3f7e6b8.jpg';
// export const BANNER_IMAGE_2 = '91fccfe8b1fe867c9e5e3a96db3fee6d.jpg';
export const BANNER_IMAGE_2_DESKTOP = 'f4356cd195453cf0633362971c9ba689.jpg';
export const BANNER_IMAGE_2_MOBILE = '3d2ca275f39fe071b469725be1e790d9.jpg';
export const LIST_YOUR_OWN_TOUR_LOTTIE =
  '8ab06c4fd35b712f16a370f41bb8e747.json';
export const PARTNERS_VIEW_LOTTIE = '5daf8381f027b6d07aedc589e8982f2c.json';
export const PARTNERS_VIEW_IMAGE = 'cdecdfe9a9bf03daaa9373e485b3bb33.jpg';
export const CREATE_TOURS_VIEW_LOTTIE = '4dc303f71fa6cae4010d9ef16bb44df5.json';
export const DELETE_ACCOUNT_LOTTIE = 'e754284a1d7799a57116d99437d1b150.json';
export const GUIDES_PAGE_LOTTIE = '5ba0fa61b14f83e46db72348dd745b26.json';
export const HERRITAGE_CLUB_TERMS_LOTTIE =
  '4099eb0b2e67253cc1bb3f74d909337e.json';
export const LIST_YOUR_OWN_TOUR_TERMS_LOTTIE =
  '4099eb0b2e67253cc1bb3f74d909337e.json';
export const MERCH_PAGE_LOTTIE = '2d4058f51133bf2e5138b31e9f25fbff.json';
export const PRIVACY_POLICY_PAGE_LOTTIE =
  'e754284a1d7799a57116d99437d1b150.json';
export const TERMS_LOTTIE = '4099eb0b2e67253cc1bb3f74d909337e.json';
export const STREET_ESCAPES_CATEGORY_EAT =
  '4b0eab01d3dca3678ed9f2975660b8a4.jpg';
export const STREET_ESCAPES_CATEGORY_DRINK =
  'bc323b85414030eab88941c1d2d65ada.jpg';
export const STREET_ESCAPES_CATEGORY_EXPERIENCES =
  '282313765614cbcf83d352fa07c4da8f.jpg';
export const STREET_ESCAPES_LOTTIE = '499102637bbb2f91ca299b54cef207b5.json';
export const MERCH_COLOSSEUM_MUG = '4a1a4cfe93cb4129cd176c6fb93a059f.jpg';
export const MERCH_ZEUS_AND_ATHENA_CLASSIC_T_SHIRT =
  '72276c3f31f68fdf03d93907d17949ad.jpg';
export const MERCH_ULPIA_TOTE_BAG = '4845e37c89e739fdee8419c160db47fb.jpg';
export const MERCH_CYBELE_CLASSIC_T_SHIRT =
  '340e09c675b97d6a6fd0ee4c68ec31f6.jpg';
export const MERCH_ACROPOLIS_PUZZLE = '6fb0166a9dacfbb7175aef0ed5d9841b.jpg';
export const MERCH_ASCLEPIUS_AND_HYGIEIA_TOTE_BAG =
  '82ad281114a76c8a982f0ef30fa7ce08.jpg';
export const MERCH_HADRIAN_CLASSIC_T_SHIRT =
  '57a4417d66368dfb45421fb6b8cee75f.jpg';
export const MERCH_THE_TEMPLE_OF_JULIUS_CAESAR_POSTCARD =
  '46edda2383beaa0dbb8f7ad64575edb9.jpg';
export const MERCH_COLOSSEUM_SOCKS = 'b8312b950224b91207354be244ac9da2.jpg';
export const MERCH_ULPIA_TRAVEL_MUG = '4c081d7451c4b687217d78a0f95af30a.jpg';
export const MERCH_ZEUS_AND_ATHENA_LONG_T_SHIRT =
  'c4d55e44ec1f3d59eeb83c0f1d20a291.jpg';
export const MERCH_ZEUS_AND_ATHENA_PULLOVER_HOODIE =
  '9db223ceb7804d8f4876b6d8c9df6be8.jpg';
export const MERCH_GODDESS_ISIS_TOTE_BAG =
  '5b32422db40c34177777ac310e1f1b96.jpg';
export const MERCH_THE_TEMPLE_OF_JULIUS_CAESAR_SLIM_FIT_T_SHIRT =
  '068173b03887f3934bd1bde854acd3c2.jpg';
export const MERCH_THE_GODDESS_ATHENA_CLASSIC_T_SHIRT =
  'fc2edc4670281f946507f8263d87628d.jpg';
export const MERCH_COLOSSEUM_PUZZLE = 'e8815858f11718455e8f6bef2e3e134d.jpg';
export const MERCH_THE_COLOSSEUM_COASTERS_SET_OF_4 =
  'bb3a315a0228f4cebe677d6c9a47a676.jpg';
export const MERCH_AUGUSTUS_PULLOVER_SWEATSHIRT =
  'd1a2aca204f926cfa7a442bd4d975ad2.jpg';
export const MERCH_THE_FAUNO_PULLOVER_SWEATSHIRT =
  '96cbf5382feb2cb15c25a1186541780e.jpg';
export const MERCH_COLOSSEUM_WATER_BOTTLE =
  'fa24c2a9283506c4594e82dd962301f9.jpg';
export const ABOUT_US_PAGE_IMAGES_1 = '2f2fd2ca2f50df5f2dcea3f6b52d3db9.jpg';
export const ABOUT_US_PAGE_IMAGES_2 = 'f34b3b30e0a19a421d2a2bab6804e08d.jpg';
export const ABOUT_US_PAGE_IMAGES_3 = '8eaccbdfe7cfd96f7105842a79bedc1f.jpg';
export const ABOUT_US_PAGE_IMAGES_4 = '830268363cea85a8c9f1547e8034a48a.jpg';
export const ABOUT_US_PAGE_IMAGES_5 = 'af0adc9aec0ca970241bbc7a50399bae.jpg';
export const ABOUT_US_PAGE_IMAGES_6 = '7bde6f0169475170f387c6034ae2cfdc.jpg';
export const ABOUT_US_PAGE_IMAGES_7 = '6d6b3e744d4ffb437dfaa89b67b4b7ea.jpg';
export const ABOUT_US_PAGE_IMAGES_8 = '159ec5393fb6ef97fcd93c201aeb0e65.jpg';
export const ABOUT_US_PAGE_IMAGES_9 = 'db64aa88d7f49400b4ca117f2e238875.jpg';
export const ABOUT_US_PAGE_IMAGES_10 = 'ac9e9ea0e6c9a886bbacc8038e8924b7.jpg';
export const ABOUT_US_PAGE_IMAGES_11 = '72e62d3e0c97198607396ee582f4b964.jpg';
export const ABOUT_US_PAGE_IMAGES_12 = '6efb60fc3a282efab027ef877abcbd1f.jpg';
export const ABOUT_US_PAGE_LOTTIE = '67ca3101b14d447ce4edf55c54c4b81b.json';
export const REDBUBBLE_ICON = 'dc255c7e8ecc7d5edaa4b930288aff3c.png';
export const HOLD_PHONE = '9516506515c99d9a672d8fd0f6cb3edc.png';
export const CART_LOTTIE = 'e09ca3c450b5af20f573d847b03e8899.json';
export const ACCOUNT_LOTTIE = '4198a02435e1758f5b0c8bc3b70b4746.json';
export const DEFAULT_APP_LOGO = 'a97edc848941857d052fce8ea94f2277.png';
export const CALIBRATE = '78eb86d84781628d17658018abf57606.gif';
export const CALIBRATE_FINISHED = 'b237c8959b7b5e81dc4af08a85d706bb.png'; //tick.png
export const NEW_NORTH_ARROW = 'd44f378f7fd8517ef997c043f84e2ac6.fbx';
export const NEW_SOUTH_ARROW = 'd44f378f7fd8517ef997c043f84e2ac6.fbx';
export const NEW_WEST_ARROW = 'd44f378f7fd8517ef997c043f84e2ac6.fbx';
export const NEW_EAST_ARROW = 'd44f378f7fd8517ef997c043f84e2ac6.fbx';
// sort them in the order of priority
export const COMMON_APP_ASSETS = [
  DEFAULT_APP_LOGO,
  // Home page
  BANNER_IMAGE,
  BANNER_IMAGE_2_DESKTOP,
  BANNER_IMAGE_2_MOBILE,
  // Account page
  ACCOUNT_LOTTIE,
  // Tour page
  HOLD_PHONE,
  REDBUBBLE_ICON,
  // FAQ page
  EXPLAINER_VIDEO_PLAYLIST_FILENAME,
  EXPLAINER_VIDEO_POSTER,
  // Guides page
  GUIDES_PAGE_LOTTIE,
  // About us page
  ABOUT_US_PAGE_IMAGES_1,
  ABOUT_US_PAGE_IMAGES_2,
  ABOUT_US_PAGE_IMAGES_3,
  ABOUT_US_PAGE_IMAGES_4,
  ABOUT_US_PAGE_IMAGES_5,
  ABOUT_US_PAGE_IMAGES_6,
  ABOUT_US_PAGE_IMAGES_7,
  ABOUT_US_PAGE_IMAGES_8,
  ABOUT_US_PAGE_IMAGES_9,
  ABOUT_US_PAGE_IMAGES_10,
  ABOUT_US_PAGE_IMAGES_11,
  ABOUT_US_PAGE_IMAGES_12,
  ABOUT_US_PAGE_LOTTIE,
  // Merch page
  MERCH_PAGE_LOTTIE,
  MERCH_COLOSSEUM_MUG,
  MERCH_ZEUS_AND_ATHENA_CLASSIC_T_SHIRT,
  MERCH_ULPIA_TOTE_BAG,
  MERCH_CYBELE_CLASSIC_T_SHIRT,
  MERCH_ACROPOLIS_PUZZLE,
  MERCH_ASCLEPIUS_AND_HYGIEIA_TOTE_BAG,
  MERCH_HADRIAN_CLASSIC_T_SHIRT,
  MERCH_THE_TEMPLE_OF_JULIUS_CAESAR_POSTCARD,
  MERCH_COLOSSEUM_SOCKS,
  MERCH_ULPIA_TRAVEL_MUG,
  MERCH_ZEUS_AND_ATHENA_LONG_T_SHIRT,
  MERCH_ZEUS_AND_ATHENA_PULLOVER_HOODIE,
  MERCH_GODDESS_ISIS_TOTE_BAG,
  MERCH_THE_TEMPLE_OF_JULIUS_CAESAR_SLIM_FIT_T_SHIRT,
  MERCH_THE_GODDESS_ATHENA_CLASSIC_T_SHIRT,
  MERCH_COLOSSEUM_PUZZLE,
  MERCH_THE_COLOSSEUM_COASTERS_SET_OF_4,
  MERCH_AUGUSTUS_PULLOVER_SWEATSHIRT,
  MERCH_THE_FAUNO_PULLOVER_SWEATSHIRT,
  MERCH_COLOSSEUM_WATER_BOTTLE,
  // Street Escapes page
  STREET_ESCAPES_LOTTIE,
  STREET_ESCAPES_CATEGORY_EAT,
  STREET_ESCAPES_CATEGORY_DRINK,
  STREET_ESCAPES_CATEGORY_EXPERIENCES,
  // Policies
  PRIVACY_POLICY_PAGE_LOTTIE,
  // Partners page
  PARTNERS_VIEW_LOTTIE,
  PARTNERS_VIEW_IMAGE,
  // List your own tour page
  LIST_YOUR_OWN_TOUR_LOTTIE,
  // Create tours
  CREATE_TOURS_VIEW_LOTTIE,
  // Delete account
  DELETE_ACCOUNT_LOTTIE,
  // Terms
  HERRITAGE_CLUB_TERMS_LOTTIE,
  LIST_YOUR_OWN_TOUR_TERMS_LOTTIE,
  // Cart/Checkout,
  CART_LOTTIE,
  //compass
  CALIBRATE,
  CALIBRATE_FINISHED,
  NEW_NORTH_ARROW,
  NEW_SOUTH_ARROW,
  NEW_WEST_ARROW,
  NEW_EAST_ARROW,
];
