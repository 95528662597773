import React from 'react';
import { DeviceMotion } from '../types/DeviceMotion';

type DeviceOrientationValue = {
  startListening: () => void;
  pauseListening: () => void;
  restartListening: () => void;
  compassEnabled: boolean;
  initialWebkitAlphaOffset: number | null;
  deviceMotionSupport: DeviceMotion;
  deviceOrientation: {
    alpha: number;
    beta: number;
    gamma: number;
    absolute: boolean;
    webkitCompassHeading: number;
    webkitCompassAccuracy: number;
  };
};

export const DeviceOrientationContext = React.createContext<DeviceOrientationValue>(
  {
    startListening: () => {},
    pauseListening: () => {},
    restartListening: () => {},
    compassEnabled: false,
    initialWebkitAlphaOffset: null,
    deviceMotionSupport: DeviceMotion.UNSUPPORTED,
    deviceOrientation: {
      alpha: 90,
      beta: 90,
      gamma: 0,
      absolute: false,
      webkitCompassHeading: 0,
      webkitCompassAccuracy: 10,
    },
  }
);
