import { handleActions } from 'redux-actions';
import {
  ACTION_TYPES,
  EnableFeatureAction,
  DisableFeatureAction,
} from './actions';
import { FeaturesState, initialState } from './types';

export const reducer = handleActions<FeaturesState, any>(
  {
    [ACTION_TYPES.ENABLE_FEATURE]: (
      state: FeaturesState,
      action: EnableFeatureAction
    ): FeaturesState => {
      return {
        ...state,
        [action.payload.feature]: true,
      };
    },
    [ACTION_TYPES.DISABLE_FEATURE]: (
      state: FeaturesState,
      action: DisableFeatureAction
    ): FeaturesState => {
      return {
        ...state,
        [action.payload.feature]: false,
      };
    },
  },
  initialState
);
