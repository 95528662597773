import React, { useState } from 'react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import ShowPasswordIcon from 'mdi-react/EyeIcon';
import HidePasswordIcon from 'mdi-react/EyeOffIcon';
import styled from '../../../style/styled';
import * as Sentry from '@sentry/react';
import { ROUTES } from '../../routes';
import {
  SIGNUP_MUTATION,
  Data,
  Variables,
} from '../../../graphql/mutations/SignUpMutation';
import { Input } from '../../../styledComponents/Input';
import { Label } from '../../../styledComponents/Label';
import { ErrorText } from '../../../styledComponents/ErrorText';
// import { HelpText } from '../../../styledComponents/HelpText';
// import { InterestsSelect } from '../../../components/dropdowns/InterestsSelect';
import { loginSuccess } from '../../../store/app/actions';
import { Grid } from '../../../Layouts/primitives/Grid';
// import { LinkLikeButton } from '../../../styledComponents/LinkLikeButton';
// import { AuthPageTitle } from '../../../styledComponents/Title';
// import { ReCaptchaDisclaimer } from '../../../components/ReCaptchaDisclaimer';
import { AuthButtonWithRecaptcha3 } from '../../../components/AuthButtonWithRecaptcha3';
import { captureEvent, EventNames } from '../../../features/analytics';
// import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import isEmail from '../../../utils/isEmail';
// import { Dropdown } from '../../../components/dropdowns/Dropdown';
// import {
//   getAgeOptions,
//   getAgeMinMax,
// } from '../../../components/dropdowns/data/age';
// import { getCountryOptions } from '../../../components/dropdowns/data/country';
// import { getGenderOptions } from '../../../components/dropdowns/data/gender';
// import { getHowDidYouHearAboutUsOptions } from '../../../components/dropdowns/data/howDidYouHearAboutUs';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { toast } from 'react-toastify';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import { isMobile } from 'react-device-detect';
import {
  // RECAPTCHA_ERROR,
  UNKNOWN_ERROR,
} from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { env } from '../../../App/config/env';
import { SignUpTerms } from '../../../components/SignUpTerms';

// type Option = {
//   value: string;
//   label: string;
// };

type FormState = {
  // confirmEmail: string;
  email: string;
  name: string;
  // firstName: string;
  // lastName: string;
  password: string;
  // confirmPassword: string;
  // country: Option | null;
  // gender: Option | null;
  // age: Option | null;
  // howDidYouHearAboutUs: Option | null;
  // interests: [Option] | null;
  // agreed: boolean;
};

type ErrorState = {
  email: string;
  // confirmEmail: string;
  name: string;
  // firstName: string;
  // lastName: string;
  password: string;
  // confirmPassword: string;
  // country: string;
  // gender: string;
  // age: string;
  // howDidYouHearAboutUs: string;
  // interests: string;
  // agreed: string;
};

function getInitialFormState() {
  return {
    email: '',
    // confirmEmail: '',
    name: '',
    // firstName: '';
    // lastName: '',
    password: '',
    // confirmPassword: '',
    // country: null,
    // gender: null,
    // age: null,
    // howDidYouHearAboutUs: null,
    // interests: null,
    // agreed: false,
  };
}

function getInitialErrorState() {
  return {
    email: '',
    // confirmEmail: '',
    name: '',
    // firstName: '';
    // lastName: '',
    password: '',
    // confirmPassword: '',
    // country: '',
    // gender: '',
    // age: '',
    // howDidYouHearAboutUs: '',
    // interests: '',
    // agreed: '',
  };
}

const RECAPTCHA_CONTEXT = 'SignUp';

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  onLogIn?: () => void;
  onForgotPassword?: () => void;
  redirectedHref: string | null | undefined;
}

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export const SignUpScreen: React.FC<Props> = ({
  onSuccess,
  onFailure,
  onLogIn,
  onForgotPassword,
  redirectedHref,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formState, setFormState] = useState<FormState>(getInitialFormState());
  const [formError, setFormError] = useState<ErrorState>(
    getInitialErrorState()
  );
  const [error, setError] = useState<Error | null>(null);
  const [signUpMutation, { loading }] = useMutation<Data, Variables>(
    SIGNUP_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        onFailure ? onFailure(error) : setError(error);
      },
      onCompleted: ({ result }) => {
        const { jwt, error } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          Sentry.configureScope(function (scope) {
            scope.setUser({ id: userID });
          });

          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({ name: EventNames.LOGGED_IN });

          const toastSuccessMessage = t(
            'Sign up successful. You have been automatically logged in.'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables(), jwt });

          onFailure
            ? onFailure(new Error(errorMessage))
            : setError(new Error(errorMessage));
        }
      },
    }
  );

  const submitForm = async (e: any) => {
    e.preventDefault();

    const token: string | null = null;

    // if (executeRecaptcha) {
    //   try {
    //     token = await executeRecaptcha(RECAPTCHA_CONTEXT);
    //   } catch (error) {
    //     captureInSentry(error?.message || RECAPTCHA_ERROR, {
    //       recaptchaContext: RECAPTCHA_CONTEXT,
    //     });
    //   }
    // }

    handleSubmit(token);
  };

  const handleSubmit = (recaptchaToken: string | null) => {
    setError(null);

    const email = formState.email.trim();
    // const confirmEmail = formState.confirmEmail.trim();
    const password = formState.password.trim();
    // const firstName = formState.firstName.trim();
    // const lastName = formState.lastName.trim();
    const name = formState.name.trim();

    // const agreed = formState.agreed;
    // const countryID = formState.country?.value;
    // const ageGroup = formState.age?.value;
    // const gender = formState.gender?.value;
    // const howDidYouHearAboutUs = formState.howDidYouHearAboutUs?.value;

    // let interests: Array<string> = [];

    // if (Array.isArray(formState.interests)) {
    //   interests = formState.interests.map((i: Option) => i.value);
    // }

    const errorState = getInitialErrorState();

    let hasError = false;
    // let ageMin = -1;
    // let ageMax = -1;

    // if (!agreed) {
    //   errorState.agreed = 'Please agree to the conditions.';
    //   hasError = true;
    // }

    // if (!firstName) {
    //   errorState.firstName = 'Please enter your First Name.';
    //   hasError = true;
    // }

    // if (!lastName) {
    //   errorState.lastName = 'Please enter your Last Name.';
    //   hasError = true;
    // }

    if (!name) {
      errorState.name = t('Please enter your Name.');
      hasError = true;
    }

    if (!isEmail(email)) {
      errorState.email = t('Please enter a valid Email.');
      hasError = true;
    }

    // if (email !== confirmEmail) {
    //   errorState.confirmEmail =
    //     'Email and the confirm email fields do not match.';
    //   hasError = true;
    // }

    if (password.length < 6) {
      errorState.password = t('Please enter a 6 or more character Password.');
      hasError = true;
    }

    // if (!gender) {
    //   errorState.gender = 'Please select your Gender.';
    //   hasError = true;
    // }

    // if (!ageGroup) {
    //   errorState.age = 'Please select your Age.';
    //   hasError = true;
    // } else {
    //   const ages = getAgeMinMax(ageGroup);
    //   ageMin = ages.ageMin;
    //   ageMax = ages.ageMax;
    // }

    // if (!countryID) {
    //   errorState.country = 'Please select your Country.';
    //   hasError = true;
    // }

    // if (interests.length === 0) {
    //   errorState.interests =
    //     'Please choose which locations you are interested in.';
    //   hasError = true;
    // }

    // if (!howDidYouHearAboutUs) {
    //   errorState.howDidYouHearAboutUs = 'Please choose how you heard about us.';
    //   hasError = true;
    // }

    if (hasError) {
      setFormError(errorState);
    } else {
      const variables: Variables = {
        input: {
          email,
          password,
          // countryID,
          // name: `${firstName} ${lastName}`,
          name,
          // ageMin,
          // ageMax,
          // interests,
          // howDidYouHearAboutUs,
          // gender,
          verificationURL: `https://${window.location.hostname}${ROUTES.authVerify}`,
          skipVerification: true,
          referralAppID: env.APP_ID,
        },
      };

      const affiliateCode = getStoredAffiliateCode();

      if (affiliateCode) {
        variables.input.affiliateCode = affiliateCode;
      }

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      signUpMutation({ variables });
    }
  };

  // With checkbox
  // const handleInputChange = (e: any) => {
  //   const { name, value, checked } = e.target;

  //   setError(null);
  //   setFormError({ ...formError, [name]: '' });

  //   let newValue = value;

  //   if (name === 'agreed') {
  //     newValue = checked;
  //   }

  //   setFormState({ ...formState, [name]: newValue });
  // };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setError(null);
    setFormError({ ...formError, [name]: '' });

    setFormState({ ...formState, [name]: value });
  };

  // const handleSelectChange = (name: string) => (option: any) => {
  //   setError(null);
  //   setFormError({ ...formError, [name]: '' });

  //   setFormState({ ...formState, [name]: option });
  // };

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={submitForm}>
        <Grid cols={1} gap={12} marginBottom={20}>
          <section>
            <Label htmlFor="name">{t('Name')}</Label>
            <Input
              autoFocus={!isMobile}
              id="name"
              name="name"
              type="text"
              disabled={loading}
              autoComplete="given-name"
              value={formState.name}
              onChange={handleInputChange}
              maxLength={50}
              data-testid="name-input-field"
            />
            <ErrorText>{formError?.name || ''}</ErrorText>
          </section>

          <section>
            <Label htmlFor="email">{t('Email address')}</Label>
            <Input
              id="email"
              name="email"
              type="email"
              disabled={loading}
              autoComplete="email"
              value={formState.email}
              onChange={handleInputChange}
              data-testid="email-input-field"
            />
            <ErrorText>{formError?.email || ''}</ErrorText>
          </section>

          {/* <section>
            <Label htmlFor="firstName">First Name</Label>
            <Input
              autoFocus
              id="firstName"
              name="firstName"
              type="text"
              disabled={loading}
              autoComplete="given-name"
              value={formState.firstName}
              onChange={handleInputChange}
              maxLength={50}
              data-testid="firstName-input-field"
            />
            <ErrorText>{formError?.firstName || ''}</ErrorText>
          </section> */}

          {/* <section>
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              disabled={loading}
              id="lastName"
              name="lastName"
              autoComplete="family-name"
              type="text"
              value={formState.lastName}
              onChange={handleInputChange}
              data-testid="lastName-input-field"
              maxLength={50}
            />
            <ErrorText>{formError?.lastName || ''}</ErrorText>
          </section> */}

          {/* <section>
            <Label htmlFor="confirmEmail">Confirm Email</Label>
            <Input
              id="confirmEmail"
              name="confirmEmail"
              type="email"
              disabled={loading}
              autoComplete="email"
              value={formState.confirmEmail}
              onChange={handleInputChange}
              data-testid="confirm-email-input-field"
            />
            <ErrorText>{formError?.confirmEmail || ''}</ErrorText>
          </section> */}

          <section>
            <Label htmlFor="password">{t('Password')}</Label>
            <PasswordInputContainer>
              <Input
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                disabled={loading}
                autoComplete="new-password"
                value={formState.password}
                onChange={handleInputChange}
                data-testid="password-input-field"
              />

              <ShowPasswordButton
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {!showPassword && <ShowPasswordIcon />}
                {showPassword && <HidePasswordIcon />}
              </ShowPasswordButton>
            </PasswordInputContainer>
            <ErrorText>{formError?.password || ''}</ErrorText>
          </section>

          {/* <section>
            <Label htmlFor="gender">Gender</Label>
            <Dropdown
              isDisabled={loading}
              value={formState.gender}
              onChange={handleSelectChange('gender')}
              options={getGenderOptions()}
            />
            <ErrorText>{formError?.gender || ''}</ErrorText>
          </section>

          <section>
            <Label htmlFor="age">Age</Label>
            <Dropdown
              isDisabled={loading}
              value={formState.age}
              onChange={handleSelectChange('age')}
              options={getAgeOptions()}
            />
            <ErrorText>{formError?.age || ''}</ErrorText>
          </section>

          <section>
            <Label htmlFor="country">Country</Label>
            <Dropdown
              isDisabled={loading}
              value={formState.country}
              onChange={handleSelectChange('country')}
              options={getCountryOptions()}
            />
            <ErrorText>{formError?.country || ''}</ErrorText>
          </section>

          <section>
            <Label htmlFor="interests">Interests</Label>
            <InterestsSelect
              value={formState.interests}
              isDisabled={loading}
              onChange={handleSelectChange('interests')}
              closeMenuOnSelect={false}
            />
            <ErrorText>{formError?.interests || ''}</ErrorText>
          </section>

          <section>
            <Label htmlFor="howDidYouHearAboutUs">
              How did you hear about us?
            </Label>
            <Dropdown
              isDisabled={loading}
              value={formState.howDidYouHearAboutUs}
              onChange={handleSelectChange('howDidYouHearAboutUs')}
              options={getHowDidYouHearAboutUsOptions()}
            />
            <ErrorText>{formError?.howDidYouHearAboutUs || ''}</ErrorText>
          </section> */}

          <div>
            <ConsentContainer>
              <SignUpTerms />
            </ConsentContainer>

            {/* <ErrorText>{formError?.agreed || ''}</ErrorText> */}
          </div>

          <ErrorText>{error?.message || ''}</ErrorText>

          <AuthButtonWithRecaptcha3
            context={RECAPTCHA_CONTEXT}
            disabled={loading}
            buttonName="SignUpButton"
          >
            {t(loading ? 'Creating account...' : 'Create account')}
          </AuthButtonWithRecaptcha3>
        </Grid>
      </form>
    </div>
  );
};

const ShowPasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
`;

const PasswordInputContainer = styled.div`
  position: relative;
`;

const ConsentContainer = styled.label`
  display: flex;
  align-items: flex-sart;
  user-select: none;
`;
