import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';
import { Button } from '../../../components/Button';
import { UNKNOWN_ERROR } from '../../../consts';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { loginSuccess } from '../../../store/app/actions';
import {
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import {
  EXPLORE_WEB_AuthAsAnonymous as Data,
  EXPLORE_WEB_AuthAsAnonymousVariables as Variables,
} from './__generated__/EXPLORE_WEB_AuthAsAnonymous';
import { ROUTES } from '../../routes';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import styled from '../../../style/styled';
import qs from 'query-string';
import { whiteLabelAppTitleForTradeShow } from '../../../App/config/enums';
import { selectWhitelabelAppSettings } from '../../../store/app/selectors';
import { unbox } from '../../../utils/unbox';
const AUTH_AS_ANONYMOUS_MUTATION = gql`
  mutation EXPLORE_WEB_AuthAsAnonymous($input: AuthAsAnonymousInput!) {
    result: authAsAnonymous(input: $input) {
      jwt
      error {
        id
        message
        type
      }
    }
  }
`;

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  originalHref?: string | null;
  redirectedHref?: string | null;
  setBusy: (busy: boolean) => void;
  busy: boolean;
}

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export const ContinueAsGuest: React.FC<Props> = ({
  onSuccess,
  onFailure,
  originalHref,
  redirectedHref,
  setBusy,
  busy,
}) => {
  const {
    banner: { title },
  } = useSelector(selectWhitelabelAppSettings);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [authAsAnonymous, { loading }] = useMutation<Data, Variables>(
    AUTH_AS_ANONYMOUS_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        const errorMessage = error?.message || UNKNOWN_ERROR;

        onFailure ? onFailure(error) : toast.error(errorMessage);
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry('ContinueAsGuest.tsx result is null or undefined', {
            variables: getVariables(),
          });

          return;
        }

        const { jwt, error } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          Sentry.configureScope(function (scope) {
            scope.setUser({ id: userID });
          });

          dispatch(loginSuccess({ userId: userID, jwt }));

          captureEvent({
            name: EventNames.USER_REGISTERED,
            data: [{ key: DataKeys.SIGNUP_METHOD, value: 'Anonymous' }],
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });

          onFailure
            ? onFailure(new Error(errorMessage))
            : toast.error(errorMessage);
        }
      },
    }
  );

  const handleClick = useCallback(() => {
    const variables: Variables = {
      input: {},
    };

    const affiliateCode = getStoredAffiliateCode();

    // recaptchaToken?: string | null;

    if (affiliateCode) {
      variables.input.affiliateCode = affiliateCode;
    }

    setVariables(variables);

    authAsAnonymous({ variables });
  }, [authAsAnonymous]);

  const caption = loading ? 'Please wait...' : 'Continue as a guest';

  const rCode = unbox(qs.parse(history.location.search)?.rcode);

  // Automatically login as an anonymous user if there is a redeem code in the URL
  // only if it is an expedition.
  useEffect(() => {
    if (rCode && whiteLabelAppTitleForTradeShow.includes(title)) {
      handleClick();
    }
  }, [title, rCode, handleClick]);

  useEffect(
    () => {
      setBusy(loading);
    },
    // setBusy should not change... but even if it changes, we don't want to call it
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading]
  );

  return (
    <Container>
      <Button
        primary
        // onClick={title === 'The Acropolis' ? handleClick() : handleClick}
        onClick={handleClick}
        width="100%"
        disabled={busy || loading}
      >
        {t(caption)}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  border: none;
`;
