import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import AppHeader from '../../components/appHeader/AppHeader';
import { Footer } from '../../Layouts/Footer/Footer';
import FacebookRedirectHandler from '../Auth/login/FacebookRedirectHandler';
import { UnauthedRoute } from '../components/UnauthedRoute';
import { AuthedRoute } from '../components/AuthedRoute';
import { ROUTES } from '../routes';
import styled from '../../style/styled';
import { COLORS } from '../../style/colors';
import { NAVBAR_HEIGHT } from '../../consts';
import { whiteLabelAppTitleForTradeShow } from '../../App/config/enums';
import { useSelector } from 'react-redux';
import {
  selectRTL,
  selectWhitelabelAppSettings,
} from '../../store/app/selectors';

const HomeScreen = React.lazy(() => import('../Home/HomeScreen'));
const ItineraryScreen = React.lazy(
  () => import('../Itinerary/ItineraryScreen')
);
const DevScreen = React.lazy(() => import('../Dev/DevScreen'));
const VerificationRoutes = React.lazy(
  () => import('../Verification/VerificationRoutesView')
);
const AuthRoutes = React.lazy(() => import('../Auth/AuthRoutes'));
const AccountRoutes = React.lazy(() => import('../Account/AccountRoutes'));

const LicensesScreen = React.lazy(() => import('../Licenses/LicensesScreen'));
const GuidesScreen = React.lazy(() => import('../Guides/GuidesScreen'));
const ShopRoutes = React.lazy(() => import('../Shop/ShopRoutes'));
const DeleteAccountRoutes = React.lazy(
  () => import('../DataDeletion/DeleteAccountRoutes')
);
const MerchScreen = React.lazy(() => import('../Merch/MerchScreen'));
const FAQScreen = React.lazy(() => import('../FAQ/FAQScreen'));
const CreateToursScreen = React.lazy(
  () => import('../CreateTours/CreateToursScreen')
);
const ListTourTermsScreen = React.lazy(
  () => import('../ListTourTerms/ListTourTermsScreen')
);
const HeritageClubTermsScreen = React.lazy(
  () => import('../HeritageClubTerms/HeritageClubTermsScreen')
);
const AssociateTermsScreen = React.lazy(
  () => import('../AssociateTerms/AssociateTermsScreen')
);
const AffiliateTermsScreen = React.lazy(
  () => import('../AffiliateTerms/AffiliateTermsScreen')
);
const TermsConditionsScreen = React.lazy(
  () => import('../TermsConditions/TermsConditionsScreen')
);
const PrivacyPolicyScreen = React.lazy(
  () => import('../PrivacyPolicy/PrivacyPolicyScreen')
);
const AboutUsScreen = React.lazy(() => import('../AboutUs/AboutUsScreen'));
const SetupScreen = React.lazy(() => import('../Setup/SetupScreen'));

const RegularPageRoutes = () => {
  const {
    banner: { title },
  } = useSelector(selectWhitelabelAppSettings);
  const MyMain =
    title && whiteLabelAppTitleForTradeShow.indexOf(title) >= 0
      ? NewMain
      : Main;

  const rtl = useSelector(selectRTL);

  return (
    <PageContainer rtl={rtl}>
      <AppHeader />

      <MyMain>
        <Switch>
          {/* authed routes */}
          <AuthedRoute path={ROUTES.account} component={AccountRoutes} />

          {/* only allow is unauthed */}
          <UnauthedRoute path={ROUTES.auth} component={AuthRoutes} />

          <Route exact path={ROUTES.aboutUs} component={AboutUsScreen} />

          <Route
            exact
            path={ROUTES.facebookRedirectHandler}
            component={FacebookRedirectHandler}
          />

          <Route
            exact
            path={ROUTES.affiliateTerms}
            component={AffiliateTermsScreen}
          />

          <Route
            exact
            path={ROUTES.associateTerms}
            component={AssociateTermsScreen}
          />

          <Route
            exact
            path={ROUTES.createTours}
            component={CreateToursScreen}
          />

          <Route exact path={ROUTES.dev} component={DevScreen} />

          <Route exact path={ROUTES.setup} component={SetupScreen} />

          <Route exact path={ROUTES.merch} component={MerchScreen} />

          <Route exact path={ROUTES.yourGuides} component={GuidesScreen} />

          <Route
            exact
            path={ROUTES.heritageClubTerms}
            component={HeritageClubTermsScreen}
          />

          <Route exact path={ROUTES.licenses} component={LicensesScreen} />

          <Route
            exact
            path={ROUTES.privacyPolicy}
            component={PrivacyPolicyScreen}
          />

          <Route path={ROUTES.deleteAccount} component={DeleteAccountRoutes} />

          <Route path={ROUTES.verify} component={VerificationRoutes} />

          <Route path={ROUTES.shop} component={ShopRoutes} />

          <Route
            exact
            path={ROUTES.termsConditions}
            component={TermsConditionsScreen}
          />

          <Route
            exact
            path={ROUTES.tourListingTerms}
            component={ListTourTermsScreen}
          />

          <Route exact path={ROUTES.faq} component={FAQScreen} />

          <Route path={ROUTES.itinerary} component={ItineraryScreen} />

          <Route path={ROUTES.index} component={HomeScreen} />

          <Redirect to={ROUTES.index} />
        </Switch>
      </MyMain>

      <LazyLoadComponent>
        <Footer />
      </LazyLoadComponent>
    </PageContainer>
  );
};

export default RegularPageRoutes;

const PageContainer = styled.main<{ rtl?: boolean }>`
  background: ${COLORS.primaryBackgroundColor};
  color: ${COLORS.primaryFontColor};
  display: flex;
  flex-direction: column;
  min-height: 100%;
  direction: ${({ rtl }) => (rtl ? 'rtl' : 'ltr')};
`;

const Main = styled.main`
  /* this needs to be exactly the same height as the header, otherwise there would be a gap in tourinfo page between the header and the cover image for example */
  padding-top: ${NAVBAR_HEIGHT}px;

  width: 100%;
  /* done to push footer to the bottom of the page in case there isn't much content. */
  flex-grow: 1;
`;
const NewMain = styled.main`
  /* this needs to be exactly the same height as the header, otherwise there would be a gap in tourinfo page between the header and the cover image for example */
  padding-top: 0;

  width: 100%;
  /* done to push footer to the bottom of the page in case there isn't much content. */
  flex-grow: 1;
`;
