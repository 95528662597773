import { gql, useQuery } from '@apollo/client';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { captureButtonPress } from '../features/analytics';
import { getTourPrice } from '../features/multiCurrency/getTourPrice';
import { ASSET_FRAGMENT } from '../graphql/fragments/Asset';
import { I18NSTRING } from '../graphql/fragments/I18nString';
import { PRICE_FRAGMENT } from '../graphql/fragments/Price';
import { ProductSKU } from '../graphql/globalTypes';
import { Grid } from '../Layouts/primitives/Grid';
import { addToCart } from '../store/app/actions';
import { selectCurrencyCode } from '../store/app/selectors';
import { setModalType } from '../store/modal/actions';
import { ModalType } from '../store/modal/types';
import styled from '../style/styled';
import { nonNullable } from '../utils/nonNullable';
import { Button } from './Button';
import { TourWidget } from './contentSections/toursSection/productWidget/TourWidget/TourWidget';
import { ShopMoreToursWidget } from './contentSections/toursSection/ShopMoreToursWidget';
import { getHomeScreenSortedTours } from './contentSections/toursSection/utils/getHomeScreenSortedTours';
import { EXPLORE_WEB_GetToursForShopToursGrid as Data } from './__generated__/EXPLORE_WEB_GetToursForShopToursGrid';
import { useUser } from '../hooks/useUser';

const GET_TOURS_FOR_SHOP_TOURS_GRID = gql`
  query EXPLORE_WEB_GetToursForShopToursGrid {
    result: getTours(input: {}) {
      id
      internalReference
      trial
      published
      nameI18n {
        ...I18nStringFragment
      }
      price {
        ...PriceFragment
      }
      pastThumbnail {
        ...AssetFragment
      }
      presentThumbnail {
        ...AssetFragment
      }
      thumbnail {
        ...AssetFragment
      }
      validityPeriodMonths
      trailLength
      timePeriod
      audioDurationMinutes
    }
  }

  ${ASSET_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${I18NSTRING}
`;

const MAX_TOURS_COUNT = isMobileOnly ? 2 : 8;

export const ShopToursGrid = () => {
  const { tourBundlePurchasesMap } = useUser();
  const currencyCode = useSelector(selectCurrencyCode);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useQuery<Data, any>(GET_TOURS_FOR_SHOP_TOURS_GRID);

  const tours = (data?.result || []).map((tour) => {
    const tourPrice = getTourPrice({ tour, currencyCode });

    return {
      ...tour,
      unitPrice: tourPrice.price,
      currencyCode: tourPrice.currencyCode,
      priceInCents: true,
    };
  });

  const validTours = getHomeScreenSortedTours(tours).filter(
    ({ id, trial }) => !trial && !tourBundlePurchasesMap[id]
  );
  const displayedTours = validTours.slice(0, MAX_TOURS_COUNT);
  const remainingTourURIs = validTours
    .slice(MAX_TOURS_COUNT)
    .map((t) => t.pastThumbnail?.uri)
    .filter(nonNullable);

  const buyTour = (tourID: string) => {
    const tour = validTours.find((t) => t.id === tourID);

    if (tour) {
      const tourPrice = getTourPrice({
        tour,
        currencyCode,
        overrideCurrencyCode: false,
      });

      dispatch(
        addToCart({
          productID: tour.id,
          productSKU: ProductSKU.PLAYLIST,
          currencyCode: tourPrice.currencyCode,
          count: 1,
        })
      );
    }
  };

  return (
    <SectionContainer>
      {/* This will only be shown on Ancient World so this title is OK */}
      <h3>{t('Ancient World Tours')}</h3>

      <Grid cols={3} colsMedium={2} colsSmall={1} gap={20} gapSmall={8}>
        {displayedTours.map((tour) => (
          <TourWidget
            key={tour.id}
            tour={tour}
            buyTour={buyTour}
            RightButton={
              <RightButton internalReference={tour.internalReference} />
            }
          />
        ))}

        {remainingTourURIs.length > 0 && (
          <ShopMoreToursWidget thumbnailURIs={remainingTourURIs} />
        )}
      </Grid>
    </SectionContainer>
  );
};

interface RightButtonProps {
  internalReference: string;
}

const RightButton: React.FC<RightButtonProps> = ({ internalReference }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Button
      secondary
      width="100%"
      onClick={() => {
        captureButtonPress({
          buttonName: 'Trial Tour End Popup  - View a Tour Itinerary',
          page: window.location.pathname,
        });

        dispatch(
          setModalType({
            modalType: ModalType.TRIAL_TOUR_END_CTA_ITINERARY,
            modalData: { internalReference },
          })
        );
      }}
    >
      {t('Learn more')}
    </Button>
  );
};

const SectionContainer = styled.div`
  text-align: center;
  display: grid;
  grid-gap: 8px;

  h3 {
    text-transform: uppercase;
  }
`;
