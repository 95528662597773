import { AppSettingsState } from './../app/types';
import { FullTour } from './../../graphql/queries/GetFullTourQuery';
import {
  setViewpointIndex,
  setViewpointSwitchingState,
  setVpIndex,
  stopNarration,
} from './../viewer/actions';
import { SagaIterator } from 'redux-saga';
import { call, select, put, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPES, UpdateUserPositionAction } from './actions';
import {
  selectCurrentViewpointIndex,
  selectCurrentVpIndex,
  selectTour,
} from '../viewer/selectors';
import { tourSupportsViewpointProximitySensing } from '../../utils/tourSupportsViewpointProximitySensing';
import { selectAppSettings } from '../app/selectors';
import { getClosestStopViewpoints } from '../../utils/getClosestStopViewpoints';
import { isMobile, isTablet } from 'react-device-detect';

export const sagas = [locationSagas];

export function* locationSagas(): any {
  yield takeLatest(ACTION_TYPES.UPDATE_USER_POSITION, updateUserPositionSaga);
}

function* updateUserPositionSaga(
  action: UpdateUserPositionAction
): SagaIterator {
  const { currentLocation } = action.payload;

  if (!currentLocation) {
    return;
  }

  const tour: FullTour | null = yield select(selectTour);
  const currentViewpointIndex = yield select(selectCurrentViewpointIndex);
  const currentVpIndex = yield select(selectCurrentVpIndex);
  const currentStop = tour?.stops[currentViewpointIndex];
  const appSettings: AppSettingsState = yield select(selectAppSettings);
  const allVPs: any[] =
    tour?.stops?.reduce((accum, cur: any) => {
      if (cur.vps && cur.vps?.length > 0) {
        return accum.concat(cur.vps);
      }
      return accum;
    }, []) || [];

  if (tour && tourSupportsViewpointProximitySensing(tour) && currentStop) {
    const { stopIndex, viewpoint } = yield call(
      getClosestStopViewpoints,
      {
        lat: currentLocation.coords.latitude,
        lng: currentLocation.coords.longitude,
      },
      allVPs,
      appSettings.closestViewpointDetectionThresholdDistance,
      tour.stops
    );

    if (stopIndex > -1) {
      if (stopIndex === currentViewpointIndex) {
        const target = currentStop.projections
          .filter(
            (p) => (isMobile && !isTablet ? p?.mobile : !p?.mobile)
            // !p?.mobile
          )
          .findIndex((p) => p.originalName === viewpoint.internalReference);
        if (target > -1 && target !== currentVpIndex) {
          yield put(setViewpointSwitchingState({ isViewpointSwitching: true }));
          yield put(setVpIndex({ index: target }));
        }
      } else if (currentViewpointIndex !== stopIndex) {
        yield put(stopNarration());
        yield put(
          setViewpointIndex({
            index: stopIndex,
            projectionName: viewpoint.internalReference,
          })
        );
      }
    }
  }
}
