import { captureInSentry } from '../App/config/reporting/captureInSentry';
import { DEFAULT_APP_CONFIGURATION } from '../features/whitelabeling/branding/consts';
import { Asset } from '../types';
import { readRemoteFileAsText } from './readRemoteFileAsText';

type App = {
  id: string;
  appID: string;
  url: string | null;
  stylesheet: Asset | null;
  theme: Asset | null;
  settings: Asset | null;
};

export async function getAppConfig(app: App) {
  let theme = DEFAULT_APP_CONFIGURATION.theme;

  // theme is a JSON file
  try {
    const themeURI = app.theme?.uri;

    if (themeURI) {
      const themeText = await readRemoteFileAsText(themeURI);
      theme = JSON.parse(themeText);
    }
  } catch (error) {
    captureInSentry(error.message);
  }

  let settings = DEFAULT_APP_CONFIGURATION.settings;

  // settings is a JSON file
  try {
    const settingsURI = app.settings?.uri;

    if (settingsURI) {
      const settingsText = await readRemoteFileAsText(settingsURI);
      settings = JSON.parse(settingsText);
    }
  } catch (error) {
    captureInSentry(error.message);
  }

  const stylesheet =
    app.stylesheet?.uri || DEFAULT_APP_CONFIGURATION.stylesheet;

  return { theme, settings, stylesheet };
}
