import React, { useRef, useCallback, useEffect, useState } from 'react';
import { DeviceOrientationContext } from '../context/deviceOrientationContext';
import { useDeviceMotion } from '../hooks/useDeviceMotion';
import degreesToRadians from '../utils/degreesToRadians';
import { throttle } from '../utils/throttle';

// turn off the disable listening for testing
const ENABLE_ORIENTATION_LISTENING = true;

// For whatever reason, it seems the reading isn't stable
// for a few iterations of reading values. Needs to be tested though.
const READINGS_TILL_STABILITY = 20;

type Props = {
  children: React.ReactNode;
};

const DEFAULT_ORIENTATION = {
  alpha: 90,
  beta: 90,
  gamma: 0,
  absolute: false,
  webkitCompassHeading: 0,
  webkitCompassAccuracy: 10,
};

export const DeviceOrientationProvider = ({ children }: Props) => {
  const [reading, setReading] = useState<boolean>(false);
  const initialWebkitAlphaOffset = useRef<number | null>(null);
  const [deviceOrientation, setDeviceOrientation] = useState(
    DEFAULT_ORIENTATION
  );
  const [compassEnabled, setCompassEnabled] = useState<boolean>(reading);
  const count = useRef<number>(0);
  const deviceMotionSupport = useDeviceMotion();

  const setDeviceOrientationThrottled = useRef(throttle(setDeviceOrientation));

  const startListening = useCallback(() => {
    setReading(true);
  }, []);

  const pauseListening = useCallback(() => {
    setReading(false);
  }, []);

  const restartListening = useCallback(() => {
    setReading(false);
    setTimeout(() => {
      // Just want to make sure the two state updates are not batched.
      // Technically can cause race conditions, but in practice shouldn't be an issue.
      setReading(true);
    });
  }, []);

  useEffect(() => {
    let listeningAbs = false;
    let listeningNormal = false;

    const eventHandler = (event: DeviceOrientationEvent) => {
      const {
        alpha,
        beta,
        gamma,
        absolute,
        // @ts-expect-error
        webkitCompassHeading,
        // @ts-expect-error
        webkitCompassAccuracy,
      } = event;

      if (alpha === null || beta === null || gamma === null) {
        // no need to set anything because defaults are set
      } else {
        setDeviceOrientationThrottled.current({
          alpha,
          beta,
          gamma,
          absolute,
          webkitCompassHeading,
          webkitCompassAccuracy,
        });

        count.current++;

        // iOS only
        // We want to keep track of the initial offset for iOS because it maintains the offset for the session.
        // Android resets the offset every time
        if (
          initialWebkitAlphaOffset.current === null &&
          typeof webkitCompassHeading === 'number' &&
          // FIXME: hack
          webkitCompassHeading !== 0 &&
          count.current === READINGS_TILL_STABILITY
        ) {
          // The 90 degree offset is required on iOS.
          // On iOS, the camera starts facing West by default otherwise
          const initialWebkitCompassHeading = webkitCompassHeading + 90;

          initialWebkitAlphaOffset.current = degreesToRadians(
            initialWebkitCompassHeading
          );
        }

        // We want to delay enabling the compass this until we have a reading,
        // Otherwise the alpha offset used by the respective DeviceControl could be incorrect.
        if (count.current === READINGS_TILL_STABILITY) {
          setCompassEnabled(true);
        }
      }
    };

    const addListener = () => {
      if ('ondeviceorientationabsolute' in window) {
        window.addEventListener(
          'deviceorientationabsolute',
          eventHandler,
          true
        );

        listeningAbs = true;
      } else if ('ondeviceorientation' in window) {
        window.addEventListener('deviceorientation', eventHandler, true);

        listeningNormal = true;
      }
    };

    const removeListener = () => {
      if (listeningAbs) {
        window.removeEventListener(
          'deviceorientationabsolute',
          eventHandler,
          true
        );
      } else if (listeningNormal) {
        window.removeEventListener('deviceorientation', eventHandler, true);
      }

      // This is required for iOS to work.
      // This initial value needs to be recalculated everytime we toggle listening to orientation event.
      initialWebkitAlphaOffset.current = null;
      count.current = 0;
    };

    if (reading && ENABLE_ORIENTATION_LISTENING) {
      addListener();
    }
    // there is no reason to have an else here because
    // the effect callback will automatically clean up the listener.

    // clean up
    return () => {
      removeListener();
    };
  }, [reading]);

  // Turn compass off when reading is false
  useEffect(() => {
    // We only want to disable it in this effect.
    // Enabled is set when we have a stable reading.
    if (!reading) {
      setCompassEnabled(false);
    } else if (!ENABLE_ORIENTATION_LISTENING) {
      setCompassEnabled(true);
    }
  }, [reading]);

  return (
    <DeviceOrientationContext.Provider
      value={{
        startListening,
        pauseListening,
        deviceOrientation,
        compassEnabled,
        initialWebkitAlphaOffset: initialWebkitAlphaOffset.current,
        deviceMotionSupport,
        restartListening,
      }}
    >
      {children}
    </DeviceOrientationContext.Provider>
  );
};
