import React from 'react';
import { useSelector } from 'react-redux';
import styled from '../../style/styled';
import { COLORS } from '../../style/colors';
import {
  SCREEN_WIDTH_MAX,
  SCREEN_WIDTH_BREAK_SMALL,
  SCREEN_WIDTH_BREAK_MEDIUM,
  SCREEN_WIDTH_BREAK_EXTRA_SMALL,
} from '../../style/SIZES';
import { Subscribe } from '../../components/SubscribeManual';
import { ROUTES } from '../../Routes/routes';
import NavLinkWithCapture from '../../components/NavLinkWithCapture';
import LinkWithCapture from '../../components/LinkWithCapture';
import { useTranslation } from 'react-i18next';
import { env } from '../../App/config/env';
import { Copyright } from './Copyright';
import { selectVariant } from '../../store/googleOptimize/selectors';
import { selectWhitelabelAppSettings } from '../../store/app/selectors';
// import { selectRTL } from '../../store/app/selectors';

interface Props {
  hasMultipleTours?: boolean;
}

const FooterWhitelabeled: React.FC<Props> = ({ hasMultipleTours = false }) => {
  const { t } = useTranslation();
  const { footer } = useSelector(selectWhitelabelAppSettings);
  const variant = useSelector(selectVariant(env.GO_EXP_ID_FOR_LIBRARY_PAGE));
  // const rtl = useSelector(selectRTL);

  return (
    <Container id="footer">
      <Wrapper>
        {
          // FIXME: Support RTL
          footer.showSubscribeToNewsletter && (
            <SubscribeSection>
              <FooterTitle>{t('Sign up for our newsletter')}</FooterTitle>
              <Subscribe />
            </SubscribeSection>
          )
        }

        <FooterCols>
          <FooterSection>
            <FooterTitle>{t(footer.mainSectionTitle)}</FooterTitle>

            <FooterNav>
              <FooterNavLinks>
                {hasMultipleTours && (
                  <li>
                    <Label>
                      <LinkWithCapture
                        buttonName="ToursLibrary_Footer"
                        to={(function () {
                          if (variant === '0') {
                            return {
                              pathname: ROUTES.index,
                              hash: '#tours_section',
                            };
                          } else if (variant === '1') {
                            return ROUTES.library;
                          }
                          return ROUTES.index;
                        })()}
                      >
                        {t('Tours library')}
                      </LinkWithCapture>
                    </Label>
                  </li>
                )}

                {footer.links.map((i, idx) => (
                  <li key={idx}>
                    <Label>
                      <a href={i.link}>{t(i.title)}</a>
                    </Label>
                  </li>
                ))}

                <li>
                  <Label>
                    <a href="https://ancient-world.co">
                      {t('Powered by Ancient World')}
                    </a>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture buttonName="FAQ" to={ROUTES.faq}>
                      {t('FAQ')}
                    </NavLinkWithCapture>
                  </Label>
                </li>
              </FooterNavLinks>
            </FooterNav>
          </FooterSection>

          <FooterSection>
            <FooterTitle>{t('Policy and terms')}</FooterTitle>

            <FooterNav>
              <FooterNavLinks>
                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="PrivacyPolicy"
                      to={ROUTES.privacyPolicy}
                    >
                      {t('Privacy policy')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="TermsConditions"
                      to={ROUTES.termsConditions}
                    >
                      {t('General terms')}
                    </NavLinkWithCapture>
                  </Label>
                </li>

                <li>
                  <Label>
                    <NavLinkWithCapture
                      buttonName="RequestAccountDeletion"
                      to={ROUTES.deleteAccountRequest}
                    >
                      {t('Request account deletion')}
                    </NavLinkWithCapture>
                  </Label>
                </li>
              </FooterNavLinks>
            </FooterNav>
          </FooterSection>
        </FooterCols>

        <Copyright />
      </Wrapper>
    </Container>
  );
};

export default FooterWhitelabeled;

const SubscribeSection = styled.div`
  width: 400px;
  padding-bottom: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding-bottom: 16px;
    width: 100%;
  }
`;

const FooterTitle = styled.h4`
  margin-bottom: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-bottom: 12px;
  }
`;

const FooterNav = styled.nav`
  padding-bottom: 24px;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding-bottom: 16px;
  }
`;

const FooterNavLinks = styled.ul`
  list-style: outside none none;
  padding: 0px;
  margin: 0px;

  li {
    padding: 3px 0;
  }
`;

const FooterSection = styled.div`
  flex: 0 0 25%;
  max-width: 25%;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_MEDIUM}px) {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_EXTRA_SMALL}px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${SCREEN_WIDTH_MAX}px;
  margin: 0 auto;
  padding: 30px 10px;
`;

const FooterCols = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: ${COLORS.primaryFontColor};
`;

const Container = styled.footer`
  margin-top: 20px;
  background: ${COLORS.offwhite};
  width: 100%;
`;

const Label = styled.p`
  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    padding: 12px 0;
  }
`;
