import { LanguageCode } from './../../../graphql/globalTypes';
import { MarkerType } from './../types';
import { nonNullable } from '../../../utils/nonNullable';
import { getI18nFieldValue } from '../../i18n/getI18nFieldValue';
import { Asset, I18nInfoField, ViewpointLocation } from '../../../types';

interface Viewpoint {
  id: string;
  nameI18n: I18nInfoField;
  taglineI18n: I18nInfoField;
  location: ViewpointLocation | null;
  thumbnail: Asset | null;
}

export function getViewpointsMarkerData(
  viewpoints: Array<Viewpoint>,
  language: LanguageCode,
  currentViewpointID?: string
) {
  return viewpoints
    .map((vp, idx) => {
      const { location, nameI18n, taglineI18n, thumbnail, id } = vp;

      if (!location || !nameI18n) {
        return null;
      }

      const { longitude: lng, latitude: lat } = location.coordinates;

      const isCurrent = currentViewpointID === vp.id || false;

      return {
        position: { lng, lat },
        name: getI18nFieldValue(nameI18n, language),
        description: getI18nFieldValue(taglineI18n, language),
        thumbnailURL: thumbnail?.uri || '',
        id,
        type: MarkerType.VIEWPOINT,
        isCurrent,
        idx,
      };
    })
    .filter(nonNullable);
}
