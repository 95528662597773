import { DEFAULT_LANGUAGE } from '../../consts';
import { LanguageCode } from '../../graphql/globalTypes';
import { I18nInfoField } from '../../types';

// extract the value of a i18n field in a given language, and fallback to default language
export function getI18nFieldValue(
  field: I18nInfoField | null,
  languageCode: LanguageCode,
  fallback = ''
): string {
  let value;

  if (field && typeof field === 'object') {
    // @ts-ignore
    value = field[languageCode];
  }

  // attempt default language
  if (value === null && field !== null) {
    value = field[DEFAULT_LANGUAGE];
  }

  return typeof value === 'string' ? value : fallback;
}
