import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { selectLocationRequested } from '../../store/location/selectors';
import {
  updateUserPosition,
  setLocationRequested,
} from '../../store/location/actions';
import { env } from '../../App/config/env';
import { GPSKalmanFilter } from '../geolocation/gpsKalmanFilter';
import { usePrevious } from '../../hooks/usePrevious';
import { Stack } from '../../Layouts/primitives/Stack';
import { isMobileOnly } from 'react-device-detect';

const kalmanFilter = new GPSKalmanFilter();

// TODO: [LVR-3132] As a developer, I have figured out how to reliably resume location monitoring, so that I can disable location monitoring outside a tour to save power
export const CurrentLocationWatcher: React.FC = () => {
  const dispatch = useDispatch();
  const locationRequested = useSelector(selectLocationRequested);
  const locationRequestedPrevious = usePrevious(locationRequested);

  const watchID = useRef<number>(-1);

  const startWatchPosition = useCallback(() => {
    watchID.current = navigator.geolocation.watchPosition(
      // navigator.geolocation.watchPosition(
      (currentLocation) => {
        // denoise gps
        if (currentLocation && env.ENABLE_GPS_FILTERING_FOR_GOOGLE_MAPS) {
          const { coords, timestamp } = currentLocation;

          // denoise gps reading using a kalmanFilter (TODO: does not seem to work, see if the implementation is correct)
          const [longitude, latitude] = kalmanFilter.process(
            coords.latitude,
            coords.longitude,
            coords.accuracy,
            timestamp
          );

          // TODO: investigate why spreading doesn't work
          // update the currentLocation with the denoised lat,lng values
          const smoothedLocation: GeolocationPosition = {
            coords: {
              latitude,
              longitude,
              altitude: coords.altitude,
              accuracy: coords.accuracy,
              altitudeAccuracy: coords.altitudeAccuracy,
              speed: coords.speed,
              heading: coords.heading,
            },
            timestamp,
          };

          dispatch(updateUserPosition({ currentLocation: smoothedLocation }));
        } else if (
          // no denoising
          currentLocation &&
          !env.ENABLE_GPS_FILTERING_FOR_GOOGLE_MAPS
        ) {
          dispatch(updateUserPosition({ currentLocation }));
        }
      },
      (error) => {
        // show an error message only on mobile phones
        if (isMobileOnly && error.code === error.PERMISSION_DENIED) {
          toast.error(<LocationPermissionsDeniedMessage />, {
            autoClose: 3000,
            pauseOnHover: true,
            hideProgressBar: true,
          });

          dispatch(setLocationRequested({ requested: false }));
        }
      },
      {
        enableHighAccuracy: true,
        maximumAge: 2000,
      }
    );
  }, [dispatch]);

  const clearWatchPosition = useCallback(() => {
    kalmanFilter.reset();
    navigator.geolocation.clearWatch(watchID.current);
    watchID.current = -1;
  }, []);

  useEffect(() => {
    // dispatch(setLocationRequested({ requested: true }));
    const requestChanged = locationRequested !== locationRequestedPrevious;

    if (
      watchID.current === -1 &&
      requestChanged &&
      locationRequested &&
      navigator.geolocation
    ) {
      startWatchPosition();
    } else if (
      watchID.current !== -1 &&
      requestChanged &&
      !locationRequested &&
      navigator.geolocation
    ) {
      clearWatchPosition();
    }
  }, [
    locationRequested,
    locationRequestedPrevious,
    dispatch,
    startWatchPosition,
    clearWatchPosition,
  ]);

  useEffect(() => {
    function handleVisibilityChange() {
      if (
        document.visibilityState === 'visible' &&
        navigator.geolocation &&
        locationRequested
      ) {
        if (watchID.current !== -1) {
          clearWatchPosition();
        }

        startWatchPosition();
      } else if (
        document.visibilityState === 'hidden' &&
        watchID.current !== -1 &&
        navigator.geolocation
      ) {
        clearWatchPosition();
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [startWatchPosition, clearWatchPosition, locationRequested]);

  // clean up the watchID when unmounting
  useEffect(() => {
    return () => {
      if (watchID.current !== -1) {
        kalmanFilter.reset();
        navigator.geolocation.clearWatch(watchID.current);
      }
    };
  }, []);

  return null;
};

const LocationPermissionsDeniedMessage = () => {
  return (
    <Stack gutter={8}>
      <p>
        You have denied location permissions to{' '}
        {env.IS_STOCK
          ? 'Ancient World website'
          : 'Lithodomos Audio-Visual Guide'}{' '}
        in the past.
      </p>
      <p>
        Please allow location permissions from your browser settings to show
        your current location.
      </p>
    </Stack>
  );
};
