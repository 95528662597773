import { Location } from './../../types';
import { RootState } from '../redux/types';
import { selectViewer } from '../viewer/selectors';

export function selectCurrentLocation(state: RootState): Location | null {
  return state.location.currentLocation;
}

export function selectLocationMonitoring(state: RootState): boolean {
  return Boolean(state.location.currentLocation);
}

export function selectLocationRequested(state: RootState): boolean {
  return state.location.requested;
}

export function selectClosestViewpointIndex(state: RootState) {
  const location = selectCurrentLocation(state);
  const coords = location?.coords;

  if (!coords) {
    return { distance: Infinity, accuracy: Infinity, viewpointIndex: -1 };
  }

  const {
    closestViewpointIndex,
    closestViewpointDistance,
    closestViewpointDetectionMode,
  } = selectViewer(state);

  return {
    accuracy: coords.accuracy,
    distance: closestViewpointDistance,
    viewpointIndex: closestViewpointIndex,
    mode: closestViewpointDetectionMode,
  };
}

export function selectIsNearAViewpoint(state: RootState): number {
  const {
    detectClosestViewpoints,
    closetViewpointDetectionThresholdAccuracy,
  } = state.app.appSettings;

  if (!detectClosestViewpoints) {
    return -1;
  }

  const { viewpointIndex, accuracy } = selectClosestViewpointIndex(state);

  if (accuracy <= closetViewpointDetectionThresholdAccuracy) {
    return viewpointIndex;
  }

  return -1;
}
