export const DEFAULT_APP_CONFIGURATION = {
  stylesheet: '/fallback/styles.css',
  theme: {
    palette: {
      appHeader: {
        background: '#000',
        color: '#fff',
      },
      brand: {
        main: '#000',
        contrastColor: '#fff',
      },
      common: {
        black: '#000',
        white: '#fff',
      },
      highlight: {
        main: '#0066FF',
        contrastColor: '#fff',
      },
      primary: {
        main: '#000',
        contrastColor: '#fff',
      },
      secondary: {
        main: '#fff',
        contrastColor: '#000',
      },
    },
  },
  settings: {
    app: {
      holdUntil: '2020-02-01',
      holdingImgURL: '',
      holdingText: 'Ancient World',
      holdingSubText: 'Step into History',
      logoURL: '',
      logoWidth: 1,
      logoHeight: 1,
    },
    banner: {
      coverImageURL: '',
      title: 'Ancient World',
      subtitle: 'Step into History',
      height: '500px',
      maxHeight: '80vh',
    },
    logo: {
      url: 'https://gaia.lithodomos.com/a97edc848941857d052fce8ea94f2277.png',
      text: '',
    },
    text: {
      fallbackTitle: 'Ancient World Tour',
      fallbackSubtitle: 'Step Into History',
      startTourCaption: 'Start tour',
      continueTourCaption: 'Continue tour',
    },
    header: {
      showAppNameInHeader: true,
      showLanguages: true,
    },
    // currently not being used
    footer: {
      mainSectionTitle: 'Default',
      links: [],
      showSubscribeToNewsletter: true,
    },
    pageLayout: {
      displayDecorationBanner: true,
    },
    tourInfo: {
      cover: {
        useLogos: [],
        hideTitle: [],
      },
      displayIntroVideoSection: true,
      toSeeSection: {
        displayTimePeriodOfVPs: true,
      },
      lotsToDoSection: {
        displayTrailLength: true,
        displayStreetView: true,
        displayTimePeriod: true,
      },
      mapSection: {
        title: 'Where and When?',
        displayStartingVP: true,
      },
      displayFewLittleTipsSection: true,
    },
    myToursPage: {
      showGiftPurchases: true,
      showTourPlans: false,
    },
    features: {
      autoDownloadPurchasedTour: false,
      sendSurvey: false,
      multiCurrency: true,
    },
  },
};
