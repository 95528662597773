import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBeforeFirstRender } from '../../hooks/useBeforeFirstRender';
import {
  setGoogleOptimizeTimedOut,
  setVariant,
  setVariants,
} from '../../store/googleOptimize/actions';
import { env } from '../config/env';

interface Props {
  timeout?: number;
}

const EXPERIMENT_IDS = [env.GO_EXP_ID_FOR_LIBRARY_PAGE, env.GO_EXP_JUST_TEST];

// Talk to Google Optimize and get all the variants corresponding to the active experiments.
// Then, store it in redux state.
// This state is no persisted. Optimize script itself persists the variants.
const GoogleOptimizeExperimentsLoader: React.FC<Props> = ({
  timeout = 3000,
}) => {
  const dispatch = useDispatch();

  // Optimization
  // let's attempt to see if the script is already loaded and we can extract the experiments synchronously
  // so that there is no delay.
  useBeforeFirstRender(() => {
    // if optimize was loaded, it would be on the window object.
    // https://support.google.com/optimize/answer/9059383
    if (window.google_optimize) {
      const variants = EXPERIMENT_IDS.map((experimentID) => ({
        experimentID,
        variant: window.google_optimize.get(experimentID),
      }));

      dispatch(setVariants(variants));
    }
  });

  useEffect(() => {
    // Sets a timeout
    let optimizeTimedOut: ReturnType<typeof setTimeout>;

    // check if optimize is supported to exit early.
    // Google Analytics should be enabled for this to work.
    const isSupported =
      typeof window.gtag === 'function' || Array.isArray(window.dataLayer);

    console.log('GoogleOptimizeExperimentsLoader: supported:', isSupported);

    // Sets the variant returned by Optimize and clears the timeout check
    function callback(value: string, name: string) {
      optimizeTimedOut && clearTimeout(optimizeTimedOut);

      console.log('Experiment:', name, value);

      dispatch(setVariant({ experimentID: name, variant: value }));
    }

    // Cleans up the optimize callback if the request times out
    function removeCallback() {
      if (!isSupported) {
        return;
      }

      optimizeTimedOut && clearTimeout(optimizeTimedOut);

      // @ts-ignore
      gtag('event', 'optimize.callback', {
        callback,
        remove: true,
      });
    }

    if (isSupported) {
      if (timeout) {
        optimizeTimedOut = setTimeout(() => {
          // Clears the callback just in case this was a network timeout
          removeCallback();

          dispatch(setGoogleOptimizeTimedOut());
        }, timeout);
      }

      // Documented here:
      // https://support.google.com/optimize/answer/9059383?hl=en
      // when the experimentID isn't provided,
      // it will call the experiment one by one for each experiment available.
      // @ts-ignore
      gtag('event', 'optimize.callback', { callback });
    } else {
      dispatch(setGoogleOptimizeTimedOut());
    }

    return removeCallback;
  }, [dispatch, timeout]);

  return null;
};

let scriptLoadError = false;

function gtag() {
  if (scriptLoadError) {
    return;
  }

  if (typeof window.gtag === 'function') {
    // eslint-disable-next-line prefer-rest-params
    window.gtag.apply(null, arguments as any);
  } else if (Array.isArray(window.dataLayer)) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  } else {
    scriptLoadError = true;

    console.warn(
      'Google Tag Manager was not found on your site. Neither "gtag()" or "dataLayer[]" could be located on the "window". If you are not using Google Tag Manager in dev you can ignore this warning. Otherwise, see the Google Tag Manager dev guide for examples: https://developers.google.com/tag-manager/devguide'
    );
  }
}

export default GoogleOptimizeExperimentsLoader;
