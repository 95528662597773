import React from 'react';
import styled, { useTheme } from '../../style/styled';
import Icon from 'mdi-react/LoginIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import { captureButtonPress } from '../../features/analytics';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText as _AppHeaderButtonText,
} from './styledComponents';
import { useTranslation } from 'react-i18next';
import { selectRTL } from '../../store/app/selectors';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const SignInLink: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const rtl = useSelector(selectRTL);
  const { t } = useTranslation();
  const theme = useTheme();

  const color = theme.palette.highlight.contrastColor;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'LogIn',
    });

    dispatch(
      setModalType({
        modalType: ModalType.AUTH_LOGIN,
        modalData: {
          redirectedHref: window.location.href,
          originalHref: window.location.href,
          reason: null,
        },
      })
    );
  };

  return (
    <Container fullWidth={fullWidth} onClick={handleClick}>
      <div>
        <Icon color={color} size={size * 1.4} />
      </div>
      <AppHeaderButtonText size={size} color={color} rtl={rtl}>
        {t('Log in / Sign up')}
      </AppHeaderButtonText>
    </Container>
  );
};

const Container = styled(AppHeaderButtonContainer)`
  background: ${(props) => props.theme.palette.highlight.main};
  padding: 4px 8px;
`;

const AppHeaderButtonText = styled(_AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
