import { AssetFragment } from '../../../graphql/fragments/__generated__/AssetFragment';

export function findAllAssets(obj: any): AssetFragment[] {
  let list: AssetFragment[] = [];

  if (!obj) {
    return list;
  }

  if (obj instanceof Array) {
    for (const i in obj) {
      list = list.concat(findAllAssets(obj[i]));
    }

    return list;
  }

  if (typeof obj == 'object' && obj.__typename === 'Asset') {
    list = list.concat(obj);
  } else if (typeof obj == 'object' && obj !== null) {
    const children = Object.keys(obj);

    if (children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        list = list.concat(findAllAssets(obj[children[i]]));
      }
    }
  }

  return list;
}
