import React from 'react';
import { LoadingView } from '../../../components/LoadingView';

const MobileDebugLazy = React.lazy(() => import('./MobileDebugLazy'));

// Console for mobile
export const MobileDebug: React.FC = () => {
  return (
    <React.Suspense fallback={<LoadingView />}>
      <MobileDebugLazy />
    </React.Suspense>
  );
};
