import React from 'react';
import styled from '../../../style/styled';

const ItemContainer = styled.div`
  padding: 4px 4px 8px 4px;
  cursor: pointer;
  border-bottom: 1px solid #333;
  margin: 4px 4px 0 4px;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  img {
    margin-right: 8px;
  }
`;

interface Props {
  onClick: () => void;
  caption: string;
  uri?: string;
}

export const LanguageSelectorMenuItem = ({ caption, onClick, uri }: Props) => {
  return (
    <ItemContainer onClick={onClick}>
      {uri ? <img src={uri} alt="" width="24px" height="18px" /> : null}
      <p>{caption}</p>
    </ItemContainer>
  );
};
