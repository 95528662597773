import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { captureEvent, DataKeys, EventNames } from '../features/analytics';
import { SUPPORTED_CURRENCIES } from '../features/multiCurrency/consts';
import { CurrencyCode } from '../graphql/globalTypes';
import { setCurrencyCode } from '../store/app/actions';
import { selectCurrencyCode } from '../store/app/selectors';
import { CurrencySetMode } from '../store/app/types';
import styled from '../style/styled';

interface Props {
  style?: React.CSSProperties;
}

export const CurrencyCodeSelector: React.FC<Props> = ({ style = {} }) => {
  const selectedCurrencyCode = useSelector(selectCurrencyCode);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleChange = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const selectedCurrencyCode = event.currentTarget.dataset
      .currency as CurrencyCode;

    if (selectedCurrencyCode) {
      captureEvent({
        name: EventNames.CURRENCY_CHANGED,
        data: [
          {
            key: DataKeys.CURRENCY,
            value: selectedCurrencyCode,
          },
        ],
      });

      dispatch(
        setCurrencyCode({
          currencyCode: selectedCurrencyCode,
          currencyCodeSetMode: CurrencySetMode.USER,
        })
      );
    }
  };

  const selectedEntry = SUPPORTED_CURRENCIES.find(
    (cur) => cur.value === selectedCurrencyCode
  );

  if (!selectedEntry) {
    return null;
  }

  const data = SUPPORTED_CURRENCIES.map(({ symbol, label, value }) => ({
    value,
    label: `${label} (${symbol})`,
  }));

  return (
    <Container
      role="button"
      onClick={() => setShowMenu(!showMenu)}
      style={style}
    >
      <TextContainer>
        <ChevronDownIcon />
        <Text>{selectedEntry.symbol}</Text>
      </TextContainer>

      {showMenu && (
        <MenuContainer>
          <OutsideClickHandler onOutsideClick={() => setShowMenu(false)}>
            <Menu>
              {data.map((currency) => (
                <MenuItem
                  key={currency.value}
                  data-currency={currency.value}
                  onClick={handleChange}
                >
                  {currency.label}
                </MenuItem>
              ))}
            </Menu>
          </OutsideClickHandler>
        </MenuContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 8px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 5px;
  border: 1px solid #ccc;
  background: #eee;

  &:hover {
    font-weight: bold;
  }
`;

const Text = styled.p`
  text-decoration: underline;
  margin-left: 4px;
`;

const MenuContainer = styled.div`
  margin-top: 4px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: 0px 0 3px 1px #00000082;
  z-index: 999;
  /* max-height: 100px;
  overflow-y: auto; */
  white-space: nowrap;
`;

const Menu = styled.ul`
  list-style: none;
`;

const MenuItem = styled.li`
  padding: 12px;
  border-bottom: 1px solid #eee;

  &:hover {
    background: #eee;
  }

  &:last-child {
    border-bottom: none;
  }
`;
