import { useDispatch, useSelector } from 'react-redux';
import { selectLocationMonitoring } from '../../../store/location/selectors';
import { selectWhitelabelAppSettings } from '../../../store/app/selectors';
import { setLocationRequested } from '../../../store/location/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const RequestLocationMonitoring = () => {
  const dispatch = useDispatch();
  const locationMonitoring = useSelector(selectLocationMonitoring);
  const whitelabelAppSettings = useSelector(selectWhitelabelAppSettings);

  useDeepCompareEffect(() => {
    if (whitelabelAppSettings && !locationMonitoring) {
      dispatch(setLocationRequested({ requested: true }));
    }
  }, [whitelabelAppSettings, locationMonitoring, dispatch]);

  return null;
};
