import React from 'react';
import styled from '../../../../style/styled';
import Icon from 'mdi-react/AlertIcon';
import { COLORS } from '../../../../style/colors';

export const TourWidgetTestTourIndicator: React.FC = () => {
  return (
    <Container
      // no i18n because this is only shown on test sites
      title={
        'This is an unpublished tour. It will only be available on a test site.'
      }
    >
      <Icon color={COLORS.errorColor} size={18} />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 4;
  cursor: pointer;
`;
