import { isMobile } from 'react-device-detect';
import { useEffect, useRef, useState } from 'react';
import { DeviceMotion } from '../types/DeviceMotion';

export const useDeviceMotion = () => {
  const _deviceMotionSupport = useRef<DeviceMotion>(DeviceMotion.UNSUPPORTED);
  const [deviceMotionSupport, setDeviceMotionSupport] = useState<DeviceMotion>(
    DeviceMotion.UNSUPPORTED
  );

  // Effect to detect if orientation is supported
  useEffect(() => {
    let isListeningToDeviceMotion = false;

    const deviceMotionListener = (event: DeviceMotionEvent) => {
      if (
        typeof event.rotationRate?.alpha === 'number' ||
        typeof event.rotationRate?.beta === 'number' ||
        typeof event.rotationRate?.gamma === 'number'
      ) {
        _deviceMotionSupport.current = DeviceMotion.ENABLED;
        setDeviceMotionSupport(_deviceMotionSupport.current);
      }

      // remove the event listener because it has already served its purpose
      window.removeEventListener('devicemotion', deviceMotionListener);
    };

    // start listening if DeviceMotionEvent is defined on window
    if (isMobile && window.DeviceMotionEvent !== undefined) {
      _deviceMotionSupport.current = DeviceMotion.SUPPORTED;
      setDeviceMotionSupport(_deviceMotionSupport.current);

      window.addEventListener('devicemotion', deviceMotionListener);

      isListeningToDeviceMotion = true;
    }

    return () => {
      if (isListeningToDeviceMotion === true) {
        window.removeEventListener('devicemotion', deviceMotionListener);
      }
    };
  }, []);

  return deviceMotionSupport;
};
