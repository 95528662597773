import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { captureButtonPress } from '../../../../features/analytics';
import { GET_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL } from '../../../../graphql/queries/GetTourByInternalReference';
import { ROUTES } from '../../../../Routes/routes';
import { runGraphQLQuery } from '../../../../utils/runGraphQLQuery';
import { Button } from '../../../Button';

interface Props {
  tourID: string;
  internalReference: string;
}

export const TourWidgetItineraryButtonForPage: React.FC<Props> = ({
  tourID,
  internalReference,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    captureButtonPress({
      buttonName: 'Itinerary',
      tourID,
      page: window.location.pathname,
    });
  };

  return (
    <Link
      to={{
        pathname: ROUTES.itinerary.replace(
          ':internalReference',
          internalReference
        ),
        state: { from: window.location.pathname },
      }}
    >
      <Button
        secondary
        width="100%"
        onClick={handleClick}
        onMouseEnter={() => {
          // Optimisation
          runGraphQLQuery(GET_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL, {
            input: {
              internalReference,
            },
          });
        }}
      >
        {t('Learn more')}
      </Button>
    </Link>
  );
};
