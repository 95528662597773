export type ThrottleFunction = (...args: any[]) => void;

export function throttle(
  func: ThrottleFunction,
  throttleForMs = 1000
): ThrottleFunction {
  let isThrottled = false;

  return function (...args: any[]) {
    if (!isThrottled) {
      // @ts-expect-error this is a function
      func.apply(this, args);
      isThrottled = true;

      setTimeout(() => {
        isThrottled = false;
      }, throttleForMs);
    }
  };
}
