import { gql } from '@apollo/client';

export const I18NSTRING = gql`
  fragment I18nStringFragment on I18nString {
    ar
    de_DE
    el_GR
    en_US
    es_ES
    fr_FR
    he_IL
    hi_IN
    it_IT
    ja_JP
    pt_PT
    ru_RU
    zh
  }
`;
