import { useTranslation } from 'react-i18next';
import React from 'react';
import Icon from 'mdi-react/RedeemIcon';
import styled, { useTheme } from '../../style/styled';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText as _AppHeaderButtonText,
} from './styledComponents';
import { captureButtonPress } from '../../features/analytics';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import { useDispatch } from 'react-redux';
import { setModalType } from '../../store/modal/actions';
import { ModalType } from '../../store/modal/types';

interface Props {
  color?: string;
  size?: number;
  fullWidth?: boolean;
}

export const ActivateProduct: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;
  const fullWidth = props.fullWidth || false;

  const handleButtonClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'ActivateProduct',
    });

    dispatch(
      setModalType({
        modalType: ModalType.ACTIVATE_PRODUCT_MODAL,
        modalData: { code: null },
      })
    );
  };

  return (
    <AppHeaderButtonContainer fullWidth={fullWidth} onClick={handleButtonClick}>
      <div>
        <Icon color={color} size={size * 1.4} />
      </div>
      <AppHeaderButtonText size={size} color={color}>
        {t('Redeem')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};

const AppHeaderButtonText = styled(_AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
