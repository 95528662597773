import React from 'react';
import styled from '../../../../../style/styled';
import { ScrollPosition } from 'react-lazy-load-image-component';
import { SCREEN_WIDTH_BREAK_SMALL } from '../../../../../style/SIZES';
import { Grid } from '../../../../../Layouts/primitives/Grid';
import {
  ContentSectionWidgetContainer,
  ContentSectionWidgetImageAspectRatioBox,
  ContentSectionWidgetImageAspectRatioBoxContent,
  ContentSectionWidgetImageAspectRatioBoxContentWrapper,
  ContentSectionWidgetTitle,
} from '../../../styledComponents';
import { TourWidgetOfflineStatusIndicator } from '../TourWidgetOfflineStatusIndicator';
import { isMobile } from 'react-device-detect';
import { TourWidgetPrice } from '../TourWidgetPrice';
import { TourWidgetTestTourIndicator } from '../TourWidgetTestTourIndicator';
import { COLORS } from '../../../../../style/colors';
import { TourWidgetPastThumbnail } from '../TourWidgetPastThumbnail';
import { TourWidgetPresentThumbnail } from '../TourWidgetPresentThumbnail';
// import { unlock } from '../../../features/audio/howlerSetup';
import { useTranslation } from 'react-i18next';
import { CurrencyCode } from '../../../../../graphql/globalTypes';
import { TourWidgetContinueTourButton } from '../TourWidgetContinueTourButton';
import { TourWidgetGoToGateButton } from '../TourWidgetGoToGateButton';
import { TourWidgetBookNowButton } from '../TourWidgetBookNowButton';
import { TourWidgetDefaultCheckInButton } from '../TourWidgetDefaultCheckInButton';
import { TourWidgetItineraryButtonConditional } from '../TourWidgetItineraryButtonConditional';

type Props = {
  buyTour: (tourID: string) => void;
  downloadable?: boolean;
  expiresIn?: string | null;
  expiresSoon?: boolean;
  id: string;
  interactiveImageURI?: string;
  internalReference: string;
  isInCart: boolean;
  LeftButton?: React.ReactNode;
  nonInteractiveImageURI?: string;
  opensItineraryPage?: boolean;
  pinned?: boolean;
  priceDisplayed?: boolean;
  currencyCode?: CurrencyCode;
  priceInCents?: boolean;
  unitPrice?: number;
  discountRate?: number;
  published?: boolean;
  purchased: boolean;
  RightButton?: React.ReactNode;
  scrollPosition?: ScrollPosition;
  tourName: string;
};

export const TourWidgetView = ({
  buyTour,
  discountRate,
  downloadable,
  expiresIn,
  expiresSoon,
  id,
  interactiveImageURI,
  internalReference,
  isInCart,
  LeftButton,
  nonInteractiveImageURI,
  opensItineraryPage,
  pinned,
  currencyCode,
  priceDisplayed,
  priceInCents,
  published,
  purchased,
  RightButton,
  scrollPosition,
  tourName,
  unitPrice,
}: Props) => {
  const { t } = useTranslation();

  let leftButton: React.ReactNode = null;

  if (LeftButton) {
    leftButton = LeftButton;
  } else if (purchased && pinned) {
    leftButton = <TourWidgetContinueTourButton tourID={id} />;
  } else if (purchased && !pinned) {
    leftButton = <TourWidgetGoToGateButton tourID={id} />;
  } else if (!purchased && !isInCart) {
    leftButton = (
      <TourWidgetBookNowButton tourID={id} buy={() => buyTour(id)} />
    );
  } else {
    leftButton = <TourWidgetDefaultCheckInButton tourID={id} />;
  }

  const rightButton = RightButton || (
    <TourWidgetItineraryButtonConditional
      tourID={id}
      internalReference={internalReference}
      opensItineraryPage={opensItineraryPage}
    />
  );

  return (
    <ContentSectionWidgetContainer interactive={!purchased}>
      <ContentSectionWidgetImageAspectRatioBox>
        <ContentSectionWidgetImageAspectRatioBoxContentWrapper>
          <ContentSectionWidgetImageAspectRatioBoxContent>
            <ContentSectionWidgetTitle>{tourName}</ContentSectionWidgetTitle>

            {/* Price indicator */}
            {priceDisplayed && currencyCode && (
              <TourWidgetPrice
                currencyCode={currencyCode}
                price={unitPrice || 0}
                priceInCents={Boolean(priceInCents)}
                discountRate={discountRate || 0}
              />
            )}

            {/* Test tour indicator */}
            {published === false && <TourWidgetTestTourIndicator />}

            {/* Only visible on desktop */}
            {!isMobile && nonInteractiveImageURI && (
              <TourWidgetPresentThumbnail
                scrollPosition={scrollPosition}
                imageURI={nonInteractiveImageURI}
              />
            )}

            {/* This image moves to left when hovered */}
            {interactiveImageURI && (
              <TourWidgetPastThumbnail
                scrollPosition={scrollPosition}
                imageURI={interactiveImageURI}
              />
            )}

            {/* Download button */}
            {downloadable && (
              <TourWidgetOfflineStatusIndicator
                playlistID={id}
                playlistName={tourName}
              />
            )}

            {/* {tour.pinned && <TourWidgetPinnedIndicator />} */}
          </ContentSectionWidgetImageAspectRatioBoxContent>
        </ContentSectionWidgetImageAspectRatioBoxContentWrapper>
      </ContentSectionWidgetImageAspectRatioBox>

      {/* Left and Right buttons */}
      <Grid gap={4} cols={2} colsMedium={2} marginTop={4}>
        {leftButton}

        {rightButton}
      </Grid>

      {expiresIn && (
        <TourWidgetExpiresAtContainer expiresSoon={expiresSoon}>
          <span
          // onClick={(e) => {
          //   e.stopPropagation();
          // }}
          >
            {t('Expires in')} {expiresIn}
          </span>

          {expiresSoon && (
            <ExpiryMessageContainer>
              {t(
                'Once a purchased tour expires, you will need to purchase it again.'
              )}
            </ExpiryMessageContainer>
          )}
        </TourWidgetExpiresAtContainer>
      )}
    </ContentSectionWidgetContainer>
  );
};

const TourWidgetExpiresAtContainer = styled.div<{ expiresSoon?: boolean }>`
  text-align: left;
  margin-top: 4px;
  color: ${({ expiresSoon }) => (expiresSoon ? COLORS.errorColor : 'inherit')};
  font-weight: ${({ expiresSoon }) => (expiresSoon ? 'bold' : 'auto')};
  position: relative;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    margin-top: 0;
  }
`;

const ExpiryMessageContainer = styled.div`
  margin-top: 4px;
  position: absolute;
  background: #eee;
  max-width: 300px;
  padding: 8px;
  border-radius: 4px;
  z-index: 4;
  display: none;
  border: 1px solid #aaa;
  color: ${(props) => props.theme.palette.common.white};
  font-weight: normal;
  font-size: 12px;

  ${TourWidgetExpiresAtContainer}:hover & {
    display: inherit;
  }
`;
