import { Howl } from 'howler';
import {
  updateNarrationLength,
  setAudioStatePartial,
  updateNarrationSeekPos,
} from '../../store/viewer/actions';
import { setUnlocked } from './howlerSetup';

interface Sound {
  url: string;
}

interface PlayNarrationArgs {
  sound: Sound;
  length: number;
  play?: boolean;
  loop?: boolean;
  html5?: boolean;
}

type SeekArgs = {
  value: number;
};

interface SoundInstance {
  id?: number;
  api: Howl;
  sound: Sound;
}

type Dispatcher = (payload: any) => any;

type Options = {
  getDispatch: (() => Dispatcher) | (() => any) | null;
};

export class AudioPlayer {
  private _currentSound: SoundInstance | null = null;
  private _queuedSound: Sound | null = null;
  private _getDispatch: (() => Dispatcher) | (() => any) | null;
  private _timerID = -1;
  private _systemPaused = false;

  constructor({ getDispatch }: Options) {
    this._getDispatch = getDispatch;
  }

  get dispatch(): Dispatcher {
    let dispatcher;

    if (this._getDispatch) {
      dispatcher = this._getDispatch();
    } else {
      dispatcher = () => {};
    }

    return dispatcher;
  }

  isPlaying = () => {
    if (this._currentSound) {
      return this._currentSound.api.playing();
    }

    return false;
  };

  isPaused = () => {
    if (this._currentSound) {
      return this._currentSound.api.playing();
    }

    return false;
  };

  playNarration = ({ sound, play, loop, length, html5 }: PlayNarrationArgs) => {
    // if there is already a track set
    if (this._currentSound) {
      // if the sound file is the same, continue playing
      if (this._currentSound.sound.url === sound.url) {
        // if we are to play it, then play
        if (play && this._currentSound.id === undefined) {
          this._currentSound.id = this._currentSound.api.play();
        } else if (play && !this.isPlaying()) {
          this._currentSound.api.play(this._currentSound.id);
        }

        return;
      }

      // queue the new sound
      this._queuedSound = sound;

      const currentSound = this._currentSound;

      this._currentSound = null;

      if (currentSound && currentSound.api.playing()) {
        // we will fade out the current track
        // fade out the current sound
        this.fadeOutSound(currentSound).then(() => {
          if (this._queuedSound) {
            // play the queued sound
            const queued = this._queuedSound;
            this._queuedSound = null;
            this.playNewSound({ sound: queued, length, play, loop, html5 });
          }
        });
      } else {
        currentSound.api.unload();

        if (this._queuedSound) {
          const queued = this._queuedSound;
          this._queuedSound = null;
          this.playNewSound({ sound: queued, length, play, loop, html5 });
        }
      }
    } else if (this._queuedSound) {
      // update the queue sound.
      // this could happen only because of the fade.
      // while fading out current track, the queued sound could have changed
      // so we want to play the latest one.
      // however, I think this flow should be rethought, because
      // the other arguments do not get applied, so ideally they need to be
      // stored in instance variables and read back from them.
      this._queuedSound = sound;
    } else {
      this.playNewSound({ sound, play, loop, length, html5 });
    }
  };

  playNewSound = ({
    sound,
    play = true,
    length,
    loop = false,
    html5 = true,
  }: PlayNarrationArgs) => {
    this.dispatch(
      setAudioStatePartial({
        seekPos: 0,
        length,
        error: null,
        loading: true,
        ready: false,
        playing: false,
        started: false,
        completed: false,
      })
    );

    // fresh track's case
    const api = new Howl({
      src: [sound.url],
      volume: 1,
      loop,
      // https://lithodomosvr.atlassian.net/wiki/spaces/LITHODOMOS/pages/1482653799/Audio
      html5,
    });

    api.on('end', () => {
      this.dispatch(
        setAudioStatePartial({
          playing: false,
          completed: true,
          started: false,
          seekPos: 0,
        })
      );
    });

    api.on('loaderror', () => {
      api?.off();
      api?.unload();
    });

    api.on('load', () => {
      // set the length of the track. this can only be determined after loading the track
      let duration = api.duration();

      if (duration === Infinity) {
        duration = length;
      }

      this.dispatch(
        setAudioStatePartial({
          loading: false,
          length: Math.floor(duration),
          ready: true,
        })
      );
    });

    api.on('unlock', () => {
      setUnlocked();

      this.dispatch(
        setAudioStatePartial({
          unlocked: true,
        })
      );
    });

    api.on('pause', this.dispatchPausedAction);

    api.on('play', () => {
      this.dispatch(
        setAudioStatePartial({
          loading: false,
          started: true,
          playing: true,
          pausedByUser: false,
          pausedByTheSystem: false,
          completed: false,
        })
      );

      clearTimeout(this._timerID);

      this._timerID = window.setTimeout(() => {
        this.step();
      }, 500);
    });

    api.on('playerror', (soundId: number, error: unknown) => {
      // Playback was unable to start. This is most commonly an issue on mobile devices and Chrome where playback was not within a user interaction.
      api?.off();
      api?.unload();
      this.dispatch(
        setAudioStatePartial({
          error,
        })
      );
    });

    // store the current sound with a handle to its howler instance
    this._currentSound = { api, sound };

    if (play) {
      this._currentSound.id = api.play();
    }
  };

  dispatchPausedAction = () => {
    if (this._systemPaused) {
      this.dispatch(
        setAudioStatePartial({
          playing: false,
          pausedByTheSystem: true,
        })
      );
    } else {
      this.dispatch(
        setAudioStatePartial({
          playing: false,
          pausedByUser: true,
        })
      );
    }
  };

  fadeOutSound = (currentSound: SoundInstance) => {
    return new Promise<void>((resolve) => {
      if (!currentSound) {
        resolve();
      }

      const { api } = currentSound;

      // call this fn after the fade
      api.once('fade', () => {
        api.off();
        api.stop();
        api.unload();
        resolve();
      });

      api.fade(api.volume(), 0, 500);
    });
  };

  pauseNarration = (bySystem = false) => {
    if (this._currentSound) {
      this._systemPaused = bySystem;

      const { api, id } = this._currentSound;

      api.pause(id);
    }
  };

  resumeNarration = () => {
    if (this._currentSound) {
      const { api, id } = this._currentSound;

      if (id) {
        api.play(id);
      } else {
        this._currentSound.id = api.play();
      }
    }
  };

  restartNarration = () => {
    if (!this._currentSound) {
      return;
    }

    const { api, id } = this._currentSound;

    if (id) {
      api.play(id);
    } else {
      this._currentSound.id = api.play();
    }
  };

  stop = () => {
    this._queuedSound = null;

    const current = this._currentSound;
    this._currentSound = null;

    clearTimeout(this._timerID);

    if (!current) {
      return;
    }

    // remove any events currently being listened to
    current.api.off();

    if (current.api.playing()) {
      this.fadeOutSound(current);
    } else {
      current.api.unload();
    }

    this.dispatch(updateNarrationLength({ seconds: 0 }));
  };

  step = () => {
    if (!this._currentSound) {
      return;
    }

    const seekPos = this._currentSound.api.seek();

    if (typeof seekPos === 'number') {
      this.dispatch(updateNarrationSeekPos({ seek: seekPos }));
    }

    const isPlaying = this._currentSound.api.playing();

    // While playing, poll for the current position and update the narrationSeekPos in state.
    if (isPlaying) {
      // continue stepping

      // we'd want a throttled this.step here
      window.setTimeout(() => {
        this.step();
      }, 500);

      // this is too aggressive
      // requestAnimationFrame(this.step);
    }
  };

  seek = ({ value }: SeekArgs) => {
    if (!this._currentSound) {
      return;
    }

    this._currentSound.api.seek(value);

    // this.dispatch(updateNarrationSeekPos({ seek: value }));
  };
}
