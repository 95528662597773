import { GeoJSONType } from '../graphql/globalTypes';
import { EXPLORE_WEB_GetFullTour_result_stops } from '../graphql/queries/__generated__/EXPLORE_WEB_GetFullTour';
import { ClosestViewpointDetectionMode } from '../store/location/types';
import { getDistanceInMetersBetweenTwoCoords } from './getDistanceInMetersBetweenTwoCoords';
import { isMobile, isTablet } from 'react-device-detect';

type Coordinate = {
  lat: number;
  lng: number;
};

type BaseViewpoint = {
  id: string;
  internalReference: string;
  location: {
    type: GeoJSONType;
    coordinates: {
      longitude: number;
      latitude: number;
    };
  } | null;
};

// const GeofencesMap = new Map();

// Assumption: geojson geofences do not overlap,
// so if we find one within a geojson geofence, we pick it as the closest vp.

export function getClosestStopViewpoints<T extends BaseViewpoint>(
  ref: Coordinate,
  viewpoints: T[],
  distanceThreshold: number,
  stops: Array<EXPLORE_WEB_GetFullTour_result_stops>
): {
  viewpoint: T | null;
  distance: number;
  mode: ClosestViewpointDetectionMode;
  stopIndex: number;
} {
  let closestViewpoint: T | null = null;
  let closestStop = -1;
  let closestDistance = Infinity;
  const mode = ClosestViewpointDetectionMode.RADIUS;
  let threshold = distanceThreshold;

  for (let i = 0; i < viewpoints.length; i++) {
    const vp = viewpoints[i];

    if (vp.location) {
      const { latitude, longitude } = vp.location.coordinates;

      const distance = getDistanceInMetersBetweenTwoCoords(ref, {
        lat: latitude,
        lng: longitude,
      });

      threshold = distanceThreshold;

      if (distance < closestDistance && distance < threshold) {
        closestDistance = distance;
        closestViewpoint = vp;
        closestStop = stops.findIndex((stop) => {
          const projectionNames = stop.projections
            .filter((projection) =>
              isMobile && !isTablet ? projection?.mobile : !projection?.mobile
            )
            .map((s) => s.originalName);
          return projectionNames.includes(vp.internalReference);
        });
      }
    }
  }

  return {
    viewpoint: closestViewpoint,
    distance: closestDistance,
    mode,
    stopIndex: closestStop,
  };
}
