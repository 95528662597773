import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ShareIcon from 'mdi-react/ShareVariantIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import styled from '../../../../style/styled';
import { ROUTES } from '../../../../Routes/routes';
import { IconButton } from '../../../../components/IconButton';
interface ShareProps {
  tourName: string;
  tourInternalReference: string;
}

// TODO: [AW-738] use webshare API if available https://web.dev/web-share/
export const Share: React.FC<ShareProps> = React.memo(
  ({ tourName, tourInternalReference }) => {
    const { t } = useTranslation();

    const url = `${window.location.href}${ROUTES.itinerary}`.replace(
      ':internalReference',
      tourInternalReference
    );

    return (
      <ShareContainer>
        <h3>{t('Share')}</h3>

        <ShareButtonsContainer>
          <FacebookShareButton url={url} quote={tourName}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton url={url} title={tourName}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <IconButton
            iconSize={32}
            iconBgColor="#000"
            Icon={ShareIcon}
            onClick={() => {
              if (
                navigator.clipboard &&
                typeof navigator.clipboard.writeText === 'function'
              ) {
                navigator.clipboard
                  .writeText(url)
                  .then(() => {
                    const successMessage = t('Link copied to clipboard');

                    toast(successMessage, {
                      autoClose: 3000,
                      pauseOnHover: true,
                      hideProgressBar: true,
                      toastId: successMessage,
                    });
                  })
                  .catch(() => {
                    const errorMessage = t('Error copying Link to clipboard');

                    toast.error(errorMessage, {
                      autoClose: 3000,
                      pauseOnHover: true,
                      hideProgressBar: true,
                      toastId: errorMessage,
                    });
                  });
              }

              // TODO: support browsers that do not support clipboard
            }}
          />

          <a
            href={`mailto:?subject=${tourName}&body=${url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              iconBgColor="#000"
              iconSize={32}
              Icon={EmailIcon}
              onClick={() => {}}
            />
          </a>
        </ShareButtonsContainer>
      </ShareContainer>
    );
  }
);

const ShareContainer = styled.div`
  grid-column: 1/2;
  text-align: center;
  display: grid;
  grid-gap: 8px;
  width: 100%;

  h3 {
    text-transform: uppercase;
  }
`;

const ShareButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  margin: 0 auto;
`;
