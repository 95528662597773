import queryString from 'query-string';

// remove the specified query variable from query string
export function removeQueryVariable(variable: string, url = false): string {
  const parsed = queryString.parse(window.location.search);

  delete parsed[variable];

  // return it combined with the current pathname
  if (url) {
    return queryString.stringifyUrl({
      url: window.location.toString().replace(window.location.search, ''),
      query: parsed,
    });
  }

  // return just the modified query string
  return queryString.stringify(parsed);
}
