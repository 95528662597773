import { Location } from './../../types';

// location state is not persisted. it is filtered out from transforms.ts
export type LocationState = {
  currentLocation: Location | null;
  requested: boolean;
};

export const initialState: LocationState = {
  currentLocation: null,
  requested: false,
};

export enum ClosestViewpointDetectionMode {
  // eslint-disable-next-line no-unused-vars
  RADIUS = 'Radius',
  // eslint-disable-next-line no-unused-vars
  GEOFENCE = 'Geofence',
}

export enum ClosestStopDetectionMode {
  // eslint-disable-next-line no-unused-vars
  RADIUS = 'Radius',
  // eslint-disable-next-line no-unused-vars
  GEOFENCE = 'Geofence',
}
