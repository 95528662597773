import React from 'react';
import CartIcon from 'mdi-react/CartIcon';
import styled, { useTheme } from '../../style/styled';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCartProductsCount, selectRTL } from '../../store/app/selectors';
import { ROUTES } from '../../Routes/routes';
// import { invertColor } from '../../utils/invertColor';
import {
  HEADER_BUTTON_SIZE,
  SCREEN_WIDTH_BREAK_SMALL,
} from '../../style/SIZES';
import { captureButtonPress } from '../../features/analytics';
import {
  AppHeaderButtonContainer,
  AppHeaderButtonText as _AppHeaderButtonText,
} from './styledComponents';
import { useTranslation } from 'react-i18next';

interface Props {
  color?: string;
  size?: number;
}

export const CartButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const rtl = useSelector(selectRTL);
  const { t } = useTranslation();
  const count = useSelector(selectCartProductsCount);
  const theme = useTheme();

  const color = props.color || theme.palette.appHeader.color;
  const size = props.size || HEADER_BUTTON_SIZE;

  const handleClick = () => {
    if (count > 0) {
      captureButtonPress({
        page: window.location.pathname,
        buttonName: 'Cart',
      });

      history.push(ROUTES.cart);
    }
  };

  return (
    <AppHeaderButtonContainer disabled={count === 0} onClick={handleClick}>
      <CartButtonIconContainer count={count}>
        <CartIcon color={color} size={HEADER_BUTTON_SIZE * 1.4} />

        {count > 0 && (
          <CartButtonCartCountContainer size={size}>
            <CartButtonCartCountText>{count}</CartButtonCartCountText>
          </CartButtonCartCountContainer>
        )}
      </CartButtonIconContainer>

      <AppHeaderButtonText
        color={color}
        bold={window.location.pathname === ROUTES.cart}
        size={size}
        rtl={rtl}
      >
        {t('Cart')}
      </AppHeaderButtonText>
    </AppHeaderButtonContainer>
  );
};

const CartButtonIconContainer = styled.div<{ count: number }>`
  position: relative;
  display: inline-block;
  margin-right: ${({ count }) => (count > 0 ? '8px' : 0)};
`;

const CartButtonCartCountContainer = styled.div<{
  size: number;
}>`
  right: -${(props) => props.size / 2}px;
  bottom: -${(props) => props.size / 2}px;
  position: absolute;
  background: #ff0000;
  padding: 2px;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CartButtonCartCountText = styled.span`
  font-size: 66.66%;
  user-select: none;
  color: #fff;
  font-weight: bold;
`;

const AppHeaderButtonText = styled(_AppHeaderButtonText)`
  display: inline-block;

  @media only screen and (max-width: ${SCREEN_WIDTH_BREAK_SMALL}px) {
    display: none;
  }
`;
