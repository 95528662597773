export const COLORS = {
  primaryBackgroundColor: '#fff',
  secondaryTextColor: '#777',
  tertiaryTextColor: '#fff',
  highlightColor: '#0066FF',
  defaultMapsTrailColor: '#0066FF',
  activeColor: '#000',
  primaryFontColor: '#0f1620',
  tertiaryColor: 'rgba(16, 22, 32)',
  errorColor: '#ff4846',
  warningColor: '#d8870f',
  offwhite: '#efefef',
  offwhiteDark: '#cecece',
  offwhiteDarker: '#ddd',
  lightTextColor: '#f5f5f5',
  darkTextColor: '#111',
  highContrastColor: '#FF0088',
};
