import { getStoredAppID } from '../../utils/getStoredAppID';
import { APP_ID } from './enums';
import { getWhitelabelAppIDFromURL } from './getWhitelabelAppIDFromURL';

export function getAppID(stock: boolean, rtm: boolean): string {
  if (stock && rtm) {
    return APP_ID.ANCIENT_WORLD;
  }

  if (stock && !rtm) {
    return APP_ID.ANCIENT_WORLD_STAGING;
  }

  // treat the first segment of the path as the appID
  // eg: guide.lithodomos.com/hellenicmuseum/xxxx => hellenicmuseum is the appID
  const appID = getWhitelabelAppIDFromURL();
  if (appID) {
    return appID;
  }

  // if the appID isn't provided, check if there is a stored appID.
  // return it if available, so that for subsequent visits, you don't need to specify the appID in the URL.
  // eg: if the user first visited guide.lithodomos.com/hellenicmuseum => next time, it will load the hellenic museum config
  // even if the user visits guide.lithodomos.com.
  const storedAppID = getStoredAppID();

  if (storedAppID) {
    return storedAppID;
  }

  // this will be handled by the effect in AppWithRemoteConfig
  // empty AppID => null appData => redirected to ancient world.
  return '';
}
