import styled from '../../../../style/styled';
import { useDispatch } from 'react-redux';
import { CurrencyCode, ProductSKU } from '../../../../graphql/globalTypes';
import { getPriceFormatted } from '../../../../utils/getPriceFormatted';
import { ContentSectionWidgetPrice } from '../../styledComponents';
import { Button } from '../../../Button';
import { captureButtonPress } from '../../../../features/analytics';
import { addToCart } from '../../../../store/app/actions';
import { PurchaseTourContainer } from './styledComponents/PurchaseTourContainer';
import { COLORS } from '../../../../style/colors';
import { useTranslation } from 'react-i18next';
import { CurrencyCodeSelector } from '../../../CurrencyCodeSelector';

interface Props {
  id: string;
  price: number;
  currencyCode: CurrencyCode;
  priceInCents: boolean;
}

export const TourInfoAddToCartButton: React.FC<Props> = ({
  id,
  price,
  currencyCode,
  priceInCents,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const undiscountedPrice = getPriceFormatted({
    // currencyCode,
    price,
    priceInCents,
  });

  const handleButtonClick = () => {
    captureButtonPress({
      page: window.location.pathname,
      buttonName: 'BookNow_IteneraryPopup',
    });

    dispatch(
      addToCart({
        // For now, just hardcode this
        productSKU: ProductSKU.PLAYLIST,
        productID: id,
        currencyCode,
        count: 1,
      })
    );
  };

  return (
    <PurchaseTourContainer>
      <PriceContainer>
        <CurrencyCodeSelector />
        <ContentSectionWidgetPrice color={COLORS.darkTextColor}>
          {undiscountedPrice}
        </ContentSectionWidgetPrice>
      </PriceContainer>

      <Button black onClick={handleButtonClick}>
        {t('Add to cart')}
      </Button>
    </PurchaseTourContainer>
  );
};

const PriceContainer = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 8px;
  }
`;
