import { env } from '../../App/config/env';
import { RootState } from '../redux/types';
import { Experiment } from './types';

export function selectExperiments({ googleOptimize }: RootState): Experiment[] {
  return googleOptimize.experiments;
}

export function selectOptimizeTimedOut({ googleOptimize }: RootState): boolean {
  return !googleOptimize.loading && googleOptimize.experiments.length === 0;
}

export function selectVariant(experimentID: string) {
  return function ({ googleOptimize }: RootState): string | null {
    if (env.isDevelopment) {
      return '1';
    }

    const { loading, experiments } = googleOptimize;

    // we don't want to return a variant here because that would cause a flash.
    // so just return null, and the component that subscribes to the state can take correct action
    // such as display a loading spinner.
    if (loading) {
      return null;
    }

    const experiment = experiments.find((e) => e.experimentID === experimentID);

    // use the variant from the experiment or fallback to original. i.e. '0'
    return experiment?.variant || '0';
  };
}
