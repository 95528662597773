import React, { useCallback } from 'react';
import { captureButtonPress } from '../../../../features/analytics';
import { Button } from '../../../Button';
import { useTranslation } from 'react-i18next';

interface Props {
  tourID: string;
  buy: () => void;
}

export const TourWidgetBookNowButton: React.FC<Props> = ({ tourID, buy }) => {
  const { t } = useTranslation();
  const page = window.location.pathname;

  const handleButtonClick = useCallback(() => {
    captureButtonPress({
      buttonName: 'BuyTour',
      tourID,
      page,
    });

    buy();
  }, [tourID, buy, page]);

  return (
    <Button black width="100%" onClick={handleButtonClick}>
      {t('Add to cart')}
    </Button>
  );
};
