import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTES } from '../../Routes/routes';
import { UpdateAvailableAlert } from './components/UpdateAvailableAlert';
import { useSelector } from 'react-redux';
import { selectIsUpdateAvailable } from '../../store/sw/selectors';

export const UpdateNotification: React.FC = () => {
  const updateAvailable = useSelector(selectIsUpdateAvailable);
  const location = useLocation();

  const uninterruptableRoute =
    location.pathname.startsWith(ROUTES.tour) ||
    location.pathname.startsWith(ROUTES.shop);

  useEffect(() => {
    if (updateAvailable && !uninterruptableRoute) {
      toast.info(<UpdateAvailableAlert />, {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: true,
        toastId: 'app_update_prompt',
      });
    }
  }, [updateAvailable, uninterruptableRoute]);

  return null;
};
