import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';
import styled from '../../../../style/styled';
import { getTourShareURL } from '../../../../utils/getTourShareURL';

interface Props {
  name: string;
  tags: string[];
  description: string;
  internalReference: string;
}

// TODO: [AW-738] use webshare API if available https://web.dev/web-share/
export const TourInfoShareButtons: React.FC<Props> = ({
  name,
  tags,
  description,
  internalReference,
}) => {
  const url = getTourShareURL(internalReference);

  return (
    <Container>
      <FacebookShareButton url={url} quote={name} hashtag="ancientworld">
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={name} hashtags={tags}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <TumblrShareButton url={url} title={name} caption={description}>
        <TumblrIcon size={32} round />
      </TumblrShareButton>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32px);
  grid-gap: 4px;
  margin-top: 8px;
`;
