import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import urljoin from 'url-join';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { toast } from 'react-toastify';
import styled from '../../../style/styled';
import * as Sentry from '@sentry/react';
import { getUserIDFromJWT } from '../../../utils/getUserIDFromJWT';
import { useDispatch } from 'react-redux';
import {
  captureButtonPress,
  captureEvent,
  DataKeys,
  EventNames,
} from '../../../features/analytics';
import { loginSuccess } from '../../../store/app/actions';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { env } from '../../../App/config/env';
import {
  EXPLORE_WEB_AuthWithFacebook as Data,
  EXPLORE_WEB_AuthWithFacebookVariables as Variables,
} from './__generated__/EXPLORE_WEB_AuthWithFacebook';
import { Button } from '../../../components/Button';
// import { SCREEN_WIDTH_BREAK_SMALL } from '../../../style/SIZES';
import { removeStoredAffiliateCode } from '../../../features/affiliateCodes/removeStoredAffiliateCode';
import { getStoredAffiliateCode } from '../../../features/affiliateCodes/getStoredAffiliateCode';
import { UNKNOWN_ERROR } from '../../../consts';
import { useTranslation } from 'react-i18next';
import { captureInSentry } from '../../../App/config/reporting/captureInSentry';

const AUTH_WITH_FACEBOOK_MUTATION = gql`
  mutation EXPLORE_WEB_AuthWithFacebook($input: AuthWithFacebookInput!) {
    result: authWithFacebook(input: $input) {
      jwt
      newUser
      error {
        id
        message
        type
      }
    }
  }
`;

type FacebookResponse = {
  accessToken?: string;
  code?: string;
};

type FacebookRenderProps = {
  onClick: Function;
  isDisabled: boolean;
  isProcessing: boolean;
  isSdkLoaded: boolean;
};

interface Props {
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  originalHref?: string | null;
  redirectedHref?: string | null;
  setBusy: (busy: boolean) => void;
  busy: boolean;
}

let _variables: Variables | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables) => {
  _variables = variables;
};

export const ContinueWithFacebook: React.FC<Props> = ({
  onFailure,
  onSuccess,
  redirectedHref,
  originalHref,
  setBusy,
  busy,
}) => {
  const { t } = useTranslation();
  const redirectURL = urljoin(
    window.location.origin,
    ROUTES.facebookRedirectHandler
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [oauthRequested, setOauthRequested] = useState<boolean>(false);
  const [authWithFacebook, { loading }] = useMutation<Data, Variables>(
    AUTH_WITH_FACEBOOK_MUTATION,
    {
      onError: (error: Error) => {
        Sentry.withScope((scope) => {
          scope.setExtra('variables', getVariables());
          Sentry.captureException(error);
        });

        const errorMessage = error?.message || UNKNOWN_ERROR;

        onFailure ? onFailure(error) : toast.error(errorMessage);
      },
      onCompleted: ({ result }) => {
        if (!result) {
          captureInSentry(
            'ContinueWithFacebook.tsx result is null or undefined',
            { variables: getVariables() }
          );

          return;
        }

        const { jwt, error, newUser } = result;

        const userID = jwt ? getUserIDFromJWT(jwt) : null;

        if (jwt && userID) {
          removeStoredAffiliateCode();

          Sentry.configureScope(function (scope) {
            scope.setUser({ id: userID });
          });

          dispatch(loginSuccess({ userId: userID, jwt }));

          if (newUser) {
            captureEvent({
              name: EventNames.USER_REGISTERED,
              data: [{ key: DataKeys.SIGNUP_METHOD, value: 'Facebook' }],
            });
          } else {
            captureEvent({
              name: EventNames.LOGGED_IN,
              data: [{ key: DataKeys.LOGIN_METHOD, value: 'Facebook' }],
            });
          }

          const toastSuccessMessage = t(
            'You have successfully logged in with Facebook'
          );

          toast.success(toastSuccessMessage, {
            autoClose: 3000,
            pauseOnHover: false,
            hideProgressBar: true,
            toastId: toastSuccessMessage,
          });

          // if the user was going to buy before logging in, take him to the shopping cart
          if (onSuccess) {
            onSuccess();
          } else if (redirectedHref) {
            const redirectedURL = new URL(redirectedHref);

            history.replace({
              pathname: redirectedURL.pathname,
              search: redirectedURL.search,
            });
          } else {
            history.replace(ROUTES.index);
          }
        } else {
          const errorMessage = error?.message || UNKNOWN_ERROR;

          captureInSentry(errorMessage, { variables: getVariables() });
          onFailure
            ? onFailure(new Error(errorMessage))
            : toast.error(errorMessage);
        }
      },
    }
  );

  useEffect(() => {
    setBusy(loading || oauthRequested);
  }, [loading, oauthRequested, setBusy]);

  const handleClick = async () => {
    captureButtonPress({
      buttonName: 'ContinueWithFacebook',
      page: window.location.pathname,
    });

    setOauthRequested(true);
  };

  const onResponse = (response: FacebookResponse) => {
    const variables: Variables = {
      input: {
        appID: env.FACEBOOK_APP_ID,
        referralAppID: env.APP_ID,
        redirectURL,
      },
    };

    const affiliateCode = getStoredAffiliateCode();
    if (affiliateCode) {
      variables.input.affiliateCode = affiliateCode;
    }

    if (response.code) {
      variables.input.code = response.code;
    }

    if (response.accessToken) {
      variables.input.accessToken = response.accessToken;
    }

    if (!response.code && !response.accessToken) {
      captureInSentry(
        'ContinueWithFacebook.tsx Did not receive an access token from Facebook auth',
        { response: JSON.stringify(response) }
      );

      toast.error(
        t(
          'Authentication with Facebook failed. Please try again later, or try another way to log in.'
        )
      );
    } else {
      setVariables(variables);

      authWithFacebook({ variables });
    }

    setOauthRequested(false);
  };

  // based on local busy state
  const caption = t(
    loading || oauthRequested ? 'Please Wait...' : 'Continue with Facebook'
  );

  return (
    <FacebookLogin
      // based on global busy state
      isDisabled={busy}
      appId={env.FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      // scope="public_profile"
      onClick={handleClick}
      callback={onResponse}
      redirectUri={redirectURL}
      state={JSON.stringify({ originalHref, redirectedHref, redirectURL })}
      // authType="code"
      // authType="token"
      render={({ onClick, isDisabled }: FacebookRenderProps) => (
        <FacebookButton
          onClick={onClick}
          disabled={isDisabled}
          bgColor="#3b5998"
        >
          {caption}
        </FacebookButton>
      )}
    />
  );
};

const FacebookButton = styled(Button)`
  width: 100%;
  margin-bottom: 16px;
`;
