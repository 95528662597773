import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type Props = RouteComponentProps;

// Scroll to anchor tag. React-router doesn't have native support for this.
export const ScrollToAnchor: React.FC<Props> = ({ history, location }) => {
  useEffect(() => {
    const scrollToAnchor = (hash: string) => {
      const anchor = hash.replace('#', '');

      const el = document.getElementById(anchor);

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToAnchor(location.hash);
  });

  return null;
};
