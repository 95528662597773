import React from 'react';
import { EXPLORE_WEB_GetTourByInternalReference_result_introNarrations } from '../../../../../graphql/queries/__generated__/EXPLORE_WEB_GetTourByInternalReference';
import { useSelector } from 'react-redux';
import { selectLanguage } from '../../../../../store/app/selectors';
import { IntroNarrationSection } from './IntroNarrationSection';
import { getIntroNarration } from './utils/getIntroNarration';

interface Props {
  introNarrations: EXPLORE_WEB_GetTourByInternalReference_result_introNarrations[];
  guildeAvatarURI?: string | null;
}

export const TourInfoIntroNarrationSection: React.FC<Props> = ({
  introNarrations,
  guildeAvatarURI,
}) => {
  const language = useSelector(selectLanguage);

  const narration = getIntroNarration(introNarrations, language);

  if (!narration) {
    return null;
  }

  const trackURI = narration.voiceTrack.uri;
  const duration = narration.voiceTrackLengthInSeconds;

  return (
    <IntroNarrationSection
      trackURI={trackURI}
      duration={duration}
      guildeAvatarURI={guildeAvatarURI}
    />
  );
};
