import { Helmet } from 'react-helmet';
import React from 'react';

interface Props {
  stylesheetURL?: string;
}

export const AncientWorldHelmet: React.FC<Props> = ({ stylesheetURL }) => {
  const title = 'Ancient World';
  const description =
    'Over 190 digital reconstructions of some of the most visited ancient sites around the world. 22 virtual tours, hundreds of supporting images,  and over 7 hours of narration by professional  local guides. The Ancient World offers on site and virtual tours from around the world. With interactive maps and downloadable tours, take your exploration of ancient sites to the next level, all from your own device. Compare the present day ruins to how these magnificent sites once stood. Whether on site or from the other side of the world, immerse yourself in these places and their stories, and be engaged by our local and professional guides as they take you on a journey back in time.';

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href="https://ancient-world.co" />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content="Virtual, Tourism, Tours, VR, AR, 360 prominence, Heritage, Cultural Tourism"
      />

      {stylesheetURL && (
        <link rel="stylesheet" type="text/css" href={stylesheetURL} />
      )}
    </Helmet>
  );
};
