import React from 'react';
import styled from '../../style/styled';
import { captureButtonPress } from '../../features/analytics';
import { Container } from './styledComponents/Container';
import { COLORS } from '../../style/colors';

interface Props {
  color?: string;
}

export const ContactUsLink: React.FC<Props> = ({
  color = COLORS.lightTextColor,
}) => {
  return (
    <Container>
      <a
        href="mailto:seeker@ancient-world.co"
        onClick={() => {
          captureButtonPress({
            page: window.location.pathname,
            buttonName: 'ContactUs',
          });
        }}
      >
        <Text color={color}>seeker@ancient-world.co</Text>
      </a>
    </Container>
  );
};

const Text = styled.p`
  margin: 0;
  color: ${(props) => props.color};
  user-select: none;
`;
