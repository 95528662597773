import React from 'react';
import { TourWidgetItineraryButtonForPopup } from './TourWidgetItineraryButtonForPopup';
import { TourWidgetItineraryButtonForPage } from './TourWidgetItineraryButtonForPage';

interface Props {
  tourID: string;
  internalReference: string;
  opensItineraryPage?: boolean;
}

export const TourWidgetItineraryButtonConditional: React.FC<Props> = ({
  opensItineraryPage = false,
  ...rest
}) => {
  if (opensItineraryPage) {
    return <TourWidgetItineraryButtonForPage {...rest} />;
  }

  return <TourWidgetItineraryButtonForPopup {...rest} />;
};
