import { createAction } from 'redux-actions';
import { AudioState, CompassSupport, ViewerOptionsState } from './types';
import { FullTour } from '../../graphql/queries/GetFullTourQuery';
import { LanguageCode } from '../../graphql/globalTypes';
import { ClosestViewpointDetectionMode } from '../location/types';

export const ACTION_TYPES = {
  OPEN_TOUR_REQUEST: 'openTourRequest',
  OPEN_TOUR_SUCCESS: 'openTourSuccess',

  CLOSE_VIEWER: 'closeViewer',
  INCREMENT_VIEWPOINT_INDEX: 'incrementViewpointIndex',
  DECREMENT_VIEWPOINT_INDEX: 'decrementViewpointIndex',
  SET_VIEWPOINT_INDEX: 'setViewpointIndex',
  SET_VP_INDEX: 'setVpIndex',
  SET_VIEWPOINT: 'setViewpoint',
  SET_VP_VISITED: 'setVPVisited',
  SET_DISCOVERY_VISITED: 'setDiscoveryVisited',
  INIT_DISCOVERY_VISITED: 'initDiscoveryVisited',
  SET_CLOSEST_VIEWPOINT_INDEX: 'setClosestViewpointIndex',

  INCREMENT_IMAGE_INDEX: 'imcrementImageIndex',
  DECREMENT_IMAGE_INDEX: 'decrementImageIndex',
  SET_IMAGE_INDEX: 'setImageIndex',
  SET_DISCOVERY_ID: 'setDiscoveryID',
  SET_ARTEFACT_INDEX: 'setArtefactIndex',
  SET_ARTEFACT_CLICK_POSITION: 'setArtefactClickPosition',

  SET_VIEWER_OPTIONS: 'setViewerOptions',
  SET_VIEWER_LOADING_STATE: 'setViewerLoadingState',
  SET_VIEWPOINT_SWITCHING_STATE: 'setViewpointSwitchingState',

  PLAY_NARRATION: 'playNarration',
  PAUSE_NARRATION: 'pauseNarration',
  RESUME_NARRATION: 'resumeNarration',
  RESTART_NARRATION: 'restartNarration',
  STOP_NARRATION: 'stopNarration',
  COMPLETED_NARRATION: 'completedNarration',
  SYSTEM_PAUSE_NARRATION: 'systemPauseNarration',
  SET_CHANGING_SLIDER: 'setChangingSlider',
  SYSTEM_RESUME_MUSIC: 'systemResumeMusic',
  SYSTEM_PAUSE_MUSIC: 'systemPauseMusic',
  SET_AUDIO_STATE_PARTIAL: 'setAudioStatePartial',

  UPDATE_NARRATION_LENGTH: 'updateNarrationLength',
  UPDATE_NARRATION_SEEK_POS: 'updateNarrationSeekPos',
  OVERRIDE_NARRATION_SEEK_POS: 'overrideNarrationSeekPos',

  TOGGLE_MUTE_MUSIC: 'toggleMuteMusic',

  SET_IMAGE_CAPTION: 'setImageCaption',

  TOGGLE_SUBTITLE_VISIBILITY: 'toggleSubtitleVisibility',
  SET_SUBTITLE_VISIBILITY: 'setSubtitleVisibility',
  SET_SUBTITLE_LANGUAGE: 'setSubtitleLanguage',

  SET_END_OF_TOUR_POPUP_CLOSED: 'setEndOfTourPopupClosed',

  INCREMENT_RESYNC_WITH_COMPASS_COUNTER: 'incrementResyncWithCompassCounter',
  SET_DEVICE_ORIENTATION: 'setDeviceOrientation',

  SET_COMPASS_SUPPORT: 'setCompassSupport',

  SET_NARRATION_LANGUAGE: 'setNarrationLanguage',

  TOGGLE_UI: 'toggleUI',

  SET_CAMERA_FACING_DIRECTION: 'setCameraFacingDirection',
};

export type OpenTourRequestPayload = {
  tour: FullTour;
  currentViewpointIndex?: number;
  currentVp?: any;
  viewerOptions?: Partial<ViewerOptionsState>;
};

export type OpenTourRequestAction = {
  type: typeof ACTION_TYPES.OPEN_TOUR_REQUEST;
  payload: OpenTourRequestPayload;
};

export type OpenTourSuccessPayload = {
  tour: FullTour;
  currentViewpointIndex: number;
  currentVpIndex: number;
  currentImageIndex: number;
  viewerOptions?: Partial<ViewerOptionsState>;
};

export type OpenTourSuccessAction = {
  type: typeof ACTION_TYPES.OPEN_TOUR_SUCCESS;
  payload: OpenTourSuccessPayload;
};

export type SetContentIndexPayload = { index: number; projectionName?: string };
export type SetContentVpIndexPayload = { index: number };

export type SetContentPayload = { viewpoint: any };

export type SetContentIndexAction = {
  type: typeof ACTION_TYPES.SET_VIEWPOINT_INDEX;
  payload: SetContentIndexPayload;
};

export type SetContentVpIndexAction = {
  type: typeof ACTION_TYPES.SET_VP_INDEX;
  payload: SetContentVpIndexPayload;
};
export type SetCameraFacingDirectionPayload = { facingDirection: string };

export type SetCameraFacingDirectionAction = {
  type: typeof ACTION_TYPES.SET_CAMERA_FACING_DIRECTION;
  payload: SetCameraFacingDirectionPayload;
};

export type SetVPVisitedPayload = { index: number };

export type SetVPVisitedAction = {
  type: typeof ACTION_TYPES.SET_VP_VISITED;
  payload: SetVPVisitedPayload;
};

export type SetDiscoveryVisitedPayload = { id: string };

export type SetDiscoveryVisitedAction = {
  type: typeof ACTION_TYPES.SET_DISCOVERY_VISITED;
  payload: SetDiscoveryVisitedPayload;
};

export type InitDiscoveryVisitedPayload = { ids: string[] };

export type InitDiscoveryVisitedAction = {
  type: typeof ACTION_TYPES.SET_DISCOVERY_VISITED;
  payload: InitDiscoveryVisitedPayload;
};

export type OpenNextImageAction = {
  type: typeof ACTION_TYPES.INCREMENT_IMAGE_INDEX;
};

export type OpenPreviousImageAction = {
  type: typeof ACTION_TYPES.DECREMENT_IMAGE_INDEX;
};

export type OpenImageByIndexPayload = number;
export type SetDiscoveryIDPayload = { id: string };
export type SetArtefactIndexPayload = { index: number };
export type SetArtefactClickPositionPayload = { x: number; y: number };
export type SetDiscoveryIDAction = {
  type: typeof ACTION_TYPES.SET_DISCOVERY_ID;
  payload: SetDiscoveryIDPayload;
};

export type SetArtefactIndexAction = {
  type: typeof ACTION_TYPES.SET_ARTEFACT_INDEX;
  payload: SetArtefactIndexPayload;
};
export type SetArtefactClickPositionAction = {
  type: typeof ACTION_TYPES.SET_ARTEFACT_CLICK_POSITION;
  payload: SetArtefactClickPositionPayload;
};

export type OpenImageByIndexAction = {
  type: typeof ACTION_TYPES.SET_IMAGE_INDEX;
  payload: OpenImageByIndexPayload;
};

export type SetViewerOptionsPayload = { options: Partial<ViewerOptionsState> };

export type SetViewerOptionsAction = {
  type: typeof ACTION_TYPES.SET_VIEWER_OPTIONS;
  payload: SetViewerOptionsPayload;
};

export type PlayNarrationPayload = {
  stopID: string;
  bySystem: boolean;
};

export type PlayNarrationAction = {
  type: typeof ACTION_TYPES.PLAY_NARRATION;
  payload: PlayNarrationPayload;
};

export type UpdateNarrationLengthPayload = { seconds: number };

export type UpdateNarrationLengthAction = {
  type: typeof ACTION_TYPES.UPDATE_NARRATION_LENGTH;
  payload: UpdateNarrationLengthPayload;
};

export type UpdateNarrationSeekPosPayload = { seek: number };

export type UpdateNarrationSeekPosAction = {
  type: typeof ACTION_TYPES.UPDATE_NARRATION_SEEK_POS;
  payload: UpdateNarrationSeekPosPayload;
};

export type OverrideNarrationSeekPosPayload = { value: number };

export type OverrideNarrationSeekPosAction = {
  type: typeof ACTION_TYPES.OVERRIDE_NARRATION_SEEK_POS;
  payload: OverrideNarrationSeekPosPayload;
};

export type ToggleMuteMusicAction = {
  type: typeof ACTION_TYPES.TOGGLE_MUTE_MUSIC;
};

export type SetViewerLoadingStatePayload = { isLoading: boolean };

export type SetViewerLoadingStateAction = {
  type: typeof ACTION_TYPES.SET_VIEWER_LOADING_STATE;
  payload: SetViewerLoadingStatePayload;
};
export type SetViewpointSwitchingStatePayload = {
  isViewpointSwitching: boolean;
};
export type SetViewpointSwitchingStateAction = {
  type: typeof ACTION_TYPES.SET_VIEWPOINT_SWITCHING_STATE;
  payload: SetViewpointSwitchingStatePayload;
};

export type SetImageCaptionPayload = { caption: string | null };

export type SetImageCaptionAction = {
  type: typeof ACTION_TYPES.SET_IMAGE_CAPTION;
  payload: SetImageCaptionPayload;
};

export type ToggleSubtitleVisibilityAction = {
  type: typeof ACTION_TYPES.TOGGLE_SUBTITLE_VISIBILITY;
};

export type SetSubtitleVisibilityPayload = { visibility: boolean };

export type SetSubtitleVisibilityAction = {
  type: typeof ACTION_TYPES.SET_SUBTITLE_VISIBILITY;
  payload: SetSubtitleVisibilityPayload;
};

export type SetSubtitleLanguagePayload = { languageCode: LanguageCode };

export type SetSubtitleLanguageAction = {
  type: typeof ACTION_TYPES.SET_SUBTITLE_LANGUAGE;
  payload: SetSubtitleLanguagePayload;
};

export type SetEndOfTourPopupClosedAction = {
  type: typeof ACTION_TYPES.SET_END_OF_TOUR_POPUP_CLOSED;
};

export type IncrementResyncWithCompassAction = {
  type: typeof ACTION_TYPES.INCREMENT_RESYNC_WITH_COMPASS_COUNTER;
};
export type SetDeviceOrientationPayload = {
  deviceOrientation: Partial<DeviceOrientationEvent>;
};

export type SetDeviceOrientationAction = {
  type: typeof ACTION_TYPES.SET_DEVICE_ORIENTATION;
  payload: SetDeviceOrientationPayload;
};

export type SetCompassSupportPayload = { compassSupport: CompassSupport };

export type SetCompassSupportAction = {
  type: typeof ACTION_TYPES.SET_COMPASS_SUPPORT;
  payload: SetCompassSupportPayload;
};

export type SetNarrationLanguagePayload = { languageCode: LanguageCode };

export type SetNarrationLanguageAction = {
  type: typeof ACTION_TYPES.SET_NARRATION_LANGUAGE;
  payload: SetNarrationLanguagePayload;
};

export type SetAudioStatePartialPayload = Partial<AudioState>;

export type SetAudioStatePartialAction = {
  type: typeof ACTION_TYPES.SET_AUDIO_STATE_PARTIAL;
  payload: SetAudioStatePartialPayload;
};

export type ToggleUIAction = {
  type: typeof ACTION_TYPES.TOGGLE_UI;
};

export type SetChangingSliderPayload = boolean;

export type SetChangingSliderAction = {
  type: typeof ACTION_TYPES.SET_CHANGING_SLIDER;
  payload: SetChangingSliderPayload;
};

export type SetClosestViewpointIndexPayload = {
  index: number;
  distance: number;
  mode: ClosestViewpointDetectionMode;
};

export type setClosestViewpointIndexAction = {
  type: typeof ACTION_TYPES.SET_CLOSEST_VIEWPOINT_INDEX;
  payload: SetClosestViewpointIndexPayload;
};

export const closeViewer = createAction(ACTION_TYPES.CLOSE_VIEWER);
export const openTourRequest = createAction<OpenTourRequestPayload>(
  ACTION_TYPES.OPEN_TOUR_REQUEST
);
export const openTourSuccess = createAction<OpenTourSuccessPayload>(
  ACTION_TYPES.OPEN_TOUR_SUCCESS
);
export const incrementViewpointIndex = createAction(
  ACTION_TYPES.INCREMENT_VIEWPOINT_INDEX
);
export const decrementViewpointIndex = createAction(
  ACTION_TYPES.DECREMENT_VIEWPOINT_INDEX
);
//stop
export const setViewpointIndex = createAction<SetContentIndexPayload>(
  ACTION_TYPES.SET_VIEWPOINT_INDEX
);

//vp
export const setVpIndex = createAction<SetContentVpIndexPayload>(
  ACTION_TYPES.SET_VP_INDEX
);
//
// export const setViewpoint = createAction<SetContentPayload>(
//   ACTION_TYPES.SET_VIEWPOINT
// );
export const setVPVisited = createAction<SetVPVisitedPayload>(
  ACTION_TYPES.SET_VP_VISITED
);
export const setDiscoveryVisited = createAction<SetDiscoveryVisitedPayload>(
  ACTION_TYPES.SET_DISCOVERY_VISITED
);
export const initDiscoveryVisited = createAction<InitDiscoveryVisitedPayload>(
  ACTION_TYPES.INIT_DISCOVERY_VISITED
);

export const imcrementImageIndex = createAction(
  ACTION_TYPES.INCREMENT_IMAGE_INDEX
);
export const decrementImageIndex = createAction(
  ACTION_TYPES.DECREMENT_IMAGE_INDEX
);
export const setImageIndex = createAction<OpenImageByIndexPayload>(
  ACTION_TYPES.SET_IMAGE_INDEX
);
export const setDiscoveryID = createAction<SetDiscoveryIDPayload>(
  ACTION_TYPES.SET_DISCOVERY_ID
);

export const setArtefactIndex = createAction<SetArtefactIndexPayload>(
  ACTION_TYPES.SET_ARTEFACT_INDEX
);
export const setArtefactClickPosition = createAction<SetArtefactClickPositionPayload>(
  ACTION_TYPES.SET_ARTEFACT_CLICK_POSITION
);
export const setCameraFacingDirection = createAction<SetCameraFacingDirectionPayload>(
  ACTION_TYPES.SET_CAMERA_FACING_DIRECTION
);
export const setViewerOptions = createAction<SetViewerOptionsPayload>(
  ACTION_TYPES.SET_VIEWER_OPTIONS
);

export const setViewerLoadingState = createAction<SetViewerLoadingStatePayload>(
  ACTION_TYPES.SET_VIEWER_LOADING_STATE
);
export const setViewpointSwitchingState = createAction<SetViewpointSwitchingStatePayload>(
  ACTION_TYPES.SET_VIEWPOINT_SWITCHING_STATE
);
export const stopNarration = createAction(ACTION_TYPES.STOP_NARRATION);
export const restartNarration = createAction(ACTION_TYPES.RESTART_NARRATION);
export const pauseNarration = createAction(ACTION_TYPES.PAUSE_NARRATION);
export const resumeNarration = createAction(ACTION_TYPES.RESUME_NARRATION);
export const playNarration = createAction<PlayNarrationPayload>(
  ACTION_TYPES.PLAY_NARRATION
);
export const completedNarration = createAction(
  ACTION_TYPES.COMPLETED_NARRATION
);
export const systemPauseNarration = createAction(
  ACTION_TYPES.SYSTEM_PAUSE_NARRATION
);
export const setChangingSlider = createAction<SetChangingSliderPayload>(
  ACTION_TYPES.SET_CHANGING_SLIDER
);
export const systemResumeMusic = createAction(ACTION_TYPES.SYSTEM_RESUME_MUSIC);
export const systemPauseMusic = createAction(ACTION_TYPES.SYSTEM_PAUSE_MUSIC);
export const updateNarrationLength = createAction<UpdateNarrationLengthPayload>(
  ACTION_TYPES.UPDATE_NARRATION_LENGTH
);
export const updateNarrationSeekPos = createAction<UpdateNarrationSeekPosPayload>(
  ACTION_TYPES.UPDATE_NARRATION_SEEK_POS
);
export const overrideNarrationSeekPos = createAction<OverrideNarrationSeekPosPayload>(
  ACTION_TYPES.OVERRIDE_NARRATION_SEEK_POS
);
export const toggleMuteMusic = createAction(ACTION_TYPES.TOGGLE_MUTE_MUSIC);
export const setImageCaption = createAction<SetImageCaptionPayload>(
  ACTION_TYPES.SET_IMAGE_CAPTION
);
export const toggleSubtitleVisibility = createAction(
  ACTION_TYPES.TOGGLE_SUBTITLE_VISIBILITY
);
export const setSubtitleVisibility = createAction<SetSubtitleVisibilityPayload>(
  ACTION_TYPES.SET_SUBTITLE_VISIBILITY
);
export const setSubtitleLanguage = createAction<SetSubtitleLanguagePayload>(
  ACTION_TYPES.SET_SUBTITLE_LANGUAGE
);
export const setEndOfTourPopupClosed = createAction(
  ACTION_TYPES.SET_END_OF_TOUR_POPUP_CLOSED
);
export const incrementResyncWithCompassCounter = createAction(
  ACTION_TYPES.INCREMENT_RESYNC_WITH_COMPASS_COUNTER
);

export const SetDeviceOrientation = createAction<SetDeviceOrientationPayload>(
  ACTION_TYPES.SET_DEVICE_ORIENTATION
);
export const setCompassSupport = createAction<SetCompassSupportPayload>(
  ACTION_TYPES.SET_COMPASS_SUPPORT
);
export const setNarrationLanguage = createAction<SetNarrationLanguagePayload>(
  ACTION_TYPES.SET_NARRATION_LANGUAGE
);

// TODO: decomponse this into actions
export const setAudioStatePartial = createAction<SetAudioStatePartialPayload>(
  ACTION_TYPES.SET_AUDIO_STATE_PARTIAL
);
export const toggleUI = createAction(ACTION_TYPES.TOGGLE_UI);

export const setClosestViewpointIndex = createAction<SetClosestViewpointIndexPayload>(
  ACTION_TYPES.SET_CLOSEST_VIEWPOINT_INDEX
);
