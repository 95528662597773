import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useSelector } from 'react-redux';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { selectUserID } from '../../store/app/selectors';
import { env } from '../config/env';

// Side effects for redux state changes
export const ReduxStateListener: React.FC = () => {
  const userID = useSelector(selectUserID);

  useEffect(() => {
    if (userID) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ id: userID });
      });

      if (env.ENABLE_LOG_ROCKET_REPORTING) {
        LogRocket.identify(userID);
      }

      ReactGA.set({ userId: userID });
    } else {
      Sentry.configureScope(function (scope) {
        scope.setUser(null);
      });

      if (env.ENABLE_LOG_ROCKET_REPORTING) {
        LogRocket.identify('Anonymous');
      }

      ReactGA.set({ userId: null });
    }
  }, [userID]);

  return null;
};
