import { SagaIterator } from 'redux-saga';
import {
  call,
  // getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
// import { AudioPlayer } from '../../features/audio/AudioPlayer';
// import { MusicPlayer } from '../../features/audio/MusicPlayer';
// import { SagaContextKeys } from '../redux/types';
import {
  ACTION_TYPES,
  OpenTourRequestAction,
  openTourSuccess,
  // OverrideNarrationSeekPosAction,
  // PlayNarrationAction,
  // SetNarrationLanguageAction,
  setViewerOptions,
  setVpIndex,
  // ToggleMuteMusicAction,
} from './actions';
import { selectViewer } from './selectors';
import { ViewerOptionsState, ViewerState } from './types';
// import { resolvePreferredNarration } from './util/resolvePreferredNarration';
import {
  imageEnd,
  imageStart,
  tourStart,
  viewpointStart,
} from '../../features/analytics';
// import { captureInSentry } from '../../App/config/reporting/captureInSentry';
import { tourSupportsUserPosition } from '../../utils/tourSupportsUserPosition';
import { isMobile } from 'react-device-detect';
import { getTargetProjection } from '../../utils/getTargetProjection';
import { setLocationRequested } from '../location/actions';
import { selectCurrentLocation } from '../location/selectors';

export const sagas = [viewerSagas];

export function* viewerSagas(): any {
  yield takeLatest(
    ACTION_TYPES.OPEN_TOUR_REQUEST,
    openCuratedPlaylistRequestSaga
  );

  // yield takeLatest(ACTION_TYPES.PLAY_NARRATION, playNarrationSaga);

  // yield takeLatest(ACTION_TYPES.RESTART_NARRATION, restartNarrationSaga);

  // yield takeLatest(ACTION_TYPES.TOGGLE_MUTE_MUSIC, toggleMusicSaga);

  // yield takeLatest(
  //   [ACTION_TYPES.STOP_NARRATION, ACTION_TYPES.CLOSE_VIEWER],
  //   stopNarrationSaga
  // );

  // yield takeLatest(ACTION_TYPES.PAUSE_NARRATION, pauseNarrationSaga);

  // yield takeLatest(ACTION_TYPES.SYSTEM_PAUSE_NARRATION, systemPauseNarration);

  // yield takeLatest(ACTION_TYPES.RESUME_NARRATION, resumeNarrationSaga);

  // yield takeLatest(ACTION_TYPES.SYSTEM_RESUME_MUSIC, resumeMusicSaga);

  // yield takeLatest(ACTION_TYPES.SYSTEM_PAUSE_MUSIC, pauseMusicSaga);

  // yield takeLatest(
  //   ACTION_TYPES.OVERRIDE_NARRATION_SEEK_POS,
  //   overrideNarrationSeekPosSaga
  // );

  // yield takeLatest(ACTION_TYPES.SET_NARRATION_LANGUAGE, changeNarrationSaga);

  yield takeLatest(
    [
      ACTION_TYPES.INCREMENT_VIEWPOINT_INDEX,
      ACTION_TYPES.SET_VIEWPOINT_INDEX,
      ACTION_TYPES.DECREMENT_VIEWPOINT_INDEX,
    ],
    changeViewpointIndexSaga
  );

  yield takeLatest(
    [
      ACTION_TYPES.INCREMENT_IMAGE_INDEX,
      ACTION_TYPES.SET_IMAGE_INDEX,
      ACTION_TYPES.DECREMENT_IMAGE_INDEX,
    ],
    changeImageIndexSaga
  );
}

function* openCuratedPlaylistRequestSaga({
  payload,
}: OpenTourRequestAction): SagaIterator {
  const { tour, currentViewpointIndex: index, currentVp } = payload;
  const viewerState: ViewerState = yield select(selectViewer);
  const currentLocation = yield select(selectCurrentLocation);

  let currentViewpointIndex =
    index && index >= 0 ? index : viewerState.currentViewpointIndex;
  let currentImageIndex = viewerState.currentImageIndex;
  const result = yield call(
    getTargetProjection,
    tour.stops[currentViewpointIndex],
    isMobile,
    currentVp?.internalReference
  );

  let viewerOptions: Partial<ViewerOptionsState> = {
    ...viewerState.viewerOptions,
  };

  const existingTour = viewerState.tour;
  const existingTourID = existingTour?.id;

  const currentStops = existingTour?.stops || [];
  const currentStop = currentStops[currentViewpointIndex];

  const currentImages = currentStop?.images || [];
  const currentImage = currentImages[currentImageIndex];

  if (existingTourID !== tour.id) {
    yield call(tourStart, tour.id);

    viewerOptions = {
      isMediaControlsVisible: true,
      isAutoRotating: false,
    };

    if (tourSupportsUserPosition(tour)) {
      viewerOptions.isPlaylistThumbnailsVisible = false;
      viewerOptions.isMapsVisible = false;
      viewerOptions.isDiscoveriesVisible = false;
    } else {
      viewerOptions.isPlaylistThumbnailsVisible = tour.stops.length > 1;
      viewerOptions.isMapsVisible = false;
      viewerOptions.isDiscoveriesVisible = false;
    }
  }

  const newCurrentStop = tour.stops[currentViewpointIndex];

  // if a new viewpoint ID (i.e. perhaps we changed the order of the vps, or added a new vp)
  if (
    newCurrentStop &&
    (existingTourID !== tour.id || currentStop.id !== newCurrentStop.id)
  ) {
    yield call(viewpointStart, newCurrentStop.id);

    // reset the currentImageIndex
    currentImageIndex = 0;
  }

  const newImages = newCurrentStop?.images || [];
  const newCurrentImage = newImages[currentImageIndex];

  if (
    // if either the tour, viewpoint or image ID changed, and if viewing images,
    // capture analytics
    newCurrentImage &&
    (existingTourID !== tour.id ||
      currentStop.id !== newCurrentStop.id ||
      currentImage.id !== newCurrentImage.id) &&
    viewerState.viewerOptions.isImagesEnabled
  ) {
    yield call(imageStart, newCurrentImage.id);
  }

  if (!currentLocation) {
    yield put(setLocationRequested({ requested: true }));
  }

  yield put(
    openTourSuccess({
      tour,
      currentViewpointIndex,
      currentVpIndex: result?.initialIndex || 0,
      currentImageIndex,
      viewerOptions,
    })
  );
}

// function* playNarrationSaga({ payload }: PlayNarrationAction): SagaIterator {
//   const {
//     currentViewpointIndex,
//     tour,
//     audio,
//     viewerOptions,
//     userSelectedAViewpoint,
//   }: ViewerState = yield select(selectViewer);

//   if (!tour) {
//     captureInSentry(
//       'viewer saga.ts playNarrationSaga Cannot play narration because tour in viewer state is null or undefined'
//     );
//     return;
//   }

//   const currentStop = tour.stops[currentViewpointIndex || 0];

//   if (!currentStop) {
//     captureInSentry(
//       'viewer saga.ts playNarrationSaga Cannot play narration because currentViewpoint is null or undefined'
//     );
//     return;
//   }

//   const { bySystem } = payload;

//   const {
//     isTutorialVisible,
//     isPlaylistThumbnailsVisible,
//     isMapsVisible,
//     isDiscoveriesVisible,
//     isSlideVisible,
//   } = viewerOptions;

//   const play =
//     !bySystem ||
//     Boolean(
//       !audio.pausedByUser &&
//         !isTutorialVisible &&
//         !isSlideVisible &&
//         !isPlaylistThumbnailsVisible &&
//         !isDiscoveriesVisible &&
//         (!isMapsVisible || (isMapsVisible && userSelectedAViewpoint))
//     );
//   // we might need to change this for future situations, but because we are playing audio automatically by default,
//   // even when autoplay was blocked, we mark it as we were "supposed to" be playing audio.
//   // wasPlaying = play !== false;

//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);

//   const narrationToPlay = resolvePreferredNarration({
//     stop: currentStop,
//     overridingNarrationConfig: tour.narrationConfig,
//     narrationLanguage: audio.narrationLanguage,
//   });

//   const musicUrl = currentStop.music?.asset?.uri;
//   const musicLength = currentStop.music?.length || 0;
//   const narrationUrl = narrationToPlay?.voiceTrack.uri;
//   const narrationLength = narrationToPlay?.voiceTrackLengthInSeconds || 0;

//   if (musicUrl) {
//     yield call(
//       musicPlayer.playMusic,
//       { url: musicUrl },
//       {
//         play,
//         muted: audio.musicMuted,
//         length: musicLength,
//       }
//     );
//   } else {
//     yield call(musicPlayer.stop);
//   }

//   if (narrationUrl) {
//     yield call(audioPlayer.playNarration, {
//       sound: { url: narrationUrl },
//       play,
//       length: narrationLength,
//     });
//   } else {
//     yield call(audioPlayer.stop);
//   }
// }

// function* changeNarrationSaga(
//   action: SetNarrationLanguageAction
// ): SagaIterator {
//   const { currentViewpointIndex, tour, audio }: ViewerState = yield select(
//     selectViewer
//   );

//   if (!tour) {
//     captureInSentry(
//       'viewer saga.ts changeNarrationSaga Cannot play narration because tour in viewer state is null or undefined'
//     );
//     return;
//   }

//   const currentStop = tour.stops[currentViewpointIndex || 0];

//   if (!currentStop) {
//     captureInSentry(
//       'viewer saga.ts changeNarrationSaga Cannot play narration because currentViewpoint is null or undefined'
//     );
//     return;
//   }

//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);

//   const narrationToPlay = resolvePreferredNarration({
//     stop: currentStop,
//     overridingNarrationConfig: tour.narrationConfig,
//     narrationLanguage: audio.narrationLanguage,
//   });

//   const narrationUrl = narrationToPlay?.voiceTrack.uri;
//   const length = narrationToPlay?.voiceTrackLengthInSeconds || 0;

//   if (narrationUrl) {
//     yield call(audioPlayer.playNarration, {
//       sound: { url: narrationUrl },
//       play: true,
//       length,
//     });
//   } else {
//     yield call(audioPlayer.stop);
//   }
// }

// function* toggleMusicSaga(action: ToggleMuteMusicAction): SagaIterator {
//   const { audio }: ViewerState = yield select(selectViewer);
//   const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);
//   yield call(musicPlayer.muteMusic, audio.musicMuted);
// }

// function* restartNarrationSaga(): SagaIterator {
//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);

//   yield call(audioPlayer.restartNarration);
//   yield call(musicPlayer.restartMusic);
// }

// function* stopNarrationSaga(): SagaIterator {
//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);

//   yield call(audioPlayer.stop);
//   yield call(musicPlayer.stop);
// }

// function* pauseNarrationSaga(): SagaIterator {
//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   yield call(audioPlayer.pauseNarration);
// }

// function* systemPauseNarration(): SagaIterator {
//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   yield call(audioPlayer.pauseNarration, true);
// }

// function* resumeMusicSaga(): SagaIterator {
//   const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);
//   yield call(musicPlayer.resumeMusic);
// }

// function* pauseMusicSaga(): SagaIterator {
//   const musicPlayer: MusicPlayer = yield getContext(SagaContextKeys.music);
//   yield call(musicPlayer.pauseMusic);
// }

// function* resumeNarrationSaga(): SagaIterator {
//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   yield call(audioPlayer.resumeNarration);
// }

// function* overrideNarrationSeekPosSaga(
//   action: OverrideNarrationSeekPosAction
// ): SagaIterator {
//   const audioPlayer: AudioPlayer = yield getContext(SagaContextKeys.audio);
//   yield call(audioPlayer.seek, {
//     value: action.payload.value,
//   });
// }

function* changeViewpointIndexSaga(): SagaIterator {
  const {
    tour,
    currentViewpointIndex,
    currentViewpointProjectionName,
    currentImageIndex,
    viewerOptions,
  }: ViewerState = yield select(selectViewer);
  // just a sanity check
  if (!tour) {
    return;
  }

  const currentStop = tour.stops[currentViewpointIndex];
  // another sanity check
  if (!currentStop) {
    return;
  }
  const result = yield call(
    getTargetProjection,
    currentStop,
    isMobile,
    currentViewpointProjectionName
  );
  yield put(setVpIndex({ index: result?.initialIndex || 0 }));
  // capture analytics
  if (viewerOptions.isImagesEnabled) {
    yield call(imageEnd);
  }

  yield call(viewpointStart, currentStop.id);
  //only first viewpoint show map,
  if (viewerOptions.isMapsVisible) {
    yield put(setViewerOptions({ options: { isMapsVisible: false } }));
  }

  if (viewerOptions.isDiscoveriesVisible) {
    yield put(setViewerOptions({ options: { isDiscoveriesVisible: false } }));
  }

  if (viewerOptions.isImagesEnabled) {
    const currentImage = currentStop.images[currentImageIndex];

    if (currentImage) {
      yield call(imageStart, currentImage.id);
    }
  }
}

function* changeImageIndexSaga(): SagaIterator {
  const {
    tour,
    currentViewpointIndex,
    currentImageIndex,
  }: ViewerState = yield select(selectViewer);

  // just a sanity check
  if (!tour) {
    return;
  }

  const currentStop = tour.stops[currentViewpointIndex];

  // another sanity check
  if (!currentStop) {
    return;
  }

  const currentImage = currentStop.images[currentImageIndex];

  // another another sanity check
  if (currentImage) {
    yield call(imageStart, currentImage.id);
  }
}
