import { I18NSTRING } from '../fragments/I18nString';
import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from '../fragments/Asset';
import { PRICE_FRAGMENT } from '../fragments/Price';

export const GET_TOUR_BY_INTERNAL_REFERENCE_FOR_TOUR_INFO_MODAL = gql`
  query EXPLORE_WEB_GetTourByInternalReference(
    $input: GetTourByInternalReferenceInput!
  ) {
    result: getTourByInternalReference(input: $input) {
      id
      internalReference
      trial
      features
      tags
      nameI18n {
        ...I18nStringFragment
      }
      taglineI18n {
        ...I18nStringFragment
      }
      descriptionLongI18n {
        ...I18nStringFragment
      }
      validityPeriodMonths
      trailLength
      timePeriod
      audioDurationMinutes
      price {
        ...PriceFragment
      }
      trial
      cover {
        ...AssetFragment
      }
      introVideo {
        id
        playlistFile {
          ...AssetFragment
        }
        videoSegments {
          ...AssetFragment
        }
      }
      mapGeoJSON {
        ...AssetFragment
      }
      stops {
        id
        nameI18n {
          ...I18nStringFragment
        }
        taglineI18n {
          ...I18nStringFragment
        }
        timePeriodSpecific
        thumbnail {
          ...AssetFragment
        }
        location {
          type
          coordinates {
            longitude
            latitude
          }
        }
        projections {
          mobile
          id
          originalName
          asset {
            ...AssetFragment
          }
          d1
          d2
          d3
          a1
          a2
          a3
          portalStops
        }
        vps {
          cameraPosition
          id
          internalReferenceIndex
          startingVp
          internalReference
          closestVps
          location {
            coordinates {
              latitude
              longitude
            }
          }
        }
      }
      guide {
        id
        name
        bio
        location
        avatar {
          ...AssetFragment
        }
      }
      introNarrations(input: {}) {
        id
        language {
          code
        }
        voiceTrackLengthInSeconds
        voiceTrack {
          ...AssetFragment
        }
      }
      map {
        id
        projectionCode
        extXMin
        extYMin
        extXMax
        extYMax
        defaultViewBoxXMin
        defaultViewBoxYMin
        defaultViewBoxXMax
        defaultViewBoxYMax
        geoJSONFiles {
          id
          asset {
            ...AssetFragment
          }
          geoJSONFileType
        }
      }
    }
  }

  ${ASSET_FRAGMENT}
  ${I18NSTRING}
  ${PRICE_FRAGMENT}
`;
