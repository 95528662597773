import { setLanguage as setLng } from '../features/i18n/i18n';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { RouteListener } from './components/RouteListner/RouteListener';
import { AffiliateCodeExtractor } from '../Routes/components/AffiliateCodeExtractor';
import Modals from './components/Modals/Modals';
import { ScrollToAnchor } from './components/ScrollToAnchor';
import { AutoRedeemFromDeeplink } from './components/AutoRedeemFromDeeplink';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectLanguage,
  selectSupportedLanguages,
} from '../store/app/selectors';
import { LoadingView } from '../components/LoadingView';
import { LoginFromRefreshToken } from './components/LoginFromRefreshToken';
import { LazyComponents } from './components/LazyComponents';
import { AppRoutes } from '../Routes/App/AppRoutes';
import { useBeforeFirstRender } from '../hooks/useBeforeFirstRender';
import { sessionStart } from '../features/analytics';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { LanguageCode } from '../graphql/globalTypes';
import { setLanguage } from '../store/app/actions';

export const App = () => {
  // call this before app renders
  useBeforeFirstRender(() => {
    // create a session ID and send it to backend. also store the session ID for analytic events.
    sessionStart();
  });

  // moved this logic to a saga, and we are making sure the language is set in the index.tsx before rendering the app.
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const supportedLanguages = useSelector(selectSupportedLanguages);
  const { ready } = useTranslation();
  const [appMounted, setAppMounted] = useState<boolean>(false);

  useDeepCompareEffect(() => {
    if (!supportedLanguages.includes(language)) {
      // pick the language to set from the supported languages
      const nextLanguageCode = supportedLanguages.includes(LanguageCode.en_US)
        ? LanguageCode.en_US
        : supportedLanguages[0];

      dispatch(setLanguage(nextLanguageCode));
    } else {
      setLng(language);
    }
  }, [language, dispatch, supportedLanguages]);

  useEffect(() => {
    setTimeout(() => {
      setAppMounted(true);
    }, 3000);
  }, []);

  if (!ready) {
    return <LoadingView />;
  }

  return (
    <>
      {/* App routes!  */}
      <AppRoutes />

      {/* put any effect that want to be fired on Route changes. DO NOT REMOVE. Read notes inside. */}
      <Route component={RouteListener} />

      {/* React router doesn't support anchor tags out of the box.
      So we have to use our own component. */}
      <Route component={ScrollToAnchor} />

      {/* extract the affiliate code from the query string and store it in localstorage to be sent with a signup payload */}
      <AffiliateCodeExtractor />

      {/* extract the redeem code from the query string and call the activate dialog */}
      <AutoRedeemFromDeeplink />

      {/* extract the discount code and store it in localstorage */}
      {/* commented out because we don't use it anymore here; the user enters
      a coupon code manually on the checkout page */}
      {/* <CouponCodeExtractor /> */}

      {/* Modals container WIP */}
      <Modals />

      {/* Log in to a user using a refresh token.
      We currently use them with links in purchase confirmation emails of guest logins.
      https://lithodomosvr.atlassian.net/wiki/spaces/LITHODOMOS/pages/1482850428/Anonymous+guest+logins#If-you-have-made-a-purchase
      */}
      <LoginFromRefreshToken />

      {appMounted && (
        //  processes that would only work after a page loads
        <LazyComponents />
      )}
    </>
  );
};
