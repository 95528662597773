import React from 'react';
import RatedStarIcon from 'mdi-react/StarRateIcon';
import UnratedStarIcon from 'mdi-react/StarBorderIcon';
import { IconButton } from '../../../../components/IconButton';

interface Props {
  onClick: () => void;
  filled: boolean;
  disabled: boolean;
}

export const Star: React.FC<Props> = ({ onClick, filled, disabled }) => {
  const Icon = filled ? RatedStarIcon : UnratedStarIcon;

  return (
    <IconButton
      iconBgColor="#000"
      Icon={Icon}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      disabled={disabled}
    />
  );
};
