import { ROUTES } from '../../Routes/routes';

export const getWhitelabelAppIDFromURL = (): string | null => {
  const appID = window.location.pathname
    .split('/')
    .filter((v) => Boolean(v))[0];

  if (appID) {
    // check if it is not an internal route
    // otherwise, guide.lithodomos.com/help can send the users back
    const appIDWithLeadingForwardSlash = `/${appID}`;
    const appRoutes = Object.values(ROUTES) as string[];

    if (!appRoutes.includes(appIDWithLeadingForwardSlash)) {
      return appID;
    }
  }

  return null;
};
