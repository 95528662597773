import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from '../../../../style/styled';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { captureButtonPress } from '../../../../features/analytics';
// import { ModalContent, AuthModal } from '../../../components/AuthModal';
import {
  Data as Data1,
  Variables as Variables1,
  RedeemableProduct,
  ACTIVATE_PRODUCT_MUTATION,
} from '../../../../graphql/mutations/ActivateProductMutation';
import { ErrorText } from '../../../../styledComponents/ErrorText';
import { AuthButtonWithRecaptcha3 } from '../../../../components/AuthButtonWithRecaptcha3';
// import { ReCaptchaDisclaimer } from '../../../components/ReCaptchaDisclaimer';
import { Input } from '../../../../styledComponents/Input';
import {
  ACTIVATE_PRODUCTS_MUTATION,
  Data as Data2,
  Variables as Variables2,
} from '../../../../graphql/mutations/ActivateProductsMutation';
import { ToursSelect } from '../../../../components/dropdowns/ToursSelect';
import { Button } from '../../../../components/Button';
import { ProductSKU } from '../../../../graphql/globalTypes';
import { isMobileOnly, osName, osVersion } from 'react-device-detect';
import {
  // RECAPTCHA_ERROR,
  UNKNOWN_ERROR,
} from '../../../../consts';
import { captureInSentry } from '../../../config/reporting/captureInSentry';
import { nonNullable } from '../../../../utils/nonNullable';
import { EXPLORE_WEB_ActivateProductMutation_result_purchase_tour } from '../../../../graphql/mutations/__generated__/EXPLORE_WEB_ActivateProductMutation';
import { getDeviceID } from '../../../../globals/deviceID';

const RECAPTCHA_CONTEXT = 'ActivateProduct';

const getDevice = () => ({
  installationID: getDeviceID(),
  name: osName,
  os: osVersion,
});

let _variables: Variables1 | Variables2 | null = null;

const getVariables = () => _variables;

const setVariables = (variables: Variables1 | Variables2) => {
  _variables = variables;
};

interface Props {
  onSuccess: (
    redeemCodeUsed: string,
    activatedTourIDs: string[],
    tour?: EXPLORE_WEB_ActivateProductMutation_result_purchase_tour | null
  ) => void;
  codeFromDeeplink?: string | null;
  setActivateError: React.Dispatch<React.SetStateAction<boolean>>;
}

// TODO: need to refactor
export const ActivateProductView: React.FC<Props> = ({
  onSuccess,
  codeFromDeeplink,
  setActivateError,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>(codeFromDeeplink || '');
  const [error, setError] = useState<Error | null>(null);
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const [
    redeemableTours,
    setRedeemableTours,
  ] = useState<Array<RedeemableProduct> | null>(null);
  const [redeemableToursCount, setRedeemableToursCount] = useState<number>(0);
  const [selectedTours, setSelectedTours] = useState<
    Array<{ label: string; value: string }>
  >([]);

  useEffect(
    () => {
      if (error) {
        setActivateError(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error]
  );

  // first try to activate code.
  // if there is a second step, like user given the option to choose a specific set of tours,
  // we need the second mutation.
  const [activateProduct, { loading: loading1 }] = useMutation<
    Data1,
    Variables1
  >(ACTIVATE_PRODUCT_MUTATION, {
    fetchPolicy: 'no-cache',
    onError: (error: Error) => {
      Sentry.withScope((scope) => {
        scope.setExtra('variables', getVariables());
        Sentry.captureException(error);
      });

      setError(error);

      const errorMessage = error?.message || UNKNOWN_ERROR;
      toast.error(errorMessage, {
        autoClose: 5000,
        pauseOnHover: true,
        hideProgressBar: true,
        toastId: errorMessage,
      });
    },
    onCompleted: ({ result }) => {
      const {
        purchase,
        redeemableProducts,
        redeemableProductsCount,
        error,
      } = result;

      if (error?.type === 'AllRedeemableProductsAlreadyPurchased') {
        onSuccess(code, []);
      } else if (purchase?.product) {
        const activatedTourID = purchase.product.id;
        const tour = purchase.tour;

        onSuccess(code, [activatedTourID], tour);
      } else if (
        Array.isArray(redeemableProducts) &&
        redeemableProducts.length > 0
      ) {
        setRedeemableTours(redeemableProducts);

        if (typeof redeemableProductsCount === 'number') {
          setRedeemableToursCount(redeemableProductsCount);
        }
      } else if (
        Array.isArray(redeemableProducts) &&
        redeemableProducts.length === 0
      ) {
        const errorMessage = t(
          'Sorry, this code cannot activate any more tours.'
        );

        setError(new Error(errorMessage));

        captureInSentry(errorMessage, { variables: getVariables() });
      } else {
        const errorMessage = error?.message || UNKNOWN_ERROR;

        captureInSentry(errorMessage, { variables: getVariables() });

        setError(new Error(errorMessage));
      }
    },
  });

  // mutation to handle codes that support activate multiple products
  const [activateProducts, { loading: loading2 }] = useMutation<
    Data2,
    Variables2
  >(ACTIVATE_PRODUCTS_MUTATION, {
    fetchPolicy: 'no-cache',
    onError: (error: Error) => {
      Sentry.withScope((scope) => {
        scope.setExtra('variables', getVariables());
        Sentry.captureException(error);
      });

      setError(error);

      const errorMessage = error?.message || UNKNOWN_ERROR;

      toast.error(errorMessage, {
        autoClose: 5000,
        pauseOnHover: true,
        hideProgressBar: true,
        toastId: errorMessage,
      });
    },
    onCompleted: ({ result }) => {
      const { purchases, error } = result;

      if (error) {
        const errorMessage = error?.message || UNKNOWN_ERROR;

        captureInSentry(errorMessage, { variables: getVariables() });

        setError(new Error(errorMessage));
      } else {
        const activatedProductIDs = purchases
          .map((p) => p.product?.id)
          .filter(nonNullable);

        onSuccess(code, activatedProductIDs);
      }
    },
  });

  // auto activate if codeFromDeeplink is set
  useEffect(() => {
    if (codeFromDeeplink) {
      const variables: Variables1 = {
        input: {
          redeemCode: codeFromDeeplink,
          device: getDevice(),
        },
      };

      setVariables(variables);

      activateProduct({ variables });
    }
  }, [codeFromDeeplink, activateProduct]);

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const token: string | null = null;

    // if (executeRecaptcha) {
    //   try {
    //     token = await executeRecaptcha(RECAPTCHA_CONTEXT);
    //   } catch (error) {
    //     captureInSentry(error?.message || RECAPTCHA_ERROR, {
    //       recaptchaContext: RECAPTCHA_CONTEXT,
    //     });
    //   }
    // }

    handleSubmit(token);
  };

  const handleSubmit = (recaptchaToken: string | null) => {
    setError(null);

    const redeemCode = code.replace('-', '').trim();

    if (redeemableTours) {
      captureButtonPress({
        page: window.location.pathname,
        buttonName: 'ActivateTours',
      });

      const variables: Variables2 = {
        input: {
          redeemCode,
          products: selectedTours.map((t) => ({
            productSKU: ProductSKU.PLAYLIST,
            productID: t.value,
          })),
        },
      };

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      activateProducts({ variables });
    } else {
      captureButtonPress({
        page: window.location.pathname,
        buttonName: 'ActivateTour',
      });

      const variables: Variables1 = {
        input: {
          redeemCode,
          device: getDevice(),
        },
      };

      if (recaptchaToken) {
        variables.input.recaptchaToken = recaptchaToken;
      }

      setVariables(variables);

      activateProduct({ variables });
    }
  };

  const handleToursSelectChange = (
    tours: Array<{ label: string; value: string }>
  ) => {
    if (tours.length > redeemableToursCount) {
      const errorMessage = t(
        'You have reached the limit of tours you can activate with this redemption code.'
      );

      toast.error(errorMessage, {
        autoClose: 5000,
        pauseOnHover: true,
        hideProgressBar: true,
        toastId: errorMessage,
      });
    } else {
      setSelectedTours(tours);
    }
  };

  const loading = loading1 || loading2;

  let buttonName = t('Redeem');
  let tourActivationText = '';

  if (loading) {
    buttonName = t('Processing...');
  } else if (redeemableTours) {
    buttonName = t('Redeem selected tours');

    if (redeemableToursCount === 1 && redeemableTours.length === 1) {
      tourActivationText = t('Yay! This code can activate the following tour.');
    } else if (redeemableToursCount === redeemableTours.length) {
      tourActivationText = t(
        'Yay! This code can activate all of the following tours.'
      );
    } else {
      // TODO: [LVR-2521] i18n see how to use this without splitting
      tourActivationText = `${t(
        'Yay! This code can activate any '
      )}${redeemableToursCount}${t(' of the following tours.')}`;
    }
  }

  return (
    <Container>
      <form onSubmit={submitForm}>
        <Content>
          <Title>{t('Redeem Code')}</Title>

          {!codeFromDeeplink && (
            <>
              <p>{t('Got a redeem code for a tour? Great!')}</p>

              <p>
                {t(
                  'Enter the code below and click the REDEEM button to unlock your tour.'
                )}
              </p>
            </>
          )}

          <p>
            <strong>{t('Note')}</strong>:{' '}
            {t('Redeemed tours are valid for 6 months from redemption.')}
          </p>

          <Input
            autoFocus
            type="text"
            value={code}
            placeholder={t('please enter your code here')}
            disabled={Boolean(loading || redeemableTours)}
            hasError={Boolean(error)}
            onChange={(e) => {
              setError(null);
              setCode(e.target.value);
            }}
          />

          {/* Extract to separate component */}
          {Array.isArray(redeemableTours) && redeemableTours.length > 0 && (
            <>
              <p>
                <strong>{tourActivationText}</strong>
              </p>

              <p>
                {t(
                  'Please select the tours you would like to redeem, and click the REDEEM SELECTED TOURS button. The tours will be available from the My Tours page after redemption.'
                )}
              </p>

              <ToursSelect
                value={selectedTours}
                isDisabled={loading}
                onChange={handleToursSelectChange}
                closeMenuOnSelect={false}
                options={redeemableTours.map((t) => ({
                  label: t.productName,
                  value: t.productID,
                }))}
              />
            </>
          )}

          {error && <ErrorText>{error?.message || ''}</ErrorText>}

          <AuthButtonWithRecaptcha3
            uppercase
            context={RECAPTCHA_CONTEXT}
            disabled={loading || code.trim().length === 0}
            buttonName="ActivateProductButton"
          >
            {buttonName}
          </AuthButtonWithRecaptcha3>

          {redeemableTours && (
            <Button
              uppercase
              secondary
              onClick={() => {
                captureButtonPress({
                  page: window.location.pathname,
                  buttonName: 'UseADifferentCode',
                });

                setRedeemableTours(null);
              }}
            >
              {t('Use a different code')}
            </Button>
          )}

          {/* <ReCaptchaDisclaimer /> */}
        </Content>
      </form>
    </Container>
  );
};

const Container = styled.div`
  width: ${isMobileOnly ? '100%' : '300px'};
  margin: 0 auto;
`;

const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 16px;
  }
`;

const Title = styled.h4`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
`;
