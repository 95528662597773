import { getFetchPolicy } from '../../utils/getFetchPolicy';
import { RootState } from '../redux/types';
import {
  initialState,
  JobStatus,
  OfflineState,
  Playlist,
  QueueStatus,
} from './types';

export function selectOfflineState(state: RootState): OfflineState {
  return {
    ...initialState,
    ...state.offline,
  };
}

export function selectOfflinePlaylists(state: RootState): Array<Playlist> {
  return selectOfflineState(state).playlists;
}

type OfflinePlaylist = {
  jobStatus: JobStatus;
  progress: number | string;
  totalSize: number | string;
  cachedSize: number | string;
  remainingSize: number | string;
};

export function selectOfflinePlaylist(playlistID: string) {
  return function (state: RootState): OfflinePlaylist {
    let jobStatus = JobStatus.NONE;
    let progress: number | string = '0.0';
    let totalSize: number | string = 0;
    let cachedSize: number | string = 0;
    let remainingSize: number | string = 0;

    const offlinePlaylists = selectOfflinePlaylists(state);

    const offlinePlaylist = offlinePlaylists.find(
      (playlist) => playlist.id === playlistID
    );

    if (offlinePlaylist) {
      jobStatus = offlinePlaylist.jobStatus;

      const cachedSizeBytes = Math.min(
        offlinePlaylist.cachedSize,
        offlinePlaylist.totalSize
      );

      if (offlinePlaylist.totalSize > 0) {
        progress = (
          (cachedSizeBytes / offlinePlaylist.totalSize) *
          100
        ).toFixed(1);

        totalSize = (offlinePlaylist.totalSize / 1048576).toFixed(1);

        cachedSize = (cachedSizeBytes / 1048576).toFixed(1);

        remainingSize = (
          Math.max(offlinePlaylist.totalSize - cachedSizeBytes, 0) / 1048576
        ).toFixed(1);
      }
    }

    return {
      jobStatus,
      progress,
      totalSize,
      cachedSize,
      remainingSize,
    };
  };
}

export function selectOfflineQueueStatus(state: RootState): QueueStatus {
  return selectOfflineState(state).queueStatus;
}

interface ResyncPlaylistIDs {
  download: Playlist[];
  cleanup: Playlist[];
  recheck: Playlist[];
}

export function selectPlaylistIDsToResync(state: RootState): ResyncPlaylistIDs {
  const offlineState = selectOfflineState(state);
  const { playlists = [], queueStatus } = offlineState;

  if (queueStatus === QueueStatus.BUSY) {
    return { download: [], cleanup: [], recheck: [] };
  }

  const cleanup = playlists.filter(
    ({ jobStatus }) =>
      jobStatus === JobStatus.CLEANING_UP ||
      jobStatus === JobStatus.CLEANUP_ERROR
  );

  const download = playlists.filter(
    ({ jobStatus }) =>
      jobStatus === JobStatus.DOWNLOAD_ERROR ||
      jobStatus === JobStatus.DOWNLOADING
  );

  const recheck = playlists.filter(
    ({ jobStatus }) => jobStatus === JobStatus.DOWNLOAD_SUCCESS
  );

  return { download, cleanup, recheck };
}

export function selectCachedQueries(state: RootState): Record<string, number> {
  return selectOfflineState(state)?.cachedQueries || {};
}

export function selectFetchPolicy(
  queryID: string,
  cacheForMS: number = 3600000 // 1 hour
) {
  return function (state: RootState) {
    const cachedAt = selectCachedQueries(state)[queryID] || 0;

    return getFetchPolicy(cachedAt, cacheForMS, queryID);
  };
}
