// import ReactGA from 'react-ga';
import { APP_ID } from '../enums';
import { env } from '../env';

export function getTrackingID(): string {
  if (env.APP_ID === APP_ID.ANCIENT_WORLD) {
    return env.GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_WORLD_RTM;
  }

  if (env.APP_ID === APP_ID.ANCIENT_WORLD_STAGING) {
    return env.GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_WORLD_ALPHA_AND_BETA;
  }

  if (
    env.APP_ID === APP_ID.ABERCROMBIE_KENT ||
    env.APP_ID === APP_ID.OSTIA ||
    env.APP_ID === APP_ID.NOT_FAIR
  ) {
    // just testing
    return env.GOOGLE_ANALYTICS_TRACKING_ID_ANCIENT_JERUSALEM_RTM;
  }

  return 'UA-0000000-0';
}

// if (env.CAPTURE_EVENTS_IN_GOOGLE_ANALYTICS) {
//   const trackingID = getTrackingID();
//   const debug = !env.IS_RTM;
//
//   ReactGA.initialize(trackingID, { debug });
// }
