import { DEFAULT_LANGUAGE } from './../../../../consts';
import { LITHODOMOS_HEADERS } from '../../enums';
import { setContext } from '@apollo/client/link/context';
import { getDeviceID } from '../../../../globals/deviceID';
import { env } from '../../env';
import { getStore } from '../../../../store';

type LVRHeaders = {
  [LITHODOMOS_HEADERS.DEVICE_ID]: string;
  [LITHODOMOS_HEADERS.APP_VERSION]: string;
  [LITHODOMOS_HEADERS.APP_ID]?: string;
  [LITHODOMOS_HEADERS.TIMEZONE]?: string;
  [LITHODOMOS_HEADERS.LOCALE]?: string;
};

// These headers are required for communicating with the api.
export const createHeadersLink = () =>
  setContext((_, previousContext) => {
    const store = getStore();
    const state = store && store.getState();

    const jwt = state?.app.user?.jwt || null;
    const locale = state?.app.language || DEFAULT_LANGUAGE;

    const lvrHeaders: LVRHeaders = {
      [LITHODOMOS_HEADERS.LOCALE]: locale,
      [LITHODOMOS_HEADERS.DEVICE_ID]: getDeviceID(),
      [LITHODOMOS_HEADERS.APP_VERSION]: env.APP_VERSION,
      [LITHODOMOS_HEADERS.APP_ID]: env.APP_ID,
    };

    const headers = {
      ...previousContext.headers,
      ...lvrHeaders,
    };

    if (jwt) {
      headers.authorization = `Bearer ${jwt}`;
    } else {
      delete headers.authorization;
    }

    return { ...previousContext, headers };
  });
