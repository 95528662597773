import center from '@turf/center';
import { GoogleMapsLatLngLiteral } from '../types';
import { getPointFeatures } from './getPointFeatures';

export function calculateCenterOfPoints(
  positions: Array<GoogleMapsLatLngLiteral | null>
): GoogleMapsLatLngLiteral | null {
  const features = getPointFeatures(positions);
  const centerTurfPoint = center(features);
  const centreCoords = centerTurfPoint.geometry?.coordinates;

  if (centreCoords) {
    return {
      lng: centreCoords[0],
      lat: centreCoords[1],
    };
  }

  return null;

  // let x = 0.0;
  // let y = 0.0;
  // let z = 0.0;

  // for (let position of positions) {
  //   if (!position) {
  //     continue;
  //   }

  //   let latitude = (position.lat * Math.PI) / 180;
  //   let longitude = (position.lng * Math.PI) / 180;

  //   x += Math.cos(latitude) * Math.cos(longitude);
  //   y += Math.cos(latitude) * Math.sin(longitude);
  //   z += Math.sin(latitude);
  // }

  // let total = positions.length;

  // x = x / total;
  // y = y / total;
  // z = z / total;

  // let centralLongitude = Math.atan2(y, x);
  // let centralSquareRoot = Math.sqrt(x * x + y * y);
  // let centralLatitude = Math.atan2(z, centralSquareRoot);

  // return {
  //   lat: (centralLatitude * 180) / Math.PI,
  //   lng: (centralLongitude * 180) / Math.PI,
  // };
}
