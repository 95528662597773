import { gql } from '@apollo/client';
import { ASSET_FRAGMENT } from './Asset';
import { I18NSTRING } from './I18nString';
import { PRICE_FRAGMENT } from './Price';

export const PURCHASEABLE_TOUR_FRAGMENT = gql`
  fragment PurchaseableTourFragment on Playlist {
    id
    internalReference
    trial
    published
    features
    tags
    validityPeriodMonths
    trailLength
    timePeriod
    audioDurationMinutes
    price {
      ...PriceFragment
    }
    createdAt
    nameI18n {
      ...I18nStringFragment
    }
    taglineI18n {
      ...I18nStringFragment
    }
    descriptionI18n {
      ...I18nStringFragment
    }
    descriptionLongI18n {
      ...I18nStringFragment
    }
    cover {
      ...AssetFragment
    }
    thumbnail {
      ...AssetFragment
    }
    pastThumbnail {
      ...AssetFragment
    }
    presentThumbnail {
      ...AssetFragment
    }
    introVideo {
      id
      playlistFile {
        ...AssetFragment
      }
      videoSegments {
        ...AssetFragment
      }
    }
    guidedTourVideo {
      id
      playlistFile {
        ...AssetFragment
      }
      videoSegments {
        ...AssetFragment
      }
    }
    guidedTourComingSoon
    tourStartFOV
    tourStartCameraPitchAngle
    mapGeoJSON {
      ...AssetFragment
    }
    guide {
      id
      name
      bio
      location
      avatar {
        ...AssetFragment
      }
    }
    introNarrations(input: {}) {
      id
      language {
        code
      }
      voiceTrackLengthInSeconds
      voiceTrack {
        ...AssetFragment
      }
    }
    country {
      id
      name
    }
  }

  ${ASSET_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${I18NSTRING}
`;
